import React, { useEffect } from 'react'
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import PortfolioTab from "./PortfolioTab";
import CommonSliderTransitionBanner from '../component/common/CommonSliderTransitionBanner';

const SlideList = [
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--10',
        category: '',
        title: 'Portfolio',
        description: 'Matching development needs of B2B or B2C businesses, we offer robust web and mobile app development solutions that accelerate business profitability.',
        buttonText: 'Contact Us',
        buttonLink: '/contact-us'
    },
]
function usePageViewTracking(location) {
    useEffect(() => {
        window.gtag("config", "AW-16650476770", {
            page_title: location.pathname,
            page_path: location.pathname,
        });
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', location.pathname]);
        _hsq.push(['trackPageView']);
    }, [location]);
}

const Portfolio = (props) => {
    usePageViewTracking(props.location);
    return (
        <div>
            <Helmet pageTitle="Portfolio || Noorisys Technologies Pvt Ltd" pageUrl='/portfolio' metaTitle="Our portfolio is our certificate of excellence." metaImage="/assets/images/bg/bg-image-10.webp"

                metaDescription="See our portfolio and know why Noorisys is a certificate of excellence in software development. We offer robust web and mobile app development solutions to match the needs of B2B or B2C businesses and accelerate profitability." />
            {/* Start Header Area  */}
            <Header />
            {/* End Header Area  */}

            {/* Start Slider Area   */}
            {/* <div className="slider-wrapper color-white">
                <div className="slider-activation slider-digital-agency">
                    <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                        {SlideList.map((value, index) => (
                            <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="2">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title theme-gradient ">{value.title}</h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>

                </div>
            </div> */}
            <CommonSliderTransitionBanner slideList={SlideList} titleClass={'theme-gradient'} />
            {/* End Slider Area   */}

            {/* Start Designer Portfolio  */}
            <div className="designer-portfolio-area ptb--40 bg_color--1">
                <div className="wrapper plr--70 plr_sm--30 plr_md--30">
                    <PortfolioTab column="col-lg-4 col-md-6 col-sm-6 col-12" />
                </div>
            </div>
            {/* End Designer Portfolio  */}

            {/* Start Footer Style  */}
            <Footer />
            {/* End Footer Style  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

        </div>
    )
}

export default Portfolio
