
export const portfolioSlick = {
  dots: true,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [{
    breakpoint: 800,
    settings: {
      slidesToShow: 2,
    }
  },
  {
    breakpoint: 420,
    settings: {
      slidesToShow: 1,
    }
  }
  ]
};

export const portfolioSlick2 = {
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  responsive: [{
    breakpoint: 800,
    settings: {
      slidesToShow: 3,
    }
  },
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 3,
    }
  },
  {
    breakpoint: 993,
    settings: {
      slidesToShow: 2,
    }
  },
  {
    breakpoint: 769,
    settings: {
      slidesToShow: 2,
    }
  },
  {
    breakpoint: 481,
    settings: {
      slidesToShow: 1,
    }
  }
  ]
};

export const slickDot = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  responsive: [{
    breakpoint: 800,
    settings: {
      slidesToShow: 2,
    }
  },
  {
    breakpoint: 993,
    settings: {
      slidesToShow: 2,
    }
  },
  {
    breakpoint: 580,
    settings: {
      slidesToShow: 2,
    }
  },
  {
    breakpoint: 481,
    settings: {
      slidesToShow: 1,
    }
  },
  ]
};


export const slideSlick = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  arrows: true,
  fade: true,
  easing: "fade",
  adaptiveHeight: true
};

export const SlideImage = {
  loop: false,
  center: true,
  margin: 15,
  URLhashListener: true,
  owlDots: false,
  autoplayHoverPause: true,
  startPosition: 'URLHash',
  responsiveClass: false,
  nav: false,
  autoplay: false,
  mouseDrag: true,
  touchDrag: true,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 3,
    },
    992: {
      items: 3,
    },
    1124: {
      items: 3,
    },
    1440: {
      items: 4,
    }
  }
}
export const AppPortfolio = {
  loop: false,
  center: true,
  infinite: true,
  slidesToShow: 3,
  URLhashListener: true,
  owlDots: false,
  autoplay: true,
  autoplayHoverPause: true,
  // startPosition: 'URLHash',
  responsiveClass: false,
  nav: true,
  autoplay: false,
  mouseDrag: true,
  touchDrag: true,
  // initialSlide:-1,
  slidesToScroll: 1,
  arrows: false,
  responsive: [
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 3,
        centerPadding: '55px',
        margin: 20,
        //   initialSlide:1
      },
    },
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
        centerPadding: '50px',
        margin: 20,
        // initialSlide:1
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        centerPadding: '45px',
        margin: 20,
        // initialSlide:1
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        centerPadding: '40px',
        margin: 15
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        centerPadding: '50px',
        // initialSlide:1,
        margin: 5
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        centerPadding: '20px',
        margin: 5
      },
    },
  ],

}

// export const AdminPortfolio = {
//   center: true,
//   infinite: false,
//   slidesToShow: 1,
//   URLhashListener: true,
//   owlDots: false,
//   arrows: false,
//   autoplayHoverPause: true,
//   // startPosition: 'URLHash',
//   responsiveClass: false,
//   nav: true,
//   autoplay: false,
//   mouseDrag: true,
//   touchDrag: true,
//   // initialSlide:-1,
//   slidesToScroll: 1,
//   responsive: [
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       },
//     },
//     {
//       breakpoint: 1124,
//       settings: {
//         slidesToShow: 1,
//         slidesToScroll: 1,
//       },
//     },
//     {
//       breakpoint: 1440,
//       settings: {
//         slidesToShow: 1.5,
//         slidesToScroll: 1,
//       },
//     },
//   ],
// };
export const AdminPortfolio = {
  center: true,
  infinite: false,
  slidesToShow: 1,
  URLhashListener: true,
  owlDots: false,
  arrows: false,
  autoplayHoverPause: true,
  // startPosition: 'URLHash',
  responsiveClass: false,
  nav: true,
  autoplay: false,
  mouseDrag: true,
  touchDrag: true,
  // initialSlide:-1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1124,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
export const DownloadPortfolio = {
  center: true,
  slidesToShow: 1,
  slidesToScroll: false,
  owlDots: false,
  arrows: false
}
