import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";


class Flutter extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Flutter || Noorisys Technologies Pvt Ltd' pageUrl='/flutter' metaTitle="Flutter" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Create visually stunning and high-performance apps for Android and iOS with the expertise of Noorisys by using Flutter." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                {/* <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--25" data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Flutter</h2>
                                    <p>Google’s UI toolkit for building beautiful, natively compiled applications for mobile, web, and desktop from a single codebase.</p>
                                </div>
                                <br />
                                <div className="view-more-btn mt--10 text-center">
                                    <Link className="rn-button-style--2 btn-solid" to={"/contact-us"}><span>Hire Now</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <CommonBreadcrumb
                    title='Flutter'
                    dataBlackOverlay={5}
                    bgImage='bg_image--25'
                    buttonTitle={'Hire Now'}
                    buttonLink={'/contact-us'}
                    p={`Google’s UI toolkit for building beautiful, natively compiled applications for mobile, web, and desktop from a single codebase.`}
                />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper  */}
                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--80 bg_color--5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        <h4 className="title">Why Flutter ?</h4>
                                        <p className="justifypara">Paint your app to life in milliseconds with Stateful Hot Reload. Use a rich set of fully-customizable widgets to build native interfaces in minutes.</p>
                                        <p className="justifypara">Quickly ship features with a focus on native end-user experiences. Layered architecture allows for full customization, which results in incredibly fast rendering and expressive and flexible designs.</p>
                                        <p className="justifypara">Flutter’s widgets incorporate all critical platform differences such as scrolling, navigation, icons and fonts, and your Flutter code is compiled to native ARM machine code using Dart's native compilers.</p>
                                        <h4 className="title">Why choose Noorisys ?</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck />We are a team of designers, senior developers, usability architects, project managers and quality assurance testers.</li>
                                            <li className="justifypara"><FiCheck />Noorisys has a broad Flutter application development experience and are skilled at complete life cycle development of projects, from conception to the delivery of the apps.</li>
                                            <li className="justifypara"><FiCheck />We have huge expertise in developing bespoke apps that help you achieve the maximum return on investment.</li>
                                            <li className="justifypara"><FiCheck />We have 30+ successful mobile app development projects under our belt</li>
                                        </ul>
                                        <h4 className="title">Why hire Noorisys ?</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck />More than 30 Flutter apps.</li>
                                            <li className="justifypara"><FiCheck />Guaranteed approval on Play store.</li>
                                            <li className="justifypara"><FiCheck />Several years of experience in the industry.</li>
                                            <li className="justifypara"><FiCheck />Clean and modern UI designs.</li>
                                            <li className="justifypara"><FiCheck />On time delivery of projects.</li>
                                            <li className="justifypara"><FiCheck />Bug free app development.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}






                </main>
                {/* End Page Wrapper  */}




                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Flutter;