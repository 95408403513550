import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import ScrollToTop from 'react-scroll-up';
import Particles from "react-tsparticles";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ServiceDetails from "../creative_homePage/ServiceDetails";
import { name } from "file-loader";
import IndustriesClientReveiw from "./IndustriesClientReveiw";
// import VerbosSimilarApps from "../component/Verbos/VerbosSimilarApps";
const FinTechClient = React.lazy(() => import("./FinTechClient"));
const NoorisysVideo = React.lazy(() => import('./NoorisysVideo'));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'AI Based Fintech Solutions',
        description: 'Your search for an experienced software development team ends here.',
        buttonText: 'Contact Us',
        buttonLink: '/contact-us'
    }
]


const fintechtest = [
    {
        textPosition: 'inner text-center',
        category: 'I would like to thank Imran and his team for their quick response and professional attitude. I engaged Noorisys to redesign my website.',
        category1: 'I got the project on time and within the estimated budget. I have no hesitation in recommending their services to any company.',
        title: '',
        description: '',
        buttonText: '',
        buttonLink: '',
        name: '- AABID CS',
        img: '/assets/images/client/aabic_co.webp',
        alt: 'aabid cs'
    }
]

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

const data = [
    {
        title: 'Payment Wallet',
        description:
            'Online payment application is a free mobile financial services platform that allows students and professionals to hold and manage an electronic account, make real-time fund transfers, process any type of payment, access a large network of partners and participate in our loyalty program. This electronic money issuance activity promotes banking and financial inclusion, through access to financial services for all socio-professional categories.',
        features: [
            'Easy Registration',
            'Secure Online Payment.',
            'Quick Money Transfer.',
            'Withdrawal of money.',
            'Merchant payment.',
            'Accounts and wallet.',
            'Alerts and notifications.',
        ],
        image: '/assets/images/service/paytmwallet.webp',
        alt: 'payment wallet',
        h4: true
    },
    {
        title: 'Scholarship Distribution System',
        description: [
            `Scholarship Distribution System is a free mobile financial services platform that allows students to hold and manage an electronic account for receiving scholarships, make real-time fund transfers, process payment requests, and participate in the government's welfare program. Scholarship Distribution System offers quality services allowing students to carry out secure, simple and fast transactions.`
        ],
        features: [
            'Easy Student Registration.',
            'Secure Online Payment with Authentication.',
            'Money Transfer for Municipal Amenities.',
            'Quick Withdrawal Money.',
            'Merchant payment.',
            'Accounts and wallet.',
            'Alerts and notifications.',
        ],
        image: '/assets/images/service/scolarship_distribution.webp',
        alt: 'Scholarship distribution',
        h4: true
    },
    {

        title: 'Micro Finance Management',
        description: 'We have worked with Banking Sectors, Non Banking Financial Companies, Microfinance Organizations, Nidhi Companies and Financial Institutions. Our finance management services involve developing applications to manage loan distribution, collateral securities record, beneficiary details with updated CIBIL Score.',
        features: [
            'Seamless Flow with easy Finance Management.',
            'Secure Online Payment with Authentication.',
            'Document Scan, Upload and Verification.',
            'Quick Request Processing.',
            'EMI Calculator and Reminder Notification.',
            'Accounts and wallet.',
        ],
        image: '/assets/images/service/micro_finance_management.webp',
        alt: 'micro finance',
        h4: true
    },
]

const data2 = [
    {
        title: 'Data Security',
        noFeature: true,
        features: [
            'We understand the sensitive nature of data.',
            'Noorisys is an ISO 27001 compliant organisation.',
            'Your information and healthcare data is safe with us.',
            'Successful performance in each and every penetration testing.',
            'We have been assessed for GDPR compliance and policy frameworks.',
            'Regular audit by external agencies and assessment is done by stakeholders.',
            'Being an ISO certified organisation we handle data with much care and diligence.',
            'Strict Access Control Policy to protect any possible threat to data and information.',
        ],
        image: '/assets/images/service/service-14.webp',
        alt: 'data security',
        whatasApp: 'Schedule a call with our security expert',
        whatasAppText: 'schedule a call with security expert'
    },
    {
        title: 'All in One Solution',
        noFeature: true,
        features: [
            'What you exactly require is all here under one roof.',
            'Do not worry about the complex onboarding process.',
            'Skip the endless interviews, we have done that for you.',
            'An experienced team for you from the prominent institutes.',
            'Perfect team coordination, quick development and faster delivery.',
            'Our all in one solution reduces your efforts in project management.',
            'Hire a trustworthy and cost effective resource that speaks your language.',
            'No more new-hire paperwork, we already found and vetted the best talents.',
        ],
        image: '/assets/images/service/service-15.webp',
        alt: 'all in one solutions',
        whatasApp: 'Schedule a meeting with our Business Analyst',
        whatasAppText: 'schedule a call with business analyst'
    },
    {
        title: 'Trusted',
        noFeature: true,
        features: [
            'Trusted by the leading organisations in the world.',
            'Carrying the badge of trust for more than 11 years.',
            'Hired by semi-government and government agencies.',
            'Working with reputed brands of healthcare and fitness sector.',
            'Endorsed by more than 30 well known names of the healthcare industry.',
            'We believe in the professional ethics and cultural values driven by people.',
            'Our long journey and client history tells our good will status in the market.',
        ],
        image: '/assets/images/service/service-16.webp',
        alt: 'trusted',
        whatasAppText: 'schedule a call for partner with you',
        whatasAppButton: 'Partner with us today'
    },
]

class HomeParticles extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader() { }
    render() {
        // const PostList = BlogContent.slice(0, 5);

        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        return (
            <Fragment>
                <Helmet pageTitle="FinTech || Noorisys Technologies Pvt Ltd" pageUrl='/fintech' metaTitle="Fintech" metaImage="/assets/images/bg/bg-image-27.webp"
                    metaDescription="Stay ahead of the curve in the fintech industry with Noorisys. Our custom software solutions for banks, lending and insurance companies, and payment providers will help your business grow." />

                {/* Start Header Area  */}
                <Header headerPosition="header--static" logo="symbol-dark" color="color-black" />
                {/* End Header Area  */}

                {/* Start Slider Area   */}

                <div className="slider-activation slider-creative-agency with-particles" >
                    <div className="frame-layout__particles">
                        <Particles className="particle"
                            options={{
                                style: {
                                    position: "absolute"
                                },
                                fpsLimit: 100,
                                interactivity: {
                                    detectsOn: "canvas",
                                    events: {
                                        onClick: {
                                            enable: true,
                                            mode: "push",
                                        },
                                        onHover: {
                                            enable: true,
                                            mode: "repulse",
                                        },
                                        resize: true,
                                    },
                                    modes: {
                                        bubble: {
                                            distance: 100,
                                            duration: 2,
                                            opacity: 0.8,
                                            size: 10,
                                            color: "#888",
                                        },
                                        push: {
                                            quantity: 4,
                                        },
                                        repulse: {
                                            distance: 100,
                                            duration: 0.4,
                                            color: "#888",
                                        },
                                    },
                                },
                                particles: {
                                    color: {
                                        value: "#888",
                                    },
                                    links: {
                                        color: "#888",
                                        distance: 150,
                                        enable: true,
                                        opacity: 0.5,
                                        width: 1,
                                    },
                                    collisions: {
                                        enable: true,
                                    },
                                    move: {
                                        direction: "none",
                                        enable: true,
                                        outMode: "bounce",
                                        random: false,
                                        speed: 6,
                                        straight: false,

                                    },
                                    number: {
                                        density: {
                                            enable: true,
                                            value_area: 2000,
                                        },
                                        value: 80,
                                    },
                                    opacity: {
                                        value: 0.5,
                                    },
                                    shape: {
                                        type: "circle",
                                    },
                                    size: {
                                        random: true,
                                        value: 5,
                                    },
                                },
                                detectRetina: true,
                            }}
                        />
                    </div>
                    <div className="bg_image bg_image--27">
                        {SlideList.map((value, index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h2 className="title theme-gradient">{value.title}</h2> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--40">
                                <React.Suspense fallback={<div>loading...</div>} >
                                    <FinTechClient branstyle="branstyle--2" />
                                </React.Suspense>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}
                {/* Start Page Wrapper */}
                <ServiceDetails data={data} bgColor="bg_color--5" padding={`ptb--30`} />
                {/* End Page Wrapper */}

                <IndustriesClientReveiw Testimonial={fintechtest} bgColor={`bg_color--1`} />

                {/* Start Page Wrapper */}
                <ServiceDetails data={data2} bgColor="bg_color--5" padding={`ptb--30`} />
                {/* End Page Wrapper */}
                <React.Suspense fallback={<div>loading...</div>} >
                    <NoorisysVideo />
                </React.Suspense>

                <div className="portfolio-area ptb--40 bg_color--5">
                    <div className="pt--40">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <VerbosSimilarApps color='theme-gradient' title={'Healthcare Apps'} items={similarapps} slidestoshow={[3, 3]} />
                        </React.Suspense>
                    </div>
                </div>

                {/* Start Footer Style  */}
                <Footer />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}
export default HomeParticles;