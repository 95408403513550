import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonBlogBanner from "../component/common/CommonBlogBanner";

class LifiBlogDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Li-Fi || Noorisys Technologies Pvt Ltd' pageUrl='/li-fi-blog' metaTitle="Li-Fi: Everything You Need To Know" metaImage="/assets/images/blog/blog-06.webp"
                    metaDescription="Explore the latest trends and insights with our blog. Stay informed and up-to-date with our in-depth articles." />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBlogBanner date={'May 07, 2022'} titlehtml={'Li-Fi: Everything You <br /> Need To Know'} writer={'AFIFA ANSARI'} />
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <h3 className="rn-page-title theme-gradient">Li-Fi: Everything You Need To Know</h3>
                                        <p className="justifypara">Li-Fi stands for Light Fidelity. It is a technology for wireless communication that utilizes light, in the infrared and visible spectrum, for the transmission of data at high speeds. Li-Fi has been described as a fifth-generation (5G) communication technology.</p>
                                        <p className="justifypara">Li-Fi can be characterized as falling under the broad category of Visible Light Communication (VLC). The fact that Li-Fi allows bi-directional transmission of data, ie; data can be communicated from a light source to a device and back, is one feature that sets it apart from other subsets of VLC.</p>
                                        <p className="justifypara">The space for conventional radio frequencies is becoming oversaturated, resulting in what is known as the Spectrum Crunch – the possible lack of enough wireless frequency spectrum needed to assist the thriving number of consumer devices. Eventually, radiofrequency technology like Wi-Fi will no longer keep up with demand.</p>
                                        <p className="justifypara">However, Li-Fi does not use radio frequencies as it uses the light spectrum, which can open up one thousand times more spectrum than radio. It would be an integral part of the future of wireless networks such as 5G. Li-Fi a world with unprecedented data and bandwidth.</p>
                                        <h4 className="title">How Does Li-Fi Work?</h4>
                                        <p className="justifypara">Li-Fi is a form of VLC, so by definition, it has a transmitter and a receiver and uses light for the transmission of data. Li-Fi networks are bi-directional, which means that an uplink and a downlink can both be formed simultaneously between the transmitter and receiver. This is what makes it so suitable for internet communication. It is to be noted that Li-Fi isn’t a different way of getting internet, it’s a different way of deploying internet.</p>
                                        <p className="justifypara">The two main components of a Li-Fi network are-</p>
                                        <ul className="list-style">
                                            <li>LED Light Source</li>
                                            <p className="justifypara">Light sources, including light fixtures already being used for illumination, as well as special light sources particularly made for the purpose, are used for the transmission of data in Li-Fi. LED lights are most suited for data transmission because of their reliability and efficiency.</p>
                                            <p className="justifypara">Infrared detectors to detect signals from devices are also present to make both uplink and downlink possible. The light fixtures act as an access point for data and can be powered and supplied with data through methods like Power over Ethernet (POE) or Power-Line Communication (PLC).</p>
                                            <p className="justifypara">The light source emits pulses of light for transmitting data, which can then be captured and decoded by a receiver. So wouldn’t it be creepy to have flickering lamps all over the place? Not really. The thing is, the pulses would be emitted at a frequency of more than a million times per second, which is just too quick for our eyes to notice.</p>
                                            <p className="justifypara">The lights don't necessarily need to be excessively bright either. In fact, they can be dimmed so low that they fall below the visible spectrum, and still serve their purpose. So just because you turn out the light in your bedroom to sleep, doesn't mean you need to cut off your internet connection too.</p>
                                            <li>Li-Fi Enabled Devices</li>
                                            <p className="justifypara">A Li-Fi enabled device has a receiver, which is a photodiode for detecting the signal contained within the light emitted by the transmitter. The detector captures the modulated pulses and converts them into data much like translating morse code. To enable the flow of data in both directions, the devices are also equipped with infrared transmitters for sending data back up to the light source.</p>
                                        </ul>
                                        <h4 className="title">Li-Fi Features:</h4>
                                        <ul className="list-style">
                                            <li>1.Two-way</li>
                                            <li>2.Fully duplex</li>
                                            <li>3.Hi-Speed</li>
                                        </ul>
                                        <br />  <p className="justifypara">With Li-Fi, data is transmitted and received at the same time, resulting in a really fast reliable user experience, especially when doing things like Skype calls, video streaming, and virtual reality.</p>
                                        <p className="justifypara">Moreover, Li-Fi is fully networked, which means all the lights in your Li-Fi network can speak to each other without interrupting your internet session. Each light even has its IP address, which means that you can offer location tracking and geofencing within your network.</p>
                                        <h4 className="title">Applications of Li-Fi</h4>
                                        <p className="justifypara">Our future may be Li-Fi suffused, where there is data flowing through every street lamp on the road and even the light in your bathrooms. But right now Li-Fi is being employed more for particular applications, where the particular features of Li-Fi have proven advantageous.</p>
                                        <p className="justifypara">Let’s look at some of the applications of Li-Fi that merit attention-</p>

                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="thumbnail">
                                                <img className="w-100" src="/assets/images/blog/blog-single-06.webp" alt="lifi bLog" loading="lazy" />
                                            </div>
                                            <div className="content">
                                                <ul className="list-style">
                                                    <br />      <li>Hospitals and Aviation:</li>
                                                    <p className="justifypara">Li-Fi is immune to electromagnetic interference. This makes it ideal for applications in hospitals, as Li-Fi does not interfere with devices that operate using radio frequencies. The speed and ubiquitousness of Li-Fi also help in real-time patient monitoring, seamless communication, etc. Similarly, in aircraft, the use of Li-Fi for communication is beneficial as there will be no interference to the radar system or other devices operating on radiofrequency. Providing internet connectivity in flights is also something that is greatly encouraging to passengers.</p>

                                                    <li>Defence, R&D, and Finance Sectors:</li>
                                                    <p className="justifypara"> Li-Fi provides a level of security that is crucial for these sectors and hence is being increasingly employed in them.</p>
                                                    <li>Schools and Offices:</li>
                                                    <p className="justifypara">The ability of Li-Fi to provide high speeds even in high-density areas makes it optimal for places like universities and office buildings, where everyone is using wireless communication all the time.</p>
                                                </ul>

                                            </div>
                                        </div>
                                        <br /> <ul className="list-style">
                                            <li>Smart Homes:</li>
                                            <p className="justifypara">The fully automated smart homes of the future would have internet connectivity in every nook and cranny, connecting devices all over the house, forming a complete ecosystem within four walls. The use of Li-Fi can help in ensuring security, interconnectedness, and ubiquitous accessibility within the smart homes of the future.</p>
                                            <li>Self Driving Cars:</li>
                                            <p className="justifypara">LiFi will turn out to be crucial for self-driving cars. Cars already use LED lights in their headlights and brake-lights and can be easily designed to use this to communicate with each other. This will help in preventing accidents, diverting traffic, and avoiding breaches of traffic rules. This is perfect for remote highway stretches where there is no WiFi signal. Cars can simply send and receive data using their own lights. Street lights and traffic signals can also be integrated in the system to form a secure traffic system that talks to each other without human intervention.</p>
                                            <li>Deep Sea Exploration:</li>
                                            <p className="justifypara">LiFi presents exciting opportunities for sea exploration. Currently, the capacity of rovers is limited by the cables. WiFi cannot be used in deep-sea as radio waves get absorbed in water. Visible light on the other hand can travel in water. It can be used to communicate between divers, remote control vehicles, and base ships.</p>
                                        </ul>
                                        <p className="justifypara">LiFi technology makes use of visible light to transmit data wirelessly. With its unlimited spectrum capacity, security, and high speed it is set to be an alternative to WiFi. However, WiFi has its own set of advantages and because of this,  LiFi may end up complementing WiFi rather than completely replacing it.</p>
                                        <p className="justifypara">LiFi has potential for unlimited applications and it’s just a matter of when the technology is made commercially viable. As of today, LiFi seems more suitable for industrial use.</p>
                                        <p className="justifypara">Industries can buy LiFi receivers in bulk and design a layout for maximum LED access by smart devices. Penetrating households might be something that will take time for this innovative technology.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default LifiBlogDetails;