import React, { useEffect } from 'react';
import ScrollToTop from 'react-scroll-up';
import FooterTwo from "../component/footer/FooterTwo";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import Particles from 'react-tsparticles';
import CommonSliderBanner from '../component/common/CommonSliderBanner';

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Noorisys Technologies',
        description: 'Our Journey',
        buttonText: 'Contact Us',
        buttonLink: '/contact-us'
    }
]

const PortfolioList = [
    {
        images: '31',
        category: 'Mumbai',
        title: 'Mumbai office inauguration'
    },
    {
        images: '32',
        category: 'Mumbai',
        title: 'Mumbai office inauguration'
    },
    {
        images: '33',
        category: 'Mumbai',
        title: 'Mumbai office inauguration'
    },
    {
        images: '34',
        category: 'Mumbai',
        title: 'Mumbai office inauguration'
    },
    {
        images: '35',
        category: 'Mumbai',
        title: 'Mumbai office inauguration'
    },
    {
        images: '36',
        category: 'Mumbai',
        title: 'Mumbai office inauguration'
    },
    {
        images: '38',
        category: 'Mumbai',
        title: 'Mumbai office inauguration'
    },
    {
        images: '37',
        category: 'Mumbai',
        title: 'Mumbai office inauguration'
    },
    {
        images: '39',
        category: 'Annual Party',
        title: 'Office Annual party'
    },
    {
        images: '40',
        category: 'Annual Party',
        title: 'Office Annual party'
    },
    {
        images: '41',
        category: 'Annual Party',
        title: 'Office Annual party'
    },
    
    {
        images: '42',
        category: 'Exhibition',
        title: 'GITEX'
    },
    {
        images: '43',
        category: 'Exhibition',
        title: 'GITEX'
    },
    {
        images: '44',
        category: 'Exhibition',
        title: 'GITEX'
    },
    {
        images: '45',
        category: 'Dubai Expo',
        title: 'Dubai Gitex'
    },
    {
        images: '46',
         category: 'Dubai Expo',
        title: 'Dubai Gitex'
    },
    {
        images: '47',
         category: 'Dubai Expo',
        title: 'Dubai Gitex'
    },
    {
        images: '48',
         category: 'Dubai Expo',
        title: 'Dubai Gitex'
    },
    {
        images: '49',
         category: 'Dubai Expo',
        title: 'Dubai Gitex'
    },
    {
        images: '50',
         category: 'Dubai Expo',
        title: 'Dubai Gitex'
    },
    {
        images: '51',
         category: 'Dubai Expo',
        title: 'Dubai Gitex'
    },
    

    {
        images: '6',
        category: 'Freelancer',
        title: 'You can see your Portfolio'
    },
    {
        images: '4',
        category: 'Developer',
        title: 'The design is Thinking'
    },
    {
        images: '5',
        category: 'Freelancer',
        title: 'The portfolio For Frelancer'
    },

    // {
    //     images: '9',
    //     category: 'Freelancer',
    //     title: 'Getting tickets to the big show'
    // },
    {
        images: '1',
        category: 'Freelancer',
        title: 'The new Thinking for Design'
    },
    {
        images: '2',
        category: 'Freelancer',
        title: 'The new Thinking for Design'
    },
    {
        images: '3',
        category: 'Freelancer',
        title: 'Getting tickets to the big show'
    },


    {
        images: '8',
        category: 'Freelancer',
        title: 'You can see your Portfolio'
    },

    {
        images: '10',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        images: '9',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        images: '7',
        category: 'Freelancer',
        title: 'The Language of Developer'
    },
    {
        images: '12',
        category: 'Freelancer',
        title: 'The Language of Developer'
    },
    {
        images: '13',
        category: 'Freelancer',
        title: 'The Language of Developer'
    },
    {
        images: '14',
        category: 'Freelancer',
        title: 'The Language of Developer'
    },
    {
        images: '15',
        category: 'Freelancer',
        title: 'The Language of Developer'
    },
    {
        images: '17',
        category: 'Freelancer',
        title: 'The Language of Developer'
    },

    {
        images: '11',
        category: 'Freelancer',
        title: 'The Language of Developer'
    },
    {
        images: '18',
        category: 'Freelancer',
        title: 'The Language of Developer'
    },
    {
        images: '19',
        category: 'Freelancer',
        title: 'The Language of Developer'
    },
    {
        images: '20',
        category: 'Freelancer',
        title: 'The Language of Developer'
    },
    {
        images: '21',
        category: 'Freelancer',
        title: 'The Language of Developer'
    },
    {
        images: '22',
        category: 'Freelancer',
        title: 'The Language of Developer'
    },
    {
        images: '23',
        category: 'Freelancer',
        title: 'The Language of Developer'
    }, {
        images: '24',
        category: 'Freelancer',
        title: 'The Language of Developer'
    }, {
        images: '25',
        category: 'Freelancer',
        title: 'The Language of Developer'
    }, {
        images: '26',
        category: 'Freelancer',
        title: 'The Language of Developer'
    }, {
        images: '27',
        category: 'Freelancer',
        title: 'The Language of Developer'
    }, {
        images: '28',
        category: 'Freelancer',
        title: 'The Language of Developer'
    }, {
        images: '29',
        category: 'Freelancer',
        title: 'The Language of Developer'
    }, {
        images: '30',
        category: 'Freelancer',
        title: 'The Language of Developer'
    },
]
function usePageViewTracking(location) {
    useEffect(() => {
        window.gtag("config", "G-F2DRSMH4G3", {
            page_title: location.pathname,
            page_path: location.pathname,
        });
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', location.pathname]);
        _hsq.push(['trackPageView']);
    }, [location]);
}

const CreativePortfolio = (props) => {
    usePageViewTracking(props.location);

    return (
        <div className="creative-portfolio">
            <Helmet pageTitle="Gallery || Noorisys Technologies Pvt Ltd" pageUrl="/gallery" metaTitle="Gallery" metaImage="/assets/images/bg/bg-image-27.jpg"
                metaDescription="Get a glimpse of our team and client interactions. Our gallery showcases our journey so far." />

            {/* Start Header Area  */}
            <Header headerPosition="header--static" logo="symbol-dark" color="color-black" />
            {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
            {/* <Header headerPosition="header--static" logo="symbol-dark" color="color-black"/> */}
            {/* End Header Area  */}

            {/* Start Slider Area   */}

            <div className="slider-activation slider-creative-agency with-particles" >
                <div className="frame-layout__particles">
                    <Particles className="particle"
                        options={{
                            style: {
                                position: "absolute"
                            },
                            fpsLimit: 100,
                            interactivity: {
                                detectsOn: "canvas",
                                events: {
                                    onClick: {
                                        enable: true,
                                        mode: "push",
                                    },
                                    onHover: {
                                        enable: true,
                                        mode: "repulse",
                                    },
                                    resize: true,
                                },
                                modes: {
                                    bubble: {
                                        distance: 100,
                                        duration: 2,
                                        opacity: 0.8,
                                        size: 10,
                                        color: "#888",
                                    },
                                    push: {
                                        quantity: 4,
                                    },
                                    repulse: {
                                        distance: 100,
                                        duration: 0.4,
                                        color: "#888",
                                    },
                                },
                            },
                            particles: {
                                color: {
                                    value: "#888",
                                },
                                links: {
                                    color: "#888",
                                    distance: 150,
                                    enable: true,
                                    opacity: 0.5,
                                    width: 1,
                                },
                                collisions: {
                                    enable: true,
                                },
                                move: {
                                    direction: "none",
                                    enable: true,
                                    outMode: "bounce",
                                    random: false,
                                    speed: 6,
                                    straight: false,

                                },
                                number: {
                                    density: {
                                        enable: true,
                                        value_area: 2000,
                                    },
                                    value: 80,
                                },
                                opacity: {
                                    value: 0.5,
                                },
                                shape: {
                                    type: "circle",
                                },
                                size: {
                                    random: true,
                                    value: 5,
                                },
                            },
                            detectRetina: true,
                        }}
                    />
                </div>
                {/* <div className="bg_image bg_image--27">
                    {SlideList.map((value, index) => (
                        <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div> */}
                <CommonSliderBanner
                    classes={'slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center bg_image bg_image--27'}
                    slideList={SlideList}
                    col={'col-lg-12'}
                    titleClass={'theme-gradient'}
                />
            </div>
            {/* End Slider Area   */}

            {/* Start Portfolio Area  */}
            <div className="creative-portfolio-wrapper bg_color--1 pt--10">
                <div className="creative-portfolio-wrapper plr--10">
                    {/* <div className='container p-5'> */}
                    <div className="row row--5">
                        {PortfolioList.map((value, i) => (
                            <div className="col-lg-4 col-md-6 col-12" key={i}>
                                <div className="portfolio-style--3">
                                    <div className="thumbnail">
                                        {/* <Link to="#"> */}
                                        <img className="w-100" src={`/assets/images/gallery/resized-${value.images}.webp`} alt={`gallery-${value.images}`} loading='lazy' />
                                        {/* </Link> */}
                                    </div>

                                </div>
                            </div>
                        ))}
                    </div>
                    {/* </div> */}
                </div>
            </div>
            {/* End Portfolio Area  */}

            {/* Start Footer Style  */}
            <FooterTwo />
            {/* End Footer Style  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </div>
    )
}

export default CreativePortfolio;