import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

// const SocialShare =[
//     {Social:<FaGlobe />,link:' https://annachi.fr/ '}
// ]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'PHP', p: 'Admin Panel', img: 'php.png' },
    { h5: 'CodeIgniter', p: 'Backend Development', img: 'ci4.png' },
    { h5: 'Mysql', p: 'Database', img: 'mysql.png' },
    // { h5: 'Native  Android', p: 'App Development', img: 'native.png' },
]

const adminButtonNames = ['Dashboard', 'Manage Providers', 'Booking Details', 'Create Sub Admin'];
const adminImageAddress = [
    '/assets/images/portfolio/btk/admin/admin-01.webp',
    '/assets/images/portfolio/btk/admin/admin-02.webp',
    '/assets/images/portfolio/btk/admin/admin-03.webp',
    '/assets/images/portfolio/btk/admin/admin-04.webp',
];

const userButtonNames = ['Home', 'Register', 'Login', 'Tour Home', 'Profile', 'Tour Details', 'My Cart Tour', 'Tour Booking', 'Discover Places', 'Place Details', 'Blogs', 'Blog Details', 'Artist', 'Artist List', 'Artist Cart', 'Artist Order'];
const userImageAddress = [
    '/assets/images/portfolio/btk/user/user-01.webp',
    '/assets/images/portfolio/btk/user/user-02.webp',
    '/assets/images/portfolio/btk/user/user-03.webp',
    '/assets/images/portfolio/btk/user/user-04.webp',
    '/assets/images/portfolio/btk/user/user-05.webp',
    '/assets/images/portfolio/btk/user/user-06.webp',
    '/assets/images/portfolio/btk/user/user-07.webp',
    '/assets/images/portfolio/btk/user/user-08.webp',
    '/assets/images/portfolio/btk/user/user-09.webp',
    '/assets/images/portfolio/btk/user/user-10.webp',
    '/assets/images/portfolio/btk/user/user-11.webp',
    '/assets/images/portfolio/btk/user/user-12.webp',
    '/assets/images/portfolio/btk/user/user-13.webp',
    '/assets/images/portfolio/btk/user/user-14.webp',
    '/assets/images/portfolio/btk/user/user-15.webp',
    '/assets/images/portfolio/btk/user/user-16.webp',
];

const providersButtonNames = ['Register', 'Profile', 'Home', 'Tour Details', 'Booking List', 'Booking Details', 'Surplus Request'];
const providersImageAddress = [
    '/assets/images/portfolio/btk/provider/provider-01.webp',
    '/assets/images/portfolio/btk/provider/provider-02.webp',
    '/assets/images/portfolio/btk/provider/provider-03.webp',
    '/assets/images/portfolio/btk/provider/provider-04.webp',
    '/assets/images/portfolio/btk/provider/provider-05.webp',
    '/assets/images/portfolio/btk/provider/provider-06.webp',
    '/assets/images/portfolio/btk/provider/provider-07.webp',
];
class BTKPortfolio extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='BTK' pageUrl='/btk' metaTitle="Digital Solution For Civil Documents" metaImage="/assets/images/portfolio/dp-portfolio-51.webp"
                    metaDescription="A comprehensive solution that seamlessly incorporates all the essential features for managing a successful food delivery business. From multi-language support and precise coverage area mapping to streamlined order management and versatile payment gateways, our app has it all" />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="BTK"
                    customColor="custom-matness-portfolio"
                    dataBlackOverlay={5}
                    bgImage="bg_image--112"
                    p="Web Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h4><span>TECHNICAL DETAILS</span></h4> */}
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-matness-portfolio">BTK</h3>
                                        <p className="justifypara">This is a comprehensive platform designed to facilitate seamless interactions between users, service providers and artists. Offering a user-friendly experience, it features many modules, including a marketplace for tour packages, artists, music and local listings. </p>
                                        <h4 className="title">Features</h4>
                                       <ul className="list-style--1">
                                            <li><FiCheck color="green" />Tour packages</li>
                                            <li><FiCheck color="green" />Artist marketplace</li>
                                            <li><FiCheck color="green" />Music marketplace</li>
                                            <li><FiCheck color="green" />Basket management</li>
                                            <li><FiCheck color="green" />Multilingual support</li>
                                            <li><FiCheck color="green" />Robust payment and billing system</li>
                                            <li><FiCheck color="green" />Stripe integration</li>
                                            <li><FiCheck color="green" />Cash on delivery</li>
                                            <li><FiCheck color="green" />Manage bookings</li>
                                            <li><FiCheck color="green" />Manage orders</li>
                                            <li><FiCheck color="green" />Newsletter subscriptions</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-btk'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-matness-portfolio' items={technologies} />
                        </React.Suspense>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-btk'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='User'
                                    p={`Users can seamlessly explore and engage with a diverse range of tour packages, artist creations, music albums, and local listings while enjoying the convenience of country-specific customization and personalized notifications.`}
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt="BTK user"
                                    color='btk'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-btk'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Provider'
                                    p={`The tour providers can effortlessly showcase, manage, and offer diverse tour packages to a global audience, ensuring a seamless experience for providers and travellers.`}
                                    buttonNames={providersButtonNames}
                                    image={providersImageAddress}
                                    alt="BTK provider"
                                    color='btk'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-btk'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin'
                                    p={`The admin oversees the comprehensive management of users, providers, bookings, orders, commission, event calendar, and newsletter subscriptions, ensuring the smooth operation and growth of the platform.`}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt="BTK admin"
                                    color='btk'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-btk'} paddingTop={'pt--30'} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        {/* <VerbosSimilarApps color='custom-food-delivery-product' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} /> */}
                        <VerbosSimilarApps color='custom-matness-portfolio' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-btk' text='View All Our Projects' />

                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default BTKPortfolio;
