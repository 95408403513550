import React, { Component } from "react";
import { FiLayers, FiUsers } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import { FaMobileAlt } from "react-icons/fa";
// import { CgWebsite } from "react-icons/cg";


const ServiceList = [
    {
        icon: <FaMobileAlt />,
        title: 'Reseller Partnerships',
        description: ' As a reseller partner, you will have access to our portfolio of services and will be able to offer them to your clients under your brand name. Leverage our expertise to offer high-quality services to your clients, while maintaining complete control over your brand and client relationships.',
        url: '/mobile-development'
    },
    {
        icon: <FiLayers />,
        title: 'Referral Partnerships',
        description: 'The referral partnership model is commission-based, meaning that you will earn a percentage of the revenue generated from the clients you refer to us. We value the trust and confidence that our partners place in us, and we are committed to ensuring that you are well-compensated for every referral.',
        url: '/website-development'
    },
    {
        icon: <FiUsers />,
        title: 'Resource Partnerships',
        description: 'Partnerships where you can hire our experienced and skilled developers, designers, and project managers to work on your in-house projects. This model allows you to leverage our expertise and talent pool while maintaining complete control over your project development process.',
        url: '/support-services'
    },
    // { 
    //     icon: <FiMonitor />,
    //     title: 'Mobile App Development',
    //     description: 'Get all kinds of customer support services under one roof with specialized skill sets, natural warmth, and customer service orientation.'
    // },
    // {
    //     icon: <FiUsers />,
    //     title: 'Marketing & Reporting',
    //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    // },
    // { 
    //     icon: <FiMonitor />,
    //     title: 'Mobile App Development',
    //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    // }
]


class Partner_Service extends Component {
    render() {
        const { column } = this.props;
        const ServiceContent = ServiceList.slice(0, this.props.item);

        return (
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map((val, i) => (
                        <div className={`${column}`} key={i}>
                            {/* <Link to={val.url}> */}
                            <div className="service service__style--2">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h3 className="title">{val.title}</h3>
                                    <p className="justifypara">{val.description}</p>
                                </div>
                            </div>
                            {/* </Link> */}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default Partner_Service;
