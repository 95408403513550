import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

// const SocialShare =[
//     {Social:<FaGlobe />,link:' https://annachi.fr/ '}
// ]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'PHP', p: 'Admin Panel', img: 'php.png' },
    { h5: 'CodeIgniter', p: 'Backend Development', img: 'ci4.png' },
    { h5: 'Mysql', p: 'Database', img: 'mysql.png' },
    { h5: 'Flutter', p: 'App Development', img: 'flutter.png' },
]

const adminButtonNames = ['Login', 'Dashboard', 'Registered Businesses', 'Business Details', 'Update Service', 'All Customer List'];
const adminImageAddress = [
    '/assets/images/portfolio/queue-management/admin/admin-01.webp',
    '/assets/images/portfolio/queue-management/admin/admin-02.webp',
    '/assets/images/portfolio/queue-management/admin/admin-03.webp',
    '/assets/images/portfolio/queue-management/admin/admin-04.webp',
    '/assets/images/portfolio/queue-management/admin/admin-05.webp',
    '/assets/images/portfolio/queue-management/admin/admin-06.webp',
];

const userButtonNames = ['Home', 'Register', 'Login', 'Tour Home', 'Profile', 'Tour Details'];
const userImageAddress = [
    '/assets/images/portfolio/queue-management/user/user-01.webp',
    '/assets/images/portfolio/queue-management/user/user-02.webp',
    '/assets/images/portfolio/queue-management/user/user-03.webp',
    '/assets/images/portfolio/queue-management/user/user-04.webp',
    '/assets/images/portfolio/queue-management/user/user-05.webp',
    '/assets/images/portfolio/queue-management/user/user-06.webp',
];

const businessOwnerButtonNames = ['Register', 'Dashboard', 'Manage Services', 'Manage Queue', 'Add Service', 'Update Service', 'Add Customer', 'Edit Profile', 'Forgot Password'];
const businessOwnerImageAddress = [
    '/assets/images/portfolio/queue-management/business-owner/business-owner-01.webp',
    '/assets/images/portfolio/queue-management/business-owner/business-owner-02.webp',
    '/assets/images/portfolio/queue-management/business-owner/business-owner-03.webp',
    '/assets/images/portfolio/queue-management/business-owner/business-owner-04.webp',
    '/assets/images/portfolio/queue-management/business-owner/business-owner-05.webp',
    '/assets/images/portfolio/queue-management/business-owner/business-owner-06.webp',
    '/assets/images/portfolio/queue-management/business-owner/business-owner-07.webp',
    '/assets/images/portfolio/queue-management/business-owner/business-owner-08.webp',
    '/assets/images/portfolio/queue-management/business-owner/business-owner-09.webp',
];
class QueueManagementPortfolio extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Queue Management' pageUrl='/queue-management' metaTitle="Digital Solution For Civil Documents" metaImage="/assets/images/portfolio/dp-portfolio-51.webp"
                    metaDescription="A comprehensive solution that seamlessly incorporates all the essential features for
managing a successful food delivery business. From multi-language support and precise
coverage area mapping to streamlined order management and versatile payment gateways,
our app has it all" />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Queue Management"
                    customColor="custom-queue-management-portfolio"
                    dataBlackOverlay={5}
                    bgImage="bg_image--113"
                    p="Web/Android/IOS Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h4><span>TECHNICAL DETAILS</span></h4> */}
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-queue-management-portfolio">Queue Management</h3>
                                        <p className="justifypara">This is a comprehensive platform designed to facilitate seamless Queue Management. Users can seamlessly join queues by scanning QR codes, receive real-time updates on queue status, and get alerts from business owners.</p>
                                        <h3 className="custom-queue-management-portfolio">Customer Features</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#586bbc" />Join the Queue</li>
                                            <li><FiCheck color="#586bbc" />Scan QR Code and Join the Queue</li>
                                            <li><FiCheck color="#586bbc" />Get Queue Updates</li>
                                            <li><FiCheck color="#586bbc" />Receive Business Person Alerts</li>
                                            <li><FiCheck color="#586bbc" />Cancel and Exit Queue</li>
                                        </ul>
                                        <h3 className="custom-queue-management-portfolio">Business Owner Features</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#586bbc" />Login and Profile Management</li>
                                            <li><FiCheck color="#586bbc" />Dashboard with Statistics</li>
                                            <li><FiCheck color="#586bbc" />QR Code Generation</li>
                                            <li><FiCheck color="#586bbc" />Queue Management</li>
                                            <li><FiCheck color="#586bbc" />Priority for Emergency Users</li>
                                            <li><FiCheck color="#586bbc" />Send Alerts to Customers</li>
                                            <li><FiCheck color="#586bbc" />Subscription Management</li>
                                            <li><FiCheck color="#586bbc" />Monthly Renewals and Invoicing</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-queue'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-queue-management-portfolio' items={technologies} />
                        </React.Suspense>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-queue'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User'
                                    p={`Users can seamlessly join queues by scanning QR codes, receive real-time updates on queue status, and get alerts from business owners.`}
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt="BTK user"
                                    color='queue-management'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-queue'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Business Owner'
                                    p={`Business owners benefit from a user-friendly dashboard displaying key statistics, QR code generation, queue management, priority for emergencies, and subscription management with monthly renewals and invoicing.`}
                                    buttonNames={businessOwnerButtonNames}
                                    image={businessOwnerImageAddress}
                                    alt="BTK provider"
                                    color='queue-management'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-queue'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin'
                                    p={`The admin panel facilitates business profile management, subscription oversight, and multilingual support in English, French, and Arabic. Overall, the platform ensures efficient and organized queuing processes for enhanced customer and business owner experiences.`}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt="BTK admin"
                                    color='queue-management-admin'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-queue'} paddingTop={'pt--30'} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-queue-management-portfolio' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-queue' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default QueueManagementPortfolio;
