import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonProductWeb from "../portfolio/CommonProductWeb";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'Angulr', p: 'Admin Panel', img: 'angular.png' },
    { h5: 'Node JS', p: 'Backend Development', img: 'node.png' },
    { h5: 'MongoDB', p: 'Database', img: 'mongo.png' },
    { h5: 'Angular', p: 'Doctor Panel', img: 'angular.png' },
]

const adminButtonNames = ['Login', 'Dashboard', 'Doctor List', 'Doctor Document Verification', 'Assistant Onboarding', 'Details Of Doctor', 'Primary Care', 'Add Primary Care', 'Secondary Care', 'Add Secondary Care', 'Patients', 'Death Reports', 'Problems Reports', 'Allergy Reports', 'View Clinical Notes', 'List Of Clinical Notes'];
const adminImageAddress = [
    '/assets/images/our-products/mugenzi/admin/admin-01.webp',
    '/assets/images/our-products/mugenzi/admin/admin-2.webp',
    '/assets/images/our-products/mugenzi/admin/admin-02.webp',
    '/assets/images/our-products/mugenzi/admin/admin-03.webp',
    '/assets/images/our-products/mugenzi/admin/admin-04.webp',
    '/assets/images/our-products/mugenzi/admin/admin-05.webp',
    '/assets/images/our-products/mugenzi/admin/admin-06.webp',
    '/assets/images/our-products/mugenzi/admin/admin-07.webp',
    '/assets/images/our-products/mugenzi/admin/admin-08.webp',
    '/assets/images/our-products/mugenzi/admin/admin-09.webp',
    '/assets/images/our-products/mugenzi/admin/admin-10.webp',
    '/assets/images/our-products/mugenzi/admin/admin-11.webp',
    '/assets/images/our-products/mugenzi/admin/admin-12.webp',
    '/assets/images/our-products/mugenzi/admin/admin-13.webp',
    '/assets/images/our-products/mugenzi/admin/admin-14.webp',
    '/assets/images/our-products/mugenzi/admin/admin-15.webp',
];

const doctorButtonNames = ['Login', 'Dashboard', 'Search Patients', 'Add Patient', 'Assistant List', 'Add Assistant', 'Assistant Profile', 'Doctor Profile', 'Notification'];
const doctorImageAddress = [
    '/assets/images/our-products/mugenzi/doctor/doctor-01.webp',
    '/assets/images/our-products/mugenzi/doctor/doctor-02.webp',
    '/assets/images/our-products/mugenzi/doctor/doctor-03.webp',
    '/assets/images/our-products/mugenzi/doctor/doctor-04.webp',
    '/assets/images/our-products/mugenzi/doctor/doctor-05.webp',
    '/assets/images/our-products/mugenzi/doctor/doctor-06.webp',
    '/assets/images/our-products/mugenzi/doctor/doctor-07.webp',
    '/assets/images/our-products/mugenzi/doctor/doctor-08.webp',
    '/assets/images/our-products/mugenzi/doctor/doctor-09.webp',
];

class MugenziEMRProduct extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isVisible: false
        }
        this.openModal = this.openModal.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='EMR' pageUrl='/mugenzi-emr' metaTitle="EMR is a comprehensive Electronic Medical Records (EMR) system designed for healthcare management. The system caters to various user roles, including Doctors, Doctor Assistants/Nurses/Midwives, Patients and Admins." />
                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Electronic Medical Record"
                    customColor="custom-mugenzi-portfolio"
                    dataBlackOverlay={5}
                    bgImage="bg_image--115"
                    p="Web Application"
                    buttonTitle={'get a quote'}
                    openPopup={true}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h4><span>TECHNICAL DETAILS</span></h4> */}
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-mugenzi-portfolio">EMR</h3>
                                        <p className="justifypara">This EMR system is a comprehensive Electronic Medical Records platform designed for efficient healthcare management. It serves multiple user roles, including doctors, doctor assistants, nurses, midwives, patients, and admins, and provides a seamless and integrated solution for managing medical records and healthcare operations. The system facilitates better communication and coordination among healthcare providers and enhances patient care through streamlined data access and management</p>
                                        <h3 className="custom-mugenzi-portfolio">Doctor Features</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#1F603B" />Registration with personal and professional details.</li>
                                            <li><FiCheck color="#1F603B" />Verification process for documents and professional information.</li>
                                            <li><FiCheck color="#1F603B" />Profile management with pending approval status.</li>
                                            <li><FiCheck color="#1F603B" />Login with 2FA (Two-Factor Authentication).</li>
                                            <li><FiCheck color="#1F603B" />Forgot Password functionality.</li>
                                            <li><FiCheck color="#1F603B" />Patient onboarding with a health card.</li>
                                            <li><FiCheck color="#1F603B" />Search patients using national ID or Health ID.</li>
                                            <li><FiCheck color="#1F603B" />Detailed patient profile with medical history and health status.</li>
                                            <li><FiCheck color="#1F603B" />Dashboard with patient details, visit history, vitals, etc.</li>
                                            <li><FiCheck color="#1F603B" />Clinical notes, prescriptions, lab reports, vaccinations, and more.</li>
                                            <li><FiCheck color="#1F603B" />Consultation history and follow-up visit booking.</li>
                                        </ul>
                                        <h3 className="custom-mugenzi-portfolio">Admin Features</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#1F603B" />Doctor onboarding and verification.</li>
                                            <li><FiCheck color="#1F603B" />Manage doctors, patients, primary and secondary care.</li>
                                            <li><FiCheck color="#1F603B" />Notifications and internal admin management.</li>
                                            <li><FiCheck color="#1F603B" />Comprehensive reporting features.</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <div className="d-sm-block d-flex justify-content-center align-items-center pt--30">
                                            <button className="get-a-quote btn-mugenzi text-center custom-school-management-product" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-mugenzi-portfolio' items={technologies} />
                            <div className="d-flex justify-content-center align-items-center pt--30">
                                <button className="get-a-quote btn-mugenzi text-center custom-school-management-product" onClick={this.showPopup}>
                                    <span>Get a quote </span>
                                </button>
                            </div>
                        </React.Suspense>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Doctor'
                                    p={`The doctor functionality involves comprehensive features such as profile management, patient onboarding, consultation history, prescriptions, lab reports, vital tracking, and administrative tasks for efficient healthcare delivery.`}
                                    buttonNames={doctorButtonNames}
                                    image={doctorImageAddress}
                                    alt="BTK provider"
                                    color='mugenzi'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-mugenzi text-center custom-school-management-product" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin'
                                    p={`Admin manages and oversees the onboarding, verification, and access permissions for doctors, and patients, ensuring the effective functioning of the EMR system.`}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt="BTK admin"
                                    color='mugenzi'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center ptb--30">
                        <button className="get-a-quote btn-mugenzi text-center custom-school-management-product" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <React.Suspense fallback={<div>loading...</div>} >
                        {/* <VerbosSimilarApps color='custom-food-delivery-product' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} /> */}
                        <VerbosSimilarApps color='custom-mugenzi-portfolio' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                </div>
                <CommonPortfolioButtons class='btn-mugenzi' text='View All Our Projects' padding='pb--20' />
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default MugenziEMRProduct;
