import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import PlatformUsed from "../component/Verbos/PlatformUsed";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const CommonMasonry = React.lazy(() => import("./CommonMasonry"))
const PortfolioList = [
    {
        image: 'hide-numbers-01',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'hide-numbers-02',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
]
// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
const technologies = [
    { h5: '', p: '', img: '' },
    { h5: 'Android', p: 'Android development', img: 'android.png' },
]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/online-shop', img: 'shop.webp', h2: 'EasyShop', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
]
class HideNumbersPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Hide Numbers || Noorisys Technologies Pvt Ltd' pageUrl='/hide-numbers' metaTitle="Hide Numbers" metaImage="/assets/images/portfolio/dp-portfolio-35.webp"
                    metaDescription="This app shows only the name of the incoming caller and hides the number. Users can make the call or send the message by seeing the name only." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Hide Numbers"
                    customColor="custom-hide-color"
                    dataBlackOverlay={7}
                    bgImage="bg_image--74"
                    p="Android"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-hide-color">Hide Numbers</h3>
                                        <p className="justifypara">Privacy of client contact details from the tele callers or sales persons is a big challenge for any company. It is observed that many sales persons had damaged the company reputation very badly by offering the same services to clients at low price outside the company. Our German customer was facing the same problem and contacted our representative for the solution. After the meeting of our technical team, we have developed this application. It shows only name of the incoming caller (not numbers) Hides the number from the incoming message User can make the call or send the message by seeing the name only. Hides all the call history and messages from the default apps. User can use only the allowed apps (Improves the productivity of resources) All calls are recorded only super admin of the device can switch on/off this feature.</p>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-hide'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30"
                // style={{backgroundColor:'#5aadbf'}}
                >
                    <div className="container">
                        <div className="pb--50">
                            <div className="row">
                                <PlatformUsed color='custom-hide-color' items={technologies} />
                            </div>
                            <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-hide'} paddingTop={'pt--30'} />
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h3 className="title">Screenshots</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" PortfolioList={PortfolioList} folder={'hide_numbers'} />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-hide'} paddingTop={'pt--30'} />
                    <div className="pt--30">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <VerbosSimilarApps color='custom-hide-color' title='Apps' items={similarapps} slidestoshow={[3, 3]} />
                        </React.Suspense>
                    </div>
                    <CommonPortfolioButtons class='btn-hide' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default HideNumbersPortfolioDetails;
