import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Header from "../component/header/Header";
import CreativeCounter from "./Creative_Counter";
import Footer from "../component/footer/Footer";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import 'reactjs-popup/dist/index.css';
import CommonSliderBanner from "../component/common/CommonSliderBanner";
import ServiceDetails from "./ServiceDetails";
const CreativeTestimonial = React.lazy(() => import("./CreativeTestimonial"));
const CreativePortfolio = React.lazy(() => import('./CreativePortfolio'));
const CreativeBrand = React.lazy(() => import('./CreativeBrand'));
const CreativeServiceList = React.lazy(() => import("./Creative_Service"));
const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Freelance digital designer',
        title: ' Empowering <br /> IT Services to Thrive with the <span> Power of AI</span>',
        // title: ' Partnering for Success with <span> Noorisys </span>Technologies',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]

const data = [
    {
        title: 'Doctor Appointment System',
        description:
            'Doctor appointment system is a smart booking system that provides patients an easy way of booking a doctor’s appointment remotely. This is a cross platform application useful in investigating, monitoring the prescriptions and management of patient records creating an electronic health record.The gathered data can be managed and consulted by authorised clinicians and staff within the health care organisation.',
        features: [
            'Easy registration for providers and patients.',
            'Email verification and Forgot password.',
            'Online Teleconsultation and physical appointment.',
            'Audio / Video appointment.',
            'EMR management.',
            'Insurance Package Marketplace.',
            'Secure online and offline payment.',
            'Multi-currency and multi-language.',
        ],
        image: '/assets/images/service/service-11.webp',
        alt: 'Service1',
        link: '/doctor-appointment-and-online-pharmacy',
        text: 'View More'
    },
    {
        title: 'eCommerce Application',
        description:
            'This is an online Indian grocery store that delivers to France, Germany, Netherlands, Spain and Italy. The website offers a wide variety of Indian products, including fruits, vegetables, spices, snacks, sweets, and more. It also offers a variety of services, such as home delivery, free shipping on orders over €50, and a loyalty program.',
        features: [
            'Prime customer features including free shipping ',
            'Customer wallet',
            'Customer reviews',
            '500+ products',
            'Easy to browse',
            'Shipping',
            'Recipes',
            'Alerts and notifications',
        ],
        image: '/assets/images/service/Kapruka_img.webp',
        alt: 'kapruka',
        link: '/online-groceries-ordering-system',
        text: 'View More'
    },
    {
        title: 'Fintech Application',
        description: 'This is a mobile financial services platform that allows users to hold and manage an electronic account, make real-time transfers, make payments, access a wide network of partners, and participate in a loyalty program.',
        features: [
            'Receive scholarships from the government',
            'Purchase and share restaurant ticket',
            'Receive money in your wallet',
            'Real-time transfers of funds to other users',
            'Pay bills or pay for purchases online or in-store',
            'Buy phone credit from all operators',
            'Participate in loyalty programs',
        ],
        image: '/assets/images/service/paytmwallet.webp',
        alt: 'scholarship',
        link: '/scholarship-distribution-system',
        text: 'View More'
    },
    {
        title: 'Tourism Application',
        description: 'It is a comprehensive travel platform designed specifically for Umrah pilgrims. It offers a wide range of services to make the pilgrimage easier and more convenient for travellers.',
        features: [
            'Search and book hotels based on preferred location, budget and amenities',
            'Search and book their preferred mode of transportation',
            'Maqam GDS Integration',
            'Easier and more convenient to obtain an Umrah visa',
            'Ground services including airport transfers, luggage handling and tour guides',
            'Travel insurance to protect pilgrims against any unforeseen circumstances',
        ],
        image: '/assets/images/service/tourism.webp',
        alt: 'tourism',
        link: '/visa-processing-system',
        text: 'View More'
    }
    // Add more items as needed
];
class CreativeAgency extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "AW-16650476770", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    render() {
        // const PostList = BlogContent.slice(0, 5);
        return (
            <Fragment>
                <Helmet pageTitle="Custom Web and Mobile App Development Company" pageUrl="/" metaTitle="Innovation and Excellence in Software Development" metaImage="/assets/images/bg/paralax/bg-image-3-overlay.jpg"
                    metaDescription="The best mobile and web development company in India. We provide IT solutions to clients all over the world. Let us help you bring your digital vision to life."
                    metaKeywords="best mobile app development company in uk ,web and mobile app development company,custom web design and development services,custom web development company ,mobile app development company in uk" />
                <Header logo="light" />

                {/* Start Slider Area   */}
                {/* <div className="slider-wrapper">
                    Start Single Slide
                    {SlideList.map((value, index) => (
                        <div className="slide personal-portfolio-slider-partner slider-paralax slider-style-partner d-flex align-items-center justify-content-center bg_image bg_image--32" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title" style={{ color: '#ffff' }} dangerouslySetInnerHTML={{ __html: value.title }}></h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    End Single Slide
                </div> */}
                <CommonSliderBanner classes={'slide personal-portfolio-slider-partner slider-paralax slider-style-partner d-flex align-items-center justify-content-center bg_image bg_image--32'} slideList={SlideList} bgImage={'bg_image--32'} />

                {/* End Slider Area   */}


                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--20">
                                    <h2>Our IT Services</h2>
                                    <p>Thinking of new projects or redefined products ? <br /> Discover our global IT consulting, delivering quality and cost-effective solutions.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <React.Suspense fallback={<div>loading...</div>} >
                                    <CreativeServiceList item="3" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                                </React.Suspense>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--60 pb--30 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Number says a lot</h3>
                                </div>
                            </div>
                        </div>
                        <CreativeCounter />
                    </div>
                </div>
                {/* End CounterUp Area */}


                {/* Start Blog Details */}
                <div className="rn-blog-details pt--20 pb--20 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <blockquote className="rn-blog-quote ">With a team of more than 200+ qualified staff from prominent institutions, we have excelled in all the modern technologies and frameworks such as NodeJS, Laravel, .Net, .Net Core, Java, Python, ReactJS, React Native, Swift, Kotlin, Objective C, Dart, Flutter, Angular, CodeIgniter, Django, and PHP.</blockquote>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="view-more-btn mt--10 text-center">
                                    <Link className="rn-button-style--2 btn-solid" to="/tech-stack"><span>View Our Tech Stack</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* End Blog Details */}

                {/* Start About Area */}
                <div className="about-area ptb--60 bg_color--5">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/working_methodology.webp" alt="About" loading="lazy" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">Working Methodology</h2>
                                            <p className="description justifypara ">At Noorisys, we prioritize our clients' needs. Our commitment is to deliver exceptional outcomes, positioning us as the best company for software development while maintaining your convenience and satisfaction as paramount. </p>
                                            <p className="description justifypara">We offer two distinct approaches to project development, allowing you to choose the one that aligns best with your needs and objectives. When you partner with us, you embark on a journey of seamless collaboration and successful project completion.</p>
                                            {/* <p className="description ">Catering to our clients’ needs is our prime goal. Noorisys aims to provide outstanding results by keeping your convenience under consideration.</p> */}
                                            {/* <p className="description">We offer two ways of project development - either choose a fixed-cost project or hire a team.</p> */}
                                            {/* <p className="description">You choose, we deliver.</p> */}
                                        </div>
                                        <div className="row mt--30">
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Fixed Cost Project</h3>
                                                    <p className="justifypara">In this model, project costs are predefined according to the Scope of Work (SOW). With transparent pricing, you can proceed confidently.</p>
                                                    <p className="justifypara">If you have a clear and well-defined website or mobile app development project vision, this is the perfect choice for you.</p>
                                                    {/* <p className="justifypara">In this model, the project cost is predefined thereby following the Scope of Work (SOW) .</p>
                                                    <p className="justifypara">If you're clear with your project ideas and product vision, this option is for you.</p> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                                <div className="about-us-list">
                                                    <h3 className="title">Hire a Team</h3>
                                                    <p className="justifypara">You have flexibility in hiring software developers, whether on a monthly basis, at an hourly rate or based on your specific needs.</p>
                                                    <p className="justifypara">You can hire a single developer or a software development team and interview them to secure time blocks starting from just 16 hours.</p>
                                                    {/* <p className="justifypara">You can hire a developer on a monthly basis for an hourly rate or hire as per your requirement.</p>
                                                    <p className="justifypara">You can interview developers and buy a time block of as low as 16 hours.</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area bg_color--1 ptb--80">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <CreativeTestimonial />
                        </React.Suspense>
                    </div>
                </div>
                {/* End Testimonial Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--50">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <React.Suspense fallback={<div>loading...</div>} >
                                    <CreativeBrand />
                                </React.Suspense>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--60 bg_color--5">
                    <div className="portfolio-sacousel-inner mb--55">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <CreativePortfolio />
                        </React.Suspense>
                    </div>
                </div>
                {/* End Portfolio Area */}


                {/* Start Page Wrapper */}

                <ServiceDetails data={data} bgColor="bg_color--5" />
                {/* Start Footer Style  */}
                <Footer />
                {/* End Footer Style  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}

export default CreativeAgency;