import React, { Component } from 'react'
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonBlogBanner from "../component/common/CommonBlogBanner";
import { GET_BASE_IMAGE_URL, GET_BASE_URL } from '../utils/constant';


export default function Layout2({ content }) {

    const postDescriptionWithColor = content?.post_description
        ? content.post_description
            // .replace(/<h1>/g, '<h1 style="color: #8bc34a;">')
            // .replace(/<h2>/g, '<h2 style="color: #8bc34a;">')
            // .replace(/<h3>/g, '<h3 style="color: #8bc34a;">')
            // .replace(/<h4>/g, '<h4 style="color: #8bc34a;">')
            // .replace(/<h5>/g, '<h5 style="color: #8bc34a;">')
            // .replace(/<h6>/g, '<h6 style="color: #8bc34a;">')
            .replace(/<strong>/g, '<strong style="font-size: 22px;">')
            .replace(/<p>\s*(<(strong|h[1-6])[^>]*>.*?<\/\2>)\s*<\/p>/gi, '$1')
            .replace(/<p>\s*(&nbsp;|<br\s*\/?>|\s*)+\s*<\/p>/gi, '')
        : null;

    const postContent1WithColor = content?.post_image_1_content
        ? content.post_image_1_content
            // .replace(/<h1>/g, '<h1 style="color: #8bc34a;">')
            // .replace(/<h2>/g, '<h2 style="color: #8bc34a;">')
            // .replace(/<h3>/g, '<h3 style="color: #8bc34a;">')
            // .replace(/<h4>/g, '<h4 style="color: #8bc34a;">')
            // .replace(/<h5>/g, '<h5 style="color: #8bc34a;">')
            // .replace(/<h6>/g, '<h6 style="color: #8bc34a;">')
            .replace(/<strong>/g, '<strong style="font-size: 22px;">')
            .replace(/<p>\s*(<(strong|h[1-6])[^>]*>.*?<\/\2>)\s*<\/p>/gi, '$1')
             .replace(/<p>\s*(&nbsp;|<br\s*\/?>|\s*)+\s*<\/p>/gi, '')
        : null;

    const postContent2WithColor = content?.post_image_2_content
        ? content?.post_image_2_content
            // .replace(/<h1>/g, '<h1 style="color: #8bc34a;">')
            // .replace(/<h2>/g, '<h2 style="color: #8bc34a;">')
            // .replace(/<h3>/g, '<h3 style="color: #8bc34a;">')
            // .replace(/<h4>/g, '<h4 style="color: #8bc34a;">')
            // .replace(/<h5>/g, '<h5 style="color: #8bc34a;">')
            // .replace(/<h6>/g, '<h6 style="color: #8bc34a;">')
            .replace(/<strong>/g, '<strong style="font-size: 22px;">')
            .replace(/<p>\s*(<(strong|h[1-6])[^>]*>.*?<\/\2>)\s*<\/p>/gi, '$1')
             .replace(/<p>\s*(&nbsp;|<br\s*\/?>|\s*)+\s*<\/p>/gi, '')
        : null;

    const postFooter = content?.post_footer_content
        ? content?.post_footer_content
            // .replace(/<h1>/g, '<h1 style="color: #8bc34a;">')
            // .replace(/<h2>/g, '<h2 style="color: #8bc34a;">')
            // .replace(/<h3>/g, '<h3 style="color: #8bc34a;">')
            // .replace(/<h4>/g, '<h4 style="color: #8bc34a;">')
            // .replace(/<h5>/g, '<h5 style="color: #8bc34a;">')
            // .replace(/<h6>/g, '<h6 style="color: #8bc34a;">')
            .replace(/<strong>/g, '<strong style="font-size: 22px;">')
            .replace(/<p>\s*(<(strong|h[1-6])[^>]*>.*?<\/\2>)\s*<\/p>/gi, '$1')
             .replace(/<p>\s*(&nbsp;|<br\s*\/?>|\s*)+\s*<\/p>/gi, '')
        : null;


    return (
        <>
            <PageHelmet pageTitle='5G technology' pageUrl='/5g-technology' metaTitle="5G will change how we connect, communicate, and innovate. As the fifth generation of mobile technology, 5G offers unparalleled speed, reliability and capacity " metaImage="/assets/images/blog/blog-01.webp"
                metaDescription="5G will change how we connect, communicate, and innovate. As the fifth generation of mobile technology, 5G offers unparalleled speed, reliability and capacity" />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <CommonBlogBanner
                date={content.created_at}
                writer={content.main_category}
                title={content.post_title}
                image={`${GET_BASE_IMAGE_URL + content.post_banner_image}`}
                titleClass={''}
            />
            {/* End Breadcrump Area */}

            {/* Start Blog Details */}
            <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-wrapper">
                                <div className="inner text-center">

                                    <div className="justifypara" dangerouslySetInnerHTML={{ __html: postDescriptionWithColor.replace(/\\\//g, "/").replace(/\\/g, "") }}></div>
                                    <img className="pb--30" width={'1010px'} height={'530px'} style={{borderRadius:'6px'}} src={`${GET_BASE_IMAGE_URL + content.post_image_1}`} alt='5g' />
                                    <p className="justifypara" dangerouslySetInnerHTML={{ __html: postContent1WithColor.replace(/\\\//g, "/").replace(/\\/g, "") }}></p>

                                     {/* <div className="container"> */}
                                        <div className="row justify-center align-items-center">
                                            <div className="col-lg-6">
                                                <div>
                                                    {content.post_image_2 ? <img className="w-100" style={{height:'500px',objectFit:'cover',borderRadius:'6px'}} src={`${GET_BASE_IMAGE_URL + content.post_image_2}`} alt="connected world bLog" loading="lazy" /> : ''}
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <br />
                                                <p className="justifypara" dangerouslySetInnerHTML={{ __html: postContent2WithColor.replace(/\\\//g, "/").replace(/\\/g, "") }}></p>
                                            </div>
                                        </div>
                                    {/* </div> */}
                                    <br />
                                    <p className="justifypara" dangerouslySetInnerHTML={{ __html: postFooter.replace(/\\\//g, "/").replace(/\\/g, "") }}></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Blog Details */}


            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />
        </>
    )
}
