import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
const CommonProductWeb = React.lazy(() => import("../portfolio/CommonProductWeb"));
const ProductCommonApp = React.lazy(() => import("../portfolio/ProductCommonAppdemo"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));


// const SocialShare =[
//     {Social:<FaGlobe />,link:' https://annachi.fr/ '}
// ]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'PHP', p: 'Admin Panel', img: 'php.png' },
    { h5: 'Laravel 10', p: 'Backend Development', img: 'laravel10.png' },
    { h5: 'Mysql', p: 'Database', img: 'mysql.png' },
    // { h5: 'Native  Android', p: 'App Development', img: 'native.png' },
    { h5: 'Flutter', p: 'App Development', img: 'flutter.png' },
]

const userButtonNames = ['Signin', 'Home', 'Add to Card', 'My Orders', 'Order Details', 'Order Tracking', 'Conversation List', 'Favourite Item', 'Favourite Restaurant', 'All Products', 'Account', 'Language', 'Delivery Man Registration', 'Store Registration'];
const userImageAddress = [
    '/assets/images/our-products/food-delivery/user/user-01.webp',
    '/assets/images/our-products/food-delivery/user/user-02.webp',
    '/assets/images/our-products/food-delivery/user/user-03.webp',
    '/assets/images/our-products/food-delivery/user/user-04.webp',
    '/assets/images/our-products/food-delivery/user/user-05.webp',
    '/assets/images/our-products/food-delivery/user/user-06.webp',
    '/assets/images/our-products/food-delivery/user/user-07.webp',
    '/assets/images/our-products/food-delivery/user/user-08.webp',
    '/assets/images/our-products/food-delivery/user/user-09.webp',
    '/assets/images/our-products/food-delivery/user/user-10.webp',
    '/assets/images/our-products/food-delivery/user/user-11.webp',
    '/assets/images/our-products/food-delivery/user/user-12.webp',
    '/assets/images/our-products/food-delivery/user/user-13.webp',
    '/assets/images/our-products/food-delivery/user/user-14.webp',
];
const deliveryBoyButtonNames = ['Signin', 'Home', 'Order Details', 'My Orders', 'Profile Light Mode', 'Profile Dark Mode', 'Language', 'Edit Profile'];
const deliveryBoyImageAddress = [
    '/assets/images/our-products/food-delivery/delivery-boy/delivery-boy-01.webp',
    '/assets/images/our-products/food-delivery/delivery-boy/delivery-boy-02.webp',
    '/assets/images/our-products/food-delivery/delivery-boy/delivery-boy-03.webp',
    '/assets/images/our-products/food-delivery/delivery-boy/delivery-boy-04.webp',
    '/assets/images/our-products/food-delivery/delivery-boy/delivery-boy-06.webp',
    '/assets/images/our-products/food-delivery/delivery-boy/delivery-boy-07.webp',
    '/assets/images/our-products/food-delivery/delivery-boy/delivery-boy-08.webp',
    '/assets/images/our-products/food-delivery/delivery-boy/delivery-boy-09.webp',
];

const restaurantButtonNames = ['Dashboard', 'All Orders', 'Add new Addons', 'Store Config', 'My Store', 'Bank Information', 'Reviews'];
const restaurantImageAddress = [
    '/assets/images/our-products/food-delivery/store/store-01.webp',
    '/assets/images/our-products/food-delivery/store/store-02.webp',
    '/assets/images/our-products/food-delivery/store/store-03.webp',
    '/assets/images/our-products/food-delivery/store/store-04.webp',
    '/assets/images/our-products/food-delivery/store/store-05.webp',
    '/assets/images/our-products/food-delivery/store/store-06.webp',
    '/assets/images/our-products/food-delivery/store/store-07.webp',
];
const adminButtonNames = ['Dashboard', 'Food Dashboard', 'All Orders', 'Add New Store', 'Stores'];
const adminImageAddress = [
    '/assets/images/our-products/food-delivery/admin/admin-01.webp',
    '/assets/images/our-products/food-delivery/admin/admin-02.webp',
    '/assets/images/our-products/food-delivery/admin/admin-03.webp',
    '/assets/images/our-products/food-delivery/admin/admin-04.webp',
    '/assets/images/our-products/food-delivery/admin/admin-05.webp',
];
class FoodDelivery extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isVisible: false
        }
        this.openModal = this.openModal.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Food Delivery Application' pageUrl='/learning-management' metaTitle="Digital Solution For Civil Documents" metaImage="/assets/images/portfolio/dp-portfolio-51.webp"
                    metaDescription="A comprehensive solution that seamlessly incorporates all the essential features for
managing a successful food delivery business. From multi-language support and precise
coverage area mapping to streamlined order management and versatile payment gateways,
our app has it all" />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Food Delivery Application"
                    // customColor="custom-food-delivery-product"
                    customColor="theme-gradient"
                    dataBlackOverlay={5}
                    bgImage="bg_image--104"
                    p="Web/Android/iOS Application"
                    buttonTitle={'get a quote'}
                    openPopup={true}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h4><span>TECHNICAL DETAILS</span></h4> */}
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-food-delivery-product">Food Delivery Application</h3>
                                        <p className="justifypara">A comprehensive solution that seamlessly incorporates all the essential features for
                                            managing a successful food delivery business. From multi-language support and precise
                                            coverage area mapping to streamlined order management and versatile payment gateways,
                                            our app has it all. With an easy-to-use admin panel and store management, integrated SMS
                                            verification, real-time delivery tracking, and a user-friendly mobile app and web interface,
                                            Food Delivery is the perfect choice to kickstart your food delivery venture. Your business can
                                            flourish with the ability to adapt modules, manage stores, and create promotional
                                            campaigns, all while keeping a close eye on financial transactions and employee roles.</p>
                                        <h3 className="rn-page-title custom-food-delivery-product">User App Functionalities</h3>
                                        <p className="justifypara">Transform your food delivery venture with our comprehensive user app, featuring seamless
                                            registration, multi-language support, real-time delivery tracking, and a user-friendly
                                            interface for effortless order management, restaurant exploration and personalized profiles.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#e96e00" />Registration and Login</li>
                                            <li><FiCheck color="#e96e00" />View List of food products</li>
                                            <li><FiCheck color="#e96e00" />View All Nearby Restaurants</li>
                                            <li><FiCheck color="#e96e00" />Add to Cart and Order</li>
                                            <li><FiCheck color="#e96e00" />Rating and Reviews</li>
                                            <li><FiCheck color="#e96e00" />Add to Favourites</li>
                                            <li><FiCheck color="#e96e00" />Profile Management</li>
                                            <li><FiCheck color="#e96e00" />Multi-language support</li>
                                        </ul>
                                        <h3 className="rn-page-title custom-food-delivery-product">Delivery Boy App Functionalities</h3>
                                        <p className="justifypara">Effortlessly register and log in, seamlessly manage order requests, accept or reject orders,
                                            review order history, engage in real-time chat with users and efficiently manage your profile
                                            with our delivery boy app.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#e96e00" />Registration and Login</li>
                                            <li><FiCheck color="#e96e00" />View Order Request</li>
                                            <li><FiCheck color="#e96e00" />Accept / Reject Orders</li>
                                            <li><FiCheck color="#e96e00" />Order History</li>
                                            <li><FiCheck color="#e96e00" />Chat with Users</li>
                                            <li><FiCheck color="#e96e00" />Profile Management</li>
                                            <li><FiCheck color="#e96e00" />Dark Mode</li>
                                            <li><FiCheck color="#e96e00" />Multi-language support</li>
                                        </ul>
                                        <h3 className="rn-page-title custom-food-delivery-product">Restaurant Panel Functionalities</h3>
                                        <p className="justifypara">Efficiently manage every aspect of your food delivery business with our comprehensive
                                            Restaurant Panel, offering features such as a user-friendly dashboard, streamlined order and
                                            item management, dynamic category and add-on control, coupon and campaign
                                            management and insightful review viewing capabilities.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#e96e00" />Dashboard</li>
                                            <li><FiCheck color="#e96e00" />Manage Items</li>
                                            <li><FiCheck color="#e96e00" />Manage Orders</li>
                                            <li><FiCheck color="#e96e00" />Manage Categories</li>
                                            <li><FiCheck color="#e96e00" />Manage  Add Ons</li>
                                            <li><FiCheck color="#e96e00" />Manage Coupons</li>
                                            <li><FiCheck color="#e96e00" />Manage Campaigns</li>
                                            <li><FiCheck color="#e96e00" />View Reviews</li>
                                        </ul>
                                        <h3 className="rn-page-title custom-food-delivery-product">Admin Panel Functionalities</h3>
                                        <p className="justifypara">A robust administration system offering a comprehensive set of tools for the effective
                                            management of a flourishing food delivery enterprise, featuring a user-friendly admin panel
                                            with capabilities for user and restaurant management, category oversight, transaction
                                            tracking and dynamic dashboard insights.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#e96e00" />Dashboard</li>
                                            <li><FiCheck color="#e96e00" />Manage Users</li>
                                            <li><FiCheck color="#e96e00" />Manage Restaurants</li>
                                            <li><FiCheck color="#e96e00" />Manage Categories</li>
                                            <li><FiCheck color="#e96e00" />Manage  Transactions</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <div className="d-sm-block d-flex justify-content-center align-items-center pt--30">
                                            <button className="get-a-quote btn-food text-center" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-food-delivery-product' items={technologies} />
                            <div className="d-flex justify-content-center align-items-center pt--30">
                                <button className="get-a-quote btn-food text-center" onClick={this.showPopup}>
                                    <span>Get a quote </span>
                                </button>
                            </div>
                        </React.Suspense>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='Transform your food delivery venture with our comprehensive user app, featuring seamless registration, multi-language support, real-time delivery tracking, and a user-friendly interface for effortless order management, restaurant exploration and personalized profiles.'
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt={'food delivery user'}
                                    color='food-delivery-app-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-food text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Delivery Boy App'
                                    p='Effortlessly register and log in, seamlessly manage order requests, accept or reject orders, review order history, engage in real-time chat with users and efficiently manage your profile with our delivery boy app.'
                                    buttonNames={deliveryBoyButtonNames}
                                    image={deliveryBoyImageAddress}
                                    alt={'food delivery boy'}
                                    color='food-delivery-app-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-food text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Restaurant Panel'
                                    p='Efficiently manage every aspect of your food delivery business with our comprehensive Restaurant Panel, offering features such as a user-friendly dashboard, streamlined order and item management, dynamic category and add-on control, coupon and campaign management and insightful review viewing capabilities.'
                                    buttonNames={restaurantButtonNames}
                                    image={restaurantImageAddress}
                                    alt='restaurant'
                                    color='food-delivery-web-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-food text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='A robust administration system offering a comprehensive set of tools for the effective management of a flourishing food delivery enterprise, featuring a user-friendly admin panel with capabilities for user and restaurant management, category oversight, transaction tracking and dynamic dashboard insights.'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='food delivery admin'
                                    color='food-delivery-web-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center ptb--30">
                        <button className="get-a-quote btn-food text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <React.Suspense fallback={<div>loading...</div>} >
                        {/* <VerbosSimilarApps color='custom-food-delivery-product' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} /> */}
                        <VerbosSimilarApps color='custom-food-delivery-product' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                </div>
                <CommonPortfolioButtons class='btn-fasterchecks' text='View All Our Projects' padding='pb--20' />
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default FoodDelivery;
