import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import CoursierDelivrable from "./CoursierDeliverables";
import PlatformUsed from "../component/Verbos/PlatformUsed";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const deliverables = [
    { item_bg: 'android.png', item_icon: 'users.png', h3: 'Patient App', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'users.png', h3: 'Patient App', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'users.png', h3: 'Patient', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
    { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'primaryadvance.png', h3: 'Nurse App ', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'primaryadvance.png', h3: 'Nurse App ', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'primaryadvance.png', h3: 'Nurse ', h4: 'Web' },
    { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
]
const technologies = [

    { h5: 'Android', p: 'Android & iOS development', img: 'androidios.png' },
    { h5: 'PHP', p: 'Backend Development', img: 'php.png' },
    { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
    { h5: 'PHP', p: 'Admin Panel Development', img: 'php.png' },

]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/doctor-appointment-and-online-pharmacy', img: 'mediconnect.webp', h2: 'MediConnect', p: 'Doctor Appointment and pharmacy' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/primary-care-management-system', img: 'federate.webp', h2: 'Federate', p: 'Primry Care Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]
const userAppButtonNames = ['Docfinder App', 'Login', 'Sidebar', 'Dashboard', 'Insurance', 'My Appointments', 'Profile', 'My Relatives', 'My Documents', 'Help Relatives'];
const userAppImageAddress = [
    '/assets/images/portfolio/healthcare-insurance/izikare-app-1.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-2.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-3.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-4.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-5.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-6.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-7.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-8.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-9.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-10.webp',
];
const adminButtonNames = ['Homepage', 'Registration', 'Login', 'Dashboard', 'Insurance Activities', 'Allodocta Activities', 'Platform Activities', 'Insurance Settings', 'Allodocta Settings', 'EMR Settings', 'Platform Settings', 'Profile'];
const adminImageAddress = [
    '/assets/images/portfolio/healthcare-insurance/izikare-web-1.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-2.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-3.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-4.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-5.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-6.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-7.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-8.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-9.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-10.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-11.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-12.webp',
];
class ElectronicMedicalPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Electronic Medical Record' pageUrl='/electronic-medical-record' metaTitle="Electronic Medical Record" metaImage="/assets/images/portfolio/dp-portfolio-20.webp"
                    metaDescription="This system is useful in investigation, monitoring and management of patients. An electronic record of health-related information of a patient can be created, gathered, managed, and consulted by authorized clinicians and staff within an organization." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Electronic Medical Record"
                    customColor="custom-pharmacist-color"
                    dataBlackOverlay={7}
                    bgImage="bg_image--57"
                    p="Web / Android / iOS Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Web / Android / iOS Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>Electronic Medical Record</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>PHP, CodeIgniter, Android, JAVA, iOS, Swift</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title custom-pharmacist-color">Electronic Medical Record</h3>
                                        <p className="justifypara">Electronic Medical Record system is useful in investigating, monitoring and management of patients. An electronic record of health-related information of a patient can be created, gathered, managed, and consulted by authorized clinicians and staff within one health care organization. With Electronic Medical Record software, medical and surgical physicians experience an advanced level of efficiency and productivity, thus improving their profitability and their bottom line.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck color={'#1396ce'} />Easy registration for providers and patients</li>
                                            <li><FiCheck color={'#1396ce'} />Email verification and forgot password</li>
                                            <li><FiCheck color={'#1396ce'} />Attractive dashboard for each Provider and Patient</li>
                                            <li><FiCheck color={'#1396ce'} />Online Teleconsultation</li>
                                            <li><FiCheck color={'#1396ce'} />Audio / Video appointment</li>
                                            <li><FiCheck color={'#1396ce'} />Physical Appointment</li>
                                            <li><FiCheck color={'#1396ce'} />Consultation at patient's home</li>
                                            <li><FiCheck color={'#1396ce'} />EMR management</li>
                                            <li><FiCheck color={'#1396ce'} />Insurance Subscriptions</li>
                                            <li><FiCheck color={'#1396ce'} />Insurance Package Marketplace</li>
                                            <li><FiCheck color={'#1396ce'} />Manage Relatives</li>
                                            <li><FiCheck color={'#1396ce'} />Claims management</li>
                                            <li><FiCheck color={'#1396ce'} />Secure online payment</li>
                                            <li><FiCheck color={'#1396ce'} />Offline payment</li>
                                            <li><FiCheck color={'#1396ce'} />Multi-currency</li>
                                            <li><FiCheck color={'#1396ce'} />Multi language</li>
                                            <li><FiCheck color={'#1396ce'} />Accounts and wallet</li>
                                            <li><FiCheck color={'#1396ce'} />Separate chat for each audio / video appointment</li>
                                            <li><FiCheck color={'#1396ce'} />Alerts and notifications</li>
                                            <li><FiCheck color={'#1396ce'} />Fully responsive design</li>
                                            <li><FiCheck color={'#1396ce'} />And many more….</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-healthcare'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 pt--30 pb--50"
                    style={{ backgroundColor: '#1296cc' }}
                >
                    <div className="container">
                        <CoursierDelivrable title={'16 Deliverables'} items={deliverables} class={'coursier'} value={[7, 5, 4]} />
                    </div>
                </div>
                <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-healthcare'} paddingTop={'pt--30'} />
                <div className="ptb--40">
                    <div className="container">
                        <PlatformUsed color='custom-coursier-color' items={technologies} />
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-healthcare'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--1">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='Users can book online and physical appointments with the doctor. Also users can search and subscribe for available insurance available on the platform.'
                                    buttonNames={userAppButtonNames}
                                    image={userAppImageAddress}
                                    alt='docfinder user'
                                    color='docfinder'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-healthcare'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='Admin can manage users, providers (doctor, pharmacy, lab etc), appointments, insurance, EMR and other platform settings'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='docfinder admin'
                                    color='docfinder'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-healthcare'} paddingTop={'pt--30'} paddingBottom={`pb--30`} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-coursier-color' items={similarapps} title={'Similar Apps'} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-healthcare' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default ElectronicMedicalPortfolioDetails;
