import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
const ProductCommonApp = React.lazy(() => import("../portfolio/ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("../portfolio/CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
]
const technologies = [
    { h5: '', p: '', img: '' },
    { h5: '.Net', p: 'Backend Development', img: 'dotnet.png' },
    // { h5: 'Mysql', p: 'Database', img: 'node.png' },
    { h5: 'Flutter', p: 'App Development', img: 'flutter.png' },
]

const userButtonNames = ['Login', 'Verification', 'Home', 'Reels', 'Share Location', 'My Events', 'Chat Messages', 'Chat Groups', 'My Followers', 'My Account', 'My Profile', 'Subscription', 'Event Details'];
const userImageAddress = [
    '/assets/images/our-products/social-platform/user/user-01.webp',
    '/assets/images/our-products/social-platform/user/user-02.webp',
    '/assets/images/our-products/social-platform/user/user-03.webp',
    '/assets/images/our-products/social-platform/user/user-04.webp',
    '/assets/images/our-products/social-platform/user/user-05.webp',
    '/assets/images/our-products/social-platform/user/user-06.webp',
    '/assets/images/our-products/social-platform/user/user-07.webp',
    '/assets/images/our-products/social-platform/user/user-08.webp',
    '/assets/images/our-products/social-platform/user/user-09.webp',
    '/assets/images/our-products/social-platform/user/user-10.webp',
    '/assets/images/our-products/social-platform/user/user-11.webp',
    '/assets/images/our-products/social-platform/user/user-12.webp',
    '/assets/images/our-products/social-platform/user/user-13.webp',
];

const adminButtonNames = ['Manage Users', 'User List', 'Subscribed Users', 'Event Management', 'Reported Events', 'Subscription Plans', 'Interest Master'];
const adminImageAddress = [
    '/assets/images/our-products/social-platform/admin/admin-01.webp',
    '/assets/images/our-products/social-platform/admin/admin-02.webp',
    '/assets/images/our-products/social-platform/admin/admin-03.webp',
    '/assets/images/our-products/social-platform/admin/admin-04.webp',
    '/assets/images/our-products/social-platform/admin/admin-05.webp',
    '/assets/images/our-products/social-platform/admin/admin-06.webp',
    '/assets/images/our-products/social-platform/admin/admin-07.webp',
];

class SocialMediaPlatform extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isVisible: false
        }
        this.openModal = this.openModal.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Social Media Platform' pageUrl='/social-platform' metaTitle="Social Media Platform" metaImage="/assets/images/portfolio/dp-portfolio-51.webp"
                    metaDescription="This is an event social media app that bridges real-world events and the convenience of
social media. Users can explore nearby events, create their own and engage with the
community through likes, comments and event sharing." />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Social Media Platform"
                    // customColor="custom-social-platform-color"
                    customColor="theme-gradient"
                    dataBlackOverlay={5}
                    bgImage="bg_image--106"
                    p="Web/Android/iOS Application"
                    buttonTitle={'get a quote'}
                    openPopup={true}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h4><span>TECHNICAL DETAILS</span></h4> */}
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-social-platform-color">Social Media Platform</h3>
                                        <p className="justifypara">This is an event social media app that bridges real-world events and the convenience of
                                            social media. Users can explore nearby events, create their own and engage with the
                                            community through likes, comments and event sharing. With a focus on user
                                            empowerment, it offers a dynamic platform for event discovery, creation and social
                                            connection, making it the ultimate tool for connecting with local happenings and
                                            like-minded individuals.</p>
                                        <h3 className="rn-page-title custom-social-platform-color">User App Functionalities</h3>
                                        <p className="justifypara">This event social media app empowers users to explore nearby events, create their own, and
                                            engage with the community through likes, comments, and event sharing, fostering social
                                            connections by allowing users to follow others, chat with friends, add and view reels and
                                            share locations.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#863cb2" />Explore nearby events</li>
                                            <li><FiCheck color="#863cb2" />Create new event</li>
                                            <li><FiCheck color="#863cb2" />Engage with the community through likes, comments and event sharing</li>
                                            <li><FiCheck color="#863cb2" />Follow other users</li>
                                            <li><FiCheck color="#863cb2" />Chat with friends</li>
                                            <li><FiCheck color="#863cb2" />Add reels</li>
                                            <li><FiCheck color="#863cb2" />View reels</li>
                                            <li><FiCheck color="#863cb2" />Share location with friends</li>
                                        </ul>
                                        <h3 className="rn-page-title custom-social-platform-color">Admin Panel Functionalities</h3>
                                        <p className="justifypara">The admin panel for this Event Social Media App provides essential functionalities, including
                                            user management capabilities, event administration tools, and the ability to manage
                                            subscription plans, ensuring efficient oversight and control over the platform.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#863cb2" />Dashboard</li>
                                            <li><FiCheck color="#863cb2" />Manage Users</li>
                                            <li><FiCheck color="#863cb2" />Manage Events</li>
                                            <li><FiCheck color="#863cb2" />Manage Subscription Plans</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <div className="d-sm-block d-flex justify-content-center align-items-center pt--30">
                                            <button className="get-a-quote btn-social-platfrom text-center" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-social-platform-color' items={technologies} />
                            <div className="d-flex justify-content-center align-items-center pt--30">
                                <button className="get-a-quote btn-social-platfrom text-center" onClick={this.showPopup}>
                                    <span>Get a quote </span>
                                </button>
                            </div>
                        </React.Suspense>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='This event social media app empowers users to explore nearby events, create their own, and engage with the community through likes, comments, and event sharing, fostering social connections by allowing users to follow others, chat with friends, add and view reels and share locations.'
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    color='social-media'
                                    alt='social-media-user'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-social-platfrom text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='The admin is responsible for managing and maintaining the website. They have access to the backend of the platform and can manage providers, packages, commission and other aspects of the website.'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    color='social-media-admin'
                                    alt='social-media-user'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center ptb--30">
                        <button className="get-a-quote btn-social-platfrom text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <React.Suspense fallback={<div>loading...</div>} >
                        {/* <VerbosSimilarApps color='custom-social-platform-color' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} /> */}
                        <VerbosSimilarApps color='custom-service-market-product' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                </div>
                <CommonPortfolioButtons class='btn-social-platform' text='View All Our Projects' padding='pb--20' />
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default SocialMediaPlatform;
