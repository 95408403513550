import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import { FiCheck } from "react-icons/fi";
import PlatformUsed from "../component/Verbos/PlatformUsed";
import { FaGlobe } from "react-icons/fa";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
// const CommonMasonry = React.lazy(()=>import  ("./CommonMasonry"))

// const PortfolioList = [
//     {
//         image: 'doctor_presentation-01',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'doctor_presentation-02',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'doctor_presentation-03',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     // {
//     //     image: 'image-4',
//     //     category: 'Development',
//     //     title: 'Getting tickets to the big show'
//     // },

// ]
// const SocialShare = [
//     { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
//     { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
//     { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
//     { Social: <FaTwitter />, link: 'https://twitter.com/' },
// ]
// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
const technologies = [
    { h5: 'React', p: 'Website Development', img: 'react.png' },
]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #8ab2ff 0%, #4636fc 100%)', link: '/healthcare-insurance-marketplace', img: 'healthcare.webp', h2: 'DocFinder', p: 'Healthcare Insurance Marketplace' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/primary-care-management-system', img: 'federate.webp', h2: 'Federate', p: 'Primry Care Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

const SocialShare = [
    { Social: <FaGlobe />, link: 'https://www.2snhealthcare.co.uk/' }
]

const buttonNames = ['Homepage', 'Services', 'Portfolio', 'Gallery', 'Technology', 'App Development & Tech', 'Research & Innovation', 'Medial Concierge', 'Training & Education', 'Medical Services'];
const imageAddress = [
    '/assets/images/portfolio/doctor_personal_presentation/doctor-1.webp',
    '/assets/images/portfolio/doctor_personal_presentation/doctor-3.webp',
    '/assets/images/portfolio/doctor_personal_presentation/doctor-4.webp',
    '/assets/images/portfolio/doctor_personal_presentation/doctor-5.webp',
    '/assets/images/portfolio/doctor_personal_presentation/doctor-6.webp',
    '/assets/images/portfolio/doctor_personal_presentation/doctor-7.webp',
    '/assets/images/portfolio/doctor_personal_presentation/doctor-8.webp',
    '/assets/images/portfolio/doctor_personal_presentation/doctor-9.webp',
    '/assets/images/portfolio/doctor_personal_presentation/doctor-10.webp',
    '/assets/images/portfolio/doctor_personal_presentation/doctor-11.webp',
];

class DoctorPersonalPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Doctor Personal Presentation' pageUrl='/doctor-personal-presentation' metaTitle="Doctor Personal Presentation" metaImage="/assets/images/portfolio/dp-portfolio-46.webp"
                    metaDescription="This is a professional networking and management platform for healthcare professionals. Features include a job application, professional profile management, rota management, smart collaboration and client/service management." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}

                <CommonBreadcrumb
                    title="Doctor Personal Presentation"
                    customColor="custom-coursier-color"
                    dataBlackOverlay={7}
                    bgImage="bg_image--85"
                    p="Web Application"
                    socialShare={SocialShare}
                    pb="pb--120"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-coursier-color">Doctor Personal Presentation</h3>
                                        <p className="justifypara">Doctor personal presentation is a powerhouse platform for the healthcare industry to build strong professional networks between doctos and hospitals. It enables doctors to securely apply for specific desired opportunites across locations, manage their professional profiles and network with other doctors. This system comes with customizable dashboards for healthcare workers, where they can manage thier services, clients, jobs activity, invoices, personal fils, notes, web article and many more.</p>
                                        <p className="justifypara">Doctor personal presentation system is a robust, integrated platform that has evolved over many years of understanding the core needs of healthcare workers by the people who have worked on the front line of the hospital structure. It has matured with the advent of contemporary technologies and today is available as a lightweight, contemporaryt downloadable files that healthcare workers can use to effectively manage rotas, easily manage for thier job and many more.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color="#2f64af" />Useful for primary care staff including doctors, advanced nurse practitioners, practice nurses, clinical pharmacists, health care assistances and admin staff</li>
                                            <li className="justifypara"><FiCheck color="#2f64af" />Build your professional network</li>
                                            <li className="justifypara"><FiCheck color="#2f64af" />Powers-up your profile</li>
                                            <li className="justifypara"><FiCheck color="#2f64af" />Rota management</li>
                                            <li className="justifypara"><FiCheck color="#2f64af" />Smart colaboration system</li>
                                            <li className="justifypara"><FiCheck color="#2f64af" />Manage events from calender</li>
                                            <li className="justifypara"><FiCheck color="#2f64af" />Add and manage services</li>
                                            <li className="justifypara"><FiCheck color="#2f64af" />Add and manage clients</li>
                                            <li className="justifypara"><FiCheck color="#2f64af" />Add and manage new job/task with clients</li>
                                            <li className="justifypara"><FiCheck color="#2f64af" />Add working files and manage them</li>
                                            <li className="justifypara"><FiCheck color="#2f64af" />Add notes and download them for offline use</li>
                                            <li className="justifypara"><FiCheck color="#2f64af" />Add web articles and read them</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-coursier'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ">
                    <div className="container">
                        <PlatformUsed color='custom-coursier-color' items={technologies} />
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-coursier'} paddingTop={'pt--30'} />
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Website'
                                    p='This website allows users to view information on a variety of healthcare products and services, including medical devices, pharmaceuticals, and healthcare IT solutions. Users can also view the technologies in which this website provides IT solutions, as well as the portfolio and gallery'
                                    buttonNames={buttonNames}
                                    image={imageAddress}
                                    alt='doctor-personal'
                                    color='sn'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-coursier'} paddingTop={'pt--30'} paddingBottom={`pb--30`} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-coursier-color' items={similarapps} title={'Apps'} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-coursier' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default DoctorPersonalPortfolioDetails;
