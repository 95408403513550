import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

// ]
// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
// const technologies = [

//     { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
//     { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
//     { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
//     { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },

// ]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

const userButtonNames = ['Homepage', 'About Us', 'Editorial Board', 'Instructions For Authors', 'Current Issue', 'Archives', 'Crossmark Policy', 'Contact Us', 'Registration', 'Login', 'Subscribe'];
const userImageAddress = [
    '/assets/images/portfolio/researcher/researcher-1.webp',
    '/assets/images/portfolio/researcher/researcher-2.webp',
    '/assets/images/portfolio/researcher/researcher-3.webp',
    '/assets/images/portfolio/researcher/researcher-4.webp',
    '/assets/images/portfolio/researcher/researcher-5.webp',
    '/assets/images/portfolio/researcher/researcher-6.webp',
    '/assets/images/portfolio/researcher/researcher-7.webp',
    '/assets/images/portfolio/researcher/researcher-8.webp',
    '/assets/images/portfolio/researcher/researcher-9.webp',
    '/assets/images/portfolio/researcher/researcher-10.webp',
    '/assets/images/portfolio/researcher/researcher-11.webp',
];

class ResearcherPersonalPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Researcher personal presentation' pageUrl='/researcher-personal-presentation' metaTitle="Researcher Personal Presentation" metaImage="/assets/images/portfolio/dp-portfolio-17.jpg"
                    metaDescription="Researcher personal presentation is an open access official peer-reviewed International Journal, publishing three Issues per year and allowing open access to abstracts and full-text." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Researcher Personal Presentation"
                    customColor="custom-researcher-color"
                    dataBlackOverlay={7}
                    bgImage="bg_image--59"
                    p="Web Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Web Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>NEWS & MAGAZINES</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>PHP, WordPress</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title custom-researcher-color">Researcher Personal Presentation</h3>
                                        <p className="justifypara">Researcher personal presentation is an open access official peer-reviewed International Journal Publishing three Issues per year and allowing open access to abstracts and full-text. The term “Neuro” is devoted to the promotion of Medical and related disciplines like Clinical research, Nursing research, Physiotherapy research, Occupational therapy research, Pharmaceutical research, Pharmacognosy & Phytochemistry, Pharmacology and Phytopharmacology, Pharmacy Practice, Clinical & Hospital Pharmacy with focus on various neurology updates on neurological disorders and there management.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck color={'#012296'} />Wider visibility through open access</li>
                                            <li><FiCheck color={'#012296'} />Manuscript Submission</li>
                                            <li><FiCheck color={'#012296'} />Archive & Indexing</li>
                                            <li><FiCheck color={'#012296'} />Navigation To Books</li>
                                            <li><FiCheck color={'#012296'} />Easy Navigation</li>
                                            <li><FiCheck color={'#012296'} />Online Submission</li>
                                            <li><FiCheck color={'#012296'} />Higher impact with wider visibility</li>
                                            <li><FiCheck color={'#012296'} />Double-blind peer-review</li>
                                            <li><FiCheck color={'#012296'} />DOI with Crossref</li>
                                            <li><FiCheck color={'#012296'} />Similarity Index Check</li>
                                            <li><FiCheck color={'#012296'} />Open-access peer-reviewed journal committed to publishing high-quality articles</li>
                                            <li><FiCheck color={'#012296'} />Crossref Content Registration and Digital Object Identifier</li>
                                            {/* <li><FiCheck color={'#012296'} />Alerts and notifications.</li> */}

                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-online-shop'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30"
                // style={{backgroundColor:'#012296'}}
                >
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Website'
                                    p='Users can submit their articles on the website, which will be published after being reviewed by experts. Additionally, users have the option to apply for the position of a reviewer.'
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt='researcher user'
                                    color='researcher-personal'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-online-shop'} paddingTop={'pt--30'} paddingBottom={`pb--20`} />
                    <div className="wrapper plr--30">
                    </div>
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-researcher-color' title={"Apps"} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-online-shop' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default ResearcherPersonalPortfolioDetails;
