import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
const CommonProductWeb = React.lazy(() => import('../portfolio/CommonProductWeb'))
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

const ImageAddress = [
    '/assets/images/our-products/lms/student/student-01.webp',
    '/assets/images/our-products/lms/student/student-02.webp',
    '/assets/images/our-products/lms/student/student-03.webp',
    '/assets/images/our-products/lms/student/student-04.webp',
    '/assets/images/our-products/lms/student/student-05.webp',
    '/assets/images/our-products/lms/student/student-06.webp',
    '/assets/images/our-products/lms/student/student-06.webp',
    '/assets/images/our-products/lms/student/student-07.webp',
    '/assets/images/our-products/lms/student/student-08.webp',
    '/assets/images/our-products/lms/student/student-09.webp',
]
const ButtonNames = ['Home', 'Login', 'My Profile', 'Course Description', 'Shopping Cart', 'My Courses', 'My Wishlist', 'My Messages', 'Purchase History']
// const SocialShare =[
//     {Social:<FaGlobe />,link:' https://annachi.fr/ '}
// ]

const instructorImageAddress = [
    '/assets/images/our-products/lms/instructor/instructor-1.webp',
    '/assets/images/our-products/lms/instructor/instructor-2.webp',
    '/assets/images/our-products/lms/instructor/instructor-3.webp',
    '/assets/images/our-products/lms/instructor/instructor-4.webp',
    '/assets/images/our-products/lms/instructor/instructor-5.webp',
    '/assets/images/our-products/lms/instructor/instructor-6.webp',
]
const instructorButtonNames = ['Home', 'Signup', 'My Profile', 'Dashboard', 'Courses', 'Add new courses'];
const adminImageAddress = [
    '/assets/images/our-products/lms/admin/admin-01.webp',
    '/assets/images/our-products/lms/admin/admin-02.webp',
    '/assets/images/our-products/lms/admin/admin-03.webp',
    '/assets/images/our-products/lms/admin/admin-04.webp',
    '/assets/images/our-products/lms/admin/admin-05.webp',
    '/assets/images/our-products/lms/admin/admin-06.webp',
    '/assets/images/our-products/lms/admin/admin-07.webp',
    '/assets/images/our-products/lms/admin/admin-08.webp',
    '/assets/images/our-products/lms/admin/admin-09.webp',
    '/assets/images/our-products/lms/admin/admin-10.webp',
    '/assets/images/our-products/lms/admin/admin-11.webp',
    '/assets/images/our-products/lms/admin/admin-12.webp',
]
const adminButtonNames = ['Dashboard', 'Courses', 'Add New Course', 'Categories', 'Coupons', 'Instructor Revenue', 'Purchase History', 'Add Admin', 'Add Instructor', 'Instructor Application', 'Instructor Payouts', 'Blogs'];
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [

    { h5: 'MySQL', p: 'Database', img: 'mysql.png' },
    { h5: 'PHP', p: 'Backend Development', img: 'php.png' },
    { h5: 'Codeigniter', p: 'Admin Panel Development', img: 'ci4.png' },
]

class LearningManagement extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isVisible: false
        }
        this.openModal = this.openModal.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Learning Management System' pageUrl='/learning-management' metaTitle="Digital Solution For Civil Documents" metaImage="/assets/images/portfolio/dp-portfolio-51.webp"
                    metaDescription="this is a platform for the healthcare industry to build strong professional networks between Administrators, Doctors, Advanced Nurse Practitioners, Clinical pharmacists, Healthcare Assistants and Admin Staff." />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Learning Management System"
                    // customColor="custom-coursier-color"
                    customColor="theme-gradient"
                    dataBlackOverlay={5}
                    bgImage="bg_image--107"
                    p="Web Application"
                    buttonTitle={'get a quote'}
                    openPopup={true}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h4><span>TECHNICAL DETAILS</span></h4> */}
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-coursier-color">Learning Management System</h3>
                                        <p className="justifypara">This learning management system serves as an online learning marketplace platform. It
                                            unites students and teachers to facilitate knowledge sharing via a structured course
                                            framework. Instructors have the flexibility to develop an unlimited number of courses, video
                                            lessons and instructional materials tailored to their expertise. Students can easily enrol in
                                            these courses, empowering themselves with new skills at their convenience and from any
                                            location.</p>
                                        <h3 className="rn-page-title custom-coursier-color">Student Panel Functionalities</h3>
                                        <p className="justifypara">Students can easily enrol in the courses, empowering themselves with new skills at their
                                            convenience and from any location.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#0096ff" />Purchase courses</li>
                                            <li><FiCheck color="#0096ff" />Search the course according to category</li>
                                            <li><FiCheck color="#0096ff" />Add course to the cart</li>
                                            <li><FiCheck color="#0096ff" />Make a wishlist</li>
                                            <li><FiCheck color="#0096ff" />See the purchased course list</li>
                                            <li><FiCheck color="#0096ff" />Update the profile</li>
                                            <li><FiCheck color="#0096ff" />Ask questions</li>
                                            <li><FiCheck color="#0096ff" />Comment on any purchased course</li>
                                        </ul>
                                        <h3 className="rn-page-title custom-coursier-color">Instructor Panel Functionalities</h3>
                                        <p className="justifypara">Instructors have the flexibility to develop an unlimited number of courses, video lessons and
                                            instructional materials tailored to their expertise.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#0096ff" />Manage students (add, edit and delete)</li>
                                            <li><FiCheck color="#0096ff" />Manage courses (add, edit and delete)</li>
                                            <li><FiCheck color="#0096ff" />Manage the enrollment of the students in a course</li>
                                            <li><FiCheck color="#0096ff" />Send a message to all students</li>
                                            <li><FiCheck color="#0096ff" />Manage payout requests</li>
                                            <li><FiCheck color="#0096ff" />Manage profile</li>
                                        </ul>
                                        <h3 className="rn-page-title custom-coursier-color">Admin Panel Functionalities</h3>
                                        <p className="justifypara">The admin panel facilitates comprehensive control over user management, course
                                            administration, category and sub-category oversight, instructor management, student
                                            enrollment supervision, messaging functionality and the efficient management of
                                            instructors' payouts.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#0096ff" />Manage sub-admin(add sub-admin, delete and edit and also assign permissions)</li>
                                            <li><FiCheck color="#0096ff" />Manage students (add, edit and delete)</li>
                                            <li><FiCheck color="#0096ff" />Manage categories (add, edit and delete)</li>
                                            <li><FiCheck color="#0096ff" />Manage sub-categories</li>
                                            <li><FiCheck color="#0096ff" />Manage instructors</li>
                                            <li><FiCheck color="#0096ff" />Manage the enrollment of the students in a course</li>
                                            <li><FiCheck color="#0096ff" />Can message to other users</li>
                                            <li><FiCheck color="#0096ff" />Manage instructors payout</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <div className="d-sm-block d-flex justify-content-center align-items-center pt--30">
                                            <button className="get-a-quote btn-lms text-center" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-coursier-color' items={technologies} />
                            <div className="d-flex justify-content-center align-items-center pt--30">
                                <button className="get-a-quote btn-lms text-center" onClick={this.showPopup}>
                                    <span>Get a quote </span>
                                </button>
                            </div>
                        </React.Suspense>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3={`Student Panel`}
                                    p={` Students can easily enrol in the courses, empowering themselves with new skills at their convenience and from any location.`}
                                    buttonNames={ButtonNames}
                                    image={ImageAddress}
                                    alt='lms student'
                                    color='lms'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-lms text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3={`Instructor Panel`}
                                    p={`Instructors have the flexibility to develop an unlimited number of courses, video lessons and instructional materials tailored to their expertise.`}
                                    buttonNames={instructorButtonNames}
                                    image={instructorImageAddress}
                                    alt='lms instructor'
                                    color='lms'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-lms text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >

                                <CommonProductWeb
                                    h3={`Admin Panel`}
                                    p={`The admin panel facilitates comprehensive control over user management, course administration, category and sub-category oversight, instructor management, student enrollment supervision, messaging functionality and the efficient management of instructors' payouts.`}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='lms admin'
                                    color='lms'
                                />

                                {/* <LearningManagementInstructorDemo h3={`Instructor Panel`}
                                    p={`Instructors have the flexibility to develop an unlimited number of courses, video lessons and instructional materials tailored to their expertise.`}
                                    buttonnames={instructorButtonNames} image={instructorImageAddress} /> 
                                <LearningManagementAdminDemo />*/}
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center ptb--30">
                        <button className="get-a-quote btn-lms text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <React.Suspense fallback={<div>loading...</div>} >
                        {/* <VerbosSimilarApps color='custom-coursier-color' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} /> */}
                        <VerbosSimilarApps color='custom-coursier-color' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                </div>
                <CommonPortfolioButtons class='btn-lms' text='View All Our Projects' padding='pb--20' />
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default LearningManagement;
