import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'Flutter', p: 'App Development', img: 'flutter.png' },
    { h5: 'Mysql', p: 'Database', img: 'mysql.png' },
    { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
]

const userAppButtonNames = [
    "Splash Screen", "Select Service",
    "Login", "Home", "Categories", "Provider",
    "Provider Services", "Bookings", "Video Call", "Profile",
    "Messages", "Transaction", "Review & Rating"
];

const userAppImageAddresses = [
    "/assets/images/portfolio/ndashaka/user/user-01.webp",
    "/assets/images/portfolio/ndashaka/user/user-02.webp",
    "/assets/images/portfolio/ndashaka/user/user-03.webp",
    "/assets/images/portfolio/ndashaka/user/user-04.webp",
    "/assets/images/portfolio/ndashaka/user/user-05.webp",
    "/assets/images/portfolio/ndashaka/user/user-06.webp",
    "/assets/images/portfolio/ndashaka/user/user-07.webp",
    "/assets/images/portfolio/ndashaka/user/user-08.webp",
    "/assets/images/portfolio/ndashaka/user/user-09.webp",
    "/assets/images/portfolio/ndashaka/user/user-10.webp",
    "/assets/images/portfolio/ndashaka/user/user-11.webp",
    "/assets/images/portfolio/ndashaka/user/user-12.webp",
    "/assets/images/portfolio/ndashaka/user/user-13.webp",
];
const providerButtonNames = [
    "Splash Screen", "Select Provider Service",
    "Login", "Add Categories", "Add Services", "Enquiries",
    "Bookings", "Ongoing Service", "Video Call", "Booking Accept Reject", "Services On Map",
    "Profile", "Customer Review", "Set Availability", "Earnings", "Messages", "Add Photos"
];

const providerImageAddresses = [
    "/assets/images/portfolio/ndashaka/provider/provider-01.webp",
    "/assets/images/portfolio/ndashaka/provider/provider-02.webp",
    "/assets/images/portfolio/ndashaka/provider/provider-03.webp",
    "/assets/images/portfolio/ndashaka/provider/provider-04.webp",
    "/assets/images/portfolio/ndashaka/provider/provider-05.webp",
    "/assets/images/portfolio/ndashaka/provider/provider-06.webp",
    "/assets/images/portfolio/ndashaka/provider/provider-07.webp",
    "/assets/images/portfolio/ndashaka/provider/provider-08.webp",
    "/assets/images/portfolio/ndashaka/provider/provider-09.webp",
    "/assets/images/portfolio/ndashaka/provider/provider-10.webp",
    "/assets/images/portfolio/ndashaka/provider/provider-11.webp",
    "/assets/images/portfolio/ndashaka/provider/provider-12.webp",
    "/assets/images/portfolio/ndashaka/provider/provider-13.webp",
    "/assets/images/portfolio/ndashaka/provider/provider-14.webp",
    "/assets/images/portfolio/ndashaka/provider/provider-15.webp",
    "/assets/images/portfolio/ndashaka/provider/provider-16.webp",
    "/assets/images/portfolio/ndashaka/provider/provider-17.webp",
];
class NDashakaPortfolio extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Market Place' pageUrl='/market-place' metaTitle="doctor-appointment" metaImage="/assets/images/portfolio/dp-portfolio-51.webp"
                    metaDescription={`This "Doctor Appointment System" is a comprehensive application designed to facilitate the
scheduling of doctor appointments and the purchase of medicines. It serves a diverse range
of users, including patients, doctors, pharmacies, and delivery personnel, offering a wide
array of functionalities to cater to each group's specific needs.`} />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Market Place"
                    customColor="custom-ndashaka-portfolio"
                    dataBlackOverlay={5}
                    bgImage="bg_image--121"
                    p="Android/iOS Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-ndashaka-portfolio">Market Place</h3>
                                        <p className="justifypara">Market Place is an on-demand service provider online platform that connects customers with professionals to enquire about any kind of service. Services may range from household work like plumbing, moving, and carpentry; to services that can be carried out in the merchants’ establishments like salons, spas, and photography. To enquire about a service, a customer will be required to search for providers near their desired location and select the preferred date and time, based on the provider’s availability. The service providers will create profiles so that their clients can view their skills and pricing.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#2f64af" />Easy to find service providers</li>
                                            <li><FiCheck color="#2f64af" />Verified provider accounts</li>
                                            <li><FiCheck color="#2f64af" />Defined working hours and days</li>
                                            <li><FiCheck color="#2f64af" />Stripe Integration</li>
                                            <li><FiCheck color="#2f64af" />Availability Management</li>
                                            <li><FiCheck color="#2f64af" />Multilingual Support</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-ndashaka'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-ndashaka-portfolio' items={technologies} />
                        </React.Suspense>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-ndashaka'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 pb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User'
                                    p='Users can search for service providers, explore their skills and pricing, and make service inquiries based on location, date, and time preferences on this platform.'
                                    buttonNames={userAppButtonNames}
                                    image={userAppImageAddresses}
                                    alt='ndashaka user'
                                    color='ndashaka'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-ndashaka'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Service Providers'
                                    p='Service providers on this platform experience a streamlined process for connecting with potential clients. They can register with ease, manage their profiles, and showcase their expertise.'
                                    buttonNames={providerButtonNames}
                                    image={providerImageAddresses}
                                    alt='ndashaka service provider'
                                    color='ndashaka'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-ndashaka'} paddingTop={'pt--30'} paddingBottom={'pb--30'} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-ndashaka-portfolio' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                </div>
                <CommonPortfolioButtons class='btn-ndashaka' text='View All Our Products' />

                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default NDashakaPortfolio;
