import React, { Component, Fragment } from "react";
import { Parallax } from "react-parallax";
import ScrollToTop from 'react-scroll-up';
import { Link } from "react-router-dom";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Counter from "./TourismCounter";
import Helmet from "../component/common/Helmet";
import TourismService from './TourismService';
import ModalVideo from 'react-modal-video';
import { videoTagString } from 'react-video-tag';
import IndustriesClientReveiw from "./IndustriesClientReveiw";
const TourismClient = React.lazy(() => import("./TourismClient"));

videoTagString({ src: '/assets/images/service/video.mp4', poster: '/assets/images/bg/bg-image-24.jpg' })

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Tourism Industry',
        description: '“We have been providing travel technology services to the World’s leading tourism organization since 2015”',
        buttonText: 'Know More',
        buttonLink: '/contact-us'
    }
]
const sliderImage =
    "/assets/images/bg/bg-image-11.webp";

const image1 =
    "/assets/images/bg/paralax/bg-image-2.webp";

const image2 =
    "/assets/images/bg/paralax/bg-image-3.webp";

const image3 =
    "/assets/images/bg/paralax/bg-image-4.webp";


const image6 =
    "/assets/images/bg/paralax/bg-image-6.webp";

const image7 =
    "/assets/images/bg/paralax/bg-image-7.webp";

const image8 =
    "/assets/images/bg/paralax/bg-image-8.webp";


const PortfolioList = [
    {
        image: 'image-7',
        category: 'Web and Mobile Applications',
        title: 'Hotel Booking Platform',
        url: '/hotel-booking-platform'
    },
    {
        image: 'image-1',
        category: 'Web Applications',
        title: 'Tourism Company Management System',
        url: '/tourism-company-management-system'
    },
    {
        image: 'image-8',
        category: 'Web and Mobile Applications',
        title: 'Ticket Booking Platform',
        url: '/ticket-booking-platform'
    }
]

const TourismTest = [
    {
        textPosition: 'inner2 text-center',
        bgImage: 'bg_image--28',
        category: "We are thankful to Noorisys Technologies on supporting us in such a big project of developing our Government approved Online Travel Agency integrated with Maqam GDS. We wish Noorisys Technologies a success in their bright future as well",
        title: '',
        description: '',
        buttonText: '',
        buttonLink: '',
        name: '- KHALID IDRIS',
        img: '/assets/images/client/khalid_idris_img.webp',
        alt: 'khalid'
    },
]

class Tourism extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <Fragment>
                <Helmet pageTitle="Tourism || Noorisys Technologies Pvt Ltd" pageUrl='/tourism' metaTitle="Tourism" metaImage="/assets/images/bg/bg-image-20.jpg"
                    metaDescription="Revolutionize your tourism business with the help of Noorisys. We provide innovative software solutions for travel agencies, hotels, and online booking platforms." />

                <Header />

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    <Parallax bgImage={sliderImage} strength={700}>
                        {SlideList.map((value, index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-solid" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Parallax>
                </div>
                {/* End Slider Area   */}

                {/* Start Brand Area */}
                <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={image3} strength={1000}>
                    <div className="brand-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <TourismClient />
                                </div>
                            </div>
                        </div>
                    </div>

                </Parallax>
                {/* End Brand Area */}

                {/* Start Service Area  */}
                <Parallax className="rn-paralax-service" bgImage={image1} strength={1000}>
                    <div className="service-area ptb--120">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                                        <h2>Tourism Segments</h2>
                                        <p>Tourism is not just a word, it is a world we are coding for you !</p>
                                    </div>
                                </div>
                            </div>
                            <TourismService item="6" column="col-lg-4 col-md-6 col-sm-6 col-12" />
                        </div>
                    </div>
                </Parallax>
                {/* End Service Area  */}



                {/* Start Service Area  */}
                <Parallax className="rn-paralax-portfolio" bgImage={image2} strength={500} >
                    <div className="portfolio-area ptb--120" data-black-overlay="6">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                                        <h2>Our Works</h2>
                                        <p>Get insights into travel technology solutions developed by us. </p>
                                        <p> From redefined IT products to the wondering ideas, we have mastered it all</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                {/* Start Single Portfolio  */}
                                {PortfolioList.map((value, index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--40" key={index}>
                                        <div className="portfolio">
                                            <div className="thumbnail-inner">
                                                <div className={`thumbnail ${value.image}`}></div>
                                                <div className={`bg-blr-image ${value.image}`}></div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4><Link to={value.url}>{value.title}</Link></h4>
                                                    <div className="portfolio-button">
                                                        <Link className="rn-btn" to={value.url}>Case Study</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link className="link-overlay" to={value.url}></Link>
                                        </div>
                                    </div>
                                ))}
                                {/* End Single Portfolio  */}
                            </div>
                        </div>
                    </div>
                </Parallax>
                {/* End Service Area  */}


                {/* Start CounterUp Area */}
                <Parallax className="rn-counterup-area rn-paralax-counterup" bgImage={image6} strength={1000} >
                    <div className="counterup-area ptb--120">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title service-style--2 text-center mb--30 mb_sm--0">
                                        <h3>We Promise To Deliver The Best</h3>
                                    </div>
                                </div>
                            </div>
                            <Counter />
                        </div>
                    </div>
                </Parallax>
                {/* End CounterUp Area */}

                {/* Start Testimonial Area */}
                <Parallax className="rn-testimonial-area rn-paralax-testimonial  rn-testimonial-light" bgImage={image7} strength={500} data-black-overlay="5">
                    <div className="testimonial-area ptb--120" data-black-overlay="6">
                        <div className="container">
                            {/* <div className="slider-wrapper">
                             
                                {TourismTest.map((value, index) => (
                                    <div className="slide designer-portfolio2 slider-style-3  d-flex align-items-center justify-content-center rn-slider-height" key={index}>
                                        <div className="container">
                                            <div className="row align-items-center">

                                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                                    <div className={`inner2 ${value.textPosition}`}>
                                                        {value.category ? <span >{value.category}</span> : ''}
                                                        <span className='text-center'>- KHALID IDRIS</span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-5">
                                                    <div className="designer-thumbnail">
                                                        <img src="/assets/images/client/khalid_idris_img.webp" alt="khalid" loading="lazy" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                           
                            </div> */}
                            <IndustriesClientReveiw Testimonial={TourismTest} bgColor={''} />
                        </div>
                    </div>
                </Parallax>
                {/* End Testimonial Area */}



                <Parallax className="rn-blog-area rn-testimonial-light" bgImage={image8} strength={700} data-black-overlay="7">
                    <div className="blog-area ptb--120" data-black-overlay="6">
                        <div className="rn-section">
                            <div className="container">
                                <div className="row sercice-details-content align-items-center">
                                    <div className="col-lg-12">
                                        <div className="thumb position-relative">
                                            <img className="w-100" src="/assets/images/blog/thumbnail_video.jpg" alt="Noorisys" />
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='h5sQUF5nTS4' onClose={() => this.setState({ isOpen: false })} />
                                            {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='NaAjhBbxjoE' onClose={() => this.setState({ isOpen: false })} /> */}
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Parallax>




                <Parallax className="rn-brand-area brand-separation ptb--120" bgImage={image3} strength={1000}>
                    <div className="brand-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <React.Suspense fallback={<div>loading...</div>} >
                                        <TourismClient />
                                    </React.Suspense>
                                </div>
                            </div>
                        </div>
                    </div>

                </Parallax>



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </Fragment>
        )
    }
}
export default Tourism;