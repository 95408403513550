import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
// import CoursierDelivrable from "./CoursierDeliverables";
import PlatformUsed from "../component/Verbos/PlatformUsed";
// import VerbosSimilarApps from "../component/Verbos/VerbosSimilarApps";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
// import CarSpaceSpeed from "./CarSpacespeed";
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
// import GroceriesAdminDemo from "./GroceriesAdminDemo";

// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users-annachi.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users-annachi.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users-annachi.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'web.png', item_icon: 'admin-annachi.png', h3: 'Admin ', h4: 'Web' },
// ]

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

const technologies = [
    { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },
    { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
    { h5: 'Angular', p: 'Customer and Dealer Panel Development', img: 'angular.png' },
];

const customerButtonNames = ['Login & Registration', 'Login Page', 'Registration', 'Dashboard', 'Search Cars By Filters', 'Car Overview', 'My Profile'];
const customerImageAddress = [
    '/assets/images/portfolio/car-space/customer/customer-1.webp',
    '/assets/images/portfolio/car-space/customer/customer-2.webp',
    '/assets/images/portfolio/car-space/customer/customer-3.webp',
    '/assets/images/portfolio/car-space/customer/customer-4.webp',
    '/assets/images/portfolio/car-space/customer/customer-5.webp',
    '/assets/images/portfolio/car-space/customer/customer-6.webp',
    '/assets/images/portfolio/car-space/customer/customer-7.webp',
];

const dealerButtonNames = ['Login & Registration', 'Login Page', 'Registration', 'Dashboard', 'Locations', 'My Profile', 'My Plots', 'Plot Booking', 'Listed Cars', 'Add New Cars', 'Car Overview'];
const dealerImageAddress = [
    '/assets/images/portfolio/car-space/dealer/dealer-1.webp',
    '/assets/images/portfolio/car-space/dealer/dealer-2.webp',
    '/assets/images/portfolio/car-space/dealer/dealer-3.webp',
    '/assets/images/portfolio/car-space/dealer/dealer-4.webp',
    '/assets/images/portfolio/car-space/dealer/dealer-11.webp',
    '/assets/images/portfolio/car-space/dealer/dealer-6.webp',
    '/assets/images/portfolio/car-space/dealer/dealer-5.webp',
    '/assets/images/portfolio/car-space/dealer/dealer-7.webp',
    '/assets/images/portfolio/car-space/dealer/dealer-8.webp',
    '/assets/images/portfolio/car-space/dealer/dealer-9.webp',
    '/assets/images/portfolio/car-space/dealer/dealer-10.webp',
];

const adminButtonNames = ['Dashboard', 'Manage Customers', 'Manage Dealers', 'Add Rental Locations', 'Manage Rental Locations', 'Plots Availability', 'Add Plots', 'Manage Plots', 'Manage Car Brands', 'Manage Featured Cars', 'Manage Admins'];
const adminImageAddress = [
    '/assets/images/portfolio/car-space/admin/admin-1.webp',
    '/assets/images/portfolio/car-space/admin/admin-2.webp',
    '/assets/images/portfolio/car-space/admin/admin-3.webp',
    '/assets/images/portfolio/car-space/admin/admin-4.webp',
    '/assets/images/portfolio/car-space/admin/admin-5.webp',
    '/assets/images/portfolio/car-space/admin/admin-6.webp',
    '/assets/images/portfolio/car-space/admin/admin-7.webp',
    '/assets/images/portfolio/car-space/admin/admin-8.webp',
    '/assets/images/portfolio/car-space/admin/admin-9.webp',
    '/assets/images/portfolio/car-space/admin/admin-10.webp',
    '/assets/images/portfolio/car-space/admin/admin-11.webp',
];

class CarSpacePortfolio extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Car Space Rental Platform' pageUrl='/car-space-rental-platform' metaTitle="Car Space Rental Platform" metaImage="/assets/images/portfolio/dp-portfolio-03.webp"
                    metaDescription="Let our experts build your eCommerce business by taking advantage of the most modern technologies available for building an online grocery store."
                    metaKeywords="best food delivery app uk,food delivery app uk,cheapest food delivery app uk" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Car Space Rental Platform"
                    customColor="custom-car-space"
                    dataBlackOverlay={7}
                    bgImage="bg_image--34"
                    p="Web Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-car-space">Car Space Rental Platform</h3>
                                        <p className="justifypara">On this platform, customers can explore various car details such as brand, condition, year, price, assigned plot for the cars and location. Dealers can search locations, book available plots and assign cars to selected plot. The admin oversees the platform's operations, moderates listings, manages rental locations and ensures a seamless experience for both customers and dealers.</p>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-car-space'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                <div className="pb--20">
                    <div className="container">
                        <PlatformUsed color='custom-car-space' items={technologies} />
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-car-space'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 ptb--20">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                {/* <CarSpaceSpeed /> */}
                                <CommonProductWeb
                                    h3={`Customer's Panel`}
                                    p='The website provides registration, login and profile management for customers. They can search and view cars listed on the website, including details such as condition, brand, year, fuel type, price etc. Customers can also view car location information, including photos and dealer contact details.'
                                    buttonNames={customerButtonNames}
                                    image={customerImageAddress}
                                    alt='car space customer'
                                    color='car-space'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-car-space'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3={`Dealer's Panel`}
                                    p='Dealers can register, login and manage their profiles on the website. They can add and edit car details, including condition, brand, year, fuel type, price etc. Dealers can also upload photos and provide descriptions for their listed cars. The website allows dealers to manage their listed cars. Additionally, dealers have the capability to book available plots for their cars and assign cars to their purchased plots.'
                                    buttonNames={dealerButtonNames}
                                    image={dealerImageAddress}
                                    alt='car space dealer'
                                    color='car-space'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-car-space'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='Admin can manage user accounts, including customers and dealers. The admin also has the authority to manage rental locations, add plots to each location. They can monitor and moderate listings, view booking activities and ensure the smooth functioning of the platform.'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='car space admin'
                                    color='car-space'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-car-space'} paddingTop={'pt--30'} />
                </div>
                <React.Suspense fallback={<div>loading...</div>} >
                    <VerbosSimilarApps color='custom-car-space' title={'Apps'} items={similarapps} slidestoshow={[3, 2]} />
                </React.Suspense>
                <CommonPortfolioButtons class='btn-car-space' text='View All Our Projects' />
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default CarSpacePortfolio;
