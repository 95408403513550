import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

// const SocialShare =[
//     {Social:<FaGlobe />,link:' https://annachi.fr/ '}
// ]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'PHP', p: 'Admin Panel', img: 'php.png' },
    { h5: 'CodeIgniter', p: 'Backend Development', img: 'ci4.png' },
    { h5: 'Mysql', p: 'Database', img: 'mysql.png' },
    // { h5: 'Native  Android', p: 'App Development', img: 'native.png' },
]




const adminButtonNames = ['Login', 'Dashboard', 'Manage Users', 'Manage Study Test', 'Manage Test Price', 'Edit Profile', 'Change Password'];
const adminImageAddress = [
    '/assets/images/portfolio/study-matching/admin/admin-01.webp',
    '/assets/images/portfolio/study-matching/admin/admin-02.webp',
    '/assets/images/portfolio/study-matching/admin/admin-03.webp',
    '/assets/images/portfolio/study-matching/admin/admin-04.webp',
    '/assets/images/portfolio/study-matching/admin/admin-05.webp',
    '/assets/images/portfolio/study-matching/admin/admin-06.webp',
    '/assets/images/portfolio/study-matching/admin/admin-07.webp',
];

const userButtonNames = ['Home', 'Register', 'Login', 'Change Password', 'Reset Password', 'Forgot Password', 'Study Advice', 'Personal Test Page 1', 'Personal Test Page 2', 'Study Selection Test', 'Dashboard', 'My Tests', 'How It Works', 'About Study Selection Test', 'Testimonial', 'About', 'Contact Us',];
const userImageAddress = [
    '/assets/images/portfolio/study-matching/user/user-01.webp',
    '/assets/images/portfolio/study-matching/user/user-02.webp',
    '/assets/images/portfolio/study-matching/user/user-03.webp',
    '/assets/images/portfolio/study-matching/user/user-16.webp',
    '/assets/images/portfolio/study-matching/user/user-17.webp',
    '/assets/images/portfolio/study-matching/user/user-07.webp',
    '/assets/images/portfolio/study-matching/user/user-04.webp',
    '/assets/images/portfolio/study-matching/user/user-05.webp',
    '/assets/images/portfolio/study-matching/user/user-06.webp',
    '/assets/images/portfolio/study-matching/user/user-08.webp',
    '/assets/images/portfolio/study-matching/user/user-10.webp',
    '/assets/images/portfolio/study-matching/user/user-09.webp',
    '/assets/images/portfolio/study-matching/user/user-11.webp',
    '/assets/images/portfolio/study-matching/user/user-12.webp',
    '/assets/images/portfolio/study-matching/user/user-13.webp',
    '/assets/images/portfolio/study-matching/user/user-14.webp',
    '/assets/images/portfolio/study-matching/user/user-15.webp',

];
class StudyMatchingPortfolio extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Study Matching Platform' pageUrl='/study-matching' metaTitle="Digital Solution For Civil Documents" metaImage="/assets/images/portfolio/dp-portfolio-51.webp"
                    metaDescription="A comprehensive solution that seamlessly incorporates all the essential features for
managing a successful food delivery business. From multi-language support and precise
coverage area mapping to streamlined order management and versatile payment gateways,
our app has it all" />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Study Matching Platform"
                    customColor="custom-study-matching-portfolio"
                    dataBlackOverlay={5}
                    bgImage="bg_image--109"
                    p="Web Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-study-matching-portfolio">Study Matching Platform</h3>
                                        <p className="justifypara">The Study Matching Platform is a comprehensive web application designed to connect users with study programs that align with their interests and goals. The platform offers two key features: a free Personality Test and a paid Study Interest Test. These assessments help users gain insights into their preferences and match them with suitable educational programs. </p>
                                        <p className="justifypara">Upon completing the tests, users receive dynamically generated PDF reports tailored to their responses, providing detailed recommendations and insights. The platform supports seamless test resumption, allowing users to continue their assessments at their convenience.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#F07317" />Personality test</li>
                                            <li><FiCheck color="#F07317" />Study interest test</li>
                                            <li><FiCheck color="#F07317" />Test resumption</li>
                                            <li><FiCheck color="#F07317" />Dynamically generated PDFs based on user responses</li>
                                            <li><FiCheck color="#F07317" />Payment gateway</li>
                                            <li><FiCheck color="#F07317" />Invoice generation</li>
                                            <li><FiCheck color="#F07317" />Multilingual support</li>
                                            <li><FiCheck color="#F07317" />Notifications</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-study-matching'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-study-matching-portfolio' items={technologies} />
                        </React.Suspense>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-study-matching'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 pb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='User'
                                    p={`Users on the Study Matching Platform engage in free Personality Tests and paid Study Interest Tests, receiving tailored PDF reports to explore personalized study program recommendations.`}
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt="User"
                                    color='study-matching-user'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-study-matching'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin'
                                    p={` Admins on the Study Matching Platform have comprehensive control, managing user details, test content, static pages, pricing, and receiving notifications for key tasks, ensuring a smooth and efficient platform operation.`}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt="Admin"
                                    color='study-matching'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-study-matching'} paddingTop={'pt--30'} paddingBottom={'pb--30'} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-study-matching-portfolio' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-study-matching' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default StudyMatchingPortfolio;
