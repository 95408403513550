import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class PartnerTab extends Component {
    render() {
        let
            tab1 = "Mutual Benefits",
            tab2 = "Transparency",
            tab3 = " Cost-Efficient",
            tab4 = "Support ";
        const { tabStyle } = this.props
        return (
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                        <Tab>{tab4}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a>Ongoing Profits:<span> Earn recurring revenue through ongoing services and support.</span></a>

                                                </li>
                                                <li>
                                                    <a>New Endeavors:<span> Jointly exploring and entering new markets or industries.</span></a>

                                                </li>
                                                <li>
                                                    <a>Sustainability:<span> Building a long-lasting and successful partnership.</span></a>

                                                </li>
                                                <li>
                                                    <a>Expertise:<span> Access to our team of experts who can help you deliver solutions more efficiently.</span></a>

                                                </li>
                                                <li>
                                                    <a>Expansion:<span> Access to our extensive network of clients and partners, enabling both parties to expand their reach and customer base.</span></a>

                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li><a>Decision-making:<span> Joint decision-making and collaboration ensure both parties are reaping equal benefits.</span></a>
                                                </li>
                                                <li>
                                                    <a>Mutual benefits:<span> Transparency in pricing and revenue sharing ensures that both parties are earning equal profits.</span></a>
                                                </li>
                                                <li>
                                                    <a>Transparency:<span> Regular communication and reporting to keep informed of the progress and performance.</span></a>
                                                </li>
                                                <li>
                                                    <a>Efficacy:<span> Commitment to quality and customer satisfaction.</span></a>
                                                </li>
                                                <li>
                                                    <a>Contentment:<span> Long-term partnership opportunities that deliver ongoing benefits.</span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>



                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a>Affordable:<span> Competitive pricing and flexible payment options.</span></a>
                                                </li>
                                                <li>
                                                    <a>Efficient:<span> No investment is required in infrastructure, hiring, or training.</span></a>
                                                </li>
                                                <li>
                                                    <a>Trending Technologies:<span> Improved efficiency and reduced overhead costs with our technology solutions.</span></a>
                                                </li>
                                                <li>
                                                    <a>Support:<span> Access to a highly skilled and experienced team of professionals.</span></a>
                                                </li>
                                                <li>
                                                    <a>Competitive market rates:<span> Reduced operating costs and improved efficiency, enabling you to deliver projects more quickly and efficiently.</span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            <ul>
                                                <li>
                                                    <a>Advancement:<span> Access to a team of professionals with extensive technical expertise.</span></a>
                                                </li>
                                                <li>
                                                    <a>Expertise:<span> Strategic guidance and project management support to minimize risk and improve efficiency.</span></a>
                                                </li>
                                                <li>
                                                    <a>Fulfillment:<span> Assistance with achieving your desired outcomes more quickly and effectively.</span></a>
                                                </li>
                                                <li>
                                                    <a>Attainment:<span> Expert guidance and support throughout the project lifecycle.</span></a>
                                                </li>
                                                <li>
                                                    <a>Support:<span> Available 24/7 to address any issues or concerns that arise, ensuring that your clients are always in good hands.</span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </TabPanel>

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default PartnerTab;