import React, { Component } from "react";
// import ContactForm from "../creative_homePage/ContactForm";
import WebDevelopmentForm from "./Web_DevelopmentForm";

class Web_Development_ContactPage extends Component {
    vacancy = this.props.vacancy
    application = this.props.application
    render() {
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h3 className="title">{this.vacancy}</h3>
                                <p className="description">Drop your CV</p>
                            </div>
                            <div className="form-wrapper">
                                <WebDevelopmentForm vacancy={this.vacancy} application={this.application} />
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/about/about-6.webp" alt="Contact Us" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Web_Development_ContactPage;