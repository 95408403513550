import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
const ProductCommonApp = React.lazy(() => import("../portfolio/ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("../portfolio/CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));


// const SocialShare =[
//     {Social:<FaGlobe />,link:' https://annachi.fr/ '}
// ]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'PHP', p: 'Admin Panel', img: 'php.png' },
    { h5: 'CodeIgniter', p: 'Backend Development', img: 'ci4.png' },
    { h5: 'Mysql', p: 'Database', img: 'mysql.png' },
    // { h5: 'Native  Android', p: 'App Development', img: 'native.png' },
    { h5: 'Flutter', p: 'App Development', img: 'flutter.png' },
]
const userAppButtonNames = [
    "Login", "Home", "Categories", "Near by Providers", "Services",
    "About", "Gallery", "Upcoming Bookings", "Past Bookings",
    "Bookings Filter", "Booking Details", "Profile", "Post a Job",
    "Provider Reviews", "Latest Blogs", "Blog Details"
];
const userAppImageAddress = [
    '/assets/images/our-products/market-services/user-mobile/user-01.webp',
    '/assets/images/our-products/market-services/user-mobile/user-02.webp',
    '/assets/images/our-products/market-services/user-mobile/user-03.webp',
    '/assets/images/our-products/market-services/user-mobile/user-04.webp',
    '/assets/images/our-products/market-services/user-mobile/user-05.webp',
    '/assets/images/our-products/market-services/user-mobile/user-06.webp',
    '/assets/images/our-products/market-services/user-mobile/user-07.webp',
    '/assets/images/our-products/market-services/user-mobile/user-08.webp',
    '/assets/images/our-products/market-services/user-mobile/user-09.webp',
    '/assets/images/our-products/market-services/user-mobile/user-10.webp',
    '/assets/images/our-products/market-services/user-mobile/user-11.webp',
    '/assets/images/our-products/market-services/user-mobile/user-12.webp',
    '/assets/images/our-products/market-services/user-mobile/user-13.webp',
    '/assets/images/our-products/market-services/user-mobile/user-14.webp',
    '/assets/images/our-products/market-services/user-mobile/user-15.webp',
    '/assets/images/our-products/market-services/user-mobile/user-16.webp',
];
const providerAppButtonNames = [
    "Login", "Upcoming Bookings", "Past Bookings", "Jobs", "Job Details",
    "Messages", "Chat", "My Earnings", "Notifications", "Set Availability",
    "Services", "Add Services"
];
const providerAppImageAddress = [
    '/assets/images/our-products/market-services/provider-mobile/user-01.webp',
    '/assets/images/our-products/market-services/provider-mobile/user-02.webp',
    '/assets/images/our-products/market-services/provider-mobile/user-03.webp',
    '/assets/images/our-products/market-services/provider-mobile/user-04.webp',
    '/assets/images/our-products/market-services/provider-mobile/user-05.webp',
    '/assets/images/our-products/market-services/provider-mobile/user-06.webp',
    '/assets/images/our-products/market-services/provider-mobile/user-07.webp',
    '/assets/images/our-products/market-services/provider-mobile/user-08.webp',
    '/assets/images/our-products/market-services/provider-mobile/user-09.webp',
    '/assets/images/our-products/market-services/provider-mobile/user-10.webp',
    '/assets/images/our-products/market-services/provider-mobile/user-11.webp',
    '/assets/images/our-products/market-services/provider-mobile/user-12.webp',
];

const userWebButtonNames = [
    "Home", "Dashboard", "Post Job", "Bookings", "Contact", "Profile",
    "Payment Method", "Provider Reviews", "Transaction History", "Nearby Providers",
    "Messages", "My Post", "Change Password"
];

// Array of image addresses
const userWebImageAddresses = [
    "/assets/images/our-products/market-services/user-web/user-01.webp",
    "/assets/images/our-products/market-services/user-web/user-02.webp",
    "/assets/images/our-products/market-services/user-web/user-03.webp",
    "/assets/images/our-products/market-services/user-web/user-04.webp",
    "/assets/images/our-products/market-services/user-web/user-05.webp",
    "/assets/images/our-products/market-services/user-web/user-06.webp",
    "/assets/images/our-products/market-services/user-web/user-07.webp",
    "/assets/images/our-products/market-services/user-web/user-08.webp",
    "/assets/images/our-products/market-services/user-web/user-09.webp",
    "/assets/images/our-products/market-services/user-web/user-10.webp",
    "/assets/images/our-products/market-services/user-web/user-11.webp",
    "/assets/images/our-products/market-services/user-web/user-12.webp",
    "/assets/images/our-products/market-services/user-web/user-13.webp",
];
const providerButtonNames = [
    "Home", "Dashboard",
    "Bookings Upcoming", "Bookings Past", "Bookings Disputed",
    "My Account", "Service Availability", "Choose Category",
    "Services", "Customer Reviews", "Gallery", "Transaction History",
    "Withdraw Transaction", "My Proposals", "View Jobs", "Add Services"
];

const providerImageAddress = [
    "/assets/images/our-products/market-services/provider-web/provider-01.webp",
    "/assets/images/our-products/market-services/provider-web/provider-02.webp",
    "/assets/images/our-products/market-services/provider-web/provider-03.webp",
    "/assets/images/our-products/market-services/provider-web/provider-04.webp",
    "/assets/images/our-products/market-services/provider-web/provider-05.webp",
    "/assets/images/our-products/market-services/provider-web/provider-06.webp",
    "/assets/images/our-products/market-services/provider-web/provider-07.webp",
    "/assets/images/our-products/market-services/provider-web/provider-08.webp",
    "/assets/images/our-products/market-services/provider-web/provider-09.webp",
    "/assets/images/our-products/market-services/provider-web/provider-10.webp",
    "/assets/images/our-products/market-services/provider-web/provider-11.webp",
    "/assets/images/our-products/market-services/provider-web/provider-12.webp",
    "/assets/images/our-products/market-services/provider-web/provider-13.webp",
    "/assets/images/our-products/market-services/provider-web/provider-14.webp",
    "/assets/images/our-products/market-services/provider-web/provider-15.webp",
    "/assets/images/our-products/market-services/provider-web/provider-16.webp"
];
const adminButtonNames = [
    "Dashboard",
    "Categories",
    "Services",
    "Manage Jobs",
    "Manage User",
    "Manage Mediator",
    "Manage Booking",
    "Manage Wallet"
];

const adminImageAddresses = [
    "/assets/images/our-products/market-services/admin/admin-01.webp",
    "/assets/images/our-products/market-services/admin/admin-02.webp",
    "/assets/images/our-products/market-services/admin/admin-03.webp",
    "/assets/images/our-products/market-services/admin/admin-04.webp",
    "/assets/images/our-products/market-services/admin/admin-05.webp",
    "/assets/images/our-products/market-services/admin/admin-06.webp",
    "/assets/images/our-products/market-services/admin/admin-07.webp",
    "/assets/images/our-products/market-services/admin/admin-08.webp"
];
class ServiceMarketPlace extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isVisible: false
        }
        this.openModal = this.openModal.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Service Provider Marketplace' pageUrl='/learning-management' metaTitle="Digital Solution For Civil Documents" metaImage="/assets/images/portfolio/dp-portfolio-51.webp"
                    metaDescription="this is a platform for the healthcare industry to build strong professional networks between Administrators, Doctors, Advanced Nurse Practitioners, Clinical pharmacists, Healthcare Assistants and Admin Staff." />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Service Provider Marketplace"
                    // customColor="custom-service-market-product"
                    customColor="theme-gradient"
                    dataBlackOverlay={5}
                    bgImage="bg_image--103"
                    p="Web/Android/iOS Application"
                    buttonTitle={'get a quote'}
                    openPopup={true}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h4><span>TECHNICAL DETAILS</span></h4> */}
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-service-market-product">Service Provider Marketplace</h3>
                                        <p className="justifypara">This comprehensive platform seamlessly connects users and service providers through a
                                            user-friendly mobile app and web interface. Users can effortlessly register via social media
                                            profiles or email, validate their registration through OTP, and enjoy features like
                                            location-based service provider searches, detailed provider profiles, scheduling and
                                            managing bookings, messaging capabilities and a robust review system. The platform
                                            empowers service providers with a user-friendly dashboard to handle bookings, manage
                                            services, track earnings, and engage with users through secure messaging.</p>
                                        <h3 className="rn-page-title custom-service-market-product">User Functionalities</h3>
                                        <p className="justifypara">Users can effortlessly register via social media profiles or email, validate their registration
                                            through OTP, and enjoy features like location-based service provider searches, detailed
                                            provider profiles, scheduling and managing bookings, messaging capabilities, and a robust
                                            review system.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#9700FF" />Registration and Login</li>
                                            <li><FiCheck color="#9700FF" />View Service Provider List</li>
                                            <li><FiCheck color="#9700FF" />Schedule a Service Booking</li>
                                            <li><FiCheck color="#9700FF" />View Bookings</li>
                                            <li><FiCheck color="#9700FF" />Add Reviews and Ratings</li>
                                            <li><FiCheck color="#9700FF" />Post a Job</li>
                                            <li><FiCheck color="#9700FF" />Chat, Audio Video Calls</li>
                                            <li><FiCheck color="#9700FF" /> Profile Management</li>
                                        </ul>
                                        <h3 className="rn-page-title custom-service-market-product">Provider Functionalities</h3>
                                        <p className="justifypara">Providers can effortlessly register, manage their services, handle bookings, engage in
                                            reviews, apply for jobs and communicate through chat and audio/video calls.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#9700FF" />Registration and Login</li>
                                            <li><FiCheck color="#9700FF" />My Services</li>
                                            <li><FiCheck color="#9700FF" />Manage Bookings</li>
                                            <li><FiCheck color="#9700FF" />View Bookings</li>
                                            <li><FiCheck color="#9700FF" />Add Reviews and Ratings</li>
                                            <li><FiCheck color="#9700FF" />Apply to Posted Jobs</li>
                                            <li><FiCheck color="#9700FF" />Chat, Audio Video Calls</li>
                                            <li><FiCheck color="#9700FF" />Profile Management</li>
                                        </ul>
                                        <h3 className="rn-page-title custom-service-market-product">Admin Panel Functionalities</h3>
                                        <p className="justifypara">The admin panel serves as the central hub for overseeing and optimizing the dynamic
                                            connection between users and service providers in a seamless mobile and web interface. It
                                            enables efficient management of user and provider data, financial transactions, service
                                            categories, commission structures, and booking activities through a comprehensive
                                            dashboard.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#9700FF" />Dashboard</li>
                                            <li><FiCheck color="#9700FF" />Manage Users</li>
                                            <li><FiCheck color="#9700FF" />Manage Providers</li>
                                            <li><FiCheck color="#9700FF" />Manage Transactions</li>
                                            <li><FiCheck color="#9700FF" />Manage Categories and Subcategories</li>
                                            <li><FiCheck color="#9700FF" />Manage Commission</li>
                                            <li><FiCheck color="#9700FF" />View Bookings</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <div className="d-sm-block d-flex justify-content-center align-items-center pt--30">
                                            <button className="get-a-quote btn-service-market text-center" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-service-market-product' items={technologies} />
                        </React.Suspense>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-service-market text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='Users can effortlessly register via social media profiles or email, validate their registration through OTP, and enjoy features like location-based service provider searches, detailed provider profiles, scheduling and managing bookings, messaging capabilities, and a robust review system.'
                                    buttonNames={userAppButtonNames}
                                    image={userAppImageAddress}
                                    alt='service marketplace user'
                                    color='service-marketplace-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-service-market text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Provider App'
                                    p='Providers can effortlessly register, manage their services, handle bookings, engage in reviews, apply for jobs and communicate through chat and audio/video calls.'
                                    buttonNames={providerAppButtonNames}
                                    image={providerAppImageAddress}
                                    alt='service marketplace provider'
                                    color='service-marketplace-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-service-market text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='User Panel'
                                    p='Users can effortlessly register via social media profiles or email, validate their registration through OTP, and enjoy features like location-based service provider searches, detailed provider profiles, scheduling and managing bookings, messaging capabilities, and a robust review system.'
                                    buttonNames={userWebButtonNames}
                                    image={userWebImageAddresses}
                                    alt='service marketplace user web'
                                    color='service-marketplace-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-service-market text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Provider Panel'
                                    p='Providers can effortlessly register, manage their services, handle bookings, engage inreviews, apply for jobs and communicate through chat and audio/video calls.'
                                    buttonNames={providerButtonNames}
                                    image={providerImageAddress}
                                    alt='service marketplace provider web'
                                    color='service-marketplace-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-service-market text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='The admin panel serves as the central hub for overseeing and optimizing the dynamic connection between users and service providers in a seamless mobile and web interface. It enables efficient management of user and provider data, financial transactions, service categories, commission structures, and booking activities through a comprehensive dashboard.'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddresses}
                                    alt='service marketplace admin '
                                    color='service-marketplace-product'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center ptb--30">
                        <button className="get-a-quote btn-service-market text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-service-market-product' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                </div>
                <CommonPortfolioButtons class='btn-service-market-product' text='View All Our Projects' padding='pb--20' />
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default ServiceMarketPlace;
