import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import Footer from "../component/footer/Footer";
import PartnerTab from "../elements/tab/PartnerTab";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import PartnerService from "../creative_homePage/Partner_Service";
import Header from "../component/header/Header";
import ContactPage from "../creative_homePage/ContactPage";
import CalendarModal from "../popup/CalenderModal";
import CommonSliderBanner from "../component/common/CommonSliderBanner";
const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Freelance digital designer',
        title: ' Partnering for Success with <span> Noorisys </span>Technologies',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]
class PersonalPortfolio extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
        };
    }

    openModal = () => {
        this.setState({ isModalOpen: true });
    };

    closeModal = () => {
        this.setState({ isModalOpen: false });
    };
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    render() {
        let title = ' Why Partner With Noorisys?',
            description = 'Noorisys Technologies is a global technology company that specializes in software development services. We are not just a vendor, but a true partner that is invested in the success of our clients and partners. We provide exceptional services and unparalleled support to ensure that our partners achieve their goals. Noorisys Technologies is the ideal partner for businesses looking to leverage the power of technology to grow and succeed.';
        return (
            <Fragment>
                <Helmet pageTitle="Personal Portfolio" />

                <Header logo="symbol-dark" color="color-black" />
                <CalendarModal show={this.state.isModalOpen} onHide={this.closeModal} />
                {/* Start Slider Area   */}
                {/* <div className="slider-wrapper">
                    Start Single Slide
                    {SlideList.map((value, index) => (
                        <div className="slide personal-portfolio-slider-partner slider-paralax slider-style-partner d-flex align-items-center justify-content-center bg_image bg_image--16" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title" dangerouslySetInnerHTML={{ __html: value.title }}></h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    End Single Slide
                </div> */}
                <CommonSliderBanner col={'col-lg-12'} slideList={SlideList} classes={'slide personal-portfolio-slider-partner slider-paralax slider-style-partner d-flex align-items-center justify-content-center bg_image bg_image--16'} color={true} />
                {/* End Slider Area   */}

                {/* Start About Area */}
                <div className="about-area about-position-top pt--30 pb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-5.webp" alt="About" loading="lazy" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner ">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description justifypara">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <PartnerTab tabStyle="tab-style--1" />
                                        </div>
                                        {/* <Link to="form" spy={true} smooth={true} duration={500} className="rn-button-style--2 btn-solid" onClick={this.Scroll}><span>Join Our Alliance</span></Link> */}
                                        {/* <div className="view-more-btn mt--30 text-center">
                                            <a className="rn-button-style--2 btn-solid" href="https://meetings-eu1.hubspot.com/afifa-ansari?embed=true" ><span>Schedule a Call</span></a>
                                        </div> */}
                                        <div className="about-button mt--30">
                                            <button className="rn-button-style--2 btn-solid" onClick={this.openModal} >Schedule a Call</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper pt--60 pt--30 bg_color--3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--20">
                                    <h2>Flexible Partnership Options</h2>
                                    <p>Whether you are looking to expand your service offerings, tap into new markets, or simply enhance your existing capabilities, we have a partnership model that can help you achieve your goals.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <PartnerService item="3" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                    <div className="section-title" >
                        {/* <Link to="form" spy={true} smooth={true} duration={500} className="rn-button-style--2 btn-solid" onClick={this.Scroll}><span>Let's Team Up</span></Link> */}
                        {/* <div className="view-more-btn mt--60 text-center">
                            <a className="rn-button-style--2 btn-solid" href="https://meetings-eu1.hubspot.com/afifa-ansari?embed=true"  ><span>Schedule a Call</span></a>
                        </div> */}
                        <div className="view-more-btn mt--60 text-center">
                            <button className="rn-button-style--2 btn-solid" onClick={this.openModal} >Schedule a Call</button>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start Faq Area */}
                <div className="pv-feaq-area bg_color--3  ptb--60">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="section-title text-left pb--30">
                                    {/* <p>Check out our FAQ section to see if we can help.</p> */}
                                    <h2 className="theme-gradient">Partnership Program FAQs</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <div className="faq-area">
                                    <Accordion className="accodion-style--1" preExpanded={'0'}>

                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Can I trust Noorisys as a partner for my business?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="justifypara"> Definitely, Noorisys has a reputation for being a reliable and trustworthy company, and takes great care to ensure the security and privacy of the clients. One way this is done is by using an Escrow service for payment security. This means that your payment is held by a third party until the work is completed, ensuring that both parties are protected.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What are the benefits of partnering with Noorisys Technologies?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="justifypara"> Our partnership programs provide a range of benefits, including mutual growth and profit, expert guidance and support, cost-efficient resources, and flexible partnership options.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How do I apply to become a partner ?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="justifypara"> You can apply to become a partner by filling out the partnership application form on given below. Once we receive your application, our team will review it and contact you to discuss the next steps.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    How much commission can I earn as a referral partner?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="justifypara">The commission you can earn as a referral partner will depend on the type of services your referred clients sign up for. Our team can provide you with more information about commission rates during the application process.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    What kind of support will I receive as a Noorisys Technologies partner?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="justifypara">As a partner, you will receive expert guidance and support from our team of experienced professionals. We are committed to helping our partners succeed and will provide you with the resources and support you need to grow your business.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Is there an agreement for the partnership?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="justifypara">Yes, we have a partnership agreement that outlines the terms and conditions of the partnership. The agreement covers important aspects such as the scope of the partnership, revenue sharing, confidentiality, breach and termination clauses. We will provide you with the agreement for review and signature before starting the partnership.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>


                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Is there any minimum requirement for partnership, such as a minimum number of clients to refer or a minimum sales target to achieve?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="justifypara">We do not have any strict minimum requirements for partnership. However, we do expect our partners to actively engage with us and promote our services to their network. We can discuss your specific needs and goals during our initial discussions and work together to create a mutually beneficial partnership agreement.</p>
                                            </AccordionItemPanel>
                                        </AccordionItem>

                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Start Faq Area */}

                {/* Start Portfolio Area */}
                <div id='form' className="portfolio-area ptb--120 bg_color--1">
                    <ContactPage title='Let’s grow together' text={`Join our growing network of partners and let's achieve success together. Fill out the form below to start the process of achieving evergreen profits.`} subject="noorisys.com | Become A Partner" />
                </div>
                {/* End Portfolio Area */}

                <Footer />

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default PersonalPortfolio;