import React, { Component } from 'react'
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
const CommonProductWeb = React.lazy(() => import("../portfolio/CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const ProductCommonApp = React.lazy(() => import("../portfolio/ProductCommonAppdemo"))

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'PHP 8', p: 'Admin Panel', img: 'php.png' },
    { h5: 'Laravel 10', p: 'Backend Development', img: 'laravel.png' },
    { h5: 'MySQL', p: 'Data Base', img: 'mysql.png' },
]

const adminButtonNames = ['All Orders', 'Order Details', 'Unassigned Order', 'Delivery Setup', 'Category setup'];
const adminImageAddress = [
    '/assets/images/our-products/parcel/admin/allorder.webp',
    '/assets/images/our-products/parcel/admin/orderdetail.webp',
    '/assets/images/our-products/parcel/admin/unassignorder.webp',
    '/assets/images/our-products/parcel/admin/deliverysetup.webp',
    '/assets/images/our-products/parcel/admin/categorysetup.webp',
];

const mobileDrButtonName = ['Home Page', 'My Order', 'Order Request', 'Profile']
const mobileDrImageAddress = [
    '/assets/images/our-products/parcel/delivery/homescreen.webp',
    '/assets/images/our-products/parcel/delivery/myorders.webp',
    '/assets/images/our-products/parcel/delivery/orderrequest.webp',
    '/assets/images/our-products/parcel/delivery/profile.webp',
]

const mobileUserButtonName = ['Home Page', 'My Order', 'Order Detail', 'Menu','Address','Track Order','Payment Success Page']
const mobileUserImageAddress = [
    '/assets/images/our-products/parcel/user/home.webp',
    '/assets/images/our-products/parcel/user/order.webp',
    '/assets/images/our-products/parcel/user/orderdetail.webp',
    '/assets/images/our-products/parcel/user/menu.webp',
    '/assets/images/our-products/parcel/user/address.webp',
    '/assets/images/our-products/parcel/user/trackorder.webp',
    '/assets/images/our-products/parcel/user/sucessfulpayment.webp',
]


export default class Parcel extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isVisible: false
        }
        this.openModal = this.openModal.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Online Parcel Delivery' pageUrl='/online-parcel' metaTitle="The Parcel Delivery dashboard provides a streamlined interface for managing the entire delivery operation" />
                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Online Parcel Delivery"
                    // customColor="custom-hospital-management"
                    customColor="theme-gradient"
                    dataBlackOverlay={5}
                    bgImage="bg_image--139"
                    p="Web, App"
                    buttonTitle={'get a quote'}
                    openPopup={true}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="custom-hospital-management">Online Parcel Delivery </h3>
                                        <p className="justifypara">
                                        The Parcel Delivery dashboard provides a streamlined interface for managing the entire delivery operation. Administrators can efficiently oversee all orders, including scheduled deliveries, pending orders, accepted tasks, in-process deliveries, those on the way, completed deliveries, cancellations, payment failures, refunds, and offline payments. The dashboard also features tools for dispatch management, allowing for effective coordination of parcel pickups and drop-offs. Additionally, administrators can create and manage promotional banners to enhance visibility, set up categories for organizing deliveries, and configure delivery settings to ensure smooth and timely parcel delivery services
                                        </p>
                                    </div>
                                </div>

                                <div className="ptb--40">
                                    <div className="container">
                                        <React.Suspense fallback={<div>loading...</div>} >
                                            <PlatformUsed color='custom-hospital-management' items={technologies} />
                                            <div className="d-flex justify-content-center align-items-center pt--30">
                                                <button className="get-a-quote btn-hospital-management text-center" onClick={this.showPopup}>
                                                    <span>Get a quote </span>
                                                </button>
                                            </div>
                                        </React.Suspense>
                                    </div>
                                </div>
                                <div className="portfolio-details">
                                    <div className="inner">                                     
                                        <h3 className="custom-hospital-management">Super admin functionalities</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#fa4a05" />Dashboard</li>
                                            <li><FiCheck color="#fa4a05" />Orders</li>
                                            <li><FiCheck color="#fa4a05" />Dispatch</li>
                                            <li><FiCheck color="#fa4a05" />Promotional Banners</li>
                                            <li><FiCheck color="#fa4a05" />Category Set up</li>
                                            <li><FiCheck color="#fa4a05" />Delivery Set up</li>
                                        </ul>
                                    </div>
                                    <div className="inner">                                     
                                        <h3 className="custom-hospital-management">Delivery Boy App Functionalities</h3>
                                        <p className='justifypara'>
                                        Effortlessly register and log in, seamlessly manage order requests, accept or reject orders, review order history, engage in real-time chat with users and efficiently manage your profile with our delivery boy app.

                                        </p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#fa4a05" />Registration and Login</li>
                                            <li><FiCheck color="#fa4a05" />View Order Request</li>
                                            <li><FiCheck color="#fa4a05" />Accept / Reject Orders</li>
                                            <li><FiCheck color="#fa4a05" />Order History</li>
                                            <li><FiCheck color="#fa4a05" />Chat with Users </li>
                                            <li><FiCheck color="#fa4a05" />Profile Management</li>
                                            <li><FiCheck color="#fa4a05" />Multi-language support</li>
                                        </ul>
                                    </div>
                                    <div className="inner">                                     
                                        <h3 className="custom-hospital-management">User App functionality</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#fa4a05" />Registration and Login</li>
                                            <li><FiCheck color="#fa4a05" />Home</li>
                                            <li><FiCheck color="#fa4a05" />Address</li>
                                            <li><FiCheck color="#fa4a05" />Orders</li>
                                            <li><FiCheck color="#fa4a05" />Update profile</li>
                                            <li><FiCheck color="#fa4a05" />Select and deliver the product </li>
                                            <li><FiCheck color="#fa4a05" />Online / cash on delivery payment</li>
                                            <li><FiCheck color="#fa4a05" />Rating and review</li>
                                            <li><FiCheck color="#fa4a05" />Track order</li>
                                        </ul>
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}
                <div className="rn-portfolio-area bg_color--3 ">                 
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p={``}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt="BTK User"
                                    color='online-delivery-system'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                </div>
                {/* Start portfolio Area  */}

                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Delivery App'
                                    p={``}
                                    buttonNames={mobileDrButtonName}
                                    image={mobileDrImageAddress}
                                    alt="BTK Mobile"
                                    color='parcel'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p={``}
                                    buttonNames={mobileUserButtonName}
                                    image={mobileUserImageAddress}
                                    alt="BTK Mobile"
                                    color='grocery'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                   
                </div>
                <div className="d-flex justify-content-center align-items-center pt--30 bg_color--3 ">
                    <button className="get-a-quote btn-hospital-management text-center" onClick={this.showPopup}>
                        <span>Get a quote </span>
                    </button>
                </div>
               
                      
                <CommonPortfolioButtons class='btn-hospital-management-cards' text='View All Our Products' link='/our-products' padding='pb--20' />
                {/* End portfolio Area  */}


                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                

                <Footer />


            </React.Fragment>
        )
    }
}
