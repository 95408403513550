import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import CommonSliderTransitionBanner from "../component/common/CommonSliderTransitionBanner";
import ServiceDetails from "../creative_homePage/ServiceDetails";
import IndustriesClientReveiw from "./IndustriesClientReveiw";
const LogisticsClient = React.lazy(() => import("../creative_homePage/CreativeBrand"));
const VerbosSimilarApps = React.lazy(() => import('../component/Verbos/VerbosSimilarApps'));
const NoorisysVideo = React.lazy(() => import('./NoorisysVideo'));

const SlideList = [
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--28',
        category: '',
        title: 'Logistics',
        description: 'Add value to your business with our logistics expertise. Handle mega logistics operations, entire supply chain network with our techie solutions.',
        buttonText: 'Contact Us',
        buttonLink: '/contact-us'
    },
]

const LogisticsTest = [
    {
        textPosition: 'inner text-center',
        bgImage: 'bg_image--28',
        category: "I am writing to express my sincere gratitude for the excellent development of my Transport project. I'm very happy with the overall experience. Noorisys team has always been helpful in developing my project at the Malegaon.",
        title: 'Logistics',
        description: 'Add value to your business with our logistics expertise. Handle mega logistics operations, entire supply chain network with our techie solutions.',
        buttonText: '',
        buttonLink: '',
        name: '- MASOOD JAFAR',
        img: "/assets/images/client/m_transport.webp",
        alt: 'masood jafae'
    },
]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

const data = [
    {
        title: 'Courier',
        description:
            'Scheduled and express delivery, packaging, COD services, delivery failures and computerized proof-of-delivery. Our courier services platform directly connects the customer with the service provider. Courier and Supply Chain Management companies can register on the platform and provide their services. Customers can easily connect with platforms from any platform (Android/IOS/Web), make a booking and do the online payment.',
        features: [
            'Multicurrency online payment option.',
            'Easy and quick registration process for each user.',
            'Separate chat room for each booking.',
            'Alerts and notifications.',
            'Attractive dashboard for each user.',
            'Customize as per your requirements.',
        ],
        image: '/assets/images/service/Courier_Management_System.png',
        alt: 'courier',
        h4: true
    },
    {
        title: 'Transport Management',
        description: [
            'Noorisys offers leading edge technology in easy to use format, delivering significant improvements in business control, transparency and visibility.',
            'From managing vehicles to transport services, from a heavy truck fleet to car rental system, we have developed excellent systems for our clients with multiple users. Minimize business efforts to maintain your vehicles and good shipments. We will manage vehicle engagement, tracking, job assignment for a driver, booking details, fuel management, transactions with reports of each driver, and customer directory.'
        ],
        features: [
            'Real Time Vehicle Location.',
            'Update Customer on Live Shipment and Transit Status.',
            'Vehicle Maintenance Records.',
            'Super Admin, Admin and Driver Profile with license details.',
            'Check Vehicle Transit Time and journey break.',
        ],
        image: '/assets/images/service/transport_management.png',
        alt: 'Transport management',
        h4: true
    },
    {
        title: 'Fleet Management :',
        description: [
            'We are bringing bottom line ground operations under your control and visibility. Handle your commercial vehicle business with a smart fleet management system.',
            'Get it for aviation machinery, construction equipment, commercial vehicles, private vehicles, ships etc. Increase your revenue with ease in work using route optimization, automated vehicle leasing and financing, vehicle maintenance, and fuel management features. Schedule maintenance and servicing tasks and measure fleet performance via reports and charts.'
        ],
        features: [
            'Reduced downtime with service reminders',
            'Real time vehicle diagnostics.',
            'Paperless procedure, saving tree.',
            'Reduce delivery cost by route optimization.',
            'Simple to operate, no extra training required.',
            'Reduced back office operation.'
        ],
        image: '/assets/images/service/fleet_management.png',
        alt: 'fleet management',
        h4: true
    },
]

const data2 = [
    {
        title: 'Data Security',
        noFeature: true,
        features: [
            'We understand the sensitive nature of data.',
            'Noorisys is an ISO 27001 compliant organisation.',
            'Your information and healthcare data is safe with us.',
            'Successful performance in each and every penetration testing.',
            'We have been assessed for GDPR compliance and policy frameworks.',
            'Regular audit by external agencies and assessment is done by stakeholders.',
            'Being an ISO certified organisation we handle data with much care and diligence.',
            'Strict Access Control Policy to protect any possible threat to data and information.',
        ],
        image: '/assets/images/service/service-14.webp',
        alt: 'data security',
        whatasApp: 'Schedule a call with our security expert',
        whatasAppText: 'schedule a call with security expert'
    },
    {
        title: 'All in One Solution',
        noFeature: true,
        features: [
            'What you exactly require is all here under one roof.',
            'Do not worry about the complex onboarding process.',
            'Skip the endless interviews, we have done that for you.',
            'An experienced team for you from the prominent institutes.',
            'Perfect team coordination, quick development and faster delivery.',
            'Our all in one solution reduces your efforts in project management.',
            'Hire a trustworthy and cost effective resource that speaks your language.',
            'No more new-hire paperwork, we already found and vetted the best talents.',
        ],
        image: '/assets/images/service/service-15.webp',
        alt: 'all in one solutions',
        whatasApp: 'Schedule a meeting with our Business Analyst',
        whatasAppText: 'schedule a call with business analyst'
    },
    {
        title: 'Trusted',
        noFeature: true,
        features: [
            'Trusted by the leading organisations in the world.',
            'Carrying the badge of trust for more than 11 years.',
            'Hired by semi-government and government agencies.',
            'Working with reputed brands of healthcare and fitness sector.',
            'Endorsed by more than 30 well known names of the healthcare industry.',
            'We believe in the professional ethics and cultural values driven by people.',
            'Our long journey and client history tells our good will status in the market.',
        ],
        image: '/assets/images/service/service-16.webp',
        alt: 'trusted',
        whatasAppText: 'schedule a call for partner with you',
        whatasAppButton: 'Partner with us today'
    },
]

class Logistics extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    render() {


        return (
            <Fragment>

                <Helmet pageTitle="Logistics || Noorisys Technologies Pvt Ltd" pageUrl='/logistics' metaTitle="Logistics" metaImage="/assets/images/bg/bg-image-28.jpg"
                    metaDescription="Improve your logistics operations with Noorisys. We provide tech-savvy solutions for transportation and warehouse management. Contact us for a consultation." />

                {/* Start Header Area  */}
                <Header logo="light" color="color-white" />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                {/* <div className="slider-wrapper color-white">
                    <div className="slider-activation slider-digital-agency">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value, index) => (
                                <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>

                    </div>
                </div> */}
                <CommonSliderTransitionBanner slideList={SlideList} titleClass={'theme-gradient'} dataBlackOverlay={2} />
                {/* End Slider Area   */}




                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--40">
                                <React.Suspense fallback={<div>loading...</div>} >
                                    <LogisticsClient branstyle="branstyle--2" />
                                </React.Suspense>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Page Wrapper */}
                <ServiceDetails data={data} bgColor="bg_color--5" />
                {/* End Page Wrapper */}

                <IndustriesClientReveiw Testimonial={LogisticsTest} bgColor={'bg_color--1'} />



                {/* Start Page Wrapper */}
                <ServiceDetails data={data2} bgColor="bg_color--5" />
                {/* End Page Wrapper */}
                <React.Suspense fallback={<div>loading...</div>} >
                    <NoorisysVideo />
                </React.Suspense>
                <div className="portfolio-area ptb--40 bg_color--5">
                    <div className="pt--40">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <VerbosSimilarApps color='theme-gradient' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} />
                        </React.Suspense>
                    </div>
                </div>

                {/* Start Footer Style  */}
                <Footer />
                {/* End Footer Style  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default Logistics;