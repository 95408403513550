import React, { useState } from "react"
import { Link } from "react-router-dom"
import GetaquotePopup from "../header/getaquotePopup";

export default function CommonBreadcrumb({ pb, bgImage, dataBlackOverlay, title, customColor, titlehtml, p, socialShare, buttonTitle, buttonLink, openPopup }) {
    const [isVisible, setIsVisible] = useState(false)
    const showPopup = () => {
        setIsVisible(true);
        window.lintrk('track', { conversion_id: 18604321 });
    }

    const closePopup = () => {
        setIsVisible(false);
    }
    return (
        <div className={`rn-page-title-area pt--120 ${pb ?? 'pb--190'} bg_image ${bgImage}`} data-black-overlay={dataBlackOverlay}>
            <div className="container">
                <div className="row">
                    <GetaquotePopup isVisible={isVisible} closePopup={closePopup} />
                    <div className="col-lg-12">
                        <div className="rn-page-title text-center pt--100">
                            {/* <h2 className="title theme-gradient">{props.title}</h2> */}
                            {title && <h2 className={`title ${customColor ? customColor : 'theme-gradient'}`}>
                                {title}
                            </h2>}
                            {titlehtml && <h2 className={`title ${customColor ? customColor : 'theme-gradient'}`} dangerouslySetInnerHTML={{ __html: titlehtml }} />}
                            {
                                p && (
                                    <p>{p}</p>
                                )
                            }
                            {
                                socialShare && (
                                    <div className="portfolio-share-link mt--20 pb_sm--40">
                                        <ul className="social-share rn-lg-size d-flex justify-content-center liststyle mt--15">
                                            {socialShare.map((val, i) => (
                                                <li key={i}><a href={`${val.link}`} rel="noopener noreferrer" target="__blank">{val.Social}</a></li>
                                            ))}
                                        </ul>
                                    </div>
                                )
                            }
                        </div>
                        {
                            buttonTitle && (
                                <>
                                    <br />
                                    <div className="view-more-btn mt--10 text-center">
                                        {openPopup ? (
                                            <>

                                                {/* <button className="rn-button-style--2 btn-solid text-center" onClick={showPopup}> */}
                                                <button className="get-a-quote btn-solid text-center" onClick={showPopup}>
                                                    <span>{buttonTitle}</span>
                                                </button>
                                            </>
                                        ) : (
                                            <Link className="rn-button-style--2 btn-solid text-center" to={buttonLink}>
                                                <span>{buttonTitle}</span>
                                            </Link>
                                        )}
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}