import React from 'react';
import {FaWhatsapp ,FaYoutube ,FaFacebookF , FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { FiInstagram, FiMail } from "react-icons/fi";
import { Link } from 'react-router-dom';

// const SocialShare = [
//     {Social: <FaFacebookF /> , link: 'https://www.facebook.com/noorisystech'},
//     {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/company/noorisys-technologies-pvt-ltd/'},
//     {Social: <FaYoutube /> , link: 'https://www.youtube.com/channel/UCkBVw01AixSyVzzkQXXjX2g'},
//      {Social: <FaWhatsapp /> , link: 'https://wa.me/7796614664'},
//      {Social: <FiMail /> , link: 'mailto:info@noorisys.com'},
// ]
const SocialShare1 = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/noorisystech'},
    {Social: <FaLinkedinIn /> , link: 'https://www.linkedin.com/company/noorisys-technologies-pvt-ltd/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/noorisys'},
    {Social: <FiInstagram /> , link: 'https://www.instagram.com/noorisys11/'},
    {Social: <FaYoutube /> , link: 'https://www.youtube.com/channel/UCkBVw01AixSyVzzkQXXjX2g'},
    {Social: <FaWhatsapp /> , link: 'https://wa.me/7796614664'},
    {Social: <FiMail /> , link: 'mailto:info@noorisys.com'},
]
// const SocialShare =[
//     {Social: <FaYoutube /> , link: 'https://www.youtube.com/channel/UCkBVw01AixSyVzzkQXXjX2g'},
//     {Social: <FaWhatsapp /> , link: 'https://wa.me/7796614664'},
//     {Social: <FiMail /> , link: 'mailto:info@noorisys.com'},
   
// ]

const FooterTwo = () => {
    return (
        <div className="footer-style-2 ptb--30 bg_image bg_image--1" data-black-overlay="6">
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner">
                            <div className="logo text-center text-sm-left mb_sm--20">
                                <Link to="/">
                                    <img src="/assets/images/logo/n-logo.png" alt="Logo images"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner text-center">
                            {/* <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul> */}
                            <ul className="social-share-custom rn-lg-size d-flex justify-content-center liststyle">
                                {SocialShare1.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                            <div className="text">
                                <p>Copyright © 2023 Noorisys.com. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                            <a className="chat_btn" href="https://wa.me/7796614664" id="chat_btn" target="_blank" rel="noopener noreferrer"><i><FaWhatsapp /></i></a>
                </div>
            </div>
        </div>
    )
}
export default FooterTwo;