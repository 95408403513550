import React, { useEffect, useRef } from 'react';
import Header from '../component/header/Header';
import { FiChevronUp } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import Footer from '../component/footer/Footer';
import CommonBreadcrumb from '../component/common/CommonBreadcrumb';
const FixMeeting = () => {
    const hubspotIframeRef = useRef(null);
    const loadHubSpotLink = () => {
        // Replace 'https://meetings-eu1.hubspot.com/afifa-ansari?embed=true' with your HubSpot link
        const hubspotLink = 'https://meetings-eu1.hubspot.com/afifa-ansari?embed=true';

        // Set the iframe's src attribute to the HubSpot link
        if (hubspotIframeRef.current) {
            hubspotIframeRef.current.src = hubspotLink;
        }
    };
    useEffect(() => {
        // Load the HubSpot link when the component mounts
        loadHubSpotLink();
    }, []);

    return (
        <React.Fragment>
            <Header />
            {/* Start Breadcrump Area */}
            {/* <div className="rn-page-title-area pt--120 pb--120 bg_image bg_image--23" data-black-overlay="3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="rn-page-title text-center pt--100">
                                <h2 className="title theme-gradient">Schedule A Meeting</h2>
                                <p>Web / Android / iOS Application</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <CommonBreadcrumb
                title="Schedule A Meeting"
                customColor="theme-gradient"
                dataBlackOverlay={3}
                bgImage="bg_image--23"
            />
            {/* End Breadcrump Area */}
            <iframe
                ref={hubspotIframeRef}
                title="HubSpot"
                width="100%"
                height="700"
                frameBorder="0"
                style={{ zIndex: 9999 }}
            ></iframe>
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />
        </React.Fragment>
    );
}
export default FixMeeting;