import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
const ProductCommonApp = React.lazy(() => import("../portfolio/ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("../portfolio/CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'PHP', p: 'Admin Panel', img: 'php.png' },
    { h5: 'Flutter', p: 'App Development', img: 'flutter.png' },
    { h5: 'Mysql', p: 'Database', img: 'mysql.png' },
    { h5: 'Laravel', p: 'Backend Development', img: 'native.png' },
]

const userAppButtonNames = [
    "Login", "Medicines", "Account", "Edit Profile",
    "Wallet", "Recent Orders", "Order Details", "My Cart"
];

const userAppImageAddresses = [
    "/assets/images/our-products/doctor-appointment/patient/patient-01.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-02.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-05.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-06.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-07.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-08.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-09.webp",
    "/assets/images/our-products/doctor-appointment/patient/patient-13.webp",
];
const pharmacyButtonNames = [
    "Login", "Recent Orders Quotation",
    "Recent Orders Active",
    "Recent Orders Completed", "My Items",
    "Item Details", "Account",
    "My Profile", "Order Detail"
];
const pharmacyImageAddress = [
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-01.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-02.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-03.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-04.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-05.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-06.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-07.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-08.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy/pharmacy-09.webp',
];
const deliveryButtonNames = [
    "Login", "Go Online",
    "Orders Pending", "Orders Accepted",
    "Orders Delivered", "My Profile", "Wallet",
    "Notification", "Account", "Go Offline"
];
const deliveryImageAddress = [
    '/assets/images/our-products/doctor-appointment/delivery/delivery-01.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-02.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-03.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-04.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-05.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-06.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-07.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-08.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-09.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-10.webp',
    '/assets/images/our-products/doctor-appointment/delivery/delivery-11.webp'
];
const adminButtonNames = [
    "Dashboard",
    "Manage Pharmacy", "Manage Delivery Man",
    "Manage Patients", "Manage Orders", "Manage Medicines",
    "Promo Codes", "Banners & Offers"
];
const adminImageAddress = [
    '/assets/images/our-products/doctor-appointment/pharmacy-admin/admin-01.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy-admin/admin-02.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy-admin/admin-03.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy-admin/admin-04.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy-admin/admin-05.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy-admin/admin-06.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy-admin/admin-07.webp',
    '/assets/images/our-products/doctor-appointment/pharmacy-admin/admin-08.webp',
];
class PharmacyOrderingProduct extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isVisible: false
        }
        this.openModal = this.openModal.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Pharmacy Ordering App' pageUrl='/doctor-appointment' metaTitle="Pharmacy Ordering App" metaImage="/assets/images/portfolio/dp-portfolio-51.webp"
                    metaDescription={`The Pharmacy app offers a user-friendly platform for customers to manage profiles, order medicines, and make payments from their wallets. Users can seamlessly navigate through a comprehensive dashboard, access a curated list of medicines, track orders in real-time, and provide valuable feedback.`} />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Pharmacy Ordering App"
                    // customColor="custom-coursier-color"
                    customColor="theme-gradient"
                    dataBlackOverlay={5}
                    bgImage="bg_image--123"
                    p="Web/Android/iOS Application"
                    buttonTitle={'get a quote'}
                    openPopup={true}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h4><span>TECHNICAL DETAILS</span></h4> */}
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-coursier-color">Pharmacy Ordering App</h3>
                                        <p className="justifypara">The Pharmacy app offers a user-friendly platform for customers to manage profiles, order medicines, and make payments from their wallets. Users can seamlessly navigate through a comprehensive dashboard, access a curated list of medicines, track orders in real-time, and provide valuable feedback. The app ensures a hassle-free experience for pharmacies with features like efficient order processing, prescription handling, and wallet management. Additionally, the Delivery Man App facilitates smooth order assignment, fulfilment, and real-time location tracking, enhancing communication with customers. The Admin Panel serves as a centralized hub for overseeing user management, pharmacy operations, delivery personnel, orders, and communications, providing a robust and integrated solution for pharmacy management.</p>
                                        <h3 className="rn-page-title custom-coursier-color">User App Functionalities</h3>
                                        <p className="justifypara">The user app provides a robust platform for managing profiles, ordering medicines, and making payments from wallets.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#2f64af" />Profile Management</li>
                                            <li><FiCheck color="#2f64af" /> Wallet Payment</li>
                                            <li><FiCheck color="#2f64af" /> Orders Medicines</li>
                                            <li><FiCheck color="#2f64af" />Feedback and Reviews</li>
                                        </ul>
                                        <h3 className="rn-page-title custom-coursier-color">Pharmacy App Functionalities</h3>
                                        <p className="justifypara">The pharmacy app provides a comprehensive platform with functionalities such as a
                                            dashboard overview, medicine list and management, a seamless cart and ordering process,
                                            prescription handling, real-time order status tracking, efficient delivery services, wallet
                                            management and a user feedback and reviews system.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#2f64af" />Dashboard Overview</li>
                                            <li><FiCheck color="#2f64af" />Medicine List and Management</li>
                                            <li><FiCheck color="#2f64af" />My Cart and Ordering Process</li>
                                            <li><FiCheck color="#2f64af" />Order Status and Delivery</li>
                                            <li><FiCheck color="#2f64af" />Wallet Management</li>
                                            <li><FiCheck color="#2f64af" />User Feedback and Reviews</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <div className="d-sm-block d-flex justify-content-center align-items-center ptb--30">
                                            <button className="get-a-quote btn-pharmacy-ordering text-center" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                        <h3 className="rn-page-title custom-coursier-color">Delivery Man App Functionalities</h3>
                                        <p className="justifypara">The Delivery Man App enables efficient management of registration, profile details, order
                                            assignment, fulfilment, real-time location tracking, customer communication, order status
                                            updates, seamless payment processing and comprehensive wallet management for delivery
                                            personnel.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#2f64af" />Registration and Profile Management</li>
                                            <li><FiCheck color="#2f64af" />Order Assignment</li>
                                            <li><FiCheck color="#2f64af" />Order Fulfillment</li>
                                            <li><FiCheck color="#2f64af" />Location Tracking</li>
                                            <li><FiCheck color="#2f64af" />Communication with Customers</li>
                                            <li><FiCheck color="#2f64af" />Order Status Updates</li>
                                            <li><FiCheck color="#2f64af" />Payment and Earnings</li>
                                            <li><FiCheck color="#2f64af" />Wallet Management</li>
                                        </ul>
                                        <h3 className="rn-page-title custom-coursier-color">Admin Panel Functionalities</h3>
                                        <p className="justifypara">The admin panel of this application provides a centralized platform with a comprehensive dashboard overview, user management capabilities, pharmacies, and delivery personnel.</p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#2f64af" />Dashboard Overview</li>
                                            <li><FiCheck color="#2f64af" />User Management</li>
                                            <li><FiCheck color="#2f64af" />Pharmacy (Store) Management</li>
                                            <li><FiCheck color="#2f64af" />Delivery Man Management</li>
                                            <li><FiCheck color="#2f64af" />Orders and Appointments</li>
                                            <li><FiCheck color="#2f64af" />Category and Medicine Management</li>
                                            <li><FiCheck color="#2f64af" />Email Communication</li>
                                            <li><FiCheck color="#2f64af" />Withdrawal Request Management</li>
                                        </ul>
                                        <div className="d-sm-block d-flex justify-content-center align-items-center pt--30">
                                            <button className="get-a-quote btn-pharmacy-ordering text-center" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-coursier-color' items={technologies} />
                        </React.Suspense>
                        <div className="d-flex justify-content-center align-items-center ptb--30">
                            <button className="get-a-quote btn-pharmacy-ordering text-center" onClick={this.showPopup}>
                                <span>Get a quote </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='The user app provides a robust platform for managing profiles, making wallet payments, ordering medicines, searching and selecting doctors, booking appointments, accessing appointment history, obtaining prescriptions and sharing feedback and reviews.'
                                    buttonNames={userAppButtonNames}
                                    image={userAppImageAddresses}
                                    alt='EMR user app'
                                    color='emr-product-app'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center ptb--30">
                        <button className="get-a-quote btn-pharmacy-ordering text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Pharmacy App'
                                    p='The pharmacy app provides a comprehensive platform with functionalities such as a dashboard overview, medicine list and management, a seamless cart and ordering process, prescription handling, real-time order status tracking, efficient delivery services, wallet management and a user feedback and reviews system.'
                                    buttonNames={pharmacyButtonNames}
                                    image={pharmacyImageAddress}
                                    alt='EMR pharmacy app'
                                    color='emr-product-app'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center ptb--30">
                        <button className="get-a-quote btn-pharmacy-ordering text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Delivery Man App'
                                    p='The Delivery Man App enables efficient management of registration, profile details, order assignment, fulfilment, real-time location tracking, customer communication, order status updates, seamless payment processing and comprehensive wallet management for delivery personnel.'
                                    buttonNames={deliveryButtonNames}
                                    image={deliveryImageAddress}
                                    alt='EMR delivery app'
                                    color='emr-product-app'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center ptb--30">
                        <button className="get-a-quote btn-pharmacy-ordering text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='The admin panel of this application provides a centralized platform with a comprehensive dashboard overview, user management capabilities and efficient management of doctors, pharmacies, and delivery personnel.'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='EMR Admin'
                                    color='emr-product-app'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center ptb--30">
                        <button className="get-a-quote btn-pharmacy-ordering text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-coursier-color' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                </div>
                <CommonPortfolioButtons class='btn-coursier' text='View All Our Products' link='/our-products' padding='pb--20' />

                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default PharmacyOrderingProduct;
