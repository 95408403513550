import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import RequestContactus from './RequestContactus';
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";


class Contact extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Request-A-Quote || Noorisys Technologies Pvt Ltd' pageUrl='/get-a-quote' metaTitle="Request a Quote" metaImage="/assets/images/bg/bg-image-17.jpg"
                    metaDescription="Share your project requirement and get a quote from our team." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb title='Request A Quote' dataBlackOverlay={6} bgImage='bg_image--17' />
                {/* End Breadcrump Area */}

                {/* <ContactForm/> */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--80 bg_color--1">
                    <RequestContactus />
                </div>
                {/* End Contact Page Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Contact