import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
// import ElearningAdminDemo from "./ElearningAdminDemo";
// import CoursierDelivrable from "./CoursierDeliverables";
// import PlatformUsed from "../component/Verbos/PlatformUsed";
// import VerbosSimilarApps from "../component/Verbos/VerbosSimilarApps";
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const CommonMasonry = React.lazy(() => import("./CommonMasonry"))

const PortfolioList = [
    {
        image: 'elearning-1',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'elearning-2',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
]
// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
// const technologies = [

//     { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
//     { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
//     { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
//     { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },

// ]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

class ElearningPortalPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='E-Learning Portal' pageUrl='/e-learning' metaTitle="E- Learning Portal" metaImage="/assets/images/portfolio/dp-portfolio-15.webp"
                    metaDescription="This platform allows educators to deliver educational content, assessments and other activities. It also allows students to access course materials, communicate with teachers and classmates, complete assignments and assessments online." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="E-Learning Portal"
                    dataBlackOverlay={1}
                    bgImage="bg_image--58"
                    p="Web Application"
                />

                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Web Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>E-Learning</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>PHP, WordPress</h6>
                                            </div>
                                        </div>
                                        {/* <h3 className="rn-page-title custom-online-shop-color">E-Learning Portal</h3> */}
                                        <h3 className="rn-page-title theme-gradient">E-Learning Portal</h3>
                                        <p className="justifypara">The platform is designed to enhance the quality of life for both adults and children through specialized voice and singing training. It is tailored for our client, who focuses on critical aspects of speech therapy, including pronunciation and intonation, which are essential for effective communication and understanding.</p>
                                        <p className="justifypara">The platform offers personalized support to help individuals communicate more comfortably and successfully. Users can access speech therapy sessions either at the practice in Hanover or in the comfort of their homes, ensuring flexibility and convenience.
                                        </p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Content based system</li>
                                            <li><FiCheck />Seminar and workshop portal</li>
                                            <li><FiCheck />Seprated and well managed section as per users need with easy navigation</li>
                                            <li><FiCheck />Embeded Video and Photo gallery</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-common-color'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h3 className="title">Screenshots</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" PortfolioList={PortfolioList} folder={'elearning'} />
                            </React.Suspense>
                        </div>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-common-color'} paddingTop={'pt--30'} />
                    </div>
                    <div className="pt--30">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <VerbosSimilarApps color='theme-gradient' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} />
                        </React.Suspense>
                    </div>
                    <div className="col-lg-12">
                        <CommonPortfolioButtons class='btn-common-color' text='View All Our Projects' />
                    </div>
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default ElearningPortalPortfolioDetails;
