import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

// const SocialShare =[
//     {Social:<FaGlobe />,link:' https://annachi.fr/ '}
// ]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'CodeIgniter', p: 'Admin Panel', img: 'ci4.png' },
    { h5: 'PHP', p: 'Backend Development', img: 'php.png' },
    { h5: 'Mysql', p: 'Database', img: 'mysql.png' },
    // { h5: 'Native  Android', p: 'App Development', img: 'native.png' },
]

const serviceProviderButtonNames = ['My Proposals', 'Booking List', 'Profile Settings', 'Search Filter'];
const serviceProviderImageAddress = [
    '/assets/images/portfolio/tukusipan/provider/provider-01.webp',
    '/assets/images/portfolio/tukusipan/provider/provider-02.webp',
    '/assets/images/portfolio/tukusipan/provider/provider-03.webp',
    '/assets/images/portfolio/tukusipan/provider/provider-04.webp',
];



const adminButtonNames = ['Login', 'Dashboard', 'Categories', 'Jobs', 'Users', 'Service Providers', 'User Details', 'Booking List'];
const adminImageAddress = [
    '/assets/images/portfolio/tukusipan/admin/admin-01.webp',
    '/assets/images/portfolio/tukusipan/admin/admin-02.webp',
    '/assets/images/portfolio/tukusipan/admin/admin-03.webp',
    '/assets/images/portfolio/tukusipan/admin/admin-04.webp',
    '/assets/images/portfolio/tukusipan/admin/admin-05.webp',
    '/assets/images/portfolio/tukusipan/admin/admin-06.webp',
    '/assets/images/portfolio/tukusipan/admin/admin-07.webp',
    '/assets/images/portfolio/tukusipan/admin/admin-08.webp',
];

const userButtonNames = ['My Posts', 'My Bookings', 'My Favourite', 'Payment History', 'Profile Setings', 'Search Jobs', 'Find Job', 'Chats'];
const userImageAddress = [
    '/assets/images/portfolio/tukusipan/user/user-1.webp',
    '/assets/images/portfolio/tukusipan/user/user-2.webp',
    '/assets/images/portfolio/tukusipan/user/user-3.webp',
    '/assets/images/portfolio/tukusipan/user/user-4.webp',
    '/assets/images/portfolio/tukusipan/user/user-01.webp',
    '/assets/images/portfolio/tukusipan/user/user-02.webp',
    '/assets/images/portfolio/tukusipan/user/user-03.webp',
    '/assets/images/portfolio/tukusipan/user/user-04.webp',
];
class TukusipanPortfolio extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Job Marketplace' pageUrl='/job-marketplace' metaTitle="Digital Solution For Civil Documents" metaImage="/assets/images/portfolio/dp-portfolio-51.webp"
                    metaDescription="A comprehensive solution that seamlessly incorporates all the essential features for
managing a successful food delivery business. From multi-language support and precise
coverage area mapping to streamlined order management and versatile payment gateways,
our app has it all" />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Job Marketplace"
                    customColor="custom-study-matching-portfolio"
                    dataBlackOverlay={5}
                    bgImage="bg_image--110"
                    p="Web Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h4><span>TECHNICAL DETAILS</span></h4> */}
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-study-matching-portfolio">Job Marketplace</h3>
                                        <p className="justifypara">This comprehensive job service marketplace facilitates seamless interactions between customers and service providers. Users can efficiently register, post jobs, and manage bookings through an intuitive dashboard. The platform offers features like proposal creation, schedule management, wallet transactions, and a review system.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#F07317" />Easy to find service providers</li>
                                            <li><FiCheck color="#F07317" />Verified provider accounts</li>
                                            <li><FiCheck color="#F07317" />Defined working hours and days</li>
                                            <li><FiCheck color="#F07317" />Chat</li>
                                            <li><FiCheck color="#F07317" />Rating and Reviews</li>
                                            <li><FiCheck color="#F07317" />Google Map Integration</li>
                                            <li><FiCheck color="#F07317" />Stripe Integration</li>
                                            <li><FiCheck color="#F07317" />Availability Management</li>
                                            <li><FiCheck color="#F07317" />Multilingual Support</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-tukusipan'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-study-matching-portfolio' items={technologies} />
                        </React.Suspense>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-tukusipan'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 pb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='User'
                                    p={` Users on this job service marketplace can effortlessly navigate through a user-friendly interface to register, post job requests, and manage bookings.`}
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt="User"
                                    color='tukusipan-user'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-tukusipan'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Service Providers'
                                    p={`Service providers on this platform experience a streamlined process for connecting with potential clients. They can register with ease, manage their profiles, and showcase expertise through proposal creation.`}
                                    buttonNames={serviceProviderButtonNames}
                                    image={serviceProviderImageAddress}
                                    alt="Service Providers"
                                    color='tukusipan-user'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-tukusipan'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin'
                                    p={`The admin panel provides robust control over categories, blogs, services, and user management, making it a versatile and efficient solution for the dynamic job service industry.`}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt="Service Providers"
                                    color='tukusipan-admin'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-tukusipan'} paddingTop={'pt--30'} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        {/* <VerbosSimilarApps color='custom-food-delivery-product' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} /> */}
                        <VerbosSimilarApps color='custom-study-matching-portfolio' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-tukusipan' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default TukusipanPortfolio;
