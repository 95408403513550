import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import { FaGlobe } from "react-icons/fa";
import PlatformUsed from "../component/Verbos/PlatformUsed";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const SocialShare = [
    { Social: <FaGlobe />, link: 'https://play.google.com/store/apps/details?id=com.tabani.kirana ' }
]

// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
const technologies = [

    { h5: 'Android', p: 'Android ', img: 'android.png' },
    { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
    // { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
    { h5: 'PHP', p: 'Web Development', img: 'php.png' },

]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/online-shop', img: 'shop.webp', h2: 'EasyShop', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #c37857 0%, #734444 100%)', link: '/online-food-delivery', img: 'food.webp', h2: 'Food', p: 'Online Food Delivery ' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/online-shop', img: 'shop.webp', h2: 'EasyShop', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #c37857 0%, #734444 100%)', link: '/online-food-delivery', img: 'food.webp', h2: 'Food', p: 'Online Food Delivery ' },
]

const buttonNames = ['Splash Screen', 'Dashboard', 'Category', 'Sub Category', 'My Cart'];
const imageAddress = [
    '/assets/images/portfolio/online_kirana/m1.webp',
    '/assets/images/portfolio/online_kirana/m2.webp',
    '/assets/images/portfolio/online_kirana/m3.webp',
    '/assets/images/portfolio/online_kirana/m4.webp',
    '/assets/images/portfolio/online_kirana/m5.webp',
];

class OnlineKiranaPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Online Grocery || Noorisys Technologies Pvt Ltd' pageUrl='/online-grocery' metaTitle="Online Grocery" metaImage="/assets/images/portfolio/dp-portfolio-49.webp"
                    metaDescription="Malegaon Basket app offers a convenient way to shop for groceries. Browse a wide range of products, view detailed descriptions and place orders for home delivery. Our app features a clean navigation menu, detailed product descriptions and high-quality product photos." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Online Grocery Shopping"
                    customColor="custom-online-kirana"
                    dataBlackOverlay={5}
                    bgImage="bg_image--88"
                    p="Android Application / Web"
                    socialShare={SocialShare}
                    pb="pb--120"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-online-kirana">Malegaon Basket- Online Grocery Shopping</h3>
                                        <p className="justifypara">Malegaon Basket app is started with a great vision and concept to provide best quality groceries at your doorsteps. It helps customers to choose and browse the products at their convenient time and place the order with peace of mind.</p>
                                        <p className="justifypara">Our aim is to provide best quality hygienic foods to our customers. We keep doing the checking and sorting our products before having them in our shop. Having more than 30 years of experience our team members are very sensitive and never compromise with the quality at any cost. In this mobile app we have a whole super shop and kiraana mart with more than 800 items. This is India's one and only app having this much kirana items at one place with home delivery facility. Best quality food and pulses are delivered to our thousands of happy customers.</p>
                                        <p className="justifypara">Malegaon Baskit is the unique app in Malegaon city (Dist-Nashik, Nasik) which provides such a reliable grocery delivery services.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#06051e'} />Clean navigational menu with an In-App Search and product categories</li>
                                            <li className="justifypara"><FiCheck color={'#06051e'} />Detailed product descriptions</li>
                                            <li className="justifypara"><FiCheck color={'#06051e'} />Good quality product photos with image zoom and thumbnails</li>
                                            <li className="justifypara"><FiCheck color={'#06051e'} />Customer rating</li>
                                            <li className="justifypara"><FiCheck color={'#06051e'} />provide best quality hygienic foods to our customers.</li>
                                            <li className="justifypara"><FiCheck color={'#06051e'} />We keep doing the checking and sorting our products before having them in our shop.</li>
                                            <li className="justifypara"><FiCheck color={'#06051e'} />Cover in this app complete grocery categories</li>
                                            <li className="justifypara"><FiCheck color={'#06051e'} />Multiple Payment options</li>
                                            <li className="justifypara"><FiCheck color={'#06051e'} />500+ Products</li>
                                            <li className="justifypara"><FiCheck color={'#06051e'} />Easy to Browse</li>
                                            <li className="justifypara"><FiCheck color={'#06051e'} />And many more….</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-online-kirana'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30">
                    <div className="container">
                        <div className="pb--40">
                            <div className="row">
                                <PlatformUsed color='custom-online-kirana' items={technologies} />
                            </div>
                            <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-online-kirana'} paddingTop={'pt--30'} />
                        </div>
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='Users have the ability to explore an extensive range of products based on categories and subcategories. They can conveniently place orders for home delivery.'
                                    buttonNames={buttonNames}
                                    image={imageAddress}
                                    alt='online grocery'
                                    color='malegaon-basket'
                                />
                            </React.Suspense>
                        </div>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-online-kirana'} paddingTop={'pt--30'} paddingBottom={`pb--30`} />
                    </div>
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-online-color' items={similarapps} title={'Similar Apps'} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-online-kirana' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default OnlineKiranaPortfolioDetails;
