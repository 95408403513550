import React, { Component } from 'react'
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
// const ScholarshipAdminDemo = React.lazy(() => import("./ScholarshipAdminDemo"));
// const ScholarshipAppDemo = React.lazy(() => import("./ScholarshipAppDemo"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
// import CoursierDelivrable from "./CoursierDeliverables";
// import VerbosSimilarApps from "../component/Verbos/VerbosSimilarApps";
// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
const technologies = [  

    { h5: 'PHP', p: 'HTML, CSS, Bootstrap, JavaScript, PHP 8.1', img: 'php.png' },
    { h5: 'Laravel 10', p: '', img: 'laravel10.png' },
    // { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
    { h5: 'MySQL', p: 'Data Base', img: 'mysql.png' },

]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]
// const SocialShare = [
//     { Social: <FaGlobe />, link: ' https://www.sesapay.sn/ ' },
//     { Social: <FaApple />, link: 'https://apps.apple.com/in/app/sesapay/id1494695602' },
//     { Social: <FaGooglePlay />, link: 'https://play.google.com/store/apps/details?id=com.app.sesapay ' },
// ]
const userAppButtonNames = ['Dashboard', 'DPS','Fund Transfer' ,'Loan', 'Transaction', 'Profile'];
const userAppImageAddress = [
    '/assets/images/portfolio/digital/user_dashboard.webp',
    '/assets/images/portfolio/digital/user_DPS_plan.webp',
    '/assets/images/portfolio/digital/user_fund_transfer_form.webp',
    '/assets/images/portfolio/digital/user_Loan_plans.webp',
    '/assets/images/portfolio/digital/user_transactions.webp',
    '/assets/images/portfolio/digital/user_update_profile.webp',
];
const adminButtonNames = ['Dashboard', 'DPS', 'Loan', 'Menu Management','Setting','Transaction','Customer List'];
const adminImageAddress = [
    '/assets/images/portfolio/digital/admin_dashboard.webp',
    '/assets/images/portfolio/digital/admin_DPS.webp',
    '/assets/images/portfolio/digital/Admin_Loans.webp',
    '/assets/images/portfolio/digital/admin_menu_anagement.webp',
    '/assets/images/portfolio/digital/admin_Page_setting.webp',
    '/assets/images/portfolio/digital/admin_transaction.webp',
    '/assets/images/portfolio/digital/customer_list.webp',
];
export default class DigitalBanking extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.pathname) {
        window.gtag("config", "G-F2DRSMH4G3", {
            page_title: this.props.location.pathname,
            page_path: this.props.location.pathname,
        })
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', this.props.location.pathname]);
        _hsq.push(['trackPageView']);
    }
}
constructor() {
    super()
    this.state = {
        isOpen: false
    }
    this.openModal = this.openModal.bind(this)
}
openModal() {
    this.setState({ isOpen: true })
}
render() {
    return (
        <React.Fragment>
            <PageHelmet pageTitle='Digital Banking portfolio || Noorisys Technologies Pvt Ltd' pageUrl='/digital-banking-system' metaTitle="Digital Banking System" metaImage="/assets/images/portfolio/dp-portfolio-02.jpg"
                metaDescription="This is an online banking website where users can securely manage their finances anytime, anywhere. Users can check account balances, view transaction history, transfer funds between accounts, pay bills, and set up recurring payments. Additionally, users can apply for loans, open new accounts, and access various banking services, all from a single, user-friendly platform." />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <CommonBreadcrumb title='Digital Banking System' dataBlackOverlay={7} customColor='custom-scholarship-color' bgImage='bg_image--134'
             p='Web Application' pb='pb--120'
             buttonTitle={'get a quote'}
             openPopup={true} />
            {/* End Breadcrump Area */}

            {/* Start Portfolio Details */}

            <div className="rn-portfolio-details ptb--60 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-details">
                                <div className="inner">
                                    <h3 className="rn-page-title custom-scholarship-color">Digital Banking System </h3>
                                    <p className="justifypara">This is an online banking website where users can securely manage their finances anytime, anywhere. Users can check account balances, view transaction history, transfer funds between accounts, pay bills, and set up recurring payments. Additionally, users can apply for loans, open new accounts, and access various banking services, all from a single, user-friendly platform.
                                    </p>                                    
                                    {/* <h4 className="title">Features</h4> */}
                                    {/* <ul className="list-style--1">
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Easy registration customer</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Attractive dashboard</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Secure online payment</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Transfer money</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Registration fee</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Withdrawal money</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Receive money</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Payment at restaurants</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Merchant payment</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Accounts and wallet</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Alerts and notifications</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Fully responsive design</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />And many more….</li>

                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="portfolio-details">
                                <div className="inner">
                                    <h3 className="rn-page-title custom-scholarship-color">Admin Panel Functionality : </h3>
                                    <p className="justifypara">The admin panel provides tools to manage customers, KYC compliance, transactions, profits, fund transfers, and financial products like DPS, FDR, and loans. Admins can handle bill management, deposits, withdrawals, and referrals, and manage reward points. Additionally, they control system settings, site appearance, landing pages, templates, and offer subscriber support, ensuring efficient system operations.
                                    </p>                                    
                                    {/* <h4 className="title">Features</h4> */}
                                    <ul className="list-style--1">
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Dashboard</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Customers </li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />KYC management</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Transactions</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Profits </li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Fund Transfer</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />DPS</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />FDR</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Loan</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Bill management</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Bill history</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Deposits</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Withdraw</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Referral</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Portfolios</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Manage Reward Point</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Subscriber & support</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />And many more….</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="portfolio-details">
                                <div className="inner">
                                    <h3 className="rn-page-title custom-scholarship-color">User Panel Functionality :  </h3>
                                    <p className="justifypara">Users can manage their finances through a dashboard that includes fund transfers, DPS/FDR investments, loans, bill payments, transaction histories, and withdrawals. They can earn through referrals, track their portfolio and rewards, access support, adjust settings, and logo.
                                    </p>                                    
                                    {/* <h4 className="title">Features</h4> */}
                                    <ul className="list-style--1">
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Dashboard</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />DPS</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />FDR</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Loan</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Pay bill </li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Transaction</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Withdraw</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Referral</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Portfolios</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} /> Reward </li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />Support</li>
                                        <li className="justifypara"><FiCheck color={'#ffd939'} />And many more….</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Details */}

            {/* Start portfolio Area  */}
            <div className="rn-portfolio-area bg_color--1 ptb--30"
            >
                {/* // style={{backgroundColor:'#ffd939'}} */}
                <div className="container">
                    {/* <CoursierDelivrable title={'16 Deliverables'} items={deliverables} class={'coursier'} value={[7, 5, 4]} /> */}
                </div>
                <div className="ptb--90">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-scholarship-color' items={technologies} />
                        </React.Suspense>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--1 pt--40 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='User Panel'
                                    p='Users can manage their finances through a dashboard that includes fund transfers, DPS/FDR investments, loans, bill payments, transaction histories, and withdrawals. They can earn through referrals, track their portfolio and rewards, access support, adjust settings, and logo.'
                                    buttonNames={userAppButtonNames}
                                    image={userAppImageAddress}
                                    alt='digital bank user'
                                    color='sesa-pay'
                                />
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='The admin panel provides tools to manage customers, KYC compliance, transactions, profits, fund transfers, and financial products like DPS, FDR, and loans. Admins can handle bill management, deposits, withdrawals, and referrals, and manage reward points. Additionally, they control system settings, site appearance, landing pages, templates, and offer subscriber support, ensuring efficient system operations.'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='scholarship admin'
                                    color='sesa-pay'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                </div>
            </div>
            <CommonPortfolioButtons class='btn-sesapay' text='View All Our Projects' padding='pb--40' />
            {/* End portfolio Area  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}

            <Footer />


        </React.Fragment>
    )
}
}