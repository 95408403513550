import React, { Component, Fragment } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';




class IndustriesCounter extends Component {
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({ didViewCountUp: true });
        }
    }
    render() {
        let Data = [
            {
                countNum: 15,
                countTitle: 'Skilled in 15+ Programming language frameworks',
            },
            {
                countNum: 3,
                countTitle: 'Noorisys has one office in the UK and two offices are in India.',
            },
            {
                countNum: 7,
                countTitle: 'Experienced staff in more than 7 coding technologies in mobile application and web development.',
            },
        ];

        return (
            <Fragment>
                <div className="row">
                    {Data.map((value, index) => (
                        <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                            <h5 className="counter">
                                <VisibilitySensor onChange={this.onVisibilityChange} offset={{ top: 10 }} delayedCall>
                                    <CountUp end={this.state.didViewCountUp ? value.countNum : 0} />
                                </VisibilitySensor>
                            </h5>
                            <p className="description">{value.countTitle}</p>
                        </div>
                    ))}
                </div>
            </Fragment>
        )
    }
}
export default IndustriesCounter;