import React, { Component, Fragment } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';




class Creative_Counter extends Component {
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({ didViewCountUp: true });
        }
    }
    render() {
        let Data = [
            {
                countNum: 200,
                countTitle: 'With 200+ dedicated IT professionals from prestigious institutions, we excel in various domains, delivering proven expertise and unwavering commitment to excellence.',
                // countTitle: 'We have 100+ dedicated IT professionals for different domains, graduated from the prominent institutions with proven expertise and delivering excellence.',
                countDesc: 'Developers',
            },
            {
                countNum: 1112,
                countTitle: `We've excelled with 1000+ software solutions across Healthcare, Travel and Tourism, FinTech, On-Demand Services, Retail, Real Estate, and various other domains.`,
                // countTitle: 'We have successfully delivered 1000+ projects in all the domains like Healthcare & Fitness, Travel & Tourism, On-Demand Services, Retail & eCommerce, Real Estate, and Education.',
                countDesc: 'Projects',
            },
            {
                countNum: 596,
                countTitle: 'Noorisys has proudly served over 500 satisfied global clients across diverse industries. Join our community and become a cherished member of our delighted clientele.',
                // countTitle: 'Noorisys has a very strong base of 500+ happy clients in all industries from all over the world. Join us and be a league member of our happy clients.',
                countDesc: 'Clients',
            },
        ];

        return (
            <Fragment>
                <div className="row">
                    {Data.map((value, index) => (
                        <div className="counterup_style--1 col-lg-4 col-md-4 col-sm-6 col-12" key={index}>
                            <h5 className="counter">
                                <VisibilitySensor onChange={this.onVisibilityChange} offset={{ top: 10 }} delayedCall>
                                    <CountUp end={this.state.didViewCountUp ? value.countNum : 0} />
                                </VisibilitySensor>
                            </h5>
                            <p className="description">{value.countDesc}</p>
                            <p className="description ">{value.countTitle}</p>
                        </div>
                    ))}
                </div>
            </Fragment>
        )
    }
}
export default Creative_Counter;