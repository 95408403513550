import React, { Component, Fragment } from "react";
// import { Parallax } from "react-parallax";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { portfolioSlick2 } from "../page-demo/script";

import Header from "../component/header/Header";
// import BlogContent from "../elements/blog/BlogContent";
import FooterTwo from "../component/footer/Footer";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import CommonSliderBanner from "../component/common/CommonSliderBanner";


const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        bgImage: 'bg_image--90',
        title: 'Career Opportunities',
        description: ` We're committed to providing a challenging and rewarding work environment that fosters growth and creativity. Check out our current openings and apply today!`,
        buttonText: 'Contact Us',
        buttonLink: '/contact-us'
    }
]

const PortfolioList = [
    {
        image: 'image-17',
        category: '  UX/UI Designing',
        title: 'UX/UI Designing',
        url: '/ux-ui-career',
        button_text: '1 opening'
    },
    {
        image: 'image-15',
        category: 'Web Development',
        title: 'Web Development',
        url: '/web-development-career',
        button_text: '0 opening'
    },
    {
        image: 'image-16',
        category: ' Mobile App Development',
        title: 'Mobile App Development',
        url: '/mobile-app-career',
        button_text: '1 opening'
    },
    {
        image: 'image-20',
        category: 'Finance & HR',
        title: 'Finance & HR',
        url: '/finance-and-hr-career',
        button_text: '0 opening'
    },
    {
        image: 'image-19',
        category: 'Customer Support Executive',
        title: 'Customer Support Executive',
        url: '/customer-support-career',
        button_text: '0 opening'
    },
    {
        image: 'image-18',
        category: 'Sales & Marketing ',
        title: ' Sales & Marketing',
        url: '/sales-and-marketing-career',
        button_text: '1 opening'
    },

]


class Career extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    render() {
        // const PostList = BlogContent.slice(0, 5);
        let title = 'Job Openings',
            description = `If you're looking to grow your career and make an impact, we encourage you to explore our job openings and consider joining our team.`;
        let title1 = 'Why join Noorisys Technologies?',
            description1 = 'We offer a dynamic and inclusive workplace culture where creativity and innovation are celebrated. We value our employees and provide them with the resources and support they need to succeed in their careers. Here are just a few reasons why you should consider joining us:';
        return (
            <Fragment>
                <Helmet pageTitle="Careers || Noorisys Technologies Pvt Ltd" pageUrl='/careers' metaTitle='' metaImage=''
                    metaDescription='' />
                <Header headerPosition="header--static" logo="symbol-dark" color="color-black" />

                {/* Start Slider Area   */}
                {/* <div className="slider-wrapper color-white">
                    <div className="slider-activation slider-digital-agency">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value, index) => (
                                <div className={`slide slide-style-4 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="5">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title theme-gradient ">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div> */}
                <CommonSliderBanner
                    classes={'slide slide-style-4 fullscreen d-flex align-items-center justify-content-center bg_image'}
                    col={'col-lg-12'}
                    titleClass={'theme-gradient'}
                    slideList={SlideList}
                    dataBlackOverlay={5}
                />
                {/* End Slider Area   */}

                {/* Start portfolio Area  */}


                <div className="portfolio-area ptb--60 bg_color--5">
                    <div className="portfolio-sacousel-inner mb--55">
                        <div className="portfolio-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center">
                                            <h2>{title}</h2>
                                            <p>{description}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="portfolio-slick-activation mt--70 mt_sm--40">
                                <Slider {...portfolioSlick2}>
                                    {PortfolioList.map((value, index) => (
                                        <div className="portfolio" key={index}>
                                            <div className="thumbnail-inner">
                                                <div className={`thumbnail ${value.image}`}></div>
                                                <div className={`bg-blr-image ${value.image}`}></div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    {/* <p>{value.category}</p> */}
                                                    <h4 className="title"><Link to={value.url}>{value.title}</Link></h4>
                                                    <div className="portfolio-button">
                                                        <Link className="rn-btn" to={value.url}>{value.button_text}</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link className="link-overlay" to={value.url}></Link>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>

                {/* End portfolio Area  */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details  bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        <div className="section-title text-center">
                                            <h2>{title1}</h2>
                                            <p>{description1}</p>
                                        </div>
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content ptb--40 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/career/career-01.webp" alt="career growth" loading="lazy" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">

                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title"> Career Growth </h4>
                                                    <p className="justifypara" >We're committed to helping our employees grow and advance in their careers. We offer a range of training programs, mentorship opportunities, and other resources to help our employees build their skills and advance in their careers.</p>
                                                    <p className="justifypara" > Internal mobility is essential to employee satisfaction and retention, and we strive to create a workplace where employees can build long and fulfilling careers.</p>
                                                    <p className="justifypara" >We also recognize that career growth means different things to different people, and we're committed to supporting our employees' individual goals and aspirations.</p>
                                                    <p className="justifypara" > Whether an employee is looking to advance in their current role, transition to a new department, or pursue a leadership position, we're here to provide the support and guidance they need.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center pb--40">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Work-Life Balance</h4>
                                                    <p className="justifypara" >At Noorisys, we believe that a healthy work-life balance is essential to our employees' well-being and productivity. We understand that our employees have lives outside of work, and we strive to create a workplace that supports their personal and professional goals.</p>
                                                    <p className="justifypara" >We offer a range of flexible work arrangements to help our employees achieve balance in their lives. This includes options such as remote work, flexible scheduling, and part-time work, as well as generous time-off policies such as vacation, sick leave, and parental leave.</p>
                                                    <p className="justifypara" > We believe that by providing our employees with the freedom and flexibility to manage their schedules and prioritize their responsibilities, we can create a workplace that's conducive to both personal and professional growth.</p>

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/career/career-02.webp" alt="worklife balance" loading="lazy" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--40 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/career/career-03.webp" alt="diversity inclusion" loading="lazy" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Diversity and Inclusion</h4>
                                                    <p className="justifypara">We're committed to building a workplace where everyone feels valued and respected, regardless of their race, gender, sexual orientation, religion, disability, or any other characteristic. We recognize that building a diverse and inclusive workplace requires ongoing effort and commitment.</p>
                                                    <p className="justifypara">Noorisys has established policies and programs to ensure that our hiring and promotion practices are fair and equitable, and we provide ongoing training and education to our employees on issues related to diversity and inclusion.</p>
                                                    <p className="justifypara">We're also committed to creating a workplace culture that's welcoming and supportive to all employees. This includes providing accommodations for employees with disabilities, offering employee resource groups and affinity networks, and ensuring that all employees have access to the same opportunities and resources.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center pb--40">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Competitive Compensation</h4>
                                                    <p className="justifypara">We're committed to providing competitive compensation packages that reflect the skills, experience, and performance of our employees.</p>
                                                    <p className="justifypara"> We offer a range of benefits and perks to support our employees' well-being, including health insurance, maternal and paternal leaves, vacation time off, and more.</p>
                                                    <p className="justifypara">By creating a positive work environment that values the contributions of all employees, we aim to build a workplace that's not only competitive in terms of compensation but also fulfilling and rewarding..</p>
                                                    <p className="justifypara"> We believe that compensating our employees fairly and providing a supportive work environment is key to attracting and retaining top talent and achieving our goals as a company</p>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/career/career-04.webp" alt="competative compnsation" loading="lazy" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}

export default Career;