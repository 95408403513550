import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LazyLoad from 'react-lazyload';
import 'react-image-lightbox/style.css';
import { Link } from 'react-router-dom';

const TabTwo = [
    {
        image: '04',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Social Media Platform',
        url: '/social-platform',
        img_name: 'social platform'
    },
]


const TabOne = [
      {
        image: '56',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-07.jpg',
        category: 'Web Design',
        title: 'Digital Banking System',
        url: '/digital-banking-system',
        img_name: 'digital banking image'
    },
    {
        image: '57',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Influencer Marketplace',
        url: '/social-sync',
        img_name: 'Influencer Marketplace image'
    },
    {
        image: '60',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Hospital Management System',
        url: '/hospital-management-system',
        img_name: 'Hospital Management image'
    },
    {
        image: '59',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Noorisys Mart',
        url: '/grocery',
        img_name: 'Noorisys Mart image'
    },
    {
        image: '61',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Online Parcel Delivery',
        url: '/online-parcel',
        img_name: 'Online Parcel Delivery image'
    },
    {
        image: '15',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Fitness App',
        url: '/fitness-app',
        img_name: 'fitness-app'
    },
    {
        image: '58',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Digital Business Card',
        url: '/digital-business-card',
        img_name: 'digital business card'
    },
    {
        image: '01',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Doctor Appointment with Teleconsultation',
        url: '/doctor-appointment',
        img_name: 'doctor appointment'
    },
    {
        image: '02',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Service Provider Marketplace',
        url: '/service-marketplace',
        img_name: 'service Marketplace'
    },
    {
        image: '11',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Pharmacy Ordering App',
        url: '/pharmacy-ordering',
        img_name: 'pharmacy ordering app'
    },
    {
        image: '03',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Food Delivery App',
        url: '/food-delivery',
        img_name: 'food delivery'
    },
    {
        image: '05',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'School Management System',
        url: '/school-management',
        img_name: 'School management'
    },
    {
        image: '04',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Social Media Platform',
        url: '/social-platform',
        img_name: 'social platform'
    },
    {
        image: '06',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Learning Management System',
        url: '/learning-management',
        img_name: 'learning management'
    },
    {
        image: '09',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Electronic Medical Record',
        url: '/mugenzi-emr',
        img_name: 'Electronic Medical Record'
    },
    {
        image: '12',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Helpdesk Ticketing Platform',
        url: '/helpdesk-ticketing-platform',
        img_name: 'Helpdesk Ticketing Platform'
    },
    {
        image: '13',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Transport Management System',
        url: '/transport-management-system',
        img_name: 'Transport Management System'
    },
    {
        image: '14',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-01.jpg',
        category: 'Web Design',
        title: 'Umrah Plus',
        url: '/umrah-app',
        img_name: 'tourism compony image'
    },
]



const TabThree = [
    {
        image: '02',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Service Provider Marketplace',
        url: '/service-marketplace',
        img_name: 'service Marketplace'
    },
    {
        image: '03',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Food Delivery App',
        url: '/food-delivery',
        img_name: 'food delivery'
    },
    {
        image: '59',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Noorisys Mart',
        url: '/grocery',
        img_name: 'Noorisys Mart image'
    },
    {
        image: '61',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Online Parcel Delivery',
        url: '/online-parcel',
        img_name: 'Online Parcel Delivery image'
    },
]
const TabFour = [

    {
        image: '01',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Doctor Appointment with Teleconsultation',
        url: '/doctor-appointment',
        img_name: 'doctor appointment'
    },
    {
        image: '09',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Electronic Medical Record',
        url: '/mugenzi-emr',
        img_name: 'Electronic Medical Record'
    },
    {
        image: '11',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Pharmacy Ordering App',
        url: '/pharmacy-ordering',
        img_name: 'pharmacy ordering'
    },
    {
        image: '15',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Fitness App',
        url: '/fitness-app',
        img_name: 'fitness-app'
    },
]

const TabFive = [

    {
        image: '06',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Learning Management System',
        url: '/learning-management',
        img_name: 'learning management'
    },
    {
        image: '05',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'School Management System',
        url: '/school-management',
        img_name: 'School management'
    },
]

const TabSix = [
    {
        image: '10',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-09.jpg',
        category: 'Logo Design',
        title: 'Digital Business Card',
        url: '/digital-business-card',
        img_name: 'digital business card'
    },
    {
        image: '14',
        bigImage: '/assets/images/portfolio/big/dp-big--portfolio-01.jpg',
        category: 'Web Design',
        title: 'Umrah Plus',
        url: '/umrah-app',
        img_name: 'tourism compony image'
    },
]
class OurProductsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab1: 0,
            tab2: 0,
            tab3: 0,
            tab4: 0,
            isOpen: false,
        };
    }
    render() {
        const { column } = this.props;
        // const { tab1, tab2, tab3, tab4, isOpen } = this.state;
        return (
            <div>
                <Tabs>
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <div className="tablist-inner">
                                <TabList className="pv-tab-button text-center mt--0">
                                    <Tab><span>All Products</span></Tab>
                                    <Tab><span>Social Media</span></Tab>
                                    {/* <Tab><span>Logo Design</span></Tab> */}
                                    <Tab><span>eCommerce</span></Tab>
                                    <Tab><span>HealthTech</span></Tab>
                                    <Tab><span>EdTech</span></Tab>
                                    <Tab><span>Others</span></Tab>
                                </TabList>
                            </div>
                        </div>
                    </div>

                    <TabPanel className="row row--35">
                        {TabOne.map((value, index) => (
                            <div className={`${column}`} key={index}>
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <Link to={value.url}>
                                                        <LazyLoad placeholder={<img src={`portfolio${value.image}`} alt='img' loading='lazy' />}>
                                                            <img src={`/assets/images/our-products/dp-product-${value.image}.webp`} alt={value.img_name} loading='lazy' />
                                                        </LazyLoad>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    {/* <p>{value.category}</p> */}
                                                    <h4><Link to={value.url}>{value.title}</Link></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>
                    <TabPanel className="row row--35">
                        {TabTwo.map((value, index) => (
                            <div className={`${column}`} key={index}>
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <Link to={value.url}>
                                                        <LazyLoad placeholder={<img src={`portfolio${value.image}`} alt='img' loading='lazy' />}>
                                                            <img src={`/assets/images/our-products/dp-product-${value.image}.webp`} alt={value.img_name} loading='lazy' />
                                                        </LazyLoad>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    {/* <p>{value.category}</p> */}
                                                    <h4><Link to={value.url}>{value.title}</Link></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>
                    <TabPanel className="row row--35">
                        {TabThree.map((value, index) => (
                            <div className={`${column}`} key={index}>
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab2: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <Link to={value.url}>
                                                        <img src={`/assets/images/our-products/dp-product-${value.image}.webp`} alt={value.img_name} loading='lazy' />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    {/* <p>{value.category}</p> */}
                                                    <h4><Link to={value.url}>{value.title}</Link></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>


                    <TabPanel className="row row--35">
                        {TabFour.map((value, index) => (
                            <div className={`${column}`} key={index}>
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab4: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <Link to={value.url}>
                                                        <img src={`/assets/images/our-products/dp-product-${value.image}.webp`} alt={value.img_name} loading='lazy' />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    {/* <p>{value.category}</p> */}
                                                    <h4><Link to={value.url}>{value.title}</Link></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel className="row row--35">
                        {TabFive.map((value, index) => (
                            <div className={`${column}`} key={index}>
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <Link to={value.url}>
                                                        <LazyLoad placeholder={<img src={`portfolio${value.image}`} alt={value.image} loading='lazy' />}>
                                                            <img src={`/assets/images/our-products/dp-product-${value.image}.webp`} alt={value.img_name} />
                                                        </LazyLoad>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    {/* <p>{value.category}</p> */}
                                                    <h4><Link to={value.url}>{value.title}</Link></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>
                    <TabPanel className="row row--35">
                        {TabSix.map((value, index) => (
                            <div className={`${column}`} key={index}>
                                <div className="item-portfolio-static">
                                    <div onClick={() => this.setState({ isOpen: true, tab1: index })}>
                                        <div className="portfolio-static">
                                            <div className="thumbnail-inner">
                                                <div className="thumbnail">
                                                    <Link to={value.url}>
                                                        <LazyLoad placeholder={<img src={`portfolio${value.image}`} alt={value.image} loading='lazy' />}>
                                                            <img src={`/assets/images/our-products/dp-product-${value.image}.webp`} alt={value.img_name} />
                                                        </LazyLoad>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    {/* <p>{value.category}</p> */}
                                                    <h4><Link to={value.url}>{value.title}</Link></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </TabPanel>

                </Tabs>
            </div>
        )
    }
}


export default OurProductsTab
