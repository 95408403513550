import React, { Component } from "react";
import { FaTwitter, FaWhatsapp, FaFacebookF, FaLinkedinIn, FaYoutube } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { FiInstagram, FiMail } from "react-icons/fi";
// import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
// import ContactPage from "../.././creative_homePage/ContactPage";

const SocialShare1 = [
    { Social: <FaFacebookF />, link: 'https://www.facebook.com/noorisystech' },
    { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/company/noorisys-technologies-pvt-ltd/' },
    { Social: <FaTwitter />, link: 'https://twitter.com/noorisys' },
    { Social: <FiInstagram />, link: 'https://www.instagram.com/noorisys11/' },
]
const SocialShare = [
    { Social: <FaYoutube />, link: 'https://www.youtube.com/channel/UCkBVw01AixSyVzzkQXXjX2g' },
    { Social: <FaWhatsapp />, link: 'https://wa.me/7796614664' },
    { Social: <FiMail />, link: 'mailto:info@noorisys.com' }
]

class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <footer className="footer-area">

                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left">
                                    <div className="inner">
                                        <span>Have an Idea?</span>
                                        <h2>Get A Quote<br />Now!</h2>

                                        <Link className="rn-button-style--2" to="/contact-us">Contact Us</Link>
                                        <div className="social-share-inner">
                                            <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                {SocialShare1.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className="social-share-inner">
                                            <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                {SocialShare.map((val, i) => (
                                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <a className="chat_btn" href="https://wa.me/7796614664" id="chat_btn" target="_blank" rel="noopener noreferrer"><i><FaWhatsapp /></i></a>

                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <h4>Quick Links</h4>
                                                <ul className="ft-link">

                                                    <li><Link to="/about">About</Link></li>
                                                    <li><Link to="/our-approach">Our Approach</Link></li>
                                                    <li><Link to="/portfolio">Portfolio</Link></li>
                                                    <li><Link to="/blogs">Blogs</Link></li>
                                                    {/* <li><Link to="/contact-us">Contact Us</Link></li> */}
                                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                                    <li><Link to="/sitemap">Sitemap</Link></li>
                                                    <li><Link to="/become-a-partner">Become a Partner</Link></li>

                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Products</h4>
                                                <ul className="ft-link">
                                                    <li><Link to="/doctor-appointment-and-hospital-management">Doctor Appointment System</Link></li>
                                                    <li><Link to="/healthcare-insurance-marketplace">Marketplace</Link></li>
                                                    {/* <li><Link to="/school-management-system">School Management System</Link></li> */}
                                                    <li><Link to="/hotel-booking-platform">Online Travel Agency</Link></li>
                                                    <li><Link to="/online-food-delivery">Food Delivery App</Link></li>
                                                    <li><Link to="/fitness-app">Fitness App</Link></li>
                                                    <li><Link to="/learning-management">Learning Management</Link></li>
                                                    <li><Link to="/digital-business-card">Digital Business Card </Link></li>
                                                </ul>


                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2024 Noorisys Technologies. All Rights Reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;