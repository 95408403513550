import React, { useState, useRef } from 'react';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
const Result = () => {
    return (
        <p className="success-message">Thank you for contacting us, our team will contact you shortly.</p>
    )
}



function ContactForm() {
    const [result, showresult] = useState(false);
    const [showbutton, setShowbutton] = useState(false);
    const captchaRef = useRef(null)
    // const [posts, setPosts] = useState([]);
    // const [title, setTitle] = useState('');
    // const [body, setBody] = useState('');
    // const [state, setState] = useState('');

    const onChange = (token) => {
        setShowbutton(true)
    }


    const submitHandler = (event) => {
        event.preventDefault();
        if (showbutton) {
            const body = new FormData();
            body.append('name', event.target.name.value);
            body.append('email', event.target.email.value);
            body.append('subject', "Noorisys.com | Request a quote");
            body.append('phone', event.target.phone.value);
            body.append('message', event.target.message.value);


            axios
                .post('https://noorisys.com/contact-submit/contact-api.php', body)
                .then((result) => {
                    console.log(result.text);
                },
                    (error) => {
                        console.log(error.text);
                    }
                );
            event.target.reset();
            showresult(true);

            console.log('The link was clicked.');
        }
        else {
            alert('Verify you are human');
        }
    }



    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form onSubmit={submitHandler}>
            <div className="rn-form-group">
                <input
                    type="text"
                    name="name"
                    id="name"


                    placeholder="Your Name"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="email"
                    name="email"
                    id="email"

                    placeholder="Your Email"
                    required
                />
            </div>

            <div className="rn-form-group">
                <input
                    type="text"
                    name="phone"
                    id="phone"

                    placeholder="Phone Number"

                />
            </div>

            {/* <div className="rn-form-group">
                <input 
                type="text"
                name="subject"
                placeholder="Subject"
                
                />
            </div> */}

            <div className="rn-form-group">
                <textarea
                    name="message"


                    placeholder="Your Message"

                >
                </textarea>
            </div>
            <div className='rn-form-group'>
                <ReCAPTCHA
                    sitekey=" 6LcQafklAAAAABsk4ORLbJLmuYSxYqgNuL_wEyQq"
                    ref={captchaRef}
                    // onVerify={verify} 
                    onChange={onChange}
                />
            </div>
            <div className="rn-form-group pt--20">
                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit">Submit Now</button>
            </div>

            <div className="rn-form-group">
                {result ? <Result /> : null}
            </div>
        </form>
    )
}
export default ContactForm;
