import React, { useEffect, useState } from 'react'
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CalendarModal from '../popup/CalenderModal';
import CommonTextLoop from '../component/common/CommonTextloopBanner';
import ServiceDetails from '../creative_homePage/ServiceDetails';
import IndustriesClientReveiw from './IndustriesClientReveiw';
const NoorisysVideo = React.lazy(() => import('./NoorisysVideo'));
// const HealthCareReview = React.lazy(() => import('./HealthCareReview'));
const VerbosSimilarApps = React.lazy(() => import('../component/Verbos/VerbosSimilarApps'));
const HealthCareClient = React.lazy(() => import('./HealthCareClient'));



function usePageViewTracking(location) {
    useEffect(() => {
        window.gtag("config", "AW-16650476770", {
            page_title: location.pathname,
            page_path: location.pathname,
        });
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', location.pathname]);
        _hsq.push(['trackPageView']);
    }, [location]);
}
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #639cd9 0%, #4636fc 100%)', link: '/healthcare-insurance-marketplace', img: 'healthcare.webp', h2: 'Izikare', p: 'Healthcare Insurance Marketplace' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/doctor-appointment-and-online-pharmacy', img: 'mediconnect.webp', h2: 'MediConnect', p: 'Doctor Appointment and online pharmacy' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'Biblo', p: 'Nursing Platform' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/primary-care-management-system', img: 'federate.webp', h2: 'Federate', p: 'Primary Care Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'Allodocta', p: 'Pharmacist Marketplace' },
]
const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Welcome to my World',
        description: '',
        buttonText: '',
        buttonLink: '',
        imageSrc: '/assets/images/about/Healthcare_header _03.webp',
        textLoopItems: ['Telehealth <br /> system', ' Online <br /> Consultation', 'EMR System', 'Pharmacy <br /> Marketplace'],
        bgClass: 'bg_color--8'
    }
]


const Testimonial = [
    {
        textPosition: 'inner text-left',
        category: "We are grateful to Noorisys Technologies for setting up our entire medical verification platform and maintaining the supply chain network for our services. I'm more than happy to work with Noorisys and the integrity with which they work and their commitment to delivering our assignment are very commendable.",
        description: "We are grateful to Noorisys Technologies for setting up our entire medical verification platform and maintaining the supply chain network for our services. I'm more than happy to work with Noorisys and the integrity with which they work and their commitment to delivering our assignment are very commendable.",
        buttonText: '',
        buttonLink: '',
        name: '- DR. MICHAEL FONSO',
        img: "/assets/images/client/michel_fonso.webp",
        alt: 'michael fonso'
    }
]
const HealthCare = (props) => {
    usePageViewTracking(props.location);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const data = [
        {
            title: 'Doctor Appointment System',
            description:
                'Doctor appointment system is a smart booking system that provides patients an easy way of booking a doctor’s appointment remotely. This is a cross platform application useful in investigating, monitoring the prescriptions and management of patient records creating an electronic health record.The gathered data can be managed and consulted by authorised clinicians and staff within the health care organisation.',
            features: [
                'Easy registration for providers and patients.',
                'Email verification and Forgot password.',
                'Online Teleconsultation and physical appointment.',
                'Audio / Video appointment.',
                'EMR management.',
                'Insurance Package Marketplace.',
                'Secure online and offline payment.',
                'Multi-currency and multi-language.',
            ],
            image: '/assets/images/service/service-11.webp',
            alt: 'Service1',
            onClick: openModal,
            text: 'Schedule a demo'
        },
        {
            title: 'Online Pharmacy',
            description: 'Online Pharmacy is an online health diagnosis platform where users can order different types of blood tests and medicines with an option to receive to collect their blood samples and send it back to the labs. Labs perform different tests and provide an HL7 file uploaded to the system. The system is useful in parsing the file and creating the reports in PDF format with a graphical presentation of the data in the platform (WEB BASED). This system is available with customizable dashboards for each lab.',
            features: [
                'Easy login and registration.',
                'Online PDF report generation.',
                'Customised panel for labs.',
                'Orders/Tests tracking details.',
                'Admin can manage multiple labs.',
                'Multilingual with enticing UI.',
                'Secure.',
            ],
            image: '/assets/images/service/service-12.webp',
            alt: 'online pharmacy',
            onClick: openModal,
            text: 'Schedule a demo'
        },
        {
            title: 'Primary and Secondary Care',
            description: 'Hospital chain management system is a powerhouse platform and secure healthcare application for hospitals. It has evolved over many years of understanding the core needs of hospital management by the experts who have worked on the front line of the NHS hospital structure. It helps doctors and hospitals to effectively manage their profiles, rotas, apply for open job positions and facilitate smooth payments.',
            features: [
                'Profile and rota management.',
                'Store, Verify, Access and Share Documents.',
                'Payment, Invoice and Leave Management.',
                'Simple doctor hiring in a few simple steps by eliminating agencies.',
                'Internal case discussion feature for better patient treatment.',
                'Cross communication with hospital admins, accounting staff and other doctors can to bring a cohesive work environment.',
            ],
            image: '/assets/images/service/service-13.webp',
            onClick: openModal,
            text: 'Schedule a demo'
        },
    ]

    const data2 = [
        {
            title: 'Data Security',
            noFeature: true,
            features: [
                'We understand the sensitive nature of data.',
                'Noorisys is an ISO 27001 compliant organisation.',
                'Your information and healthcare data is safe with us.',
                'Successful performance in each and every penetration testing.',
                'We have been assessed for GDPR compliance and policy frameworks.',
                'Regular audit by external agencies and assessment is done by stakeholders.',
                'Being an ISO certified organisation we handle data with much care and diligence.',
                'Strict Access Control Policy to protect any possible threat to data and information.',
            ],
            image: '/assets/images/service/service-14.webp',
            alt: 'data security',
            whatasApp: 'Schedule a call with our security expert',
            whatasAppText: 'schedule a call with security expert'
        },
        {
            title: 'All in One Solution',
            noFeature: true,
            features: [
                'What you exactly require is all here under one roof.',
                'Do not worry about the complex onboarding process.',
                'Skip the endless interviews, we have done that for you.',
                'An experienced team for you from the prominent institutes.',
                'Perfect team coordination, quick development and faster delivery.',
                'Our all in one solution reduces your efforts in project management.',
                'Hire a trustworthy and cost effective resource that speaks your language.',
                'No more new-hire paperwork, we already found and vetted the best talents.',
            ],
            image: '/assets/images/service/service-15.webp',
            alt: 'all in one solutions',
            whatasApp: 'Schedule a meeting with our Business Analyst',
            whatasAppText: 'schedule a call with business analyst'
        },
        {
            title: 'Trusted',
            noFeature: true,
            features: [
                'Trusted by the leading organisations in the world.',
                'Carrying the badge of trust for more than 11 years.',
                'Hired by semi-government and government agencies.',
                'Working with reputed brands of healthcare and fitness sector.',
                'Endorsed by more than 30 well known names of the healthcare industry.',
                'We believe in the professional ethics and cultural values driven by people.',
                'Our long journey and client history tells our good will status in the market.',
            ],
            image: '/assets/images/service/service-16.webp',
            alt: 'trusted',
            whatasAppText: 'schedule a call for partner with you',
            whatasAppButton: 'Partner with us today'
        },
    ]

    return (
        <div>
            <Helmet pageTitle="Health Care || Noorisys Technologies Pvt Ltd" pageUrl='/health-care' metaTitle="Healthcare" metaImage="/assets/images/about/Healthcare_header _03.webp"
                metaDescription="Noorisys - A trusted partner for healthcare technology solutions. We provide secure and efficient software development services for hospitals, clinics, and medical devices companies. Let us help you improve patient outcomes and streamline processes." />
            {/* Start Header Area  */}
            <Header headerPosition="header--static" logo="symbol-dark" color="color-black" />
            {/* End Header Area  */}
            <CalendarModal show={isModalOpen} onHide={closeModal} />
            {/* <div className="slider-wrapper">
                // Start Single Slide
                {SlideList.map((value, index) => (
                    <div className="slide designer-portfolio slider-style-3  d-flex align-items-center justify-content-center bg_color--8 rn-slider-height" key={index}>
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-5">
                                    <div className="designer-thumbnail">
                                        <img src="/assets/images/about/Healthcare_header _03.webp" alt="Slider" loading='lazy' />
                                    </div>
                                </div>
                                <div className="col-lg-7 mt_md--40 mt_sm--40">
                                    <div className={`inner ${value.textPosition}`}>
                                        <h1 className="title">Get Your <br />
                                            <TextLoop>
                                                <span> Telehealth <br /> system</span>
                                                <span> Online <br /> Consultation</span>
                                                <span> EMR System</span>
                                                <span> Pharmacy <br /> Marketplace</span>
                                            </TextLoop>{" "}
                                        </h1>
                                        <h2>NOW</h2>
                                        {value.description ? <p className="description">{value.description}</p> : ''}
                                        {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="view-more-btn mt--10 text-center">
                                                <Link className="rn-button-style--2 btn-solid" to="/contact-us"><span>Send an inquiry</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ))}
                //  End Single Slide 
            </div> */}
            <CommonTextLoop slideList={SlideList} />


            {/* Start Brand Area */}
            <div className="rn-brand-area bg_color--1 ptb--30">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <HealthCareClient />
                            </React.Suspense>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Brand Area */}

            {/* Start Page Wrapper */}
            <ServiceDetails data={data} bgColor="bg_color--5" />
            {/* End Page Wrapper */}

            <IndustriesClientReveiw Testimonial={Testimonial} bgColor={`bg_color--1`} />

            {/* Start Page Wrapper */}
            <ServiceDetails data={data2} bgColor="bg_color--5" />
            {/* End Page Wrapper */}

            {/* <Video />   */}

            <React.Suspense fallback={<div>loading...</div>} >
                <NoorisysVideo />
            </React.Suspense>

            <div className="portfolio-area ptb--40 bg_color--5">
                {/* <div className="portfolio-sacousel-inner mb--55">
                    <React.Suspense fallback={<div>loading...</div>} >
                        <HealthCareReview />
                    </React.Suspense>
                </div> */}
                <div className='pt--40'>
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='theme-gradient' title={'Healthcare Apps'} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                </div>
            </div>



            {/* Start Footer Style  */}
            <Footer />
            {/* End Footer Style  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}



        </div>
    )
}

export default HealthCare
