import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiCheck } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";
import { Link } from "react-router-dom";
import CommonSliderTransitionBanner from "../component/common/CommonSliderTransitionBanner";
// import WebDevelopmentService from "./SupportServicesService";
const Brand = React.lazy(() => import('../creative_homePage/CreativeBrand'));
const SupportServicesTab = React.lazy(() => import('./SupportServicesTab'));
const WebDevelopmentService = React.lazy(() => import("./SupportServicesService"));
// const SupportServicesReview = React.lazy(() => import('./SupportServicesReview'));
const SupportServicesPortfolio = React.lazy(() => import('./SupportServicesPortfolio'));

const SlideList = [
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--23',
        category: '',
        title: 'Customer Support',
        description: 'Delivering a great customer experience can be a challenging task, Especially if you don’t have the right support. Get it from us.',
        buttonText: 'Hire A Support Team',
        buttonLink: '/contact-us'
    },
]



class SupportServices extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    render() {
        // const PostList = BlogContent.slice(0, 3);
        let title = 'Success Stories',
            description = 'Our well trained customer support team equipped with right tools and continuous learning imparts your business an extra edge over your competitors. With our support services we have excelled in Healthcare industry, Travel and Tourism segments, Document management services. Few are listed below.';
        return (
            <Fragment>

                <Helmet pageTitle="Customer Support || Noorisys Technologies Pvt Ltd" pageUrl='/support-services' metaTitle="Support Services" metaImage="/assets/images/bg/bg-image-23.webp"
                    metaDescription="Get the support you need to keep your business running smoothly with Noorisys. Our experienced team provides top-notch IT and non IT support services."
                    metaKeywords="customer support services ,support customer service" />

                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                {/* <div className="slider-wrapper color-white">
                    <div className="slider-activation slider-digital-agency">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value, index) => (
                                <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title ">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>

                    </div>
                </div> */}
                <CommonSliderTransitionBanner slideList={SlideList} />
                {/* End Slider Area   */}

                {/* Start Brand Area */}
                <div className="rn-brand-area ptb--20 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ptb--60">
                                <Brand />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Pricing Tbale Area  */}

                <div className="rn-pricing-table-area ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row ptb--20">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3>Choose Your Customer Support Options</h3>
                                    <p>Hire the best support agents for your business!</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Clinical Coding</h4>
                                            <p className="title">Get your patients data perfectly
                                                <br />aligned as per UK NHS model.</p>

                                            <div className="pricing">
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Well versed with clinical coding</li>
                                                <li><FiCheck /> Remote access to authorities</li>
                                                <li><FiCheck /> UK NHS model practiced</li>
                                                <li><FiCheck /> Patient details at single place</li>
                                                <li><FiCheck /> GDPR Compliant</li>


                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to={"/contact-us"}>Hire Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Data Entry</h4>
                                            <p className="title">Secure your record and data with us.</p>
                                            <div className="pricing">
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck />Online Data Entry</li>
                                                <li><FiCheck />Offline Data Entry</li>
                                                <li><FiCheck />Image Data Entry</li>
                                                <li><FiCheck />Survey Forms Entry</li>
                                                <li><FiCheck />Document Management</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to={"/contact-us"}>Hire Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Email Support</h4>
                                            <p className="title">Delivering a reliable customer experience </p>
                                            <div className="pricing">
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Quick Response</li>
                                                <li><FiCheck /> Timely Support </li>
                                                <li><FiCheck /> 24x7 Availability</li>
                                                <li><FiCheck /> Service Oriented Attitude</li>
                                                <li><FiCheck /> CRM Acquainted </li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to={"/contact-us"}>Hire Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                        </div>
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}





                {/* Start About Area */}
                <div id="about" className="fix">
                    <div className="about-area ptb--80  bg_color--5">
                        <div className="about-wrapper">
                            <div className="container">
                                <div className="row row--35 align-items-center">
                                    <div className="col-lg-5">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/about/success_stories_3.webp" alt="About Images" loading="lazy" />
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="about-inner inner">
                                            <div className="section-title">
                                                <h2 className="title">{title}</h2>
                                                <p className="description justifypara">{description}</p>
                                            </div>
                                            <div className="row mt--30">
                                                <React.Suspense fallback={<div>loading...</div>} >
                                                    <SupportServicesTab tabStyle="tab-style--1" />
                                                </React.Suspense>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* End About Area */}

                <div className="service-area ptb--60  bg_color--1">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <WebDevelopmentService />
                        </React.Suspense>
                    </div>
                </div>

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--30 bg_color--5">
                    <div className="portfolio-sacousel-inner mb--55">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <SupportServicesPortfolio />
                        </React.Suspense>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start review Area */}
                {/* <div className="portfolio-area ptb--40 bg_color--5">
                    <div className="portfolio-sacousel-inner mb--55">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <SupportServicesReview />
                        </React.Suspense>
                    </div>
                </div> */}
                {/* end review Area */}

                {/* Start Footer Style  */}
                <Footer />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default SupportServices;