import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import PlatformUsed from "../component/Verbos/PlatformUsed";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const CommonMasonry = React.lazy(() => import('./CommonMasonry'))
const PortfolioList = [
    {
        image: 'online_laundry-01',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'online_laundry-02',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'online_laundry-03',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },
    {
        image: 'online_laundry-04',
        category: 'Development',
        title: 'Getting tickets to the big show'
    },

]
// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
const technologies = [

    { h5: 'Android', p: 'Android ', img: 'android.png' },
    { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
    // { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
    { h5: 'PHP', p: 'Admin Panel Development', img: 'php.png' },

]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

class OnlineLaundaryPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Online Laundary || Noorisys Technologies Pvt Ltd' pageUrl='/online-laundry' metaTitle="Online Laundry" metaImage="/assets/images/portfolio/dp-portfolio-48.webp"
                    metaDescription="Book your laundry services easily with our Laundry Booking System. Connect with service providers, track your bookings, make secure online payments and manage your account all in one place. Get your clothes picked up, washed and delivered to your door with just a few clicks." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Online Laundry"
                    customColor="custom-courier-color"
                    dataBlackOverlay={3}
                    bgImage="bg_image--87"
                    p="Web / Android/ iOS Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-courier-color">Online Laundry</h3>
                                        <p className="justifypara">Laundry booking system is a platform to provide service to your door steps. This platform directly connects the customer with the service providers, and simplifies, optimizes and brings operational excellence to our customers. The system provides an easy booking for your laundry to pick up from home, wash and deliver at your door step.</p>

                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Easy registration for customer and laundry service providers</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Attractive dashboard for each users</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Customer relationship management</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Secure online payment</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Offline payment</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Prepaid service</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Digitalization of your processes and flows</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Clothes selection</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Online booking</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Easy payment options (Pay on pick and deliver)</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Track your booking</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Easy cancellation and refunds</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Multi-currency</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Multi language</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Manage address book</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Review and ratings</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Accounts and wallet</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Separate chat room for each booking</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Alerts and notifications</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Mark providers as favorite</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Attractive and transparent prices managed by admin and provider</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />Fully responsive design</li>
                                            <li className="justifypara"><FiCheck color="#2b3a8f" />And many more….</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-choice-making'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30"
                // style={{backgroundColor:'#013277'}}
                >
                    <div className="container">
                        <div className="pb--40">
                            <div className="row">
                                <PlatformUsed color='custom-courier-color' items={technologies} />
                            </div>
                            <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-choice-making'} paddingTop={'pt--30'} />
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h3 className="title">Screenshots</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper plr--30">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonMasonry item="9" column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover" PortfolioList={PortfolioList} folder={'online-laundry'} />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-choice-making'} paddingTop={'pt--30'} />
                    <div className="pt--30">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <VerbosSimilarApps color='custom-courier-color' title='Apps' items={similarapps} slidestoshow={[3, 3]} />
                        </React.Suspense>
                    </div>
                    <CommonPortfolioButtons class='btn-choice-making' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default OnlineLaundaryPortfolioDetails;
