import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const QysmatProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));

// const SocialShare =[
//     {Social:<FaGlobe />,link:' https://annachi.fr/ '}
// ]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'PHP', p: 'Admin Panel', img: 'php.png' },
    { h5: 'CodeIgniter', p: 'Backend Development', img: 'ci4.png' },
    { h5: 'Mysql', p: 'Database', img: 'mysql.png' },
    // { h5: 'Native  Android', p: 'App Development', img: 'native.png' },
    { h5: 'Flutter', p: 'App Development', img: 'flutter.png' },
]

const singletonButtonNames = ['Splash Screen', 'Register', 'Login', 'Users', 'Home', 'Matches', 'Quotes', 'Profile', 'Settings', 'Update Profile', 'Match Screen'];
const singletonImageAddress = [
    '/assets/images/portfolio/match-making/singleton/singleton-01.webp',
    '/assets/images/portfolio/match-making/singleton/singleton-02.webp',
    '/assets/images/portfolio/match-making/singleton/singleton-03.webp',
    '/assets/images/portfolio/match-making/singleton/singleton-04.webp',
    '/assets/images/portfolio/match-making/singleton/singleton-05.webp',
    '/assets/images/portfolio/match-making/singleton/singleton-06.webp',
    '/assets/images/portfolio/match-making/singleton/singleton-07.webp',
    '/assets/images/portfolio/match-making/singleton/singleton-08.webp',
    '/assets/images/portfolio/match-making/singleton/singleton-09.webp',
    '/assets/images/portfolio/match-making/singleton/singleton-10.webp',
    '/assets/images/portfolio/match-making/singleton/singleton-11.webp',
];

const parentButtonNames = ['Splash Screen', 'Register', 'Login', 'Users', 'Home', 'Matches', 'Chat', 'Quotes', 'Profile', 'Settings', 'Update Profile', 'Match Screen'];
const parentImageAddress = [
    '/assets/images/portfolio/match-making/parent/parent-01.webp',
    '/assets/images/portfolio/match-making/parent/parent-02.webp',
    '/assets/images/portfolio/match-making/parent/parent-03.webp',
    '/assets/images/portfolio/match-making/parent/parent-04.webp',
    '/assets/images/portfolio/match-making/parent/parent-05.webp',
    '/assets/images/portfolio/match-making/parent/parent-06.webp',
    '/assets/images/portfolio/match-making/parent/parent-07.webp',
    '/assets/images/portfolio/match-making/parent/parent-08.webp',
    '/assets/images/portfolio/match-making/parent/parent-09.webp',
    '/assets/images/portfolio/match-making/parent/parent-10.webp',
    '/assets/images/portfolio/match-making/parent/parent-11.webp',
    '/assets/images/portfolio/match-making/parent/parent-12.webp'
];

const adminButtonNames = ['Login', 'Dashboard', 'Manage Singleton', 'Manage Subscription', 'Manage Islamic Quotes', 'Manage FAQs', 'Add FAQ', 'Manage Web Pages', 'Update Page', 'Change Password', 'Singleton Details'];
const adminImageAddress = [
    '/assets/images/portfolio/match-making/admin/admin-01.webp',
    '/assets/images/portfolio/match-making/admin/admin-02.webp',
    '/assets/images/portfolio/match-making/admin/admin-03.webp',
    '/assets/images/portfolio/match-making/admin/admin-04.webp',
    '/assets/images/portfolio/match-making/admin/admin-05.webp',
    '/assets/images/portfolio/match-making/admin/admin-06.webp',
    '/assets/images/portfolio/match-making/admin/admin-07.webp',
    '/assets/images/portfolio/match-making/admin/admin-08.webp',
    '/assets/images/portfolio/match-making/admin/admin-09.webp',
    '/assets/images/portfolio/match-making/admin/admin-10.webp',
    '/assets/images/portfolio/match-making/admin/admin-11.webp',
    '/assets/images/portfolio/match-making/admin/admin-12.webp',
];
class QysmatPortfolio extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Match Making Application' pageUrl='/match-making' metaTitle="Digital Solution For Civil Documents" metaImage="/assets/images/portfolio/dp-portfolio-51.webp"
                    metaDescription="A comprehensive solution that seamlessly incorporates all the essential features for
managing a successful food delivery business. From multi-language support and precise
coverage area mapping to streamlined order management and versatile payment gateways,
our app has it all" />

                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Matchmaking Application"
                    customColor="theme-gradient"
                    dataBlackOverlay={5}
                    bgImage="bg_image--108"
                    p="Web/Android/iOS Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-match-making-product">Matchmaking Application</h3>
                                        <p className="justifypara">This is a matchmaking platform for Muslims with users as singletons and parents. Singletons and parents will register themselves individually. Then parents will search for their child and they can be linked via 6 digit code received on the child’s app. And singletons can not use the app before linking themselves with their parents.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#8f7c5d" />Singleton and parent registration</li>
                                            <li><FiCheck color="#8f7c5d" />Two-stage verification process (Email and SMS OTP)</li>
                                            <li><FiCheck color="#8f7c5d" />ID check process</li>
                                            <li><FiCheck color="#8f7c5d" />Weekly islamic quotes</li>
                                            <li><FiCheck color="#8f7c5d" />Basic and premium subscriptions</li>
                                            <li><FiCheck color="#8f7c5d" />Features for singletons and parents</li>
                                            <li><FiCheck color="#8f7c5d" />Unlimited swipes</li>
                                            <li><FiCheck color="#8f7c5d" />Audio/Video call</li>
                                            <li><FiCheck color="#8f7c5d" />Profile sharing</li>
                                            <li><FiCheck color="#8f7c5d" />Undo swipes</li>
                                            <li><FiCheck color="#8f7c5d" />Category preferences</li>
                                            <li><FiCheck color="#8f7c5d" />Connection of profile with children</li>
                                            <li><FiCheck color="#8f7c5d" />Child-child and parent-parent communication</li>
                                            <li><FiCheck color="#8f7c5d" />Multilingual support</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-match-making'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-match-making-product' items={technologies} />
                        </React.Suspense>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-match-making'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 pb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <QysmatProductCommonApp
                                    h3='Singleton'
                                    p='Singletons on this App can create detailed profiles, undergo ID verification and use premium features for personalized matches and secure communication.'
                                    buttonNames={singletonButtonNames}
                                    image={singletonImageAddress}
                                    alt="singleton"
                                    color='qysmat'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-match-making'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <QysmatProductCommonApp
                                    h3='Parent'
                                    p={`Parents can manage their profiles, connect with their children, explore potential matches, and utilize premium features for secure communication, actively participating in their children's matchmaking journey.`}
                                    buttonNames={parentButtonNames}
                                    image={parentImageAddress}
                                    alt="Parent"
                                    color='qysmat'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-match-making'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin'
                                    p={`Parents can manage their profiles, connect with their children, explore potential matches, and utilize premium features for secure communication, actively participating in their children's matchmaking journey.`}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt="Admin"
                                    color='qysmat'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-match-making'} paddingTop={'pt--30'} paddingBottom={'pb--30'} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-match-making-product' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-match-making' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default QysmatPortfolio;
