import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import ProductCommonApp from "../portfolio/ProductCommonAppdemo";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
const CommonProductWeb = React.lazy(() => import("../portfolio/CommonProductWeb"));
const CommonPortfolioButtons = React.lazy(() => import("../component/CommonPortfolioButton"));
const CoursierDelivrable = React.lazy(() => import("../portfolio/CoursierDeliverables"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const deliverables = [
    { item_bg: 'android.png', item_icon: 'user-fitness.png', h3: 'User App', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'user-fitness.png', h3: 'User App', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'admin-fitness.png', h3: 'Admin ', h4: 'Web' },
]
const technologies = [

    { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
    { h5: 'PHP', p: 'Admin Panel Development', img: 'php.png' },
    { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },

]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
]

const userAppButtonNames = ['Register', 'Login', 'Home', 'Diet', 'Shop', 'Matrics', 'Profile', 'Body Parts Exercise', 'Workouts', 'Workout Levels', 'Diet Categories', 'Best Diet Discoveries', 'Favourite Workout', 'Favourite Nutritious', 'Blogs'];
const userAppImageAddress = [
    '/assets/images/our-products/fitness-app/users/fitness-1.webp',
    '/assets/images/our-products/fitness-app/users/fitness-2.webp',
    '/assets/images/our-products/fitness-app/users/fitness-3.webp',
    '/assets/images/our-products/fitness-app/users/fitness-4.webp',
    '/assets/images/our-products/fitness-app/users/fitness-5.webp',
    '/assets/images/our-products/fitness-app/users/fitness-6.webp',
    '/assets/images/our-products/fitness-app/users/fitness-7.webp',
    '/assets/images/our-products/fitness-app/users/fitness-8.webp',
    '/assets/images/our-products/fitness-app/users/fitness-9.webp',
    '/assets/images/our-products/fitness-app/users/fitness-10.webp',
    '/assets/images/our-products/fitness-app/users/fitness-11.webp',
    '/assets/images/our-products/fitness-app/users/fitness-12.webp',
    '/assets/images/our-products/fitness-app/users/fitness-13.webp',
    '/assets/images/our-products/fitness-app/users/fitness-14.webp',
    '/assets/images/our-products/fitness-app/users/fitness-15.webp',
];

const adminButtonNames = ['Admin Dashboard', 'Users', 'Equipment', 'Exercise', 'Workout List', 'Diet List', 'Exercise Levels', 'Body Parts List', 'Products List', 'Blogs List'];
const adminImageAddress = [
    '/assets/images/our-products/fitness-app/admin/admin-1.webp',
    '/assets/images/our-products/fitness-app/admin/admin-2.webp',
    '/assets/images/our-products/fitness-app/admin/admin-3.webp',
    '/assets/images/our-products/fitness-app/admin/admin-4.webp',
    '/assets/images/our-products/fitness-app/admin/admin-5.webp',
    '/assets/images/our-products/fitness-app/admin/admin-6.webp',
    '/assets/images/our-products/fitness-app/admin/admin-7.webp',
    '/assets/images/our-products/fitness-app/admin/admin-8.webp',
    '/assets/images/our-products/fitness-app/admin/admin-9.webp',
    '/assets/images/our-products/fitness-app/admin/admin-10.webp',
];
class FitnessProduct extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isVisible: false
        }
        this.openModal = this.openModal.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Fitness App || Noorisys Technologies Pvt Ltd' pageUrl='/fitness-app' metaTitle="Fitness App" metaImage="/assets/images/portfolio/dp-portfolio-01.jpg"
                    metaDescription="This Fitness app offers a versatile and user-centric platform, providing a diverse library of exercises for users. Users can opt for workout routines designed to address specific goals and they can access diet plans to make healthier food choices." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title='Fitness App'
                    dataBlackOverlay={7} bgImage='bg_image--131'
                    customColor='custom-fitness-app-product'
                    p='Web / Android / iOS Application'
                    // socialShare={SocialShare}
                    pb={'pb--120'}
                    buttonTitle={'get a quote'}
                    openPopup={true}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-fitness-app-product">Fitness App</h3>
                                        <p className="justifypara">This Fitness app offers a versatile and user-centric platform, providing a diverse library of exercises for users. Users can opt for workout routines designed to address specific goals and they can access diet plans to make healthier food choices.</p>
                                        <h4 className="title">User Functionalities </h4>
                                        <p className="justifypara">Users can explore a variety of exercises and they can opt for workout routines designed to address specific goals, be it weight loss, muscle gain, or overall fitness improvement. Users can access diet plans and nutritional guidance to make healthier food choices.</p>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Register And Login</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Exercises</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Workouts </li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Diet Plans</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Blogs</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Step Counts</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Bmi Calculation</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Weight And Heart Rate Matrics</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Subscriptions</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Payments</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Favourite Diets And Workouts</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Multi-Language Support</li>
                                        </ul>
                                        <h4 className="title">Admin Panel Functionalities</h4>
                                        <p className="justifypara">The admin can efficiently manage and oversee every aspect of the Fitness App through the dedicated admin panel. </p>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Dashboard</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Manage Users</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Manage Body Parts</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Manage Equipments</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Manage Workouts and Workout Types</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Manage Levels</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Manage Diets</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Manage Blogs</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Manage Subscriptions</li>
                                            <li className="justifypara"><FiCheck color={'#E64C1A'} />Push Notifications</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <div className="d-sm-block d-flex justify-content-center align-items-center pt--30">
                                            <button className="get-a-quote btn-fitness text-center" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--60"
                    style={{ backgroundColor: '#FC5B27' }}
                >
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <CoursierDelivrable title={'3 Deliverables'} items={deliverables} class={'fitness'} value={[3, 3, 3]} />
                        </React.Suspense>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center pt--50">
                    <button className="get-a-quote btn-fitness text-center" onClick={this.showPopup}>
                        <span>Get a quote </span>
                    </button>
                </div>
                <div className="ptb--90">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-fitness-app-product' items={technologies} />
                        </React.Suspense>
                        <div className="d-flex justify-content-center align-items-center pt--30">
                            <button className="get-a-quote btn-fitness text-center" onClick={this.showPopup}>
                                <span>Get a quote </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--1 ptb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='Users can explore a variety of exercises and they can opt for workout routines designed to address specific goals, be it weight loss, muscle gain, or overall fitness improvement. Users can access diet plans and nutritional guidance to make healthier food choices.'
                                    buttonNames={userAppButtonNames}
                                    image={userAppImageAddress}
                                    alt='umrah user app'
                                    color='fitness-app'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-fitness text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='The admin can efficiently manage and oversee every aspect of the Fitness App through the dedicated admin panel.'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='umrah admin'
                                    color='fitness-app'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-fitness text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                </div>
                <React.Suspense fallback={<div>loading...</div>} >
                    <VerbosSimilarApps color='custom-fitness-app-product' title={'Other Apps'} items={similarapps} slidestoshow={[3, 3]} />
                </React.Suspense>
                <CommonPortfolioButtons class='btn-fitness' text='View All Our Products' link='/our-products' />
                {/* </div> */}
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default FitnessProduct;
