import React, { Component } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import PageHelmet from "./Helmet";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import CommonBreadcrumb from "./CommonBreadcrumb";


class CookiesPolicy extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Cookies Policy || Noorisys Technologies Pvt Ltd' pageUrl='/cookies-policy' metaTitle="The Ultimate Guide to Chatbots || Noorisys Technologies" metaImage="/assets/images/bg/blog-02.png"
                    metaDescription="Explore the latest trends and insights with our blog. Stay informed and up-to-date with our in-depth articles." />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Cookies Policy"
                    dataBlackOverlay={7}
                    bgImage="bg_image--26"
                />
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p className="justifypara" >At Noorisys Technologies Pvt. Ltd, we are committed to protecting the privacy of our users and visitors to our website and mobile applications. This Cookies Policy outlines how we use cookies and similar technologies on our website and mobile applications.</p>

                                        <h4 className="title">What are cookies? </h4>
                                        <p className="justifypara" >Cookies are small data files that are placed on your computer or mobile device when you visit a website or use a mobile application. Cookies are widely used to improve website functionality, to provide website owners with information about how visitors use their websites, and to deliver targeted advertising.</p>
                                        <h4 className="title">Types of cookies we use </h4>
                                        <p className="justifypara" >We use two types of cookies on our website and mobile applications: session cookies and persistent cookies.</p>
                                        <p className="justifypara" >Session cookies are temporary cookies that are stored on your computer or mobile device only for the duration of your visit to our website or use of our mobile application. They are deleted when you close your browser or mobile application.</p>
                                        <p className="justifypara" >Persistent cookies remain on your computer or mobile device after you have closed your browser or mobile application. They are used to remember your preferences or to provide you with targeted advertising.</p>
                                        <p className="justifypara" >We use both first-party and third-party cookies on our website and mobile applications. First-party cookies are set by us, while third-party cookies are set by third-party providers.</p>

                                        <h4 className="title">How we use cookies </h4>
                                        <p className="justifypara" >We use cookies and similar technologies on our website and mobile applications for the following purposes:</p>
                                        <ul className="list-style">
                                            <li className="justifypara" >To improve website and mobile application functionality and performance.</li>
                                            <li className="justifypara" >To provide website owners with information about how visitors use their websites.</li>
                                            <li className="justifypara" >To deliver targeted advertising.</li>
                                            <li className="justifypara" >To remember user preferences.</li>
                                        </ul>
                                        <br />
                                        <p className="justifypara" >We do not use cookies to collect personal information about our users.</p>
                                        <h4 className="title">Third-party cookies </h4>
                                        <p className="justifypara" >We use third-party cookies on our website and mobile applications to deliver targeted advertising. These cookies may be set by third-party providers, such as Google, Facebook, and Twitter. We have no control over these cookies or how the information they collect is used. You should check the third-party provider's website for information about how they use cookies and how you can opt out of their use.</p>
                                        <h4 className="title">Managing cookies </h4>
                                        <p className="justifypara" >You can manage cookies through your web browser or mobile device settings. Most web browsers and mobile devices allow you to control cookies through their settings. However, if you disable cookies, some website features may not function properly.</p>
                                        <h4 className="title">Updates to this policy </h4>
                                        <p className="justifypara" >We may update this Cookies Policy from time to time. We will post any updates on our website and mobile applications. Your continued use of our website and mobile applications after the posting of any updates will be deemed as acceptance of those updates.</p>

                                        <h4 className="title">Contact us</h4>
                                        <p className="justifypara" > If you have any questions about our Cookies Policy, please contact us at  <a className='btn-transparent rn-btn-dark' href="mailto:info@noorisys.com">info@noorisys.com.</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default CookiesPolicy;