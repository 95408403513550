import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
//  import CoursierDelivrable from "./CoursierDeliverables";
import PlatformUsed from "../component/Verbos/PlatformUsed";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
const technologies = [

    { h5: '', p: '', img: '' },
    { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
    { h5: 'Feather Js', p: 'Backend Development', img: 'feather.png' },
]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

const userButtonNames = ['Splash Screen', 'Login', 'Select Language', 'Dashboard', 'Profile', 'Parties', 'Papeos', 'Requested', 'Participation & Tickets', 'My Own Parties', 'Artist', 'Papeo Filter', 'Swipe Screen'];
const userImageAddress = [
    '/assets/images/portfolio/event-management/user-1.webp',
    '/assets/images/portfolio/event-management/user-2.webp',
    '/assets/images/portfolio/event-management/user-3.webp',
    '/assets/images/portfolio/event-management/user-4.webp',
    '/assets/images/portfolio/event-management/user-7.webp',
    '/assets/images/portfolio/event-management/user-5.webp',
    '/assets/images/portfolio/event-management/user-6.webp',
    '/assets/images/portfolio/event-management/user-8.webp',
    '/assets/images/portfolio/event-management/user-9.webp',
    '/assets/images/portfolio/event-management/user-10.webp',
    '/assets/images/portfolio/event-management/user-11.webp',
    '/assets/images/portfolio/event-management/user-12.webp',
    '/assets/images/portfolio/event-management/user-13.webp',
];

class EventManagmentPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Event Management System' pageUrl='/event-management-system' metaTitle="Event Management System" metaImage="/assets/images/portfolio/dp-portfolio-26.webp"
                    metaDescription="Event Management app is a social networking application available for android and IOS users. The app allows you to create events and invite others to join the party and share images and videos with all party members along with the chat option." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Event Management System"
                    customColor="theme-gradient"
                    dataBlackOverlay={7}
                    bgImage="bg_image--65"
                    p="Android Application"
                />

                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title theme-gradient">Event Management System</h3>
                                        <p className="justifypara">Event Management app is a social networking application available for android and IOS users. The app allows you to create party and invite others to join the party and share the medias (like images & videos) with all party members and chat with them.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck colorProfile={'#fe679a'} />Easy registration for customer</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Social media</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Connect with your friends and relatives</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Upload photos and share</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Create party and invite peoples near you</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Chat with friends and people</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Search and add new friends</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Attractive UI for customer</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Secure online payment</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Track your party</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Multi language</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Manage party and events</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Review and ratings for party and events</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Accounts and wallet</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Buy membership and party points</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Alerts and notifications</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Mark party as favorite</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Attractive and transparent prices of memebership and party points</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />Fully responsive design</li>
                                            <li><FiCheck colorProfile={'#fe679a'} />And many more….</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-event-kirana'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30"
                // style={{backgroundColor:'#fe679a'}}
                >
                </div>
                <div className="pb--30">
                    <div className="container">
                        <PlatformUsed color='theme-gradient' items={technologies} />
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-event-kirana'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--1 ptb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='Users can easily create and manage parties, invite others to join, share media like images and videos, and chat with party members, all within an attractive and multilingual user interface.'
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt='event user'
                                    color='papeo'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-event-kirana'} paddingTop={'pt--30'} paddingBottom={`pb--30`} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='theme-gradient' title={'Other Apps'} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-event-kirana' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default EventManagmentPortfolioDetails;
