import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonBlogBanner from "../component/common/CommonBlogBanner";

class MetaverseBlogDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Metaverse || Noorisys Technologies Pvt Ltd' pageUrl='/metaverse-blog' metaTitle="Introduction to The Metaverse || Noorisys Technologies" metaImage="/assets/images/blog/blog-09.webp"
                    metaDescription="Explore the latest trends and insights with our blog. Stay informed and up-to-date with our in-depth articles." />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBlogBanner titlehtml={'Introduction to  the<br /> Metaverse'} date={'April 15, 2022'} writer={'AFIFA ANSARI'} />
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <h3 className="rn-page-title theme-gradient">Introduction to the Metaverse</h3>
                                        <p className="justifypara">A metaverse is a network of 3D virtual worlds focused on social connection. A metaverse is a network of 3D virtual worlds focused on social connection. The term metaverse was first coined in the 1992 science fiction novel Snow Crash by Neal Stephenson. Various metaverses have been developed for popular use such as virtual world platforms like Second Life that allow people to create an avatar for themselves and have a second life in an online virtual world.</p>
                                        <p className="justifypara">The Metaverse, is a virtual world created by combining different technologies, including virtual real estate and Augmented Reality (AR).  One of the most unique aspects of the Metaverse is that it’s constantly expanding, growing, and finding its own place. The Metaverse will offer a variety of virtual experiences and environments where people can meet, work, play games, attend school, go shopping, and experience live concerts using VR headsets, augmented reality (AR) glasses, Smartphone apps, or other devices. </p>
                                        <p className="justifypara">The metaverse will be driven by augmented reality, with each user controlling a character or avatar. For example, you might take a mixed reality meeting with an Oculus VR headset in your virtual office, finish work and relax in a blockchain-based game, and then manage your crypto portfolio and finances all inside the metaverse.</p>

                                        <div className="blog-single-list-wrapper d-flex flex-wrap">
                                            <div className="thumbnail">
                                                <img className="w-100" src="/assets/images/blog/blog-single-09.webp" alt="meta verse blog" loading="lazy" />
                                            </div>
                                            <div className="content">
                                                <p className="justifypara">In order for the metaverse to function like the real world, the inventory to sell, objects people use online, and even online real estate all need to be ownable, sellable, and transferable within the metaverse, NFTs came into existence.</p>
                                                <p className="justifypara">NFTs are a key building block in a new, blockchain-powered digital economy. Numerous projects have experimented with NFTs in a variety of use cases, including gaming, digital identity, licensing, certificates, and fine art. What’s more, NFTs even allow for fractional ownership of high-value items. </p>
                                                <p className="justifypara">NFTs can be used by decentralized applications (DApps) to issue unique digital items and crypto-collectibles. These tokens can either be a collectible item, an investment product, or something else.</p>
                                                <p className="justifypara">Creating your own NFTs on either BSC or Ethereum is a simple process offered by numerous platforms and NFT exchanges. All you need to get started is some crypto to pay your minting fee and something to turn into an NFT. You’ll also need to choose between minting your NFT on Ethereum or Binance Smart Chain.</p>
                                            </div>
                                        </div>

                                        <p className="justifypara">Ethereum has traditionally been the home of NFTs and their development. It has a large user base and well established NFT community, but transaction fees are very costly. This makes small purchases, sales, and transactions costly for users. BSC is a newer blockchain but has already seen a lot of growth in its NFT markets. Transactions are also much cheaper.</p>
                                        <p className="justifypara">While a single, united metaverse is likely a long way off, we already can see developments that may lead to its creation. It looks to be yet another sci-fi use case for blockchain technology and cryptocurrencies. If we will ever really reach the point of a metaverse is unsure. But in the meantime, we can already experience metaverse-like projects and continue to integrate blockchain more into our daily lives.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default MetaverseBlogDetails;