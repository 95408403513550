import React, { Component } from "react";
// import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import Slider from "react-slick";
import './primarycaredeliverables.css'
// import { DeliverPortfolio } from "../page-demo/script";
// import './react-slick.css'





class PrimaryCareDelivrables extends Component {
    constructor(props) {
        super(props);
        this.sliderRef = React.createRef();
        // this.state = {
        //     hasPrevious: false,
        //     hasNext: false
        //   };
    }
    handleNextClick = () => {
        if (this.sliderRef.current && !this.sliderRef.current.slickNext()) {
            // No next items
            return;
        }
        this.sliderRef.current.slickNext();
    };

    handlePreviousClick = () => {
        if (this.sliderRef.current && !this.sliderRef.current.slickPrev()) {
            // No previous items
            return;
        }
        this.sliderRef.current.slickPrev();
    };
    //   componentDidMount() {
    //     const sliderInstance = this.sliderRef.current;
    //     sliderInstance.props.onSwipe = () => {
    //       this.setState({
    //         hasPrevious: sliderInstance.slickPrev(),
    //         hasNext: sliderInstance.slickNext()
    //       });
    //     };
    //   }


    render() {
        const DeliverPortfolio = {
            // loop: false,
            // margin: 15,
            // nav: false,
            // owlDots: false,
            // autoWidth: true,
            // items: 1, 
            // dots: false,
            infinite: true,
            autoplay: true,
            swipeable: true,
            // slidesToShow: this.props.value[0],
            slidesToShow: 6,
            slidesToScroll: 1,
            centerMode: false,
            // arrows: true,
            // nextArrow: <SampleNextArrow />,
            // prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 350,
                    settings: {
                        variableWidth: false,
                        centerMode: false,
                        centerPadding: '50px',
                        slidesToShow: 1,
                        dots: false
                    },
                },
                {
                    breakpoint: 426,
                    settings: {
                        variableWidth: false,
                        centerMode: false,
                        centerPadding: '50px',
                        slidesToShow: 1,
                        dots: false
                    },
                },
                {
                    breakpoint: 650,
                    settings: {
                        variableWidth: false,
                        centerMode: false,
                        centerPadding: '50px',
                        slidesToShow: 2,
                        dots: false
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        variableWidth: false,
                        centerMode: false,
                        centerPadding: '50px',
                        slidesToShow: 4,
                        dots: false
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        variableWidth: false,
                        centerMode: false,
                        centerPadding: '50px',
                        slidesToShow: 4,
                        dots: false
                    },
                },
                {
                    breakpoint: 1024,
                    settings: {
                        variableWidth: false,
                        centerMode: false,
                        centerPadding: '50px',
                        slidesToShow: 5,
                        // slidesToShow: this.props.value[1],
                        dots: false
                    },
                },
                {
                    breakpoint: 1250,
                    settings: {
                        variableWidth: false,
                        centerMode: false,
                        centerPadding: '50px',
                        slidesToShow: 6,
                        // slidesToShow: this.props.value[0],
                        dots: false
                    },
                },
                {
                    breakpoint: 1440,
                    settings: {
                        variableWidth: false,
                        centerMode: false,
                        centerPadding: '100px',
                        slidesToShow: 6,
                        // slidesToShow: this.props.value[0],
                        dots: false
                    },
                },
            ],
        }
        return (
            <>
                <div className="primary_care">
                    <div className="body_wrapper">

                        <section className="get_started_area primary_app_deliverables" style={{ width: '100%', overflow: 'hidden' }}>
                            <div className="background">
                                <div className="shap one"></div>
                                <div className="shap two"></div>
                                <div className="shap one three"></div>
                                <div className="shap two four"></div>
                            </div>
                            <div className="app_deliverables_content">
                                <div className="container">
                                    <div className="row">
                                        <div className="hosting_title erp_title text-center mb-0 text-center col-12 pb-5">
                                            <h2 style={{ color: '#fff' }} className="title" data-wow-delay="0.1s">
                                                {this.props.title}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="deliverables_item">
                                <div className="row">
                                    {/* <div className="col-12 col-md-12 col-lg-5"></div> --> */}
                                    <div className="col-12 col-md-12 col-lg-12 " style={{ position: 'relative' }}>
                                        {/* <!-- <div className="slider_btn NextBtn wow zoomIn" data-wow-delay="0.1s">
                                            <a className="customPreviousBtn">
                                                <i className="zmdi zmdi-long-arrow-left"></i>
                                            </a>
                                        </div>  */}
                                        <Slider ref={this.sliderRef} {...DeliverPortfolio} className="deliverables_carousel owl-carousel owl-theme">
                                            {this.props.items.map((value, index) => (
                                                <div key={index} className="item  wow  fadeInUp" data-wow-delay="0.1s"  >
                                                    <div className="item_inner"  >
                                                        <div className={this.props.class}>
                                                            {/* <img src={value.item_bg} /> */}
                                                            <img src={`/assets/images/deliverables/${value.item_bg}`} alt='primary' />
                                                        </div>
                                                        <div className="app_icon">
                                                            {/* <img src={value.item_icon} className="img-fluid" /> */}
                                                            <img src={`/assets/images/deliverables/${value.item_icon}`} className="img-fluid" alt='secondary' />
                                                        </div>
                                                        <h3 style={{ marginLeft: this.props.margin ? '-17px' : '0px', fontSize: '14px' }}>{value.h3}</h3>
                                                        {/* <h3>User App</h3> */}
                                                        <h4>{value.h4}</h4>
                                                        {/* <h4>Android</h4> */}
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                        {/* <div className="item  wow  fadeInUp" data-wow-delay="0.1s">
                                            <div className="item_inner">
                                                <div className="item_bg">
                                                    <img src="/assets/images/deliverables/ios.png" />
                                                </div>
                                                <div className="app_icon">
                                                    <img src="/assets/images/deliverables/user_app.png" className="img-fluid" />
                                                </div>
                                                <h3>User App</h3>
                                                <h4>iOS</h4>
                                            </div>
                                        </div>


                                        <div className="item  wow  fadeInUp" data-wow-delay="0.1s">
                                            <div className="item_inner">
                                                <div className="item_bg">
                                                    <img src="/assets/images/deliverables/web.png" />
                                                </div>
                                                <div className="app_icon">
                                                    <img src="/assets/images/deliverables/doctor_app.png" className="img-fluid" />
                                                </div>
                                                <h3>User</h3>
                                                <h4>Web</h4>
                                            </div>
                                        </div>
                                        <div className="item  wow  fadeInUp" data-wow-delay="0.1s">
                                            <div className="item_inner">
                                                <div className="item_bg">
                                                    <img src="/assets/images/deliverables/android.png" />
                                                </div>
                                                <div className="app_icon">
                                                    <img src="/assets/images/deliverables/doctor_app.png" className="img-fluid" />
                                                </div>
                                                <h3>Doctor App</h3>
                                                <h4>Android</h4>
                                            </div>
                                        </div>

                                        <div className="item  wow  fadeInUp" data-wow-delay="0.1s">
                                            <div className="item_inner">
                                                <div className="item_bg">
                                                    <img src="/assets/images/deliverables/ios.png" />
                                                </div>
                                                <div className="app_icon">
                                                    <img src="/assets/images/deliverables/admin_app.png" className="img-fluid" />
                                                </div>
                                                <h3>Doctor App</h3>
                                                <h4>iOS</h4>
                                            </div>
                                        </div>
                                        <div className="item  wow  fadeInUp" data-wow-delay="0.1s">
                                            <div className="item_inner">
                                                <div className="item_bg">
                                                    <img src="/assets/images/deliverables/web.png" />
                                                </div>
                                                <div className="app_icon">
                                                    <img src="/assets/images/deliverables/admin_app.png" className="img-fluid" />
                                                </div>
                                                <h3>Doctor</h3>
                                                <h4>Web</h4>
                                            </div>
                                        </div>
                                        // 7
                                        <div className="item  wow  fadeInUp" data-wow-delay="0.1s">
                                            <div className="item_inner">
                                                <div className="item_bg">
                                                    <img src="/assets/images/deliverables/android.png" />
                                                </div>
                                                <div className="app_icon">
                                                    <img src="/assets/images/deliverables/delivery_app.png" className="img-fluid" />
                                                </div>
                                                <h3>Hospital App</h3>
                                                <h4>Android </h4>
                                            </div>
                                        </div>
                                        <div className="item  wow  fadeInUp" data-wow-delay="0.1s">
                                            <div className="item_inner">
                                                <div className="item_bg">
                                                    <img src="/assets/images/deliverables/ios.png" />
                                                </div>
                                                <div className="app_icon">
                                                    <img src="/assets/images/deliverables/delivery_app.png" className="img-fluid" />
                                                </div>
                                                <h3>Hospital App</h3>
                                                <h4>iOS </h4>
                                            </div>
                                        </div>

                                        <div className="item  wow  fadeInUp" data-wow-delay="0.1s">
                                            <div className="item_inner">
                                                <div className="item_bg">
                                                    <img src="/assets/images/deliverables/web.png" />
                                                </div>
                                                <div className="app_icon">
                                                    <img src="/assets/images/deliverables/delivery_app.png" className="img-fluid" />
                                                </div>
                                                <h3>Hospital</h3>
                                                <h4>Web</h4>
                                            </div>
                                        </div>
                                        <div className="item  wow  fadeInUp" data-wow-delay="0.1s">
                                            <div className="item_inner">
                                                <div className="item_bg">
                                                    <img src="/assets/images/deliverables/android.png" />
                                                </div>
                                                <div className="app_icon">
                                                    <img src="/assets/images/deliverables/delivery_app.png" className="img-fluid" />
                                                </div>
                                                <h3>Pharmacy App</h3>
                                                <h4>Android</h4>
                                            </div>
                                        </div>

                                        <div className="item  wow  fadeInUp" data-wow-delay="0.1s">
                                            <div className="item_inner">
                                                <div className="item_bg">
                                                    <img src="/assets/images/deliverables/ios.png" />
                                                </div>
                                                <div className="app_icon">
                                                    <img src="/assets/images/deliverables/delivery_app.png" className="img-fluid" />
                                                </div>
                                                <h3>Pharmacy App</h3>
                                                <h4>iOS</h4>
                                            </div>
                                        </div>
                                        <div className="item  wow  fadeInUp" data-wow-delay="0.1s">
                                            <div className="item_inner">
                                                <div className="item_bg">
                                                    <img src="/assets/images/deliverables/web.png" />
                                                </div>
                                                <div className="app_icon">
                                                    <img src="/assets/images/deliverables/delivery_app.png" className="img-fluid" />
                                                </div>
                                                <h3>Pharmacy</h3>
                                                <h4>Web</h4>
                                            </div>
                                        </div>

                                        <div className="item  wow  fadeInUp" data-wow-delay="0.1s">
                                            <div className="item_inner">
                                                <div className="item_bg">
                                                    <img src="/assets/images/deliverables/android.png" />
                                                </div>
                                                <div className="app_icon">
                                                    <img src="/assets/images/deliverables/delivery_app.png" className="img-fluid" />
                                                </div>
                                                <h3>Delivery App</h3>
                                                <h4>Android</h4>
                                            </div>
                                        </div>
                                        <div className="item  wow  fadeInUp" data-wow-delay="0.1s">
                                            <div className="item_inner">
                                                <div className="item_bg">
                                                    <img src="/assets/images/deliverables/ios.png" />
                                                </div>
                                                <div className="app_icon">
                                                    <img src="/assets/images/deliverables/delivery_app.png" className="img-fluid" />
                                                </div>
                                                <h3>Delivery App</h3>
                                                <h4>iOS</h4>
                                            </div>
                                        </div>

                                        <div className="item  wow  fadeInUp" data-wow-delay="0.1s">
                                            <div className="item_inner">
                                                <div className="item_bg">
                                                    <img src="/assets/images/deliverables/web.png" />
                                                </div>
                                                <div className="app_icon">
                                                    <img src="/assets/images/deliverables/delivery_app.png" className="img-fluid" />
                                                </div>
                                                <h3>Admin Panel</h3>
                                                <h4>Web </h4>
                                            </div>
                                        </div> */}

                                        {/* <div className="slider_btn ml-auto mr-0 PreviousBtn wow zoomIn" data-wow-delay="0.15s">
                                            <a className="customNextBtn"><i className="zmdi zmdi-long-arrow-right"></i></a>
                                        </div>  */}

                                    </div>

                                </div>
                            </div>

                        </section>

                    </div>

                </div>
                {/* <div className="container">
                        <div className="row justify-content-center d-flex ">
                        {this.state.hasPrevious &&( 
                              <button className="btn btn-style-2" onClick={this.handlePreviousClick} ><FiArrowLeft  /> </button>
                               )} 
                        {this.state.hasNext && (   
                             <button className="btn btn-style-2" onClick={this.handleNextClick}><FiArrowRight /></button>
                               )} 
                        </div>
                    </div> */}
            </>
        )
    }
}
export default PrimaryCareDelivrables;