import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from 'react-modal-video';
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import CoursierDelivrable from "./CoursierDeliverables";
import PlatformUsed from "../component/Verbos/PlatformUsed";
// import HealthcareMasonry from "./HealthcareMasonry";

import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
// import HealthcareWebDemo from "./HealthcareWebDemo";
const deliverables = [
    { item_bg: 'android.png', item_icon: 'users.png', h3: 'Patient App', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'users.png', h3: 'Patient App', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'users.png', h3: 'Patient', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
    { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'primaryadvance.png', h3: 'Nurse App ', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'primaryadvance.png', h3: 'Nurse App ', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'primaryadvance.png', h3: 'Nurse ', h4: 'Web' },
    { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
]
const technologies = [

    { h5: 'Android', p: 'Android & iOS development', img: 'androidios.png' },
    { h5: 'PHP', p: 'Backend Development', img: 'php.png' },
    { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
    { h5: 'PHP', p: 'Admin Panel Development', img: 'php.png' },

]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/doctor-appointment-and-online-pharmacy', img: 'mediconnect.webp', h2: 'MediConnect', p: 'Doctor Appointment and pharmacy' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/primary-care-management-system', img: 'federate.webp', h2: 'Federate', p: 'Primry Care Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

// const SocialShare = [
//     { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
//     { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
//     { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
//     { Social: <FaTwitter />, link: 'https://twitter.com/' },
// ]
const userAppButtonNames = ['Docfinder App', 'Login', 'Sidebar', 'Dashboard', 'Insurance', 'My Appointments', 'Profile', 'My Relatives', 'My Documents', 'Help Relatives'];
const userAppImageAddress = [
    '/assets/images/portfolio/healthcare-insurance/izikare-app-1.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-2.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-3.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-4.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-5.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-6.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-7.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-8.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-9.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-10.webp',
];
const adminButtonNames = ['Homepage', 'Registration', 'Login', 'Dashboard', 'Insurance Activities', 'Allodocta Activities', 'Platform Activities', 'Insurance Settings', 'Allodocta Settings', 'EMR Settings', 'Platform Settings', 'Profile'];
const adminImageAddress = [
    '/assets/images/portfolio/healthcare-insurance/izikare-web-1.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-2.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-3.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-4.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-5.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-6.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-7.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-8.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-9.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-10.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-11.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-12.webp',
];
class HealthcarePortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Healthcare Insurance || Noorisys Technologies Pvt Ltd' pageUrl='/healthcare-insurance-marketplace' metaTitle="Healthcare Insurance Marketplace" metaImage="/assets/images/portfolio/dp-portfolio-06.jpg"
                    metaDescription="This Healthcare insurance marketplace allows patients to use their health insurance on the platform. Services include online appointments with Doctors, Hospitals, Technicians, etc."
                    metaKeywords="marketplace online,online marketplace uk,best online marketplace uk ,insurance healthcare marketplace" />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb title='Healthcare Insurance Marketplace' dataBlackOverlay={7} customColor='custom-healthcare-color' bgImage='bg_image--44' p='Web / Android / iOS Application ' />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-healthcare-color">Healthcare Insurance Marketplace</h3>
                                        <p className="justifypara">Healthcare insurance marketplace is a digital platform that allows the patient to take out connected health insurance allowing their loved ones to be treated without paying costs. Healthcare insurance marketplace is a Medical Health platform that allows taking Online appointment to any Providers (Doctor, Hospital, Technician, etc.). Healthcare insurance marketplace platforms directly connect to Patients with Doctors. Doctors and Hospitals can register on the platform and provide the Services. Patients can easily connect with platforms from any platform (Android / iOS / Web ), make appointments, and do Online Payment.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Easy registration for providers and patients</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Email verification and forgot password</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Attractive dashboard for each providers and patient</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Online teleconsultation</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Audio / Video appointment</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Physical appointment</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Consult at patient home</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />EMR management</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Insurance subscriptions</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Insurance package marketplace</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Manage relatives</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Claims management</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Secure online payment</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Offline payment</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Multi-currency</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Multi language</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Accounts and wallet</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Separate chat for each audio / video appointment</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Alerts and notifications</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />Fully responsive design</li>
                                            <li className="justifypara"><FiCheck color={'#1296cc'} />And many more….</li>


                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30"
                    style={{ backgroundColor: '#1296cc' }}
                >
                    <div className="container">
                        <CoursierDelivrable title={'16 Deliverables'} items={deliverables} class={'coursier'} value={[7, 5, 4]} />
                    </div>
                </div>
                <div className="ptb--90">
                    <div className="container">
                        <PlatformUsed color='custom-healthcare-color' items={technologies} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--1 ptb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='Users can book online and physical appointments with the doctor. Also users can search and subscribe for available insurance available on the platform.'
                                    buttonNames={userAppButtonNames}
                                    image={userAppImageAddress}
                                    alt='docfinder user'
                                    color='docfinder'
                                />
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='Admin can manage users, providers (doctor, pharmacy, lab etc), appointments, insurance, EMR and other platform settings'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='docfinder admin'
                                    color='docfinder'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-coursier-color' items={similarapps} title={'Similar Apps'} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-healthcare' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default HealthcarePortfolioDetails;
