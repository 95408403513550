import React, { useEffect, useState } from "react";
import PortfolioJson from "./portfolioJson";
import { FaGlobe } from "react-icons/fa";
import { Redirect } from "react-router-dom";

const validPortfolioNames = [
    "scholarship-distribution-system",
    "online-groceries-ordering-system",
    "courier-and-transport-management",
    "multilingual-pregnancy-app",
    "doctor-appointment-and-online-pharmacy",
    "healthcare-insurance-marketplace",
    "ticket-booking-platform",
    "visa-processing-system",
    "digital-solution-for-civil-documents",
    "hotel-booking-platform"
];

const JSON = [
    {
        pageTitle: "scholarship-distribution-system",
        metaTags: {
            pageTitle: "Scholarship Distribution || Noorisys Technologies Pvt Ltd",
            pageUrl: "/scholarship-distribution-system",
            metaTitle: "Scholarship Distribution System",
            metaImage: "/assets/images/portfolio/dp-portfolio-02.jpg",
            metaDescription:
                "Easily manage your money with our Scholarship Distribution System. Withdraw money and position it in your wallet, pay for registration fees, make merchant payments online or in-store, and buy phone credit from all operators.",
        },
        breadCrumbs: {
            title: "Scholarship Distribution with KYC Verification",
            customColor: "custom-scholarship-color",
            dataBlackOverlay: 7,
            bgImage: "bg_image--40",
            p: "Web / Android / iOS Application",
            pb: "pb--120",
        },
        technologies: [
            { h5: "Flutter", p: "Android & iOS development", img: "flutter.png" },
            { h5: ".Net", p: "Backend Development", img: "dotnet.png" },
            { h5: "Angular", p: "Admin Panel Development", img: "angular.png" },
        ],
        similarApps: {
            items: [
                {
                    backgroundcolor:
                        "-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)",
                    link: "/covid-antigen-test-verification",
                    img: "covid.webp",
                    h2: "Fasterchecks",
                    p: "Covid Antigen Test Verification",
                },
                {
                    backgroundcolor:
                        "-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)",
                    link: "/tourism-company-management-system",
                    img: "umrah.webp",
                    h2: "Umrah Plus",
                    p: "Tourism Compony",
                },
                {
                    backgroundcolor:
                        "-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)",
                    link: "/online-groceries-ordering-system",
                    img: "annachi.webp",
                    h2: "FreshCart",
                    p: "Online Shop",
                },
                {
                    backgroundcolor:
                        "-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)",
                    link: "/courier-and-transport-management",
                    img: "gonagoo.webp",
                    h2: "ServicePro",
                    p: "Courier and Trasport Management",
                },
                {
                    backgroundcolor:
                        "-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)",
                    link: "/pharmacist-marketplace",
                    img: "electronic.webp",
                    h2: "HealthConnect",
                    p: "Pharmacist Marketplace",
                },
            ],
            title: "Other Apps",
            slidesToShow: [3, 3],
        },
        screens: [
            {
                type: "app",
                appButtonNames: [
                    "Pesa Pay App",
                    "Registration",
                    "Login",
                    "Dashboard",
                    "Profile",
                    "Side Menu",
                    "Secret Code",
                    "Notification",
                    "Transaction History",
                ],
                appImages: [
                    "/assets/images/portfolio/scholarship/sesapay-1.webp",
                    "/assets/images/portfolio/scholarship/sesapay-2.webp",
                    "/assets/images/portfolio/scholarship/sesapay-3.webp",
                    "/assets/images/portfolio/scholarship/sesapay-4.webp",
                    "/assets/images/portfolio/scholarship/sesapay-5.webp",
                    "/assets/images/portfolio/scholarship/sesapay-6.webp",
                    "/assets/images/portfolio/scholarship/sesapay-7.webp",
                    "/assets/images/portfolio/scholarship/sesapay-8.webp",
                    "/assets/images/portfolio/scholarship/sesapay-9.webp",
                ],
                h3: "User App",
                p: "This app allows users to hold and manage an electronic account, make real-time transfers, make payments, receive scholarships from the government and participate in a loyalty program.",
                alt: "scholarship distribution user",
                color: "sesa-pay",
            },
            {
                type: "web",
                adminButtonNames: [
                    "Login",
                    "Dashboard",
                    "Manage Platform Services",
                    "Wallet Transaction",
                    "Manage Privilege",
                ],
                adminImageAddress: [
                    "/assets/images/portfolio/scholarship/sesapay-admin-1.webp",
                    "/assets/images/portfolio/scholarship/sesapay-admin-2.webp",
                    "/assets/images/portfolio/scholarship/sesapay-admin-3.webp",
                    "/assets/images/portfolio/scholarship/sesapay-admin-4.webp",
                    "/assets/images/portfolio/scholarship/sesapay-admin-5.webp",
                ],
                h3: "Admin Panel",
                p: "The admin is responsible for managing and maintaining the website. They have access to the backend of the platform and can manage user accounts, appointments, pharmacy listings, and other aspects of the website.",
                alt: "scholarship admin",
                color: "sesa-pay",
            },
        ],
        title: "Scholarship Distribution System",
        p: [
            "Scholarship Distribution System is a free mobile financial services platform that allows students and professionals to hold and manage an electronic account, make real-time fund transfers, process any type of payment, access a large network of partners and participate in our loyalty program.",
            "This electronic money issuance activity promotes banking and financial inclusion, through access to financial services for all socio-professional categories.",
            "Scholarship Distribution System offers quality services allowing its customers to carry out secure, simple and fast transactions.",
        ],
        features: {
            title: "Features",
            li: [
                "Easy registration customer",
                "Attractive dashboard",
                "Secure online payment",
                "Transfer money",
                "Registration fee",
                "Withdrawal money",
                "Receive money",
                "Payment at restaurants",
                "Merchant payment",
                "Accounts and wallet",
                "Alerts and notifications",
                "Fully responsive design",
                "And many more….",
            ],
            colorCode: "ffd939",
        },
        titleClass: "custom-scholarship-color",
        portfolioButton: {
            class: "btn-sesapay",
            text: "View All Our Projects",
            padding: "pb--40",
        },
        pageName: 'btn-sesapay'
    },
    {
        pageTitle: "online-groceries-ordering-system",
        metaTags: {
            pageTitle: "Online Grocery Ordering|| Noorisys Technologies Pvt Ltd",
            pageUrl: "/online-groceries-ordering-system",
            metaTitle: "Online Grocery Ordering",
            metaImage: "/assets/images/portfolio/dp-portfolio-03.jpg",
            metaDescription:
                "Let our experts build your eCommerce business by taking advantage of the most modern technologies available for building an online grocery store.",
        },
        breadCrumbs: {
            title: "Online Grocery Ordering",
            dataBlackOverlay: 7,
            customColor: "custom-annachi-color",
            bgImage: "bg_image--42",
            p: "Web / Android/ iOS Application",
        },
        socialShare: [
            {
                Social: <FaGlobe />,
                link: "https://annachi.fr/",
            },
        ],
        deliverables: [
            {
                item_bg: "android.png",
                item_icon: "users-annachi.png",
                h3: "User App",
                h4: "Android",
            },
            {
                item_bg: "ios.png",
                item_icon: "users-annachi.png",
                h3: "User App",
                h4: "iOS",
            },
            {
                item_bg: "web.png",
                item_icon: "users-annachi.png",
                h3: "User",
                h4: "Web",
            },
            {
                item_bg: "web.png",
                item_icon: "admin-annachi.png",
                h3: "Admin ",
                h4: "Web",
            },
        ],
        deliverablesProps: {
            title: "4 Deliverables",
            class: "annachi",
            value: [4, 4, 4],
            colorCode: "83b735"
        },
        technologies: [
            { h5: "Angular", p: "Admin Panel Development", img: "angular.png" },
            { h5: "Swift", p: "Android & iOS development", img: "swift.png" },
            { h5: "Java", p: "Backend Development", img: "java.png" },
            { h5: "Codeigniter", p: "Backend Development", img: "ci4.png" },
            { h5: "PHP", p: "Backend Development", img: "php.png" },
        ],
        similarApps: {
            items: [
                {
                    backgroundcolor:
                        "-webkit-radial-gradient(50% 50%, circle closest-side, #8ab2ff 0%, #4636fc 100%)",
                    link: "/online-grocery",
                    img: "grocery.webp",
                    h2: "Malegaon Basket",
                    p: "Online Grocery",
                },
                {
                    backgroundcolor:
                        "-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)",
                    link: "/online-shop",
                    img: "shop.webp",
                    h2: "EasyShop",
                    p: "Online Shop",
                },
                {
                    backgroundcolor:
                        "-webkit-radial-gradient(50% 50%, circle closest-side, #c37857 0%, #734444 100%)",
                    link: "/online-food-delivery",
                    img: "food.webp",
                    h2: "Food",
                    p: "Online Food Delivery ",
                },
                {
                    backgroundcolor:
                        "-webkit-radial-gradient(50% 50%, circle closest-side, #8ab2ff 0%, #4636fc 100%)",
                    link: "/online-grocery",
                    img: "grocery.webp",
                    h2: "Malegaon Basket",
                    p: "Online Grocery",
                },
                {
                    backgroundcolor:
                        "-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)",
                    link: "/online-shop",
                    img: "shop.webp",
                    h2: "EasyShop",
                    p: "Online Shop",
                },
                {
                    backgroundcolor:
                        "-webkit-radial-gradient(50% 50%, circle closest-side, #c37857 0%, #734444 100%)",
                    link: "/online-food-delivery",
                    img: "food.webp",
                    h2: "Food",
                    p: "Online Food Delivery ",
                },
            ],
            title: "Similar Apps",
            slidesToShow: [3, 2],
        },
        screens: [{
            type: "app",
            appButtonNames: [
                "Splash Screen",
                "Dashboard",
                "Category Menu",
                "Category Submenu",
                "Best Seller",
                "Shopping Cart",
                "Product Details",
                "Day Offers",
                "My Account",
            ],
            appImages: [
                '/assets/images/portfolio/annachi/annachi-1.webp',
                '/assets/images/portfolio/annachi/annachi-2.webp',
                '/assets/images/portfolio/annachi/annachi-3.webp',
                '/assets/images/portfolio/annachi/annachi-4.webp',
                '/assets/images/portfolio/annachi/annachi-5.webp',
                '/assets/images/portfolio/annachi/annachi-6.webp',
                '/assets/images/portfolio/annachi/annachi-7.webp',
                '/assets/images/portfolio/annachi/annachi-8.webp',
                '/assets/images/portfolio/annachi/annachi-9.webp',
            ],
            h3: "User App",
            p: "Users have the ability to explore an extensive range of products based on categories and subcategories. They can conveniently place orders for home delivery.",
            alt: "online grocery user",
            color: "online-grocery"
        }],
        title: "Grocery Buying & Selling",
        p: [
            "FreshCart is a grocery buying and selling platform that sells Indian groceries for the French market. It helps customers to choose and browse the products at their convenient time and place the order with peace of mind. It sells everything from spices, sauces, seasonal fruits and vegetables, snacks, cereals and grains, beverages to many more. The aim is to provide the best quality hygienic foods to the customers in France who are longing to devour Indian flavors.",
        ],
        features: {
            title: "Features",
            li: [
                "Good quality product photos with image zoom and thumbnails",
                "Prime customer features",
                "Customer wallet",
                "Customer reviews",
                "500+ products",
                "Easy to browse",
                "Shipping",
                "Recipes",
                "Alerts and notifications"
            ],
            colorCode: "83b735"
        },
        titleClass: 'custom-annachi-color',
        portfolioButton: {
            class: "btn-groceries",
            text: "View All Our Projects",
            padding: "pb--20",
        },
        pageName: 'btn-groceries'
    },
    {
        pageTitle: "courier-and-transport-management",
        metaTags: {
            pageTitle: "Courier managment || Noorisys Technologies Pvt Ltd",
            pageUrl: "/courier-and-transport-management",
            metaTitle: "Courier and Transport Management System",
            metaImage: "/assets/images/portfolio/dp-portfolio-04.jpg",
            metaDescription:
                "Courier and Transport Management System is a platform to provide service to your door steps. This platform directly connects the customer with the service providers, and simplifies, optimizes and brings operational excellence to our customers.",
        },
        breadCrumbs: {
            title: "Courier And Transport Management",
            customColor: "custom-courier-color",
            dataBlackOverlay: 7,
            bgImage: "bg_image--43",
            p: "Web / Android / iOS Application ",
            pb: "pb--120",
        },
        technologies: [
            { h5: 'Android', p: 'Android ', img: 'android.png' },
            { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
            { h5: 'PHP', p: 'Admin Panel Development', img: 'php.png' },
        ],
        similarApps: {
            items: [
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
            ],
            title: "Apps",
            slidesToShow: [3, 3],
        },
        screens: [
            {
                type: "app",
                appButtonNames: [
                    'Splash Screen', 'Login or Signup', 'Signup Option', 'Register As', 'Registration', 'Login', 'Dashboard', 'Sidebar', 'Create Booking', 'Address Book list', 'Location'
                ],
                appImages: [
                    '/assets/images/portfolio/courier/gonago-app-1.webp',
                    '/assets/images/portfolio/courier/gonago-app-2.webp',
                    '/assets/images/portfolio/courier/gonago-app-3.webp',
                    '/assets/images/portfolio/courier/gonago-app-4.webp',
                    '/assets/images/portfolio/courier/gonago-app-5.webp',
                    '/assets/images/portfolio/courier/gonago-app-6.webp',
                    '/assets/images/portfolio/courier/gonago-app-7.webp',
                    '/assets/images/portfolio/courier/gonago-app-8.webp',
                    '/assets/images/portfolio/courier/gonago-app-9.webp',
                    '/assets/images/portfolio/courier/gonago-app-10.webp',
                    '/assets/images/portfolio/courier/gonago-app-11.webp',
                ],
                h3: "User App",
                p: "Users can register and log in on the platform to manage their profile, address book, and create shipping bookings.",
                alt: "service pro user",
                color: "service-pro",
            },
            {
                type: "web",
                adminButtonNames: ['Homepage', 'Signup', 'Login', 'Dashboard', 'Currency List', 'Group Permissions', 'User List', 'Order List', 'Transport Vehicles', 'Point to Point Rates'],
                adminImageAddress: [
                    '/assets/images/portfolio/courier/gonago-web-1.webp',
                    '/assets/images/portfolio/courier/gonago-web-2.webp',
                    '/assets/images/portfolio/courier/gonago-web-3.webp',
                    '/assets/images/portfolio/courier/gonago-web-4.webp',
                    '/assets/images/portfolio/courier/gonago-web-5.webp',
                    '/assets/images/portfolio/courier/gonago-web-6.webp',
                    '/assets/images/portfolio/courier/gonago-web-7.webp',
                    '/assets/images/portfolio/courier/gonago-web-8.webp',
                    '/assets/images/portfolio/courier/gonago-web-9.webp',
                    '/assets/images/portfolio/courier/gonago-web-10.webp',
                    '/assets/images/portfolio/courier/gonago-web-11.webp',
                ],
                h3: "Admin Panel",
                p: "Admin can manage users, orders, relay points, withdrawal requests, commission, promo code and other platform settings.",
                alt: "service pro admin",
                color: "service-pro",
            },
        ],
        title: "Courier and Transport Management Application",
        p: [
            "Courier and Transport Management System is a platform to provide service to your door steps. This platform directly connects the customer with the service providers, and simplifies, optimizes and brings operational excellence to our customers. Courier and Transport companies can register on the platform and provide their services. Customer can easily connect with platform from any platform (Android/iOS/Web), make a booking and do the online payment."
        ],
        features: {
            title: "Features",
            li: [
                "Easy registration for buyer and seller",
                "Attractive dashboard for each user",
                "Customer relationship management",
                "Secure online payment",
                "Offline payment",
                "Prepaid service",
                "Digitalization of your processes and flows",
                "Online booking",
                "Easy payment options (Pay on pick and deliver)",
                "Track your booking",
                "Easy cancellation and refunds",
                "Multi-currency",
                "Multi language",
                "Manage address book",
                "Review and ratings",
                "Accounts and wallet",
                "Separate chat room for each booking",
                "Alerts and notifications",
                "Mark favorites",
                "Attractive and transparent prices managed by admin and seller",
                "Fully responsive design",
                "And many more….",
            ],
            colorCode: "012296",
        },
        titleClass: "custom-courier-color",
        portfolioButton: {
            class: "btn-courier",
            text: "View All Our Projects",
            padding: "pb--20",
        },
        pageName: 'btn-courier'
    },
    {
        pageTitle: "multilingual-pregnancy-app",
        metaTags: {
            pageTitle: "Multilingual Pregnency Portfolio || Noorisys Technologies Pvt Ltd",
            pageUrl: "/multilingual-pregnancy-app",
            metaTitle: "Pregnancy Management App",
            metaImage: "/assets/images/portfolio/dp-portfolio-09.jpg",
            metaDescription: " Expecting mothers can now access vital information and support with our Pregnancy Management mobile application for pregnancy. The app offers a wide range of topics and articles that focus on common health conditions and problems experienced during pregnancy."
        },
        breadCrumbs: {
            title: "Pregnancy Management App",
            customColor: "custom-multilingual-color",
            dataBlackOverlay: 7,
            bgImage: "bg_image--49",
            p: "Android / iOS Application",
            pb: "pb--120"
        },
        technologies: [
            { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
            { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
            { h5: 'PHP', p: 'Admin Panel Development', img: 'php.png' },
        ],
        similarApps: {
            items: [
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },

            ],
            title: "Apps",
            slidesToShow: [3, 3]
        },
        screens: [
            {
                type: "app",
                appButtonNames: [
                    'Pregnancy Management App',
                    'Multilingual',
                    'Select Hospital',
                    'About You',
                    'Enter Access Code',
                    'Enter Your Age',
                    'Dashboard',
                    'Emergency',
                    'Favourite Articles',
                    'Feedback'
                ],
                appImages: [
                    '/assets/images/portfolio/janam/01.webp',
                    '/assets/images/portfolio/janam/02.webp',
                    '/assets/images/portfolio/janam/03.webp',
                    '/assets/images/portfolio/janam/04.webp',
                    '/assets/images/portfolio/janam/06.webp',
                    '/assets/images/portfolio/janam/08.webp',
                    '/assets/images/portfolio/janam/10.webp',
                    '/assets/images/portfolio/janam/12.webp',
                    '/assets/images/portfolio/janam/13.webp',
                    '/assets/images/portfolio/janam/14.webp',
                ],
                h3: "User App",
                p: "Pregnancy Management App is a Pregnancy Management mobile application that provides pregnant women with information and resources related to pregnancy",
                alt: "mommycare user",
                color: "mommycare"
            },
            {
                type: "web",
                adminButtonNames: [
                    'Login',
                    'Dashboard',
                    'Manage Categories',
                    'Manage Subcategories',
                    'Manage Articles',
                    'Manage Language',
                    'Manage Hospitals'
                ],
                adminImageAddress: [
                    '/assets/images/portfolio/janam/janam7.webp',
                    '/assets/images/portfolio/janam/janam1.webp',
                    '/assets/images/portfolio/janam/janam2.webp',
                    '/assets/images/portfolio/janam/janam3.webp',
                    '/assets/images/portfolio/janam/janam4.webp',
                    '/assets/images/portfolio/janam/janam5.webp',
                    '/assets/images/portfolio/janam/janam6.webp',
                ],
                h3: "Admin Panel",
                p: "Advanced web admin panel with well-structured code that controls every aspect of users, doctor and Hospital . A dashboard that helps to analyze overall growth and informative graphs/charts makes it more understandable.",
                alt: "mommycare admin",
                color: "mommycare"
            }
        ],
        title: "Pregnancy Management App",
        p: [
            'A Pregnancy Management mobile application that will help to solve common health conditions or problems a woman may experience during her pregnancy period. The app has a category of various topics which can also be found by keywords, and portrays articles that focus on health issues and all the informative content that can aid pregnant women. It helps the users to get more information about each trimester and all the dos and don’ts of the pregnancy cycle. The articles consist of images and videos for better understanding. The admin has the authority to add, edit, delete the articles.'
        ],
        features: [
            {
                title: "Visitor",
                li: [
                    'Multiple languages.',
                    'Category wise articles.',
                    'Search by keywords.',
                    'Read articles in your language.',
                    'Searchable article contents.',
                    'Detailed article page with title, image, video, date and description.',
                    'Contact via call/ email/ WhatsApp',
                ],
                colorCode: "422400"
            },
            {
                title: "Admin",
                li: [
                    'Statistics of categories, articles and users.',
                    'Manage Categories.',
                    'Search by keywords.',
                    'Add Articles.',
                    'Edit or delete articles.',
                ],
                colorCode: "422400"
            }
        ],
        titleClass: "custom-multilingual-color",
        portfolioButton: {
            class: "btn-mommycare",
            text: "View All Our Projects",
            padding: ""
        },
        pageName: 'btn-mommycare'
    },
    {
        pageTitle: "doctor-appointment-and-online-pharmacy",
        metaTags: {
            pageTitle: "Doctor Appointment and Online Pharmacy",
            pageUrl: "/doctor-appointment-and-online-pharmacy",
            metaTitle: "Doctor Appointment and Online Pharmacy",
            metaImage: "/assets/images/portfolio/dp-portfolio-51.jpg",
            metaDescription: "this is a platform for the healthcare industry to build strong professional networks between Administrators, Doctors, Advanced Nurse Practitioners, Clinical pharmacists, Healthcare Assistants and Admin Staff."
        },
        breadCrumbs: {
            title: "Doctor Appointment and Online Pharmacy",
            customColor: "custom-coursier-color",
            dataBlackOverlay: 7,
            bgImage: "bg_image--97",
            p: "Web / Android / iOS Application",
            pb: "pb--120"
        },
        deliverables: [
            { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
            { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
            { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
            { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
            { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
            { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
            { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
            { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
            { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
            { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
            { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
            { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
            { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
            { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
            { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },

        ],
        deliverablesProps: {
            title: "16 Deliverables",
            class: "coursier",
            value: [7, 5, 4],
            colorCode: '2f64af'
        },
        technologies: [
            { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
            { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
            { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
            { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },
        ],
        similarApps: {
            items: [
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #8ab2ff 0%, #4636fc 100%)', link: '/healthcare-insurance-marketplace', img: 'healthcare.webp', h2: 'DocFinder', p: 'Healthcare Insurance Marketplace' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/primary-care-management-system', img: 'federate.webp', h2: 'Federate', p: 'Primry Care Management' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
            ],
            title: "Similar Apps",
            slidesToShow: [
                3, 3
            ]
        },
        screens: [
            {
                type: "app",
                appButtonNames: [
                    'MediConnect App',
                    'Select User Type',
                    'Account',
                    'Search Doctor',
                    'Search Doctor Result',
                    'Doctor Details',
                    'Select Date & Time',
                    'My Appointments',
                    'Appointments Details',
                    'Notifications',
                    'Medicines'
                ],
                appImages: [
                    '/assets/images/portfolio/coursier-medical/coursier-1.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-2.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-3.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-4.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-5.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-6.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-7.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-8.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-9.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-10.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-11.webp',
                ],
                h3: "User App",
                p: "Patients can use the platform to book appointments with doctors, purchase medicine from various pharmacies, and access health-related information. They can also use the platform to view their medical records and communicate with their doctors.",
                alt: "user mediconnect",
                color: "mediconnect"
            },
            {
                type: "app",
                appButtonNames: [
                    'MediConnect App', 'Select User Type', 'Account', 'My Appointments', 'Appointment Details', 'Medicines', 'Notification', 'My Profile', 'Reviews', 'Recent Orders', 'Shop By Category', 'Saved Addresses'
                ],
                appImages: [
                    '/assets/images/portfolio/coursier-medical/coursier-1.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-2.webp',
                    '/assets/images/portfolio/coursier-medical/doctor-1.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-3.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-4.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-5.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-6.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-7.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-9.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-8.webp',
                    '/assets/images/portfolio/coursier-medical/coursier-10.webp',
                    '/assets/images/portfolio/coursier-medical/doctor-2.webp'
                ],
                h3: "Doctor App",
                p: "Doctors can create their accounts manage their appointments and prescribe medicines.",
                alt: "doctor mediconnect",
                color: "mediconnect"
            },
            {
                type: "web",
                adminButtonNames: [
                    'Dashboard', 'Manage Doctors Appointment', 'Manage Hospitals Appointment', 'Manage Medical Store', 'Manage Delivery Man', 'Manage Patients', 'Manage Doctors', 'Manage Hospitals', 'Manage Customers Order', 'Manage Medicines', 'Manage Registration Numbers', 'Master'
                ],
                adminImageAddress: [
                    '/assets/images/portfolio/coursier-medical/admin-1.webp',
                    '/assets/images/portfolio/coursier-medical/admin-2.webp',
                    '/assets/images/portfolio/coursier-medical/admin-3.webp',
                    '/assets/images/portfolio/coursier-medical/admin-4.webp',
                    '/assets/images/portfolio/coursier-medical/admin-5.webp',
                    '/assets/images/portfolio/coursier-medical/admin-6.webp',
                    '/assets/images/portfolio/coursier-medical/admin-7.webp',
                    '/assets/images/portfolio/coursier-medical/admin-8.webp',
                    '/assets/images/portfolio/coursier-medical/admin-9.webp',
                    '/assets/images/portfolio/coursier-medical/admin-10.webp',
                    '/assets/images/portfolio/coursier-medical/admin-11.webp',
                    '/assets/images/portfolio/coursier-medical/admin-12.webp',
                ],
                h3: "Admin Panel",
                p: "The admin is responsible for managing and maintaining the website. They have access to the backend of the platform and can manage user accounts, appointments, pharmacy listings, and other aspects of the website.",
                alt: "admin mediconnect",
                color: "mediconnect"
            }
        ],
        title: "Doctor Appointment and Online Pharmacy",
        p: [
            'This is a healthcare platform that provides users with various healthcare-related services such as booking online doctor appointments, purchasing medicine from pharmacies, and accessing health-related information. It has a user-friendly design allows users to easily navigate the different sections and find the information they need.',
            'Online Doctor Appointment allows users to book appointments with doctors online. Users can select their preferred doctor, choose a suitable time slot, and provide other relevant information. The users have access to a wide range of medicines from various pharmacies. They can browse the available medication, view their prices, and order online. Users can make payments online for the services they have availed of on the platform.',
        ],
        features: {
            title: "Features",
            li: [
                'Online Doctor Appointment',
                'Audio/Video Call and Chat',
                'Appointment Reminders',
                'User Reviews',
                'Order Medicine Online',
                'Pharmacy Access',
                'Health Information',
                'User Management',
                'Payment Gateway',
                'Delivery Management',
                'Multilingual',
                'Secure Transactions',
            ],
            colorCode: "2f64af"
        },
        titleClass: "custom-coursier-color",
        portfolioButton: {
            class: "btn-coursier",
            text: "View All Our Projects",
            padding: "pb--20"
        },
        pageName: 'btn-coursier'
    },
    {
        pageTitle: "healthcare-insurance-marketplace",
        metaTags: {
            pageTitle: "Healthcare Insurance || Noorisys Technologies Pvt Ltd",
            pageUrl: "/healthcare-insurance-marketplace",
            metaTitle: "Healthcare Insurance Marketplace",
            metaImage: "/assets/images/portfolio/dp-portfolio-06.jpg",
            metaDescription: "This Healthcare insurance marketplace allows patients to use their health insurance on the platform. Services include online appointments with Doctors, Hospitals, Technicians, etc.",
            metaKeywords: 'marketplace online,online marketplace uk,best online marketplace uk ,insurance healthcare marketplace'
        },
        breadCrumbs: {
            title: "Healthcare Insurance Marketplace",
            customColor: "custom-healthcare-color",
            dataBlackOverlay: 7,
            bgImage: "bg_image--44",
            p: "Web / Android / iOS Application",
        },
        deliverables: [
            { item_bg: 'android.png', item_icon: 'users.png', h3: 'Patient App', h4: 'Android' },
            { item_bg: 'ios.png', item_icon: 'users.png', h3: 'Patient App', h4: 'iOS' },
            { item_bg: 'web.png', item_icon: 'users.png', h3: 'Patient', h4: 'Web' },
            { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
            { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
            { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
            { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
            { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
            { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
            { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
            { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
            { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
            { item_bg: 'android.png', item_icon: 'primaryadvance.png', h3: 'Nurse App ', h4: 'Android' },
            { item_bg: 'ios.png', item_icon: 'primaryadvance.png', h3: 'Nurse App ', h4: 'iOS' },
            { item_bg: 'web.png', item_icon: 'primaryadvance.png', h3: 'Nurse ', h4: 'Web' },
            { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },

        ],
        deliverablesProps: {
            title: "16 Deliverables",
            class: "coursier",
            value: [7, 5, 4],
            colorCode: '1296cc'
        },
        technologies: [
            { h5: 'Android', p: 'Android & iOS development', img: 'androidios.png' },
            { h5: 'PHP', p: 'Backend Development', img: 'php.png' },
            { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
            { h5: 'PHP', p: 'Admin Panel Development', img: 'php.png' },
        ],
        similarApps: {
            items: [
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/doctor-appointment-and-online-pharmacy', img: 'mediconnect.webp', h2: 'MediConnect', p: 'Doctor Appointment and pharmacy' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/primary-care-management-system', img: 'federate.webp', h2: 'Federate', p: 'Primry Care Management' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },

            ],
            title: "Similar Apps",
            slidesToShow: [
                3, 3
            ]
        },
        screens: [
            {
                type: "app",
                appButtonNames: [
                    'Docfinder App', 'Login', 'Sidebar', 'Dashboard', 'Insurance', 'My Appointments', 'Profile', 'My Relatives', 'My Documents', 'Help Relatives'
                ],
                appImages: [
                    '/assets/images/portfolio/healthcare-insurance/izikare-app-1.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-app-2.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-app-3.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-app-4.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-app-5.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-app-6.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-app-7.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-app-8.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-app-9.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-app-10.webp',

                ],
                h3: "User App",
                p: "Users can book online and physical appointments with the doctor. Also users can search and subscribe for available insurance available on the platform.",
                alt: "docfinder user",
                color: "docfinder"
            },
            {
                type: "web",
                adminButtonNames: [
                    'Homepage', 'Registration', 'Login', 'Dashboard', 'Insurance Activities', 'Allodocta Activities', 'Platform Activities', 'Insurance Settings', 'Allodocta Settings', 'EMR Settings', 'Platform Settings', 'Profile'
                ],
                adminImageAddress: [
                    '/assets/images/portfolio/healthcare-insurance/izikare-web-1.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-web-2.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-web-3.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-web-4.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-web-5.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-web-6.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-web-7.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-web-8.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-web-9.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-web-10.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-web-11.webp',
                    '/assets/images/portfolio/healthcare-insurance/izikare-web-12.webp',

                ],
                h3: "Admin Panel",
                p: "Admin can manage users, providers (doctor, pharmacy, lab etc), appointments, insurance, EMR and other platform settings",
                alt: "docfinder admin",
                color: "docfinder"
            }
        ],
        title: "Healthcare Insurance Marketplace",
        p: [
            'Healthcare insurance marketplace is a digital platform that allows the patient to take out connected health insurance allowing their loved ones to be treated without paying costs. Healthcare insurance marketplace is a Medical Health platform that allows taking Online appointment to any Providers (Doctor, Hospital, Technician, etc.). Healthcare insurance marketplace platforms directly connect to Patients with Doctors. Doctors and Hospitals can register on the platform and provide the Services. Patients can easily connect with platforms from any platform (Android / iOS / Web ), make appointments, and do Online Payment.'
        ],
        features: {
            title: "Features",
            li: [
                'Easy registration for providers and patients',
                'Email verification and Forgot password',
                'Attractive dashboard for each Providers and Patient',
                'Online Teleconsultation',
                'Audio / Video appointment',
                'Physical Appointment',
                'Consult at patient home',
                'EMR management',
                'Insurance Subscriptions',
                'Insurance Package Marketplace',
                'Manage Relatives',
                'Claims management',
                'Secure online payment',
                'Offline payment',
                'Multi-currency',
                'Multi language',
                'Accounts and wallet',
                'Separate chat for each audio / video appointment',
                'Alerts and notifications',
                'Fully responsive design',
                'And many more….'
            ],
            colorCode: "1296cc"
        },
        titleClass: "custom-healthcare-color",
        portfolioButton: {
            class: "btn-healthcare",
            text: "View All Our Projects",
            padding: "pb--20"
        },
        pageName: 'btn-healthcare'
    },
    {
        pageTitle: "ticket-booking-platform",
        metaTags: {
            pageTitle: "Online Ticket Booking || Noorisys Technologies Pvt Ltd",
            pageUrl: "/ticket-booking-platform",
            metaTitle: "Online Ticket Booking",
            metaImage: "/assets/images/portfolio/dp-portfolio-08.jpg",
            metaDescription: "Online ticket booking system allows users to purchase tickets for various modes of transportation such as flights, trains, buses, and taxis. It provides easy booking, online payments, cancellation and refunds."
        },
        breadCrumbs: {
            title: "Online Ticket Booking",
            customColor: "theme-gradient",
            dataBlackOverlay: 6,
            bgImage: "bg_image--48",
            p: "Web / Android / iOS Application",
        },
        technologies: [
            { h5: 'Android', p: 'Android ', img: 'android.png' },
            { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
            { h5: 'PHP', p: 'Admin Panel Development', img: 'php.png' },
        ],
        similarApps: {
            items: [
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
            ],
            title: "Apps",
            slidesToShow: [
                3, 3
            ]
        },
        screens: [
            {
                type: "app",
                appButtonNames: [
                    'Splash Screen', 'Login or Signup', 'Signup Option', 'Register As', 'Registration', 'Login', 'Dashboard', 'Sidebar', 'Create Booking', 'Address Book list', 'Location'
                ],
                appImages: [
                    '/assets/images/portfolio/courier/gonago-app-1.webp',
                    '/assets/images/portfolio/courier/gonago-app-2.webp',
                    '/assets/images/portfolio/courier/gonago-app-3.webp',
                    '/assets/images/portfolio/courier/gonago-app-4.webp',
                    '/assets/images/portfolio/courier/gonago-app-5.webp',
                    '/assets/images/portfolio/courier/gonago-app-6.webp',
                    '/assets/images/portfolio/courier/gonago-app-7.webp',
                    '/assets/images/portfolio/courier/gonago-app-8.webp',
                    '/assets/images/portfolio/courier/gonago-app-9.webp',
                ],
                h3: "User App",
                p: "Users can register and log in on the platform to manage their profile, address book, and create shipping bookings.",
                alt: "service pro user",
                color: "service-pro"
            },
            {
                type: "web",
                adminButtonNames: [
                    'Homepage', 'Signup', 'Login', 'Dashboard', 'Currency List', 'Group Permissions', 'User List', 'Order List', 'Transport Vehicles', 'Point to Point Rates'
                ],
                adminImageAddress: [
                    '/assets/images/portfolio/courier/gonago-web-1.webp',
                    '/assets/images/portfolio/courier/gonago-web-2.webp',
                    '/assets/images/portfolio/courier/gonago-web-3.webp',
                    '/assets/images/portfolio/courier/gonago-web-4.webp',
                    '/assets/images/portfolio/courier/gonago-web-5.webp',
                    '/assets/images/portfolio/courier/gonago-web-6.webp',
                    '/assets/images/portfolio/courier/gonago-web-7.webp',
                    '/assets/images/portfolio/courier/gonago-web-8.webp',
                    '/assets/images/portfolio/courier/gonago-web-9.webp',
                    '/assets/images/portfolio/courier/gonago-web-10.webp',
                    '/assets/images/portfolio/courier/gonago-web-11.webp',

                ],
                h3: "Admin Panel",
                p: "Admin can manage users, orders, relay points, withdrawal requests, commission, promo code and other platform settings.",
                alt: "service-pro admin",
                color: "service-pro"
            }
        ],
        title: "Online Ticket Booking",
        p: [
            'Online ticket booking system is a platform to provide booking of Air, Train, buses and Taxis.The system provides an easy booking, online payments, cancellation and refunds.'
        ],
        features: {
            title: "Features",
            li: [
                'Easy registration for customer and bus service providers',
                'Attractive dashboard for customer and providers',
                'Customer relationship management',
                'Secure online payment',
                'Offline payment',
                'Prepaid service',
                'Digitalization of your processes and flows',
                'Vehicle selection',
                'Chose different pickup point and drop point as per convenience',
                'Platform send you E-ticket to your email and whatsapp',
                'Online booking',
                'Easy payment options (Pay on service providers)',
                'Track your booking',
                'Easy cancellation and refunds',
                'Multi-currency',
                'Multi language',
                'Manage address book',
                'Review and ratings',
                'Accounts and wallet',
                'Separate chat room for each booking',
                'Alerts and notifications',
                'Mark providers as favorite',
                'Attractive and transparent prices managed by admin and provider',
                'Fully responsive design',
                'And many more….'
            ],
            colorCode: "012296"
        },
        titleClass: "custom-courier-color",
        portfolioButton: {
            class: "btn-courier",
            text: "View All Our Projects",
            padding: "pb--20"
        },
        pageName: 'btn-courier'
    },
    {
        pageTitle: "visa-processing-system",
        metaTags: {
            pageTitle: "visa processing system",
            pageUrl: "/visa-processing-system",
            metaTitle: "Umrah visa processing system",
            metaImage: "/assets/images/portfolio/dp-portfolio-10.webp",
            metaDescription: "Our platform seamlessly connects millions of travellers around the world with hotels registered on the platform. Make your own customized hotel management system with Noorisys.",
            metaKeywords: "best online travel agency uk ,travel agency online ,online travel agencies"
        },
        breadCrumbs: {
            title: "Visa Processing OTA",
            customColor: "custom-hotel-booking-color",
            dataBlackOverlay: 4,
            bgImage: "bg_image--86",
            p: "Web / Android / iOS Application",
        },
        deliverables: [
            { item_bg: 'android.png', item_icon: 'users-annachi.png', h3: 'User App', h4: 'Android' },
            { item_bg: 'ios.png', item_icon: 'users-annachi.png', h3: 'User App', h4: 'iOS' },
            { item_bg: 'web.png', item_icon: 'users-annachi.png', h3: 'User', h4: 'Web' },
            { item_bg: 'web.png', item_icon: 'admin-annachi.png', h3: 'Admin ', h4: 'Web' },
        ],
        deliverablesProps: {
            title: "4 Deliverables",
            class: "zowar",
            value: [4, 4, 4],
            colorCode: '00722e'
        },
        technologies: [
            { h5: '', p: '', img: '' },
            { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
            { h5: '.Net', p: 'Backend Development', img: 'dotnet.png' },
            { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },
        ],
        similarApps: {
            items: [
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/doctor-appointment-and-online-pharmacy', img: 'mediconnect.webp', h2: 'MediConnect', p: 'Doctor Appointment and pharmacy' },

            ],
            title: "Apps",
            slidesToShow: [
                3, 3
            ]
        },
        screens: [

            {
                type: "web",
                adminButtonNames: [
                    'Login', 'B2B DashBoard', 'B2C DashBoard', 'Hotel Bookings', 'B2C User List', 'Agents', 'Cancelled Booking List', 'Tranport Bookings', 'Ground Service Bookings', 'Umrah + Visa Package List', 'Pending Refund List'
                ],
                adminImageAddress: [
                    '/assets/images/portfolio/zowar/admin-1.webp',
                    '/assets/images/portfolio/zowar/admin-2.webp',
                    '/assets/images/portfolio/zowar/admin-3.webp',
                    '/assets/images/portfolio/zowar/admin-4.webp',
                    '/assets/images/portfolio/zowar/admin-5.webp',
                    '/assets/images/portfolio/zowar/admin-6.webp',
                    '/assets/images/portfolio/zowar/admin-7.webp',
                    '/assets/images/portfolio/zowar/admin-8.webp',
                    '/assets/images/portfolio/zowar/admin-9.webp',
                    '/assets/images/portfolio/zowar/admin-10.webp',
                    '/assets/images/portfolio/zowar/admin-11.webp',

                ],
                h3: "Admin Panel",
                p: "Admin can manage Umrah Visa along with the booking of hotels, transportation, ground services and other aspects of the website.",
                alt: "visa precessing admin",
                color: "zowar"
            }
        ],
        title: "Visa Processing OTA",
        p: [
            'It is a comprehensive travel platform designed specifically for Umrah pilgrims. It offers a wide range of services to make the pilgrimage easier and more convenient for travellers.'
        ],
        features: [
            {
                title: "Visa Assistance:",
                p: ['Platform has integrated Maqam GDS and it can assist pilgrims in the visa application process, making it easier and more convenient for them to obtain their Umrah visa.']
            },
            {
                title: "Hotel Booking:",
                p: ['This app offers various hotels in Makkah and Madinah to offer pilgrims a range of accommodation options to choose from. Users can search and book hotels based on their preferred location, budget and amenities.']
            },
            {
                title: "Transportation:",
                p: ['It offers a variety of transportation options, including flights, buses, and taxis. Users can search and book their preferred mode of transportation based on their budget and travel preferences.']
            },
            {
                title: "Ground Services:",
                p: ['It can arrange a range of ground services for pilgrims, including airport transfers, luggage handling, and tour guides. This ensures that pilgrims have a comfortable and hassle-free travel experience.']
            },
            {
                title: "Travel Insurance:",
                p: ['This platform offers travel insurance to protect pilgrims against any unforeseen circumstances during their journey.']
            },
        ],
        titleClass: "custom-hotel-booking-color",
        portfolioButton: {
            class: "btn-umrah-visa",
            text: "View All Our Projects",
            padding: "pb--20"
        },
        pageName: 'btn-umrah-visa'
    },
    {
        pageTitle: "digital-solution-for-civil-documents",
        metaTags: {
            pageTitle: "Digital Solution For Civil Documents",
            pageUrl: "/digital-solution-for-civil-documents",
            metaTitle: "Digital Solution For Civil Documents",
            metaImage: "/assets/images/portfolio/dp-portfolio-51.webp",
            metaDescription: "this is a platform for the healthcare industry to build strong professional networks between Administrators, Doctors, Advanced Nurse Practitioners, Clinical pharmacists, Healthcare Assistants and Admin Staff."
        },
        breadCrumbs: {
            title: "Digital Solution For Civil Documents",
            customColor: "custom-digital-solution-color",
            dataBlackOverlay: 5,
            bgImage: "bg_image--29",
            p: "Web Application",
        },
        deliverables: [
            { item_bg: 'web.png', item_icon: 'operator.png', h3: 'Operator Panel ', h4: 'Web' },
            { item_bg: 'web.png', item_icon: 'controller.png', h3: 'Controller Panel ', h4: 'Web' },
            { item_bg: 'web.png', item_icon: 'supervisor.png', h3: 'Supervisor Panel ', h4: 'Web' },
            { item_bg: 'web.png', item_icon: 'admin-digital.png', h3: 'Admin Panel', h4: 'Web' },
        ],
        deliverablesProps: {
            title: "4 Deliverables",
            class: "digital_solution",
            value: [4, 4, 4],
            colorCode: "0096ff"
        },
        technologies: [
            { h5: '', p: '', img: '' },
            { h5: '', p: '', img: '' },
            { h5: 'NodeJS', p: 'Backend Development', img: 'node.png' },
            { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },
        ],
        similarApps: {
            items: [
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
            ],
            title: "Apps",
            slidesToShow: [
                3, 3
            ]
        },
        screens: [
            {
                type: "web",
                adminButtonNames: [
                    'Login', 'Dashboard', 'My Profile', 'Verify Document', 'Rejected Documents'
                ],
                adminImageAddress: [
                    '/assets/images/portfolio/digitalsolution/indexer-01.webp',
                    '/assets/images/portfolio/digitalsolution/indexer-02.webp',
                    '/assets/images/portfolio/digitalsolution/indexer-03.webp',
                    '/assets/images/portfolio/digitalsolution/indexer-04.webp',
                    '/assets/images/portfolio/digitalsolution/indexer-05.webp',
                ],
                h3: "Indexing Operator Panel",
                p: "This panel allows indexing operators to log in, select the type of certificate they are working on (birth, death, or marriage) and assign unassigned files to themselves. They can review and modify the required fields scanned by the OCR system before submitting the completed pages.",
                alt: "indexer",
                color: "civipol-user"
            },
            {
                type: "web",
                adminButtonNames: [
                    'Controller Login', 'Controller Dashboard', 'My Profile', 'Controller Verify Reject'
                ],
                adminImageAddress: [
                    '/assets/images/portfolio/digitalsolution/controller-01.webp',
                    '/assets/images/portfolio/digitalsolution/controller-02.webp',
                    '/assets/images/portfolio/digitalsolution/controller-03.webp',
                    '/assets/images/portfolio/digitalsolution/controller-04.webp',

                ],
                h3: "Quality Controller Panel",
                p: "Quality controllers can log in and review the completed files assigned to them. They can make modifications if necessary and search and view rejected files.",
                alt: "operator",
                color: "civipol-user"
            },
            {
                type: "web",
                adminButtonNames: [
                    'Supervisor Login', 'Supervisor Dashboard', 'My Profile', 'Birth Certificate', 'Marriage Certificate', 'Death Certificate', 'Rejected Birth Certificate', 'Rejected Marriage Certificate', 'Rejected Death Certificate'
                ],
                adminImageAddress: [
                    '/assets/images/portfolio/digitalsolution/supervisor-01.webp',
                    '/assets/images/portfolio/digitalsolution/supervisor-02.webp',
                    '/assets/images/portfolio/digitalsolution/supervisor-03.webp',
                    '/assets/images/portfolio/digitalsolution/supervisor-04.webp',
                    '/assets/images/portfolio/digitalsolution/supervisor-05.webp',
                    '/assets/images/portfolio/digitalsolution/supervisor-06.webp',
                    '/assets/images/portfolio/digitalsolution/supervisor-07.webp',
                    '/assets/images/portfolio/digitalsolution/supervisor-08.webp',
                    '/assets/images/portfolio/digitalsolution/supervisor-09.webp',

                ],
                h3: "DEC Supervisor Panel",
                p: "DEC supervisors have access to completed files and can mark them as okay or not okay. They can also search and view rejected files.",
                alt: "dec supervisor",
                color: "civipol-user"
            },
            {
                type: "web",
                adminButtonNames: [
                    'Admin Login', 'Dashboard', 'Manage Birth Files', 'Manage Marriage Files', 'Manage Death Files', 'Manage Index Operator', 'Manage Quality Controllers', 'Manage Supervisor', 'Birth Certificate', 'Marriage Certificate', 'Death Certificate', 'All Certificates', 'All Rejected Certificates', 'Rejected Birth Certificates', 'Rejected Birth Certificates By Supervisor'
                ],
                adminImageAddress: [
                    '/assets/images/portfolio/digitalsolution/admin-00.webp',
                    '/assets/images/portfolio/digitalsolution/admin-01.webp',
                    '/assets/images/portfolio/digitalsolution/admin-02.webp',
                    '/assets/images/portfolio/digitalsolution/admin-03.webp',
                    '/assets/images/portfolio/digitalsolution/admin-04.webp',
                    '/assets/images/portfolio/digitalsolution/admin-05.webp',
                    '/assets/images/portfolio/digitalsolution/admin-06.webp',
                    '/assets/images/portfolio/digitalsolution/admin-07.webp',
                    '/assets/images/portfolio/digitalsolution/admin-08.webp',
                    '/assets/images/portfolio/digitalsolution/admin-09.webp',
                    '/assets/images/portfolio/digitalsolution/admin-10.webp',
                    '/assets/images/portfolio/digitalsolution/admin-11.webp',
                    '/assets/images/portfolio/digitalsolution/admin-12.webp',
                    '/assets/images/portfolio/digitalsolution/admin-13.webp',
                    '/assets/images/portfolio/digitalsolution/admin-14.webp',

                ],
                h3: "Admin Web Panel",
                p: "Administrators have access to manage users and their access to functionalities. They can create and manage user accounts for indexing operators, quality controllers, and DEC supervisors. The admin panel also allows file management, including uploading files, viewing file statuses, managing certificates, generating reports and interlinking all the certificates from the backend.",
                alt: "admin",
                color: "civipol-admin"
            },
        ],
        title: "Digital Solution For Civil Documents",
        p: [
            'This project was developed to digitize important civil documents for a West African country, streamlining the process and ensuring easy accessibility. It involves an indexing operator panel where operators can log in, select the type of certificate, and assign and complete files with required fields. Quality controllers review and modify completed files, while supervisors mark them as okay or not okay. An admin web panel allows user and access management, file upload and status tracking, certificate management and report generation. This comprehensive approach digitizes and simplifies the management of civil documents, enhancing efficiency and accessibility for the country.'
        ],
        features: {
            colorCode: ''
        },
        titleClass: "custom-digital-solution-color",
        portfolioButton: {
            class: "btn-digital-solution",
            text: "View All Our Projects",
            padding: "pb--20"
        },
        pageName: 'btn-digital-solution'
    },
    {
        pageTitle: "hotel-booking-platform",
        metaTags: {
            pageTitle: "Online Hotel Booking || Noorisys Technologies Pvt Ltd",
            pageUrl: "/hotel-booking-platform",
            metaTitle: "Hotel Booking Platform",
            metaImage: "/assets/images/portfolio/dp-portfolio-10.jpg",
            metaDescription: "Our platform seamlessly connects millions of travellers around the world with hotels registered on the platform. Make your own customized hotel management system with Noorisys.",
            metaKeywords: "best online travel agency uk ,travel agency online ,online travel agencies"
        },
        breadCrumbs: {
            title: "Hotel Booking Platform",
            customColor: "theme-gradient",
            dataBlackOverlay: 7,
            bgImage: "bg_image--46",
            p: "Web / Android / iOS Application",
        },
        deliverables: [
            { item_bg: 'android.png', item_icon: 'users-annachi.png', h3: 'User App', h4: 'Android' },
            { item_bg: 'ios.png', item_icon: 'users-annachi.png', h3: 'User App', h4: 'iOS' },
            { item_bg: 'web.png', item_icon: 'users-annachi.png', h3: 'User', h4: 'Web' },
            { item_bg: 'web.png', item_icon: 'admin-annachi.png', h3: 'Admin ', h4: 'Web' },
        ],
        deliverablesProps: {
            title: "4 Deliverables",
            class: "zowar",
            value: [4, 4, 4],
            colorCode: "00722e"
        },
        technologies: [
            { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
            { h5: '.Net', p: 'Backend Development', img: 'dotnet.png' },
            { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },
        ],
        similarApps: {
            items: [
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #8ab2ff 0%, #4636fc 100%)', link: '/healthcare-insurance-marketplace', img: 'healthcare.webp', h2: 'Izikare', p: 'Healthcare Insurance Marketplace' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/primary-care-management-system', img: 'federate.webp', h2: 'Federate', p: 'Primry Care Management' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'Biblo', p: 'Nursing Platform' },
                { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'Allodocta', p: 'Pharmacist Marketplace' },
            ],
            title: "Apps",
            slidesToShow: [
                3, 3
            ]
        },
        screens: [
            {
                type: "web",
                adminButtonNames: [
                    'Login', 'B2B DashBoard', 'B2C DashBoard', 'Hotel Bookings', 'B2C User List', 'Agents', 'Cancelled Booking List', 'Tranport Bookings', 'Ground Service Bookings', 'Umrah + Visa Package List', 'Pending Refund List'
                ],
                adminImageAddress: [
                    '/assets/images/portfolio/zowar/admin-1.webp',
                    '/assets/images/portfolio/zowar/admin-2.webp',
                    '/assets/images/portfolio/zowar/admin-3.webp',
                    '/assets/images/portfolio/zowar/admin-4.webp',
                    '/assets/images/portfolio/zowar/admin-5.webp',
                    '/assets/images/portfolio/zowar/admin-6.webp',
                    '/assets/images/portfolio/zowar/admin-7.webp',
                    '/assets/images/portfolio/zowar/admin-8.webp',
                    '/assets/images/portfolio/zowar/admin-9.webp',
                    '/assets/images/portfolio/zowar/admin-10.webp',
                    '/assets/images/portfolio/zowar/admin-11.webp',

                ],
                h3: "Admin Panel",
                p: "Admin can manage Umrah Visa along with the booking of hotels, transportation, ground services and other aspects of the website.",
                alt: "visa processing admin",
                color: "zowar"
            }
        ],
        title: "Online Hotel Booking",
        p: [
            'By investing in technology that takes the friction out of travel, Hotel Management System seamlessly connects millions of travelers to memorable experiences and incredible places to stay – from homes to hotels, and much more. Hotel Management System enables properties around the world to reach a global audience and grow their businesses.'
        ],
        features: {
            title: "Features",
            li: [
                'Easy registration for customer and service providers',
                'Attractive dashboard for each user',
                'Super Admin Account to manage system',
                'Secure online payment',
                'Customers can book online and pay with credit or debit card or can pay at hotel.',
                'Customers can search based on hotel, apartment',
                'Customers able to cancel their booking from their account',
                'Booking confirmation email after successful booking with report',
                'Customers can write reviews about hotels and apartment and also rate them',
                'Track your booking',
                'Promotions and deal options',
                'Multi-currency converter',
                'Multi language',
                'Provider can add and edit new property from provider panel',
                'Calender to set opening closing dates',
                'Calender to set change in rate for fixed / specific dates',
                'Provider can add new promotions',
                'In-app messaging for client, provider and super admin',
                'Alerts and notifications',
                'Sale and invoice reports',
                'Provider can manage any booking within his/her property',
                'Admin panel consist all transaction from all available providers',
                'Super admin can manage any hotel, provider, client, booking and many more',
                'Fully responsive design',
                `Power of Microsoft's ASP CORE and Angular which makes system more secure`,
                'And many more….'
            ],
            colorCode: "00722e"
        },
        titleClass: "custom-hotel-booking-color",
        portfolioButton: {
            class: "btn-umrah-visa",
            text: "View All Our Projects",
            padding: "pb--20"
        },
        pageName: 'btn-umrah-visa'
    }

];

export default function MainPortfolio({ match }) {
    const [isVisible, setisVisible] = useState(false);
    const [index, setIndex] = useState(0);
    const { portfolioName } = match.params;

    console.log("portfolioName===>", portfolioName);
    const showPopup = () => {
        setisVisible(true);
        window.lintrk("track", { conversion_id: 18604321 });
    };
    const closePopup = () => {
        setisVisible(false);
    };
    function findIndexByTitle(titleToFind) {
        console.log("title to find===>", titleToFind);
        return JSON.findIndex((item) => item.pageTitle === titleToFind);
    }
    useEffect(() => {
        console.log(
            "return index===> ",
            findIndexByTitle(match.params.portfolioName)
        );
        setIndex(findIndexByTitle(match.params.portfolioName));
        console.log("location ==>", portfolioName);
    }, [portfolioName]);
    if (!validPortfolioNames.includes(portfolioName)) {
        return <Redirect to={`${process.env.PUBLIC_URL}/404`} />;
    }
    return (
        <PortfolioJson
            metaTags={JSON[index].metaTags}
            breadCrumbs={JSON[index].breadCrumbs}
            socialShare={JSON[index].socialShare}
            title={JSON[index].title}
            p={JSON[index].p}
            features={JSON[index].features}
            colorCode={JSON[index].features.colorCode}
            deliverablesProps={JSON[index].deliverablesProps}
            deliverables={JSON[index].deliverables}
            technologies={JSON[index].technologies}
            titleClass={JSON[index].titleClass}
            screens={JSON[index].screens}
            similarApps={JSON[index].similarApps}
            portfolioButton={JSON[index].portfolioButton}
            isVisible={isVisible}
            showPopup={showPopup}
            closePopup={closePopup}
            getAquote={true}
            pageName={JSON[index].pageName}
        />
    );
}
