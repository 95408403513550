import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiCheck } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";
import { Link } from "react-router-dom";
import CommonSliderTransitionBanner from "../component/common/CommonSliderTransitionBanner";
const Brand = React.lazy(() => import('../creative_homePage/CreativeBrand'));
const MobileDevelopmentTab = React.lazy(() => import('./MobileDevelopmentTab'));
const MobileDevelopmentService = React.lazy(() => import("./MobileDevelopmentService"));
const MobileDevelopmentReview = React.lazy(() => import('./MobileDevelopmentReview'));
const MobileDevelopmentPortfolio = React.lazy(() => import('./MobileDevelopmentPortfolio'));
const SlideList = [
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--22',
        category: '',
        title: 'Mobile App Development',
        description: 'Noorisys excels in creating transformative mobile applications for all platforms, including native iOS and Android apps, as well as hybrid apps, delivering engaging mobile solutions.',
        buttonText: 'Contact Us',
        buttonLink: '/contact-us'
    },
]

class MobileDevelopment extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    render() {
        // const PostList = BlogContent.slice(0 , 3);
        let title = 'Success Stories',
            description = 'The tales of our success showcase our global presence and achievements in the IT sector, illustrating how we have empowered clients to realize their business ideas, support start-ups, and execute expansion plans. Noorisys has collaborated with numerous ambitious start-ups, assisting them in establishing a strong presence in the market. ';

        return (
            <Fragment>

                <Helmet pageTitle="Mobile Development || Noorisys Technologies Pvt Ltd" pageUrl='/mobile-development' metaTitle="Mobile App Development" metaImage="/assets/images/bg/bg-image21.webp"
                    metaDescription="Noorisys has a track record of creating transformative mobile applications for all platforms, be it native iOS, Android App or Hybrid Apps, we thrive to deliver engaging mobile solutions." />

                {/* Start Header Area  */}
                <Header logo="light" color="color-white" />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                {/* <div className="slider-wrapper color-white">
                    <div className="slider-activation slider-digital-agency">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value, index) => (
                                <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>

                    </div>
                </div> */}
                <CommonSliderTransitionBanner slideList={SlideList} />
                {/* End Slider Area   */}

                {/* Start Brand Area */}
                <div className="rn-brand-area ptb--20 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ptb--40">
                                <React.Suspense fallback={<div>loading...</div>} >
                                    <Brand />
                                </React.Suspense>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Pricing Tbale Area  */}

                <div className="rn-pricing-table-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row ptb--20">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3>Choose Your Mobile Development Options</h3>
                                    <p>Choose the Ideal Mobile App Development Solution for Your Business!</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="custom-rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">iOS App</h4>
                                            <p className="title">Build a strong brand value
                                                <br />Save your time and money</p>

                                            <div className="pricing">
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Enhance Brand Value Effectively</li>
                                                <li><FiCheck /> Time and Cost-Efficient Solution</li>
                                                <li><FiCheck />  More than 13 years of expertise</li>
                                                <li><FiCheck /> Wearables and iPad Optimization</li>
                                                <li><FiCheck />  Deployment and Publishing </li>
                                                {/* <li><FiCheck /> Guaranteed app store publishing</li> */}
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to="/contact-us">Get A Quote</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="custom-rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Hybrid apps</h4>
                                            <p className="title">Bridging Platforms for Versatile Marketing Solutions</p>
                                            <div className="pricing">
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck />Cross-Platform Development </li>
                                                <li><FiCheck />Seamless Web Integration Expertise</li>
                                                <li><FiCheck />Flutter for Engaging User Interfaces</li>
                                                <li><FiCheck />Efficiency with React Native </li>
                                                <li><FiCheck />Affordable and Low Maintenance </li>
                                                {/* <li><FiCheck />Easy for maintenance</li> */}
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to="/contact-us">Get A Quote</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="custom-rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Android</h4>
                                            <p className="title">Transform ideas into products<br />Let us power your business </p>
                                            <div className="pricing">
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Innovative App Prototyping</li>
                                                <li><FiCheck /> Vast proficiency in Java and Kotlin </li>
                                                <li><FiCheck /> Guaranteed Play Store Publishing</li>
                                                <li><FiCheck /> Navigating Tablets and KIOSKs</li>
                                                <li><FiCheck /> Optimized Hardware Performance</li>
                                                {/* <li><FiCheck /> Tablets / android  KIOSKs/POS</li> */}

                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to="/contact-us">Get A Quote</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                        </div>
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}





                {/* Start About Area */}
                <div id="about" className="fix">
                    <div className="about-area ptb--80  bg_color--5">
                        <div className="about-wrapper">
                            <div className="container">
                                <div className="row row--35 align-items-center">
                                    <div className="col-lg-5">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/about/success_stories_2.webp" alt="About Images" loading="lazy" />
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="about-inner inner">
                                            <div className="section-title">
                                                <h2 className="title">{title}</h2>
                                                <p className="description justifypara">{description}</p>
                                            </div>
                                            <div className="row mt--30">
                                                <React.Suspense fallback={<div>loading...</div>} >
                                                    <MobileDevelopmentTab tabStyle="tab-style--1" />
                                                </React.Suspense>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* End About Area */}

                <div className="service-area ptb--60  bg_color--1">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <MobileDevelopmentService />
                        </React.Suspense>
                    </div>
                </div>

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--30 bg_color--5">
                    <div className="portfolio-sacousel-inner mb--55">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <MobileDevelopmentPortfolio />
                        </React.Suspense>
                    </div>
                </div>
                {/* End Portfolio Area */}

                <div className="portfolio-area ptb--40 bg_color--5">
                    <div className="portfolio-sacousel-inner mb--55">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <MobileDevelopmentReview />
                        </React.Suspense>
                    </div>
                </div>


                {/* Start Footer Style  */}
                <Footer />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default MobileDevelopment;