import React from 'react';
import { Modal } from 'antd';

const CookieModal = ({ onAccept,onDecline }) => {
//     onAccept = () => {
//     const expirationDate = new Date();
//     expirationDate.setDate(expirationDate.getDate() + 30);
//     document.cookie = `acceptedCookies=true; expires=${expirationDate.toUTCString()}; path=/`;
//     this.setState({ visible: false });
//   };
  return (
    <Modal
    //   title="Cookie Policy"
      open={true}
      onOk={onAccept}
      okText="Accept"
      okButtonProps={{className:'ant-btn'}}
      onCancel={onDecline}
      cancelText="Decline"
      cancelButtonProps={{className:'ant-btn'}}
      className='custom-modal'  
    >
        <p>cookie policy</p>
        <h5>We use cookies to give you the best possible experience on our website.</h5>
      {/* <p>Our website uses cookies to improve your experience.</p> */}
      <p>When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.</p>
    </Modal>
  );
};

export default CookieModal;
// import React, { useState } from 'react';

// const CookiePopup = ({ onAccept }) => {
//   const [isVisible, setIsVisible] = useState(true);

//   return (
//     <div className={`cookie-popup ${isVisible ? 'visible' : 'hidden'}`}>
//       <p>Our website uses cookies to improve your experience.</p>
//       <button onClick={onAccept}>Accept</button>
//       <button onClick={() => setIsVisible(false)}>Decline</button>
//     </div>
//   );
// };

// export default CookiePopup;

