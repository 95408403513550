import React, { Component } from 'react'
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaQuoteButton from "../component/GetaQuoteButton";
import GetaquotePopup from "../component/header/getaquotePopup";
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const CoursierDelivrable = React.lazy(() => import("./CoursierDeliverables"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));


// const SocialShare =[
//     {Social:<FaGlobe />,link:' https://annachi.fr/ '}
// ]
const deliverables = [
    { item_bg: 'web.png', item_icon: 'influencer.png', h3: 'Social Sync ', h4: 'Web' },
    { item_bg: 'web.png', item_icon: 'company.png', h3: 'Company ', h4: 'Web' },
    { item_bg: 'web.png', item_icon: 'influencer_admin.png', h3: 'Admin ', h4: 'Web' },
]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]
const technologies = [

    { h5: '', p: '', img: '' },
    { h5: 'Angular', p: 'Frontend Development', img: 'angular.png' },
    { h5: 'Python', p: 'Backend Development', img: 'python.png' },
    { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },

]

const influencerButtonNames = ['Home Page', 'Campaigns', 'Campaigns Detail', 'Basic Info', 'Career'];
const influencerImageAddress = [
    '/assets/images/portfolio/social-sync/inful/home page.webp',
    '/assets/images/portfolio/social-sync/inful/campaingns.webp',
    '/assets/images/portfolio/social-sync/inful/campaign-details.webp',
    '/assets/images/portfolio/social-sync/inful/influenser profile.webp',
    '/assets/images/portfolio/social-sync/inful/career.webp',
];

const companyButtonNames = ['Home Page', 'Profile','Creator', 'Contact Us', ];
const companyImageAddress = [
    '/assets/images/portfolio/social-sync/company/home_page.webp',
    '/assets/images/portfolio/social-sync/company/profile.webp',
    '/assets/images/portfolio/social-sync/company/creator.webp',
    '/assets/images/portfolio/social-sync/company/contact us.webp',
];

const adminButtonNames = [ 'Dashboard', 'List Of Creator', 'List of Company', 'List Of Categories', 'List Of Campaigns'];
const adminImageAddress = [
    '/assets/images/portfolio/social-sync/admin/dashboard.webp',
    '/assets/images/portfolio/social-sync/admin/creator.webp',
    '/assets/images/portfolio/social-sync/admin/company.webp',
    '/assets/images/portfolio/social-sync/admin/category.webp',
    '/assets/images/portfolio/social-sync/admin/campaigns.webp',
];

export default class SocialSync extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Influencer Marketplace' pageUrl='/influencer-marketplace' metaTitle="Influencer Marketplace" metaImage="/assets/images/portfolio/dp-portfolio-51.webp"
                    metaDescription="this is a platform for the healthcare industry to build strong professional networks between Administrators, Doctors, Advanced Nurse Practitioners, Clinical pharmacists, Healthcare Assistants and Admin Staff." />
                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Influencer Marketplace"
                    customColor=""
                    dataBlackOverlay={3}
                    bgImage="bg_image--136"
                    p="Web Application"
                    openPopup={true}
                    buttonTitle={'get a quote'}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-social-color">Influencer Marketplace</h3>
                                        <p className="justifypara">This is a marketplace for influencers of social media where admin will publish campaigns for companies after getting the request from the companies. Influencers will apply for these campaigns and they will get paid for the same. Overall, this marketplace platform facilitates a seamless connection between influencers and companies, allowing for efficient campaign management and collaboration.</p>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-social'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--3 ptb--80" style={{ backgroundColor: '#774eb3' }} data-black-overlay='3'>
                    <div className="container" >
                        <React.Suspense fallback={<div>loading...</div>} >
                            <CoursierDelivrable title={'3 Deliverables'} items={deliverables} class={'social-sync'} value={[3, 3, 3]} />
                        </React.Suspense>
                    </div>
                </div>
                <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-social'} paddingTop={'pt--30'} />
                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-social-color' items={technologies} />
                        </React.Suspense>
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-social'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Influencer Panel'
                                    p='Influencers can register, create profiles and apply for various marketing campaigns posted by companies. They can view campaign details, such as the title, qualification requirements and price per post, and submit their applications accordingly. Influencers can also showcase their packages and pricing.'
                                    buttonNames={influencerButtonNames}
                                    image={influencerImageAddress}
                                    alt='influncer'
                                    color='social-sync-system'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-social'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Company Panel'
                                    p='Companies can register on the platform, create profiles and add campaign requests for influencers to apply. They can provide details such as the campaign title, additional photos, qualification requirements, number of influencers, posts required and objectives. Companies can view the list of influencers who have applied for their campaigns.'
                                    buttonNames={companyButtonNames}
                                    image={companyImageAddress}
                                    alt='company'
                                    color='social-sync-system'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-social'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p={`The admin has access to the platform's backend and can log in using their credentials. They can manage both influencers and companies, including actions such as blocking/unblocking or deleting users. The admin can verify influencers, review campaign requests from companies and publish campaigns that align with the platform's guidelines.`}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='influncer admin'
                                    color='social-sync-system'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-social'} paddingTop={'pt--30'} paddingBottom={`pb--30`} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-social-color' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-social' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
