import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
//  import CoursierDelivrable from "./CoursierDeliverables";
// import PlatformUsed from "../component/Verbos/PlatformUsed";
// import VerbosSimilarApps from "../component/Verbos/VerbosSimilarApps";
// import OnlinePaymentAppDemo from "./OnlinePaymentAppDemo";
// import OnlinePaymentAdminDemo from "./OnlinePaymentAdminDemo";
import PlatformUsed from "../component/Verbos/PlatformUsed";
import VerbosSimilarApps from "../component/Verbos/VerbosSimilarApps";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaQuoteButton from "../component/GetaQuoteButton";
import GetaquotePopup from "../component/header/getaquotePopup";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
// const ScholarshipAppDemo = React.lazy(() => import("./ScholarshipAppDemo"));
// const ScholarshipAdminDemo = React.lazy(() => import("./ScholarshipAdminDemo"));
// const VerbosSimilarApps = React.lazy(()=>import ("../component/Verbos/VerbosSimilarApps"));
// const PortfolioList = [
//     {
//         image: 'online-payment-1',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'online-payment-2',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'online-payment-3',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'online-payment-4',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },

// ]
// const deliverables = [
//     { item_bg: 'android.png', item_icon: 'users.png', h3: 'User App', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'users.png', h3: 'User App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'users.png', h3: 'User', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
//     { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
//     { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Delivery App ', h4: 'Android' },
//     { item_bg: 'ios.png', item_icon: 'delivery.png', h3: 'Delivery App ', h4: 'iOS' },
//     { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
// ]
const technologies = [

    { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
    { h5: '.Net', p: 'Backend Development', img: 'dotnet.png' },
    // { h5: 'Laravel', p: 'Backend Development', img: 'laravel.png' },
    { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },

]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/tourism-company-management-system', img: 'umrah.webp', h2: 'Umrah Plus', p: 'Tourism Compony' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
    // { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.png', h2: 'Biblo', p: 'Nursing Platform' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

// const SocialShare = [
//     { Social: <FaGlobe />, link: '  https://www.sesapay.sn/ ' },
//     { Social: <FaGooglePlay />, link: 'https://play.google.com/store/apps/details?id=com.app.sesapay' },
//     { Social: <FaApple />, link: 'https://apps.apple.com/in/app/sesapay/id1494695602' },
// ]

const userAppButtonNames = ['Pesa Pay App', 'Registration', 'Login', 'Dashboard', 'Profile', 'Side Menu', 'Secret Code', 'Notification', 'Transaction History'];
const userAppImageAddress = [
    '/assets/images/portfolio/scholarship/sesapay-1.webp',
    '/assets/images/portfolio/scholarship/sesapay-2.webp',
    '/assets/images/portfolio/scholarship/sesapay-3.webp',
    '/assets/images/portfolio/scholarship/sesapay-4.webp',
    '/assets/images/portfolio/scholarship/sesapay-5.webp',
    '/assets/images/portfolio/scholarship/sesapay-6.webp',
    '/assets/images/portfolio/scholarship/sesapay-7.webp',
    '/assets/images/portfolio/scholarship/sesapay-8.webp',
    '/assets/images/portfolio/scholarship/sesapay-9.webp',
];
const adminButtonNames = ['Login', 'Dashboard', 'Manage Platform Services', 'Wallet Transaction', 'Manage Privilege'];
const adminImageAddress = [
    '/assets/images/portfolio/scholarship/sesapay-admin-1.webp',
    '/assets/images/portfolio/scholarship/sesapay-admin-2.webp',
    '/assets/images/portfolio/scholarship/sesapay-admin-3.webp',
    '/assets/images/portfolio/scholarship/sesapay-admin-4.webp',
    '/assets/images/portfolio/scholarship/sesapay-admin-5.webp',
];
class OnlinePaymentPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Online Payment Application' pageUrl='/online-payment-application' metaTitle="Online Payment Application" metaImage="/assets/images/portfolio/dp-portfolio-30.webp"
                    metaDescription="Easily manage your money with our online payment application. Withdraw money and position it in your wallet, pay for registration fees, make merchant payments online or in-store, and buy phone credit from all operators." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Online Payment Application"
                    customColor="custom-scholarship-color"
                    dataBlackOverlay={7}
                    bgImage="bg_image--69"
                    p="Web / Android / iOS Application"
                    pb="pb--120"
                />


                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">

                                        <h3 className="rn-page-title custom-scholarship-color">Online Payment Application</h3>
                                        <p className="justifypara">Online payment application is a free mobile financial services platform that allows students and professionals to hold and manage an electronic account, make real-time fund transfers, process any type of payment, access a large network of partners and participate in our loyalty program.</p>
                                        <p className="justifypara">This electronic money issuance activity promotes banking and financial inclusion, through access to financial services for all socio-professional categories.</p>
                                        <p className="justifypara">Scholarship Distribution System offers quality services allowing its customers to carry out secure, simple and fast transactions.</p>
                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck color={'#ffd939'} />Easy registration for customer</li>
                                            <li><FiCheck color={'#ffd939'} />Attractive dashboard</li>
                                            <li><FiCheck color={'#ffd939'} />Secure online payment</li>
                                            <li><FiCheck color={'#ffd939'} />Transer money</li>
                                            <li><FiCheck color={'#ffd939'} />Registration fee</li>
                                            <li><FiCheck color={'#ffd939'} />Withdrawal money</li>
                                            <li><FiCheck color={'#ffd939'} />Receive money</li>
                                            <li><FiCheck color={'#ffd939'} />Payment at restaurents</li>
                                            <li><FiCheck color={'#ffd939'} />Merchant payment</li>
                                            <li><FiCheck color={'#ffd939'} />Accounts and wallet</li>
                                            <li><FiCheck color={'#ffd939'} />Alerts and notifications</li>
                                            <li><FiCheck color={'#ffd939'} />Fully responsive design</li>
                                            <li><FiCheck color={'#ffd939'} />And many more….</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-sesapay'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30">
                    <div className="container">
                    </div>
                    <div className="ptb--40">
                        <div className="container">
                            <PlatformUsed color='custom-scholarship-color' items={technologies} />
                            <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-sesapay'} paddingTop={'pt--30'} />
                        </div>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--1 ptb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='This app allows users to hold and manage an electronic account, make real-time transfers, make payments, receive scholarships from the government and participate in a loyalty program.'
                                    buttonNames={userAppButtonNames}
                                    image={userAppImageAddress}
                                    alt='scholarship user'
                                    color='sesa-pay'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-sesapay'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='The admin is responsible for managing and maintaining the website. They have access to the backend of the platform and can manage user accounts, appointments, pharmacy listings, and other aspects of the website.'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='scholarship admin'
                                    color='sesa-pay'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-sesapay'} paddingTop={'pt--30'} paddingBottom={`pb--30`} />
                    <React.Suspense fallback={<div>loading...</div>} >
                        <VerbosSimilarApps color='custom-scholarship-color' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} />
                    </React.Suspense>
                    <CommonPortfolioButtons class='btn-sesapay' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default OnlinePaymentPortfolioDetails;
