import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";
import { FiUsers, FiTruck, FiShoppingCart, FiFlag } from "react-icons/fi";
import { Link } from 'react-router-dom';
import { FaWallet, FaLaptopMedical } from "react-icons/fa";
import Slider from "react-slick";
import { portfolioSlick2 } from "../page-demo/script";
import CommonSliderTransitionBanner from "../component/common/CommonSliderTransitionBanner";
const CaseStudy = React.lazy(() => import("../casestudy/casestudt"));
const IndustriesClient = React.lazy(() => import("../creative_homePage/CreativeBrand"));

const PortfolioList = [
    {
        image: 'image-1',
        category: 'Web Applications',
        title: 'Tourism Company Management System',
        url: '/tourism-company-management-system'
    },
    {
        image: 'image-2',
        category: 'Mobile Applications',
        title: 'Scholarship Distribution with KYC Verification',
        url: '/scholarship-distribution-system'
    },
    {
        image: 'image-3',
        category: 'Web and Mobile Applications',
        title: 'Online Grocery Ordering System',
        url: '/online-groceries-ordering-system'
    },
    {
        image: 'image-4',
        category: 'Web and Mobile Applications',
        title: 'Courier And Transport Management',
        url: '/courier-and-transport-management'
    },
    {
        image: 'image-5',
        category: 'Web and Mobile Applications',
        title: 'Hospital Chain Management System (Secondary Care)',
        url: '/hospital-chain-management-system'
    },
    {
        image: 'image-6',
        category: 'Web and Mobile Applications',
        title: 'Healthcare Insurance Marketplace',
        url: '/healthcare-insurance-marketplace'
    },
    {
        image: 'image-7',
        category: 'Web and Mobile Applications',
        title: 'Hotel Booking Platform',
        url: '/hotel-booking-platform'
    },
]

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        bgImage: 'bg_image--24',
        title: 'Industries',
        description: 'Shaping the future in every walk of human life.',
        description2: 'Navigate our exposure across a wide range of industries that demand highly skilled individuals, a legacy we proudly carry forward.',
        buttonText: 'Contact Us',
        buttonLink: '/contact-us'
    }
]

const ServiceList = [
    {
        icon: <FaLaptopMedical />,
        title: 'Health Care',
        // description: 'Amidst a difficult period of covid-19 Noorisys has contributed towards a  safer world by introducing a covid-19 antigen test verification platform with over millions of users.',
        description: 'In response to the global health crisis, Noorisys has developed innovative solutions to combat COVID-19. Our antigen test verification platform has revolutionized safety protocols, serving millions in the UK.',
        url: '/health-care'
    },
    {
        icon: <FiTruck />,
        title: 'Logistics',
        // description: 'We are experts in supply chain management. Track your parcels, update to your customers, and win the hearts of millions with timely tracking, reporting and delivery status updates.',
        description: 'Our software solutions empower businesses to manage their logistics and supply chains efficiently, ensuring real-time reporting, seamless delivery management and customer satisfaction at every step.',
        url: '/logistics'
    },
    {
        icon: <FiShoppingCart />,
        title: 'eCommerce',
        // description: 'Stand out in the highly competitive market of e-commerce with our customized software with rich interface. We help you to increase your revenue and skyrocket your business.',
        description: `In today's competitive online marketplace, we help businesses stand out with captivating web design and user-friendly e-commerce platforms that convert visitors into loyal customers and upscale your revenue.`,
        url: '/ecommerce'
    },
    {
        icon: <FaWallet />,
        title: 'Fintech',
        // description: 'Thinking of secure payment transactions? Worried about payment integration for your business? Do you want to distribute money securely for Scholarships? We have done that all successfully.',
        description: 'Seeking secure payment transactions or seamless integration for your business? We develop secure and reliable fintech solutions, including payment processing platforms and custom software for financial institutions.',
        url: '/fintech'
    },
    {
        icon: <FiFlag />,
        title: 'Tourism',
        // description: 'Our innovative travel technology enhance your customer experience by providing perfect solutions. Building Hotel Booking Platform, setting up an OTA to integrating a GDS system we have gracefully done it all.',
        description: 'We create innovative travel technology solutions that enhance customer experiences. From hotel booking systems to online travel agencies, we elevate customer satisfaction and operational efficiency.',
        url: '/tourism'
    },
    {
        icon: <FiUsers />,
        title: 'Support Services',
        // description: 'We are offering customer support services to medical, health care, aviation, travel & tourism, banks and financial institutions. Accelerate your business with our customer support services.',
        description: 'Our expertise extends beyond software development. We offer customer support services across various sectors like healthcare, aviation, tourism, banking. Transform your customer experience with our reliable team.',
        url: '/support-services'
    }
]
const caseStudies = [
    {
        title: 'Spearheading Digital Transformation for Government Documentation in Africa',
        introduction: 'In the digital era, one African nation has made a breakthrough by anchoring its modernization agenda on a revolutionary digital platform. This tackles the problem of aging identification cards such as birth certificates and death records plus historical data. By offering a secure and user-friendly way to verify and digitize, it ensures that these critical documents are available in modern format.',
        iTitle: 'A Pivotal Force in Modernization:',
        objectives: [
            {
                title: 'The Power of Digital Verification and Archiving:',
                p: 'The platform utilizes cutting-edge technology to confirm the authenticity of old identification documents. This meticulous verification process guarantees the accuracy and reliability of the digital transformation, safeguarding the integrity of vital information. Additionally, the platform goes beyond mere digitization. It offers a secure digital archive for these historical documents, safeguarding them for future generations and guaranteeing the longevity of this invaluable information.',
            },
            {
                title: 'The Power behind Digital Verification and Archiving:',
                p: 'Through advanced technology, this platform authenticates old identification documents. The integrity of vital information is ensured through this advanced verification process hence guarantees the accuracy and reliability of the digital changeover. Additionally, beyond just turning them into soft copies, this platform serves as a secure digital repository for historical records thereby preserving invaluable information for coming years.',
            }
        ],
        conclusion: [
            {
                title: 'Enhanced Efficiency through Streamlined Processes :',
                p: 'This innovative platform has greatly influenced government efficiency by making it easier to verify important documents via online procedures thus accelerating their digitization. It eliminates the burden of excessive paperwork, reduces processing times, and enhances accessibility for both government officials and citizens.'
            },
            {
                title: 'Empowering Citizens:',
                p: `The platform's impact extends far beyond government operations. It directly benefits citizens as they can easily access their personal records proficiently. This not only simplifies daily tasks but also expedites verification processes, and ultimately reduces bureaucratic hurdles and frustrations.`
            },
            {
                title: 'A Vision for a Digital Future',
                p: `This is a standout example of digital transformation at its finest. It’s part of modernizing the process of government documentation because it offers a secure and efficient way to verify and digitize historical records. Accordingly, this will allow for more digital inclusion in future as people interact securely and efficiently with governments online.`
            },
        ],
        image: "/assets/images/about/nekkal.webp",
        alt: 'nekkal'
    },
    {
        title: 'Transforming Healthcare Through Integrated Online Services	',
        introduction: 'There is now a brand new approach to how individuals meet their healthcare needs through an innovative online healthcare platform. A wide variety of health care services have been brought together into one system by this platform acting as a single integrated stop shop for all your health care needs.',
        iTitle: 'Revolutionizing the Healthcare Experience:',
        objectives: [
            {
                title: 'Convenience at Your Fingertips:',
                p: 'An online portal that makes it possible for you to access medication without visiting the pharmacy. A person can simply make an order online and have it delivered to their doorstep. Additionally, users can send their tests through the platform for analysis. These tests are then analyzed and accurate results are reported back to the clients and makes diagnostics fast.',
            },
            {
                title: 'Booking Appointments Made Easy:',
                p: `Scheduling appointments with General Practitioners (GPs) is no longer a hassle. The platform's online appointment system allows users to swiftly book appointments with healthcare professionals, ensuring timely access to essential medical consultations.`,
            },
            {
                title: 'Simplified Appointment Scheduling:',
                p: `Booking appointments with General Practitioners (GPs) is no longer a problem. The platform has introduced an online appointment system that enables users to quickly schedule appointments with medical practitioners and thus ensuring prompt access to vital healthcare consultations.`,
            },
        ],
        conclusion: [
            {
                title: 'Designed for Everyone:',
                p: `The platform places ease of use as its top priority. An intuitive interface and simple navigation make this platform accessible by people of all ages and levels of tech knowledge, which allows anyone regardless of how good they are at technology to enjoy the advantages of this service.`
            },
            {
                title: 'Efficiency Through Integration:',
                p: `This is achieved through one easy-to-use platform that incorporates most services required in health care management. Through this process of integration, users can easily control their healthcare needs ranging from the purchase of medications to booking appointments and accessing test results – all in a single place. By doing so, time is saved and health care delivery is improved.`
            },
            {
                title: 'A Success Story in Action:',
                p: `Positive feedback from users has shown that the service is very effective. User are able to work through their medical issues using an online pharmacy as well as performing lab diagnostics and scheduling online appointments through one efficient method thus improving health outcomes and enhancing patient experience demonstrating the platforms ability to cater for different preferences by its clients.`
            },
            {
                title: 'The Future of Healthcare is Here:',
                p: `This online healthcare platform is a perfect example of innovation in the healthcare sector. Its commitment to user-centricity with different services offered are key determinants to better healthcare for everyone.`
            },
        ],
        image: "/assets/images/about/welzo.webp",
        alt: 'welzo'
    },
    {
        title: 'Digital Receipts - Goodbye Paper, Hello Efficiency!',
        introduction: `Think of a world where receipts are not written on paper. Imagine an effortless and eco-friendly way of managing your purchase. That is the reality that can be achieved from a cutting edge paperless printer for receipts. This revolutionary device allows users to get digital receipts with just a simple tap of their mobile phones, completely changing the way we take care of our purchases. `,
        iTitle: '',
        objectives: [
            {
                title: 'Seamless Transactions, Instant Results:',
                p: `This paperless receipt generator was designed to facilitate the user experience as much as possible. Once its indicator lights comes to life, all you need to do is place your smartphone onto it and then there will be an instant transfer of digital receipt. Forget about waiting for papers to print out, forget about struggling with faded inks; simply give it one touch and your receipt will be safely stored in your phone.`,
            },
            {
                title: 'Beyond In-Store Transactions: A Digital Receipt Ecosystem:',
                p: `Brick-and-mortar stores are not the only ones that have reaped benefits from this paperless method. These include participating e-commerce shops, train station kiosks, self-service checkouts at retail outlets as well as restaurants and even bars. With a unique in-app ID number, users can effortlessly collect digital receipts from these outlets making it a central point for all their buying details.`,
            },
        ],
        conclusion: [
            {
                title: 'Unrivalled Adaptability: Receipts Management Made Easy :',
                p: `In fact this paperless receipt generator is all about convenience; it gives the users the personal control to manage their receipts effortlessly. Just picture that you could find your purchase in seconds by date, category or store. Say goodbye to rummaging around heaps of paper! The system also enables users to send receipts with colleagues for expense reports, mark favorite purchases for future reference and initiate refund requests at a touch of a button. Also those who may still need hard copies can print out digital receipts.`
            },
            {
                title: 'A Pioneering Partnership: Driving Innovation in Tandem:',
                p: `The immense pride that we have as an exclusive partner to this game changing paperless receipt generator cannot be measured. It emphasizes our commitment in coming up with up-to-date solutions that improves customer interactions. However, because of this exclusivity we cannot reproduce such an app for other entities but we are looking forward to potential collaborations that will assist us embed this technology in your organization so as to create more seamless and environmentally friendly shopping experiences for your buyers.`
            },
            {
                title: 'Here Comes the Future of Receipts :',
                p: `This paperless receipt generator is a significant step in the evolution of our purchasing processes. Through this, we are able to get rid of the environmental damage caused by all those paper receipts while making sure that our users enjoy unparalleled convenience and control. It demonstrates our commitment to moving forward and establishes an example for other companies in developing more sustainable and effective ways for using receipts in future.`
            },
        ],
        image: "/assets/images/about/digital-receipt.webp",
        alt: 'noumi'
    }
]
class Industries extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    render() {
        let title = 'Work Samples',
            description = 'Noorisys has touched every walk of life from Healthcare to eCommerce, Fintech, Tourism, Logistics, ERP systems, Research and Education, Construction and Real Estate. We are coding the cores of the lives.';
        return (
            <Fragment>
                <Helmet pageTitle="Explore the diverse industries we serve" pageUrl='/industries' metaTitle="Shaping the future in a wide range of industries" metaImage="/assets/images/bg/bg-image-24.webp"
                    metaDescription="Learn about the industries we cater to. Explore our expertise in delivering innovative solutions customized to meet the unique needs of different sectors." />

                {/* Start Header Area  */}
                <Header />
                {/* End Header Area  */}
                {/* Start Slider Area   */}
                {/* <div className="slider-wrapper">
                    Start Single Slide
                    {SlideList.map((value, index) => (
                        <div className="slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--24" key={index} data-black-overlay="6">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.description2 ? <p className="description">{value.description2}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    End Single Slide
                </div> */}
                <CommonSliderTransitionBanner slideList={SlideList} titleClass={'theme-gradient'} dataBlackOverlay={6} />
                {/* End Slider Area   */}

                <div className="service-area mt--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <p>
                                        Every industry has its own specific challenges and opportunities. Our experienced team takes the time to understand your business goals and the unique digital landscape you operate in. This allows us to tailor software development solutions that speak directly to your target audience and drive measurable results.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Service Area */}
                <div className="service-area creative-service-wrapper pt--20 pb--30 bg_color--1">
                    <div className="container">
                        <div className="row creative-service">
                            {ServiceList.map((val, i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <Link to={val.url}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p >{val.description}</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}

                {/* Start About Area */}
                <div className="bg_color--3 ptb--30">
                    <div className="container">
                        <div className="title">
                            <h2 className="text-center">Case Study</h2>
                        </div>
                    </div>
                    {caseStudies.map((data, index) =>
                        <React.Suspense fallback={<div>loading...</div>} >
                            <CaseStudy tabStyle="tab-style--1" title={data.title}
                                introduction={data.introduction}
                                iTitle={data.iTitle}
                                objectives={data.objectives}
                                conclusion={data.conclusion}
                                index={index}
                                key={index}
                                image={data.image}
                                alt={data.alt}
                            />
                        </React.Suspense>
                    )}
                </div>

                {/* End About Area */}

                {/* Start portfolio Area  */}


                <div className="portfolio-area ptb--60 bg_color--5">
                    <div className="portfolio-sacousel-inner mb--55">
                        <div className="portfolio-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="section-title text-center">
                                            <h2>{title}</h2>
                                            <p>{description}</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="portfolio-slick-activation mt--70 mt_sm--40">
                                <Slider {...portfolioSlick2}>
                                    {PortfolioList.map((value, index) => (
                                        <div className="portfolio" key={index}>
                                            <div className="thumbnail-inner">
                                                <div className={`thumbnail ${value.image}`}></div>
                                                <div className={`bg-blr-image ${value.image}`}></div>
                                            </div>
                                            <div className="content">
                                                <div className="inner">
                                                    <p>{value.category}</p>
                                                    <h4 className="title"><Link to={value.url}>{value.title}</Link></h4>
                                                    <div className="portfolio-button">
                                                        <Link className="rn-btn" to={value.url}>Case Study</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <Link className="link-overlay" to={value.url}></Link>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>

                {/* End portfolio Area  */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--1 ptb--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <React.Suspense fallback={<div>loading...</div>} >
                                    <IndustriesClient />
                                </React.Suspense>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Footer Area  */}
                <Footer />
                {/* End Footer Area  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default Industries;