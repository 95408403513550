import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import { FaGlobe } from "react-icons/fa";
import ProductCommonApp from "../portfolio/ProductCommonAppdemo";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
const CommonProductWeb = React.lazy(() => import("../portfolio/CommonProductWeb"));
const CommonPortfolioButtons = React.lazy(() => import("../component/CommonPortfolioButton"));
const CoursierDelivrable = React.lazy(() => import("../portfolio/CoursierDeliverables"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const deliverables = [
    { item_bg: 'android.png', item_icon: 'user-tourism.png', h3: 'User App', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'user-tourism.png', h3: 'User App', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'user-tourism.png', h3: 'User', h4: 'Web' },
    { item_bg: 'web.png', item_icon: 'admin-tourism.png', h3: 'Admin ', h4: 'Web' },
]
const technologies = [

    { h5: 'Flutter', p: 'Android & iOS development', img: 'flutter.png' },
    { h5: 'PHP', p: 'Backend Development', img: 'php.png' },
    { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },

]
// const similarapps = [
//     { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #ffd939 100%)', link: '/scholarship-distribution-system', img: 'pesapay.webp', h2: 'Pesapay', p: 'Scholarship Distribution with KYC' },
//     { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
//     { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4AB610 0%,#83b735 100%)', link: '/online-groceries-ordering-system', img: 'annachi.webp', h2: 'FreshCart', p: 'Online Shop' },
//     { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/courier-and-transport-management', img: 'gonagoo.webp', h2: 'ServicePro', p: 'Courier and Trasport Management' },
//     { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect ', p: 'Pharmacist Marketplace' },
// ]
const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    // { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/food-delivery', img: 'food_delivery.webp', h2: `Food Delivery<br />App `, p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
]

const SocialShare = [
    { Social: <FaGlobe />, link: ' https://umrahplus.app/package-list ' }
];

const userAppButtonNames = ['Splash Screen', 'Login', 'Dashboard', 'Ziyarat Packages', 'Package Details', 'Meals', 'Meal Details', 'Transport'];
const userAppImageAddress = [
    '/assets/images/portfolio/tourism/umrah-1.webp',
    '/assets/images/portfolio/tourism/umrah-3.webp',
    '/assets/images/portfolio/tourism/umrah-4.webp',
    '/assets/images/portfolio/tourism/umrah-5.webp',
    '/assets/images/portfolio/tourism/umrah-6.webp',
    '/assets/images/portfolio/tourism/umrah-7.webp',
    '/assets/images/portfolio/tourism/umrah-8.webp',
    '/assets/images/portfolio/tourism/umrah-2.webp',
];

const userPanelButtonNames = ['Login', 'Umrah Packages', 'Package Details', 'Package Bookings', 'Booking Details'];
const userPanelImageAddress = [
    '/assets/images/portfolio/tourism/umrah-login.webp',
    '/assets/images/portfolio/tourism/umrah-web-1.webp',
    '/assets/images/portfolio/tourism/umrah-web-2.webp',
    '/assets/images/portfolio/tourism/umrah-web-3.webp',
    '/assets/images/portfolio/tourism/umrah-web-4.webp',
    '/assets/images/portfolio/tourism/umrah-web-5.webp',
    '/assets/images/portfolio/tourism/umrah-web-6.webp',
    '/assets/images/portfolio/tourism/umrah-web-7.webp',
    '/assets/images/portfolio/tourism/umrah-web-8.webp',
    '/assets/images/portfolio/tourism/umrah-web-9.webp',
    '/assets/images/portfolio/tourism/umrah-web-10.webp',
    '/assets/images/portfolio/tourism/umrah-web-11.webp',
    '/assets/images/portfolio/tourism/umrah-web-12.webp',
];

const adminButtonNames = ['Login', 'Manage Provider', 'My Bookings', 'Manage Packages', 'Manage Commision', 'Add Providers'];
const adminImageAddress = [
    '/assets/images/portfolio/tourism/umrah-login.webp',
    '/assets/images/portfolio/tourism/umrah-admin-1.webp',
    '/assets/images/portfolio/tourism/umrah-admin-2.webp',
    '/assets/images/portfolio/tourism/umrah-admin-3.webp',
    '/assets/images/portfolio/tourism/umrah-admin-4.webp',
    '/assets/images/portfolio/tourism/umrah-admin-5.webp',
];
class TourismPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isVisible: false
        }
        this.openModal = this.openModal.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Umrah Plus || Noorisys Technologies Pvt Ltd' pageUrl='/tourism-company-management-system' metaTitle="Umrah Plus" metaImage="/assets/images/portfolio/dp-portfolio-01.jpg"
                    metaDescription="Umrah Plus is a platform to book tour packages, meals, local transportation and guides for umrah pilgrims in Makkah and Madinah. Contact us to make your own tourism management system." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title='Umrah Plus'
                    dataBlackOverlay={7} bgImage='bg_image--86'
                    customColor='custom-tourism-color'
                    p='Web / Android / iOS Application'
                    socialShare={SocialShare}
                    pb={'pb--120'}
                    buttonTitle={'get a quote'}
                    openPopup={true}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h3 className="rn-page-title custom-tourism-color">Umrah Plus</h3>
                                        <p className="justifypara">Umrah Plus is a platform to book tour packages, meals, local transportation and guides for umrah pilgrims in Makkah and Madinah.</p>
                                        <h4 className="title">Package Booking</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Affordable Ziyarat & sightseeing packages.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Easy to schedule & flexible in time.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Explore the undiscovered places.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Compare & choose the best package.</li>
                                        </ul>
                                        <h4 className="title">Book Meals</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Pickup from your favourite restaurants.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Choose your favourite cuisine.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Tiffin services for small groups.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Mess services for large groups.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Food delivery option available.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Assured quality and delicious foods.</li>
                                        </ul>
                                        <h4 className="title">Transportation</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Call the cab to your residence.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Book a vehicle at any time as per your requirement.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />From 4 seater to 40 seater, all available for you.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Hustle-free freight and passenger movement services</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Travel with comfort, safety, and security with a reliable service provider.</li>
                                        </ul>
                                        <h4 className="title">Guides</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Multilingual, speaks your language.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Well-versed in local and regional history.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Trusted and experienced professional guides.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Profile and background verified by our team.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Get the chance to peep into the past with your guide.</li>
                                        </ul>
                                        <h4 className="title">App Features</h4>
                                        <ul className="list-style--1">
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Most trusted and reliable service partners.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Easy to register and use.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Suitable for all age groups.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Multi-lingual app.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Locate nearby holy places.</li>
                                            <li className="justifypara"><FiCheck color={'#00aeff'} />Available on Play Store and App Store.</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <div className="d-sm-block d-flex justify-content-center align-items-center pt--30">
                                            <button className="get-a-quote btn-umrah text-center" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--60"
                    style={{ backgroundColor: '#00aecf' }}
                >
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <CoursierDelivrable title={'4 Deliverables'} items={deliverables} class={'tourism'} value={[4, 4, 4]} />
                        </React.Suspense>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center pt--30">
                    <button className="get-a-quote btn-umrah text-center" onClick={this.showPopup}>
                        <span>Get a quote </span>
                    </button>
                </div>
                <div className="pt--60">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-tourism-color' items={technologies} />
                        </React.Suspense>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-umrah text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--1 ptb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='With Umrah Plus, users can easily book tour packages, meals, local transportation, and guides to make their trip smooth and hassle free. The platform offers affordable Ziyarat and sightseeing packages where users can choose from variety of meals and cuisines. Umrah Plus also provides transportation services ranging from cabs to large vehicles.'
                                    buttonNames={userAppButtonNames}
                                    image={userAppImageAddress}
                                    alt='umrah user app'
                                    color='umrah'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-umrah text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='User Panel'
                                    p='With Umrah Plus, users can easily book tour packages, meals, local transportation, and guides to make their trip smooth and hassle free. The platform offers affordable Ziyarat and sightseeing packages where users can choose from variety of meals and cuisines. Umrah Plus also provides transportation services ranging from cabs to large vehicles.'
                                    buttonNames={userPanelButtonNames}
                                    image={userPanelImageAddress}
                                    alt='umrah user panel'
                                    color='umrah'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-umrah text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='The admin is responsible for managing and maintaining the website. They have access to the backend of the platform and can manage providers, packages, commission and other aspects of the website.'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='umrah admin'
                                    color='umrah'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt--30">
                        <button className="get-a-quote btn-umrah text-center" onClick={this.showPopup}>
                            <span>Get a quote </span>
                        </button>
                    </div>
                </div>
                <React.Suspense fallback={<div>loading...</div>} >
                    <VerbosSimilarApps color='custom-coursier-color' title={'Other Apps'} items={similarapps} slidestoshow={[3, 3]} />
                </React.Suspense>
                <CommonPortfolioButtons class='btn-tourism' text='View All Our Products' link='/our-products' />
                {/* </div> */}
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default TourismPortfolioDetails;
