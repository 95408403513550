import React, { Component, Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiCheck } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";
import { Link } from "react-router-dom";
import CommonSliderTransitionBanner from "../component/common/CommonSliderTransitionBanner";
const WebDevelopmentService = React.lazy(() => import("./WebDevelopmentService"));
const WebsiteDevelopmentReview = React.lazy(() => import('./WebsiteDevelopmentReview'));
const WebsiteDevelopmentPortfolio = React.lazy(() => import('./WebsiteDevelopmentPortfolio'));
const WebsiteDevelopmentTab = React.lazy(() => import('./WebsiteDevelopmentTab'));
const Brand = React.lazy(() => import('../creative_homePage/CreativeBrand'));

const SlideList = [
    {
        textPosition: 'text-center',
        bgImage: 'bg_image--21',
        category: '',
        title: 'Website Development',
        description: 'Elevate Your Business with Our Custom Web Development Services That Deliver Outstanding Results and Drive Growth Effortlessly.',
        // description: 'Matching development needs of B2B or B2C businesses, we offer robust web development solutions that accelerate business profitability.',
        buttonText: 'Contact Us',
        buttonLink: '/contact-us'
    },
]

class WebsiteDevelopment extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    render() {
        let title = 'Success Stories',
            description = 'The tales of our success portrays splendid achievements and our global presence in the IT sector. Our success stories tell how we helped our clients prosper their businesses, ideas, startups and business expansion plan. We have worked with many challenging startup ideas and helped establish their footprints in the market by timely delivery and robust systems.';
        return (
            <Fragment>

                <Helmet pageTitle="Website Development  || Noorisys Technologies Pvt Ltd" pageUrl='/website-development' metaTitle=" Website Development" metaImage="/assets/images/bg/bg-image-21.webp"

                    metaDescription="Matching development needs of B2B or B2C businesses, we offer robust web development solutions that accelerate business profitability."
                    metaKeywords="website development software,software development website " />

                {/* Start Header Area  */}
                <Header logo="light" color="color-white" />
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                {/* <div className="slider-wrapper color-white">
                    <div className="slider-activation slider-digital-agency">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value, index) => (
                                <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title ">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>

                    </div>
                </div> */}
                <CommonSliderTransitionBanner slideList={SlideList} />
                {/* End Slider Area   */}

                {/* Start Brand Area */}
                <div className="rn-brand-area ptb--60 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 ptb--60">
                                <React.Suspense fallback={<div>loading...</div>} >
                                    <Brand />
                                </React.Suspense>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Pricing Tbale Area  */}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center">
                            <h3>Choose Your Website Development Options</h3>
                            <p>Choose the best development option for your business website!</p>
                        </div>
                    </div>
                </div>
                <div className="rn-pricing-table-area ptb--60 bg_color--1">
                    <div className="container">
                        <div className="row">

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="custom-rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Ready Scripts</h4>
                                            <p className="title">Save your time and money with our IT services.</p>

                                            <div className="pricing">
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Swift solutions, on-time delivery</li>
                                                <li><FiCheck /> Affordable ready-to-use scripts</li>
                                                <li><FiCheck /> Secure , robust and hassle-free</li>
                                                <li><FiCheck /> Efficient and easy integration</li>
                                                <li><FiCheck /> Time and money-saving option</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to={"/contact-us"}>Purchase Now</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="custom-rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Custom Development</h4>
                                            <p className="title">Secure websites with the trending technologies </p>
                                            <div className="pricing">
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck />Expertise in custom web solutions </li>
                                                <li><FiCheck />Versatile range of tech stack</li>
                                                <li><FiCheck />Precised, secure web development</li>
                                                <li><FiCheck />Stay updated with industry trends</li>
                                                <li><FiCheck /> Customer-focused web solutions</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to={"/contact-us"}>Get Quote</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="custom-rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">eCommerce</h4>
                                            <p className="title">Prosper your life with our eCommerce services </p>
                                            <div className="pricing">
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck />  Top-notch eCommerce services</li>
                                                <li><FiCheck /> WordPress, Shopify and more </li>
                                                <li><FiCheck /> Customized unique marketplace  </li>
                                                <li><FiCheck /> eCommerce with SEO potential</li>
                                                <li><FiCheck /> Expand eCommerce Opportunities</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <Link className="rn-btn" to={"/contact-us"}>Select</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                        </div>
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}





                {/* Start About Area */}
                <div id="about" className="fix">
                    <div className="about-area ptb--80  bg_color--5">
                        <div className="about-wrapper">
                            <div className="container">
                                <div className="row row--35 align-items-center">
                                    <div className="col-lg-5">
                                        <div className="thumbnail">
                                            <img className="w-100" src="/assets/images/about/about-7.webp" alt="About" loading="lazy" />
                                        </div>
                                    </div>
                                    <div className="col-lg-7">
                                        <div className="about-inner inner">
                                            <div className="section-title">
                                                <h2 className="title">{title}</h2>
                                                <p className="description justifypara">{description}</p>
                                            </div>
                                            <div className="row mt--30">
                                                <React.Suspense fallback={<div>loading...</div>} >
                                                    <WebsiteDevelopmentTab tabStyle="tab-style--1" />
                                                </React.Suspense>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* End About Area */}

                <div className="service-area ptb--60  bg_color--1">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <WebDevelopmentService />
                        </React.Suspense>
                    </div>
                </div>

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--30 bg_color--5">
                    <div className="portfolio-sacousel-inner mb--55">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <WebsiteDevelopmentPortfolio />
                        </React.Suspense>
                    </div>
                </div>
                {/* End Portfolio Area */}

                <div className="portfolio-area ptb--40 bg_color--5">
                    <div className="portfolio-sacousel-inner mb--55">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <WebsiteDevelopmentReview />
                        </React.Suspense>
                    </div>
                </div>


                {/* Start Footer Style  */}
                <Footer />
                {/* End Footer Style  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}

export default WebsiteDevelopment;