import React from "react"
import Slider from "react-slick"
import { slideSlick } from "../../page-demo/script"
import { Link } from "react-router-dom"
export default function CommonSliderTransitionBanner({ slideList, titleClass, dataBlackOverlay }) {
    return (
        <div className="slider-wrapper color-white">
            <div className="slider-activation slider-digital-agency">
                <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                    {slideList.map((value, index) => (
                        <div className={`slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay={dataBlackOverlay}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className={`title ${titleClass}`}>{value.title}</h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.description2 ? <p className="description">{value.description2}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>

            </div>
        </div>
    )
}