import React, { useEffect } from 'react';
import Header from './component/header/Header';
import Loading from './popup/Loading';

const RedirectComponent = () => {
    useEffect(() => {
        // Clear local storage and session storage
        localStorage.clear();
        sessionStorage.clear();

        // Unregister service workers
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then((registrations) => {
                for (let registration of registrations) {
                    registration.unregister();
                }
            }).then(() => {
                // Redirect to the new URL
                const redirectUrl = 'https://noorisys.com/top-mobile-app-development-company-india/';
                window.location.replace(redirectUrl);
            });
        } else {
            // If service workers are not supported, still redirect
            const redirectUrl = 'https://noorisys.com/top-mobile-app-development-company-india/';
            window.location.replace(redirectUrl);
        }
    }, []);

    return (
        <div>
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            <Loading />
            {/* You can optionally add a message or loading indicator here */}
        </div>
    );
};

export default RedirectComponent;
