import React, { Component } from 'react'
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
const CommonProductWeb = React.lazy(() => import("../portfolio/CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const ProductCommonApp = React.lazy(() => import("../portfolio/ProductCommonAppdemo"))

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'PHP', p: 'Admin Panel', img: 'php.png' },
    { h5: 'PHP', p: 'Backend Development', img: 'php.png' },
    { h5: 'PHP', p: 'User Panel', img: 'php.png' },
]

const adminButtonNames = ['Doctor List', 'Appointment', 'Schedule', 'Assigned Bed', 'Bad Status'];
const adminImageAddress = [
    '/assets/images/our-products/hospital/App/Doctor/doctor list.webp',
    '/assets/images/our-products/hospital/App/Doctor/appointment.webp',
    '/assets/images/our-products/hospital/App/Doctor/appointment.webp',
    '/assets/images/our-products/hospital/App/Doctor/assigned bed.webp',
    '/assets/images/our-products/hospital/App/Doctor/bed status.webp',
];

const subadminButtonNames = ['Dashboard', 'Appointment List','Edit Profile', 'Setting',];
const subadminImageAddress = [
    '/assets/images/our-products/hospital/Web/Hospital/hospital dashboard.webp',
    '/assets/images/our-products/hospital/Web/Hospital/appointment list.webp',
    '/assets/images/our-products/hospital/Web/Hospital/edit profile.webp',
    '/assets/images/our-products/hospital/Web/Hospital/setting.webp',
];

const userButtonNames = ['Dashboard', 'Appointment', 'Admission', 'Cases','Bills'];
const userImageAddress = [
    '/assets/images/our-products/hospital/Web/patient/dashboard.webp',
    '/assets/images/our-products/hospital/Web/patient/appointments.webp',
    '/assets/images/our-products/hospital/Web/patient/admission.webp',
    '/assets/images/our-products/hospital/Web/patient/cases.webp',
    '/assets/images/our-products/hospital/Web/patient/bills.webp',
];

const mobileDrButtonName = ['Sign In', 'Sign Up', 'Dashboard', 'Doctor List', 'Patient List', 'Patient Detail', 'Appointment', 'Cancel Appointment','Bad Status']
const mobileDrImageAddress = [
    '/assets/images/our-products/hospital/App/doctorapp/login page.webp',
    '/assets/images/our-products/hospital/App/doctorapp/registration.webp',
    '/assets/images/our-products/hospital/App/doctorapp/dashboard.webp',
    '/assets/images/our-products/hospital/App/doctorapp/doctors list.webp',
    '/assets/images/our-products/hospital/App/doctorapp/patient list.webp',
    '/assets/images/our-products/hospital/App/doctorapp/patient detail.webp',
    '/assets/images/our-products/hospital/App/doctorapp/all appointment.webp',
    '/assets/images/our-products/hospital/App/doctorapp/cancel appointments.webp',
    '/assets/images/our-products/hospital/App/doctorapp/bed status.webp',
]

const mobileHosButtonName = ['Dashboard', 'Doctor List', 'Patient List', 'Appointment','Bad Status']
const mobileHosImageAddress = [
    '/assets/images/our-products/hospital/App/Hospital/dashboard.webp',
    '/assets/images/our-products/hospital/App/Hospital/doctor list.webp',
    '/assets/images/our-products/hospital/App/Hospital/patients list.webp',
    '/assets/images/our-products/hospital/App/Hospital/appoinement.webp',
    '/assets/images/our-products/hospital/App/Hospital/bed status.webp',
]

const mobileButtonName = ['Document', 'Prescription Detail', 'Diagnosis Test Detail','Bills', 'Bill Details',  'Notice Board']
const mobileImageAddress = [
    '/assets/images/our-products/hospital/App/Patient/documents.webp',
    '/assets/images/our-products/hospital/App/Patient/prescription detail.webp',
    '/assets/images/our-products/hospital/App/Patient/diagnosis test detail.webp',
    '/assets/images/our-products/hospital/App/Patient/bills.webp',
    '/assets/images/our-products/hospital/App/Patient/bill details.webp',
    '/assets/images/our-products/hospital/App/Patient/notice board.webp',
]



export default class HospitalManagement extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isVisible: false
        }
        this.openModal = this.openModal.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Hospital Management System' pageUrl='/hospital-management-system' metaTitle="Patients can manage their profiles, schedule appointments, access live consultations, view prescriptions, and download diagnostic reports and invoices." />
                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Hospital Management System"
                    // customColor="custom-hospital-management"
                    customColor="theme-gradient"
                    dataBlackOverlay={5}
                    bgImage="bg_image--137"
                    p="Web, App"
                    buttonTitle={'get a quote'}
                    openPopup={true}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-hospital-management">Digital Business Card</h3>
                                        <p className="justifypara">The NFC Card project aims to develop a smart visiting card using Near Field Communication (NFC) technology. This card will allow users to easily share their contact details with others by tapping the card on a compatible mobile device. The primary functionalities include opening contact details on the recipient's mobile device and providing options to add the details to their contact list.</p>
                                        <p className="justifypara">Users can register and log in to the user panel, where they can create and manage various features for their NFC cards. The admin panel provides administrative control over user accounts and system settings.</p> */}
                                        <h3 className="custom-hospital-management">Patient App Functionalities </h3>
                                        <p className="justifypara">
                                        Patients can manage their profiles, schedule appointments, access live consultations, view prescriptions, and download diagnostic reports and invoices.
                                        </p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#fa4a05" />Profile Management</li>
                                            <li><FiCheck color="#fa4a05" />Book Appointments</li>
                                            <li><FiCheck color="#fa4a05" />Live Consultation</li>
                                            <li><FiCheck color="#fa4a05" />View Prescription</li>
                                            <li><FiCheck color="#fa4a05" />View And Download Diagnosis Report</li>
                                            <li><FiCheck color="#fa4a05" />View Invoices and Bills</li>
                                        </ul>
                                        <h3 className="custom-hospital-management">Doctor App Functionalities</h3>
                                        <p className='justifypara'>
                                        Doctors can manage their profiles, handle appointments and schedules, conduct live consultations, create prescriptions and diagnoses, manage medical reports and access their payroll data.
                                        </p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#fa4a05" />Profile Management</li>
                                            <li><FiCheck color="#fa4a05" />Appointments</li>
                                            <li><FiCheck color="#fa4a05" />Live Consultation</li>
                                            <li><FiCheck color="#fa4a05" />Manage Prescription</li>
                                            <li><FiCheck color="#fa4a05" />Manage Diagnosis</li>
                                            <li><FiCheck color="#fa4a05" />Manage Schedules</li>
                                            <li><FiCheck color="#fa4a05" />Manage Reports</li>
                                            <li><FiCheck color="#fa4a05" />Access Payroll Data</li>
                                        </ul>
                                        <h3 className="custom-hospital-management">Pharmacist App Functionalities
                                        </h3>
                                        <p className='justifypara'>
                                        Pharmacists can view prescriptions, manage medicines and their categories and handle medicine billing.
                                        </p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#fa4a05" />View Prescription</li>
                                            <li><FiCheck color="#fa4a05" />Manage Medicines</li>
                                            <li><FiCheck color="#fa4a05" />Manage Medicine Categories</li>
                                            <li><FiCheck color="#fa4a05" />Manage Medicine Bills</li>
                                        </ul>
                                        <h3 className="custom-hospital-management">Nurse App Functionalities
                                        </h3>
                                        <p className='justifypara'>
                                        Nurses can manage their profiles, view patient information, handle inpatient and outpatient details, and manage bed assignments.
                                        </p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#fa4a05" />Profile Management</li>
                                            <li><FiCheck color="#fa4a05" />View Patients</li>
                                            <li><FiCheck color="#fa4a05" />Manage IPD/OPD</li>
                                            <li><FiCheck color="#fa4a05" />Manage Beds</li>
                                            <li><FiCheck color="#fa4a05" />Access Payroll Data</li>
                                        </ul>
                                        <h3 className="custom-hospital-management">Case Manager Functionalities
                                        </h3>
                                        <p className='justifypara'>
                                        Case Managers can manage their profiles, handle patient cases, coordinate ambulance services, manage mail services and access payroll data.
                                        </p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#fa4a05" />Profile Management</li>
                                            <li><FiCheck color="#fa4a05" />Manage Patient Cases</li>
                                            <li><FiCheck color="#fa4a05" />Manage Ambulance</li>
                                            <li><FiCheck color="#fa4a05" />Manage Mail Service</li>
                                            <li><FiCheck color="#fa4a05" />Access Payroll Data</li>
                                        </ul>
                                        <h3 className="custom-hospital-management">Receptionist Functionalities
                                        </h3>
                                        <p className='justifypara'>
                                        Receptionists can manage their profiles, oversee patient information, coordinate with case handlers, handle patient admissions, manage mail services and access payroll data.
                                        </p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#fa4a05" />Profile Management</li>
                                            <li><FiCheck color="#fa4a05" />Manage Patient</li>
                                            <li><FiCheck color="#fa4a05" />Manage Case Handlers</li>
                                            <li><FiCheck color="#fa4a05" />Manage Patient Admissions</li>
                                            <li><FiCheck color="#fa4a05" />Manage Mail Service</li>
                                            <li><FiCheck color="#fa4a05" />Access Payroll Data</li>
                                        </ul>
                                        <h3 className="custom-hospital-management">Lab Manager Functionalities
                                        </h3>
                                        <p className='justifypara'>
                                        Lab managers can manage their profiles, oversee the blood bank, handle pathology and radiology tests and access payroll data.
                                        </p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#fa4a05" />Profile Management</li>
                                            <li><FiCheck color="#fa4a05" />Manage blood bank</li>
                                            <li><FiCheck color="#fa4a05" />Manage Pathology Tests</li>
                                            <li><FiCheck color="#fa4a05" />Manage Radiology Tests</li>
                                            <li><FiCheck color="#fa4a05" />Access Payroll Data</li>
                                        </ul>
                                        <h3 className="custom-hospital-management">Accountant App Functionalities
                                        </h3>
                                        <p className='justifypara'>
                                        Accountants can manage their profiles, oversee accounts, handle invoices and payments, and access payroll data.
                                        </p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#fa4a05" />Profile Management</li>
                                            <li><FiCheck color="#fa4a05" />Manage Account</li>
                                            <li><FiCheck color="#fa4a05" />Manage Invoices</li>
                                            <li><FiCheck color="#fa4a05" />Manage Payments</li>
                                            <li><FiCheck color="#fa4a05" />Access Payroll Data</li>
                                        </ul>                                      
                                        <h3 className="custom-hospital-management">Accountant App Functionalities
                                        </h3>
                                        <p className='justifypara'>
                                        Accountants can manage their profiles, oversee accounts, handle invoices and payments, and access payroll data.
                                        </p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#fa4a05" />Profile Management</li>
                                            <li><FiCheck color="#fa4a05" />Manage Account</li>
                                            <li><FiCheck color="#fa4a05" />Manage Invoices</li>
                                            <li><FiCheck color="#fa4a05" />Manage Payments</li>
                                            <li><FiCheck color="#fa4a05" />Access Payroll Data</li>
                                        </ul>
                                        <h3 className="custom-hospital-management">Admin Panel Functionalities
                                        </h3>
                                        <p className='justifypara'>
                                        An Admin can oversee the entire hospital, including managing healthcare professionals, patients, appointments, prescriptions, payments and various medical services such as lab tests, radiology and ambulance services. They also handle payroll, insurance, bed assignments, medical supplies, reports and financial transactions.
                                        </p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#fa4a05" />Dashboard Overview</li>
                                            <li><FiCheck color="#fa4a05" />Manage Patients</li>
                                            <li><FiCheck color="#fa4a05" />Manage Doctors</li>
                                            <li><FiCheck color="#fa4a05" />Manage Pharmacists</li>
                                            <li><FiCheck color="#fa4a05" />Manage Nurses</li>
                                            <li><FiCheck color="#fa4a05" />Manage Case Managers</li>
                                            <li><FiCheck color="#fa4a05" />Manage Receptionists</li>
                                            <li><FiCheck color="#fa4a05" />Manage Lab Managers</li>
                                            <li><FiCheck color="#fa4a05" />Manage Accountants</li>
                                            <li><FiCheck color="#fa4a05" />Manage Payment</li>
                                            <li><FiCheck color="#fa4a05" />Manage Appointments</li>
                                            <li><FiCheck color="#fa4a05" />Manage Operation History</li>
                                            <li><FiCheck color="#fa4a05" />Prescriptions</li>
                                            <li><FiCheck color="#fa4a05" />Ambulance</li>
                                            <li><FiCheck color="#fa4a05" />Employee Payrolls</li>
                                            <li><FiCheck color="#fa4a05" />Radiology Category</li>
                                            <li><FiCheck color="#fa4a05" />Beds Assigns</li>
                                            <li><FiCheck color="#fa4a05" />Blood Bank</li>
                                            <li><FiCheck color="#fa4a05" />Manage Medicines</li>
                                            <li><FiCheck color="#fa4a05" />Pathology Tests</li>
                                            <li><FiCheck color="#fa4a05" />Radiology Tests</li>
                                            <li><FiCheck color="#fa4a05" />Diagnosis Tests</li>
                                            <li><FiCheck color="#fa4a05" />Insurance</li>
                                            <li><FiCheck color="#fa4a05" />IPD Patients</li>
                                            <li><FiCheck color="#fa4a05" />OPD Patients</li>
                                            <li><FiCheck color="#fa4a05" />Manage Charges</li>
                                            <li><FiCheck color="#fa4a05" />Manage Vaccinations</li>
                                            <li><FiCheck color="#fa4a05" />Birth Reports</li>
                                            <li><FiCheck color="#fa4a05" />Death Reports</li>
                                            <li><FiCheck color="#fa4a05" />Manage Income and Expenses</li>
                                        </ul>
                                        <h3 className="custom-hospital-management">Super Admin Panel Functionalities
                                        </h3>
                                        <p className='justifypara'>
                                        The Super Admin can manage hospital registrations, configure subscription plans, handle billing adjustments, monitor transactions, oversee subscriber information and customise the front-end content of the platform.
                                        </p>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#fa4a05" />Dashboard Overview</li>
                                            <li><FiCheck color="#fa4a05" />Manage Hospitals</li>
                                            <li><FiCheck color="#fa4a05" />Manage Subscription Plans</li>
                                            <li><FiCheck color="#fa4a05" />Manage Transactions</li>
                                            <li><FiCheck color="#fa4a05" />Manage Subscribers</li>
                                            <li><FiCheck color="#fa4a05" />Manage Enquiries</li>
                                            <li><FiCheck color="#fa4a05" />Manage Front CMS</li>
                                            <li><FiCheck color="#fa4a05" />Manage Settings</li>
                                        </ul>  
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <div className="d-sm-block d-flex justify-content-center align-items-center pt--30">
                                            <button className="get-a-quote btn-hospital-management text-center" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-hospital-management' items={technologies} />
                            <div className="d-flex justify-content-center align-items-center pt--30">
                                <button className="get-a-quote btn-hospital-management text-center" onClick={this.showPopup}>
                                    <span>Get a quote </span>
                                </button>
                            </div>
                        </React.Suspense>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                               <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Doctor App'
                                    p={``}
                                    buttonNames={mobileDrButtonName}
                                    image={mobileDrImageAddress}
                                    alt="BTK Mobile"
                                    color='them-gradient'
                                />
                            </React.Suspense>
                        </div>
                    </div>                    
                    <div className="container">
                        <div className="row">
                               <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Hospital App'
                                    p={``}
                                    buttonNames={mobileHosButtonName}
                                    image={mobileHosImageAddress}
                                    alt="BTK Mobile"
                                    color='them-gradient'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                               <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Patient App'
                                    p={``}
                                    buttonNames={mobileButtonName}
                                    image={mobileImageAddress}
                                    alt="BTK Mobile"
                                    color='them-gradient'
                                />
                            </React.Suspense>
                        </div>
                    </div>                  
                </div>
                <div className="d-flex justify-content-center align-items-center pt--30 bg_color--3 ">
                    <button className="get-a-quote btn-hospital-management text-center" onClick={this.showPopup}>
                        <span>Get a quote </span>
                    </button>
                </div>
                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Hospital Panel'
                                    p={``}
                                    buttonNames={subadminButtonNames}
                                    image={subadminImageAddress}
                                    alt="BTK admin"
                                    color='hospital-management-system'
                                />
                            </React.Suspense>
                        </div>
                        <div className="d-flex justify-content-center align-items-center pt--30 bg_color--3 ">
                            <button className="get-a-quote btn-hospital-management text-center" onClick={this.showPopup}>
                                <span>Get a quote </span>
                            </button>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Patient Panel'
                                    p={``}
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt="BTK User"
                                    color='hospital-management-system'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    {/* <div className="pt--50">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <VerbosSimilarApps color='custom-food-delivery-product' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} />
                            <VerbosSimilarApps color='custom-hospital-management' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                        </React.Suspense>
                    </div> */}
                </div>
                <div className="rn-portfolio-area bg_color--3 ">
                    {/* <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Super Admin Panel'
                                    p={`The admin panel provides administrative control over user accounts, NFC card orders, subscriptions and system settings.`}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt="BTK admin"
                                    color='hospital-management-system'
                                />
                            </React.Suspense>
                        </div>
                        <div className="d-flex justify-content-center align-items-center pt--30 bg_color--3 ">
                            <button className="get-a-quote btn-hospital-management text-center" onClick={this.showPopup}>
                                <span>Get a quote </span>
                            </button>
                        </div>
                    </div> */}
                    <div className="pt--50">
                        <React.Suspense fallback={<div>loading...</div>} >
                            {/* <VerbosSimilarApps color='custom-food-delivery-product' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} /> */}
                            <VerbosSimilarApps color='custom-hospital-management' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                        </React.Suspense>
                    </div>
                </div>
                <CommonPortfolioButtons class='btn-hospital-management-cards' text='View All Our Products' link='/our-products' padding='pb--20' />
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
