// Create a new file, e.g., Slider.js

import React from 'react';
import { Link } from 'react-router-dom';

const CommonSliderBanner = ({ slideList, classes, col, dataBlackOverlay, titleClass, color, openModal }) => (
    <div className="slider-wrapper">
        {slideList.map((item, index) => (
            <div className={`${classes} ${item.bgImage}`} key={index} data-black-overlay={dataBlackOverlay} >
                <div className="container">
                    <div className="row">
                        <div className={`${col} ?? col-lg-9`}>
                            <div className={`inner ${item.textPosition}`}>
                                {/* {item.category && <span>{item.category}</span>} */}
                                {item.title && <h1 className={`title ${titleClass}`}
                                    // style={{ color: '#ffff' }}
                                    style={!color ? { color: '#ffffff' } : {}}
                                    dangerouslySetInnerHTML={{ __html: item.title }}></h1>}
                                {item.description && <p className="description">{item.description}</p>}
                                {item.description1 && <p className="description">{item.description1}</p>}
                                {item.description3 && <p className="description">{item.description3}</p>}
                                {item.buttonText && <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={item.buttonLink}>{item.buttonText}</Link></div>}
                                {item.buttonTexts && <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={item.buttonLinks} target="_blank" rel="noopener noreferrer">{item.buttonTexts}</a></div>}
                                {item.modalTexts ? <div className="slide-btn"><button onClick={openModal} className="rn-button-style--2 btn-primary-color" >{item.modalTexts}</button></div> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </div >
);

export default CommonSliderBanner;
