import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";

class NodeJS extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    render() {
        return (
            <React.Fragment>

                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='NodeJS || Noorisys Technologies Pvt Ltd' pageUrl='/node-js' metaTitle="NodeJS" metaImage="/assets/images/bg/bg-image-25.jpg"
                    metaDescription="Build high-performance Node.js web applications with Noorisys. Our team of experts provides top-notch development services using the latest technologies." />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title='NodeJS'
                    dataBlackOverlay={5}
                    bgImage='bg_image--25'
                    buttonTitle={'Hire Now'}
                    buttonLink={'/contact-us'}
                    p='If you’re worried about faster performance and reliability, then, our NodeJS development solution is for you.'
                />
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}

                <main className="page-wrapper">

                    {/* Start Columns Area  */}
                    <div className="rn-columns-area ptb--120 bg_color--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="single-column">
                                        {/* <h4 className="tilte">One Full</h4> */}
                                        <p className="justifypara">NodeJS has gained popularity over the time because of its rich data exchange capabilities while dealing with multimedia, chat services and other high data demanding services like real time tracking.  It provides cross-platform applications that run easily on any web. Noorisys Technologies’ developers use NodeJS to deliver fast, scalable software for many industries, including healthcare, retail, and financial services.</p>
                                        <p className="justifypara">NodeJS is the perfect tool for building web applications. That means you don’t need anything extra for running up a node application, just for making the one. This results in increasing the efficiency of the development process and it fills the gap between frontend and backend applications.</p>

                                        <h4 className="title">Looking for a NodeJS Development Company?</h4>
                                        <p className="justifypara">We provide highly skilled NodeJS developers and dedicated teams, as well as services on turn-key development with NodeJS at the core.</p>
                                        <h4 className="title">The high level of our NodeJS services is ensured by:</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />11 years of experience in software development.</li>
                                            <li><FiCheck />Over 1000+ projects completed since 2011.</li>
                                            <li><FiCheck />7+ NodeJS developers on board.</li>
                                        </ul><br />
                                        <h4 className="title">Why Node JS?</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />Rapid development.</li>
                                            <li><FiCheck />Ideal for microservices.</li>
                                            <li><FiCheck />Native support in AWS.</li>
                                            <li><FiCheck />Scalable server side environment.</li>
                                            <li><FiCheck />Possibility of sharing and reuse many free tools.</li>
                                            <li><FiCheck />All benefits of full featured JavaScript development.</li>
                                        </ul>
                                        <h4 className="title">What do we develop with NodeJS?</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck />NodeJS API development and integration.</li>
                                            <li><FiCheck />NodeJS migration.</li>
                                            <li><FiCheck />NodeJS plugin development.</li>
                                            <li><FiCheck />Web servers.</li>
                                            <li><FiCheck />Custom APIs.</li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Columns Area  */}


                </main>



                {/* </main> */}
                {/* End Page Wrapper  */}





                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default NodeJS;
