import React from 'react';
import { Link } from 'react-router-dom';
import { FiCheck } from 'react-icons/fi';

const ServiceDetails = ({ data, bgColor, padding }) => {
    return (
        <div className={`rn-service-details ${bgColor} ${padding}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="service-details-inner">
                            <div className="inner">
                                {data.map((item, index) => (
                                    <div
                                        className={`row sercice-details-content pb--40 align-items-center ${index % 2 === 1 ? 'order-lg-1' : 'order-lg-2'
                                            }`}
                                        key={index}
                                    >
                                        <div className={`col-lg-6 col-12 ${index % 2 === 1 ? 'order-lg-2' : 'order-lg-1'}`}>
                                            <div className="thumb position-relative">
                                                <img className="w-100" src={item.image} alt={item.alt} loading="lazy" />
                                            </div>
                                        </div>
                                        <div className={`col-lg-6 col-12 ${index % 2 === 1 ? 'order-lg-1' : 'order-lg-2'}`}>
                                            <div className="details mt_md--30 mt_sm--30">
                                                <h4 className="title">{item.title}</h4>
                                                {Array.isArray(item.description) ?
                                                    item.description.map((data, index) => (
                                                        <p className="justifypara" key={index}>{data}</p>
                                                    ))
                                                    : <p className="justifypara">{item.description}</p>}
                                                {item.noFeature ? '' : item.h4 ? <h4 className="title mt--10">Features</h4> : <h3 className="title mt--10">Features</h3>}
                                                <ul className="list-style--1">
                                                    {item.features.map((feature, i) => (
                                                        <li className="justifypara" key={i}>
                                                            <FiCheck />
                                                            {feature}
                                                        </li>
                                                    ))}
                                                </ul>
                                                {item.link && <div className="about-button mt--30">
                                                    <Link className="rn-button-style--2 btn-solid" to={item.link}>
                                                        {item.text}
                                                    </Link>
                                                </div>}
                                                {item.onClick &&
                                                    <div className="about-button mt--30">
                                                        <button className="rn-button-style--2 btn-solid" onClick={item.onClick}>
                                                            {item.text}
                                                        </button>
                                                    </div>
                                                }
                                                {item.whatasApp && <h3>  <a className='btn-transparent rn-btn-dark' href={`https://wa.me/7796614664?text=${item.whatasAppText}`} target="_blank" rel="noopener noreferrer">{item.whatasApp}</a></h3>}
                                                {item.whatasAppButton &&
                                                    <div className="about-button mt--30">
                                                        <a className="rn-button-style--2 btn-solid" href={`https://wa.me/7796614664?text=${item.whatasAppText}`} target="_blank" rel="noopener noreferrer">
                                                            {item.whatasAppButton}
                                                        </a>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceDetails;
