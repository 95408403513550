import React, { Component, Fragment } from "react";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import 'reactjs-popup/dist/index.css';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CommonSliderBanner from "../component/common/CommonSliderBanner";

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Freelance digital designer',
        title: ' Empowering <br /> IT Services to Thrive with the <span> Power of AI</span>',
        // title: ' Partnering for Success with <span> Noorisys </span>Technologies',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]


class Sitemap extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    render() {
        return (
            <Fragment>
                <Helmet pageTitle="Home Page ||Noorisys Technologies Pvt Ltd" pageUrl="/" metaTitle="Best Mobile and Web Development Company" metaImage="/assets/images/bg/paralax/bg-image-3-overlay.jpg"
                    metaDescription="Welcome to Noorisys - the best mobile and web development company in India. We provide IT solutions to clients all over the world. Let us help you bring your digital vision to life."
                    metaKeywords="best mobile app development company in uk ,web and mobile app development company,custom web design and development services,custom web development company ,mobile app development company in uk" />
                <Header logo="light" />

                {/* Start Slider Area   */}
                {/* <div className="slider-wrapper">
                    Start Single Slide
                    {SlideList.map((value, index) => (
                        <div className="slide personal-portfolio-slider-partner slider-paralax slider-style-partner d-flex align-items-center justify-content-center bg_image bg_image--32" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title" style={{ color: '#ffff' }} dangerouslySetInnerHTML={{ __html: value.title }}></h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    End Single Slide
                </div> */}
                <CommonSliderBanner
                    classes={'slide personal-portfolio-slider-partner slider-paralax slider-style-partner d-flex align-items-center justify-content-center bg_image bg_image--32'}
                    slideList={SlideList}
                    bgImage={'bg_image--32'}
                />
                {/* End Slider Area   */}
                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <div className="content pb--40">
                                            <h4 className="title">Sitemap</h4>
                                            <ul >
                                                <li style={{ color: 'blue' }} ><Link to='/'>Home</Link>
                                                    <ul >
                                                        <li className="" style={{ color: 'blue' }}><Link to='/mobile-development'>Mobile App Development</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/website-development'>Website Development</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/support-services'>Support Services</Link></li>
                                                    </ul>
                                                </li>
                                                <li style={{ color: 'blue' }} ><Link to='/about'>About</Link>
                                                    <ul >
                                                        <li className="" style={{ color: 'blue' }}><Link to='/gallery'>Gallery</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/careers'></Link>Careers</li>
                                                        <ul>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/web-development-career'>Web Development Career </Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/mobile-app-career'>Mobile App Development Career</Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/finance-and-hr-career'>Finance And HR Career </Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/customer-support-career'></Link>Customer Support Executive Career</li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/sales-and-marketing-career'>Sales and Marketing Career</Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/ux-ui-career'>UX and UI Designing Career</Link></li>
                                                        </ul>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/tech-stack'></Link>Tech-stack</li>
                                                        <ul>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/node-js'>NodeJS</Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/dot-net'>.Net</Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/laravel'>Laravel</Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/ci4'>CI 4 </Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/python'>Python</Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/angular'>Angular</Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/react-js'>React JS</Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/wordpress'>Wordpress</Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/shopify'>Shopify</Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/flutter'>Flutter</Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/react-native'>React Native</Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/java'>Java</Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/swift'>Swift</Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/kotlin'>Kotlin</Link></li>
                                                            <li className="" style={{ color: 'blue' }}><Link to='/objective-c'>Objective-c </Link></li>
                                                        </ul>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/our-approach'>Our Approach</Link></li>
                                                    </ul>
                                                </li>
                                                <li style={{ color: 'blue' }} ><Link to='/our-services'>Services</Link>
                                                    <ul >
                                                        <li className="" style={{ color: 'blue' }}><Link to='/data-protection-assessment'>Explore DPDP Services</Link></li>
                                                    </ul>
                                                </li>
                                                <li style={{ color: 'blue' }} ><Link to='/industries'>Industries</Link>
                                                    <ul >
                                                        <li className="" style={{ color: 'blue' }}><Link to='/health-care'>Health Care</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/logistics'>Logistics</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/ecommerce'>eCommerce</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/fintech'>Fintech</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/tourism'>Tourism</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/support-services'>Support Services</Link></li>
                                                    </ul>
                                                </li>
                                                <li style={{ color: 'blue' }} ><Link to='/portfolio'>Portfolio</Link>
                                                    <ul>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/primary-care-management-system'>Primary Care Management System</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/scholarship-distribution-system'>Scholarship Distribution with KYC Verification</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/online-groceries-ordering-system'>Online Grocery Ordering System</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/courier-and-transport-management'>Courier And Transport Management</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/multilingual-pregnancy-app'>Multilingual Pregnancy App</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/doctor-appointment-and-online-pharmacy'>Doctor Appointment and Online Pharmacy</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/healthcare-insurance-marketplace'>Healthcare Insurance Marketplace</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/hospital-chain-management-system'>Hospital Chain Management System (Secondary Care)</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/ticket-booking-platform'>Ticket Booking Platform</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/visa-processing-system'>Visa Processing OTA</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/digital-solution-for-civil-documents'>Digital Solution for Civil Documents</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/tourism-company-management-system'>Tourism Company Management System</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/hotel-booking-platform'>Hotel Booking Platform</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/faster-checks'>Faster Checks</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/nursing-platform'>Nursing Platform</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/car-space-rental-platform'>Car Space Rental</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/service-provider-marketplace'>Service Provider Marketplace</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/influencer-marketplace'>Influencer Marketplace</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/online-book'>Online Book</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/pharmacist-marketplace'>Pharmacist Marketplace</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/online-shop'>Online Shop</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/researcher-personal-presentation'>Researcher Personal Presentation</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/covid-antigen-test-verification'>Covid Antigen Test Verification</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/delivery-management'>Delivery Management</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/government-financial-scheme-presentation-system'>Government Financial Scheme Presentation System</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/electronic-medical-record'>Electronic Medical Record</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/e-learning'>e-Learning</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/diet-app'>Diet App</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/coupons-and-cashback-offer-application'>Coupons And Cashback Offer Application</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/doctor-appointment-and-hospital-management'>Doctor Appointment And Hospital Management</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/erp-oil-company-outlets'>ERP for Oil Company Outlets</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/event-management-system'>Event Management System</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/lead-management-system'>Lead Management System</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/meditation-app'>Meditation App</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/online-food-delivery'>Online Food Delivery</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/online-payment-application'>Online Payment Application</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/school-management-system'>School Management System</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/asset-management-repairing-service'>Asset Management For Repairing Service Provider</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/property-showcase'>Property Showcase</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/hajj-application'>Hajj Application</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/hide-numbers'>Hide Numbers</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/memories'>Memories</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/online-travel-agency'>Online Travel Agency</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/powerloom-invoice-management'>Powerloom Invoice Management</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/service-center-booking-system'>Service Center Booking System</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/social-app'>Social App</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/vehicle-managment-system'>Vehicle Managment System</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/survey-application'>Survey Application</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/choice-making-app'>Choice Making App</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/discovery-music'>Discovery Music</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/doctor-personal-presentation'>Doctor Personal Presentation</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/online-laundry'>Online Laundry</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/online-grocery'>Online Grocery</Link></li>
                                                        <li className="" style={{ color: 'blue' }}><Link to='/freelancing-portal'>Freelancing Portal</Link></li>
                                                    </ul>
                                                </li>
                                                <li style={{ color: 'blue' }} ><Link to='/healthtech'>Health Tech</Link></li>
                                                <li style={{ color: 'blue' }} ><Link to='/our-products'>Our Products</Link></li>
                                                <li style={{ color: 'blue' }} ><Link to='/contact-us'>Contact</Link></li>
                                            </ul>
                                            <h4 className="title">Quick Links</h4>
                                            <ul>
                                                <li className="" style={{ color: 'blue' }}><Link to='/about'>About</Link></li>
                                                <li className="" style={{ color: 'blue' }}><Link to='/our-approach'>Our Approach</Link></li>
                                                <li className="" style={{ color: 'blue' }}><Link to='/portfolio'>Portfolio</Link></li>
                                                <li className="" style={{ color: 'blue' }}><Link to='/blogs'>Blogs</Link></li>
                                                <ul className="" >
                                                    <li className="" style={{ color: 'blue' }}><Link to='/agile-methodology'>Agile Methodology</Link></li>
                                                    <li className="" style={{ color: 'blue' }}><Link to='/hosting-dream-blog'>Hosting Dream Blog</Link></li>
                                                    <li className="" style={{ color: 'blue' }}><Link to='/rise-of-ai'>Rise Of Artificial Intelligence </Link></li>
                                                    <li className="" style={{ color: 'blue' }}><Link to='/web-development-trend'>Web Development Trend</Link></li>
                                                    <li className="" style={{ color: 'blue' }}><Link to='/rest-api-blog'>Rest Api Security</Link></li>
                                                    <li className="" style={{ color: 'blue' }}><Link to='/chat-bots-blog'>Ultimate Guide To Chatbot</Link></li>
                                                    <li className="" style={{ color: 'blue' }}><Link to='/ipass-blog'>iPass Vs Saas</Link></li>
                                                    <li className="" style={{ color: 'blue' }}><Link to='/digital-twin-blog'>Digital Twin Organization</Link></li>
                                                    <li className="" style={{ color: 'blue' }}><Link to='/hyper-automation-blog'>Hyperautomation Need For Business</Link></li>
                                                    <li className="" style={{ color: 'blue' }}><Link to='/li-fi-blog'>Li-Fi Everything you need to know </Link></li>
                                                    <li className="" style={{ color: 'blue' }}><Link to='/connected-world-blog'>Connected World</Link></li>
                                                    <li className="" style={{ color: 'blue' }}><Link to='/new-tech-blog'>New Technologies</Link></li>
                                                    <li className="" style={{ color: 'blue' }}><Link to='/metaverse-blog'>Introduction To Metavers</Link></li>
                                                    <li className="" style={{ color: 'blue' }}><Link to='/health-care-blog'>Healtcare-Strong Foundation</Link></li>
                                                </ul>
                                                <li className="" style={{ color: 'blue' }}><Link to='/privacy-policy'>Privacy Policy</Link></li>
                                                <li style={{ color: 'blue' }} ><Link to='/become-a-partner'>Become A Partner</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}



                {/* Start Footer Style  */}
                <Footer />
                {/* End Footer Style  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}

export default Sitemap;