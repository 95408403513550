import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FaChartBar, FaCheckSquare, FaCloudUploadAlt, FaCode, FaConnectdevelop, FaFileAlt, FaHeadset, FaLevelUpAlt, FaLinkedinIn, FaVial } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
// import { Link } from "react-router-dom";
import AboutCounter from "./AboutCounter";
import CommonSliderBanner from "../component/common/CommonSliderBanner";
const Testimonial = React.lazy(() => import("./Testimonial"));
const Brand = React.lazy(() => import("../creative_homePage/CreativeBrand"));

const ServiceList = [
    {
        icon: <FaFileAlt />,
        title: 'Proposal',
        description: 'Our highly qualified and experienced professionals look into your requirements and gather required information about your business and the project. We make sure that we understand your requirements well, and then create a proposal.',

    },
    {
        icon: <FaCheckSquare />,
        title: 'Evaluation',
        description: 'Our business process starts with understanding and identifying your idea or business challenges. As we receive information about your project our professionals get straight to work on developing the perfect solution with a great understanding of core requirements.',

    },
    {
        icon: <FaChartBar />,
        title: 'Pre-development Analysis',
        description: "Once the development has been handed over to our team the Project Manager will work on clearly defining the project's scope. The specification process is a highly transparent analysis, with our constant commenting and feedback model.",

    },
    {
        icon: <FaConnectdevelop />,
        title: 'Prototyping',
        description: 'At this stage we offer design mock-ups which are combined with the proposed user structure. This process is in place to ensure the resulting project will be work in accordance with the learned interface interaction, have quality assured feedback before any development starts.',

    },
    {
        icon: <FaCode />,
        title: 'Coding',
        description: 'Our next step is to start the coding, following the best accepted methods. We use various techniques for the front end and backend coding. During the process, we offer you with continual updates and listen to your feedback and implement the same in the next update.',

    },
    {
        icon: <FaVial />,
        title: 'Testing',
        description: 'The quality assurance process is a systematic procedure that monitors our software engineering competency, and regularly confirms the quality in our software development practices. This is driven by our passion to secure conformance to high quality standards such as ISO 9001 and CMMI.',

    },
    {
        icon: <FaCloudUploadAlt />,
        title: 'Publishing/Launching/Deployment',
        description: "This is the final stage of the application development process where the product undergoes customer's approval. We help our clients with the construction of User Acceptance Testing methods, client use cases and in-house testing protocols.",

    },
    {
        icon: <FaLevelUpAlt />,
        title: 'Updates',
        description: 'Ever been to a small business project that looks a decade late and money short? That’s because those sites haven’t been updated and maintained. Lucky for you Noorisys IT Consultant has an updates team committed to keeping your project current, functional and aesthetically pleasing.',

    },
    {
        icon: <FaHeadset />,
        title: 'Support',
        description: 'To maintain the service standards and for the satisfaction of our clients, we offer 2 months warranty totally free. Once the warranty period is complete we offer custom support packages to reflect their individual needs.',

    }
]

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'About Us',
        // description: 'Coding the cores of the lives and transforming the business concepts for our clients.',
        description: '13 Years Of Technical Excellence In Information Technology',
        buttonText: 'Contact Us',
        buttonLink: '/contact-us'
    }
]

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    render() {
        const show = this.state.show
        let title = 'Noorisys',
            description = 'is an IT company, providing customised software development,  website development and mobile application development along with customer support and data security services all under one roof.';
        return (
            <React.Fragment>
                <PageHelmet pageTitle='About Us || Noorisys Technologies Pvt Ltd' pageUrl='/about' metaTitle="10+ years of Technical excellence in Information Technology Services" metaImage="/assets/images/bg/bg-image-26.jpg"
                    metaDescription="Experience technical excellence with Noorisys - a leading software development company with 10+ years of experience in mobile and web app development. Trust us to bring your ideas to reality." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Slider Area   */}
                {/* <div className="slider-wrapper">
                    // Start Single Slide
                    {SlideList.map((value, index) => (
                        <div className="slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--26" key={index} data-black-overlay="6">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            // {value.category ? <span>{value.category}</span> : ''}
                                            {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.description1 ? <p className="description">{value.description1}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><Link className="rn-button-style--2 btn-primary-color" to={`${value.buttonLink}`}>{value.buttonText}</Link></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    // End Single Slide
                </div> */}
                <CommonSliderBanner
                    slideList={SlideList}
                    classes={'slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--26'}
                    dataBlackOverlay={6}
                    titleClass='theme-gradient'
                    col='col-lg-12'
                />
                {/* End Slider Area   */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about-3.webp" alt="About Images" loading="lazy" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description justifypara">{description}</p>
                                            <br />
                                            <p className="description justifypara">With a team of experienced staff and qualified professionals we emerged on a global stage. Now we are trusted by more than 500 clients globally including government and semi-government entities and corporate giants. </p>
                                            <br />
                                            <p className="description justifypara">We have developed a value based relationship with our clients by making their visions come true in the era of blooming technology.</p>
                                            <br />
                                            <p className="description justifypara">Noorisys has helped the organisations in setting up their strong business foundation by adopting new technologies, security tools and enhanced user experience.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Glory of a Decade</h3>
                                </div>
                            </div>
                        </div>
                        <AboutCounter />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Finding Us Area  */}
                <div className="rn-finding-us-area rn-finding-us bg_color--3">
                    <div className="inner">
                        <div className="content-wrapper">
                            <div className="content">
                                <h4 className="theme-gradient">Our Approach</h4>
                                <p className="justifypara">With the help of our highly qualified and talented professionals, we aim to provide clients with high-quality, relevant & customised solutions, outstanding services & support and value to their business solutions.</p>
                                {/* <Link className="rn-btn btn-white" to="/our-approach">Know more</Link> */}
                                <button className="rn-btn btn-white" onClick={() => this.setState({ show: !this.state.show })}>Know more</button>
                            </div>
                        </div>
                        <div className="thumbnail">
                            <div className="image">
                                <img src="/assets/images/about/finding-us-01.webp" alt="Finding" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}
                {show && <div className="service-area creative-service-wrapper pt--40 pb--40 bg_color--1">
                    <div className="container">
                        <div className="row creative-service">
                            {ServiceList.map((val, i) => (
                                <div className="col-12" key={i}>

                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {val.icon}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p className="justifypara">{val.description}</p>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                </div>}
                {/* Start Team Area  */}
                <div className="rn-team-area bg_color--1 ptb--60">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25">
                                    <h2 className="title">Meet Our Team</h2>
                                    <h4>Meet our leaders and driving forces of Noorisys projecting perfection <br />with their expertise.</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-01.png" alt="Shahid" loading="lazy" />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Shahid Ahmed</h4>
                                        <p className="designation">Chief Technology Officer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.linkedin.com/in/shahidmcm"><FaLinkedinIn /></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-02.png" alt="Imran" loading="lazy" />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Mohammed Imran Tabani</h4>
                                        <p className="designation">Chief Executive Officer</p>
                                    </div>
                                    <ul className="social-icon" >
                                        <li><a href="https://www.linkedin.com/in/mohammed-imran-tabani-b018632b/"><FaLinkedinIn /></a></li>
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/team-05.png" alt="Sameer" loading="lazy" />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Dr.Sameer Nakedar</h4>
                                        <p className="designation">Director, Healthcare Technology</p>
                                    </div>
                                    <ul className="social-icon" >
                                        {/* <li><a href="https://www.facebook.com/"><FaFacebookF /></a></li> */}
                                        <li><a href="https://www.linkedin.com/in/dr-sameer-nakedar-826302119?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BmILG6hMVSxCy63btoRmKuw%3D%3D" ><FaLinkedinIn /></a></li>
                                        {/* <li><a href="https://twitter.com/"><FaTwitter /></a></li> */}
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  */}

                        </div>
                    </div>
                </div>
                {/* End Team Area  */}



                {/* Start Testimonial Area */}
                <div className="active-dark rn-testimonial-area bg_color--5 ptb--80">

                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <Testimonial />
                        </React.Suspense>
                    </div>

                </div>
                {/* End Testimonial Area */}

                {/* Start Brand Area */}
                <div className="rn-brand-area brand-separation bg_color--5 ptb--40">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <React.Suspense fallback={<div>loading...</div>} >
                                    <Brand />
                                </React.Suspense>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default About