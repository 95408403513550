import React from 'react';
import { Modal } from 'antd';

const GitexPass = ({ onAccept, onDecline }) => {

    return (
        <Modal
            open={true}
            onOk={onAccept}
            okText="Accept"
            footer={null}
            // okButtonProps={{ className: 'ant-btn' }}
            onCancel={onDecline}
            // cancelText="Decline"
            // cancelButtonProps={{ className: 'ant-btn' }}
            className='custom-modal p-0'
        >
            <img src='/assets/images/gitex-global.webp' alt="gitex global" className='mt-4' />
            <h6 className='text-center mt-3 mb-2' style={{fontWeight:'300'}}>Noorisys Technologies Participating in Gitex Global 2024</h6>
            <div className='d-flex justify-content-center'>
                <a href="https://wa.me/917796614664?text=Hi Noorisys Team, I want to meet you at Gitex, please provide me a free pass." target="_blank" rel="noopener noreferrer"><button className='rn-button-style--2 btn-solid mt-1' ><b>Get Free Pass</b></button></a>
            </div>
        </Modal>
    );
};

export default GitexPass;