import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import CoursierDelivrable from "./CoursierDeliverables";
import PlatformUsed from "../component/Verbos/PlatformUsed";
// import VerbosSimilarApps from "../component/Verbos/VerbosSimilarApps";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
import GetaQuoteButton from "../component/GetaQuoteButton";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
// import PharmacistAppDemo from "./PharmacistAppDemo";
// import PharmacistAdminDemo from "./PharmacistAdminDemo";
// const CommonMasonry = React.lazy(() => import('./CommonMasonry'))
// const PortfolioList = [
//     {
//         image: 'pharmacist-marketplace-1',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'pharmacist-marketplace-2',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'pharmacist-marketplace-3',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },
//     {
//         image: 'pharmacist-marketplace-4',
//         category: 'Development',
//         title: 'Getting tickets to the big show'
//     },

// ]
const deliverables = [
    { item_bg: 'android.png', item_icon: 'users.png', h3: 'Patient App', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'users.png', h3: 'Patient App', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'users.png', h3: 'Patient', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'Android' },
    { item_bg: 'android.png', item_icon: 'doctor.png', h3: 'Doctor App', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'doctor.png', h3: 'Doctor ', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'hospital.png', h3: 'Hospital ', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'pharmacy.png', h3: 'Pharmacy App ', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'pharmacy.png', h3: 'Pharmacy ', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'primaryadvance.png', h3: 'Nurse App ', h4: 'Android' },
    { item_bg: 'ios.png', item_icon: 'primaryadvance.png', h3: 'Nurse App ', h4: 'iOS' },
    { item_bg: 'web.png', item_icon: 'primaryadvance.png', h3: 'Nurse ', h4: 'Web' },
    { item_bg: 'web.png', item_icon: 'admin.png', h3: 'Admin ', h4: 'Web' },
]
const technologies = [

    { h5: 'Android', p: 'Android & iOS development', img: 'androidios.png' },
    { h5: 'PHP', p: 'Backend Development', img: 'php.png' },
    { h5: 'Codeigniter', p: 'Backend Development', img: 'ci4.png' },
    { h5: 'PHP', p: 'Admin Panel Development', img: 'php.png' },

]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/doctor-appointment-and-online-pharmacy', img: 'mediconnect.webp', h2: 'MediConnect', p: 'Doctor Appointment and online pharmacy' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/primary-care-management-system', img: 'federate.webp', h2: 'Federate', p: 'Primry Care Management' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]

const userAppButtonNames = ['Docfinder App', 'Login', 'Sidebar', 'Dashboard', 'Insurance', 'My Appointments', 'Profile', 'My Relatives', 'My Documents', 'Help Relatives'];
const userAppImageAddress = [
    '/assets/images/portfolio/healthcare-insurance/izikare-app-1.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-2.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-3.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-4.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-5.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-6.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-7.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-8.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-9.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-app-10.webp',
];
const adminButtonNames = ['Homepage', 'Registration', 'Login', 'Dashboard', 'Insurance Activities', 'Allodocta Activities', 'Platform Activities', 'Insurance Settings', 'Allodocta Settings', 'EMR Settings', 'Platform Settings', 'Profile'];
const adminImageAddress = [
    '/assets/images/portfolio/healthcare-insurance/izikare-web-1.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-2.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-3.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-4.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-5.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-6.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-7.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-8.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-9.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-10.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-11.webp',
    '/assets/images/portfolio/healthcare-insurance/izikare-web-12.webp',
];

class PharmacistMarketplacePortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Pharmacist Marketplace || Noorisys Technologies Pvt Ltd' pageUrl='/pharmacist-marketplace' metaTitle="Pharmacist Marketplace" metaImage="/assets/images/portfolio/dp-portfolio-21.jpg"
                    metaDescription="Pharmacist marketplace aims to connect and serve as an intermediary between hospital pharmacies and patients, through a platform that allows them to order and keep track of medicines." />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Pharmacist Marketplace"
                    customColor="custom-pharmacist-color"
                    dataBlackOverlay={7}
                    bgImage="bg_image--54"
                    p="Web / Android / iOS Application"
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <h4><span>TECHNICAL DETAILS</span></h4>
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                            <div className="port-view">
                                                <span>Category</span>
                                                <h6>Web / Android / iOS Application</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Project Type</span>
                                                <h6>Pharmacist Marketplace</h6>
                                            </div>

                                            <div className="port-view">
                                                <span>Technology Used</span>
                                                <h6>PHP, CodeIgniter, Android, JAVA, iOS, Swift</h6>
                                            </div>
                                        </div>
                                        <h3 className="rn-page-title custom-pharmacist-color">Pharmacist Marketplace</h3>
                                        <p className="justifypara">Pharmacist marketplace system aims to connect and serve as an intermediary between hospital pharmacies and patient, through a platform that allows the order of medicines, and simultaneously keeps track of what medicines have been ordered and in what quantity. In the Pharmacist Marketplace, access to medical records make it easy to influence the selection of medicines based on allergies or previous problems encountered. Subsequently, the power to control the process of going into the acquisition of medicines and other pharmaceutical devices, help in ensuring the supply of premium quality medicines. Pharmacist Marketplace provides an information technology platform by an e-Commerce entity on a digital and electronic network to act as a facilitator between a patient and a hospital pharmacies.</p>

                                        <h4 className="title">Features</h4>
                                        <ul className="list-style--1">
                                            <li><FiCheck color={'#1396ce'} />Easy registration for providers and patients</li>
                                            <li><FiCheck color={'#1396ce'} />Easy to order medicines, and simultaneously keeps track of what medicines have been ordered</li>
                                            <li><FiCheck color={'#1396ce'} />EMR management</li>
                                            <li><FiCheck color={'#1396ce'} />Email verification and Forgot password</li>
                                            <li><FiCheck color={'#1396ce'} />Attractive dashboard for each Providers and Patient</li>
                                            <li><FiCheck color={'#1396ce'} />Online Teleconsultation</li>
                                            <li><FiCheck color={'#1396ce'} />Audio / Video appointment</li>
                                            <li><FiCheck color={'#1396ce'} />Physical Appointment</li>
                                            <li><FiCheck color={'#1396ce'} />Consult at patient home</li>
                                            <li><FiCheck color={'#1396ce'} />Insurance Subscriptions</li>
                                            <li><FiCheck color={'#1396ce'} />Insurance Package Marketplace</li>
                                            <li><FiCheck color={'#1396ce'} />Manage Relatives</li>
                                            <li><FiCheck color={'#1396ce'} />Claims management</li>
                                            <li><FiCheck color={'#1396ce'} />Secure online payment</li>
                                            <li><FiCheck color={'#1396ce'} />Offline payment</li>
                                            <li><FiCheck color={'#1396ce'} />Multi-currency</li>
                                            <li><FiCheck color={'#1396ce'} />Multi language</li>
                                            <li><FiCheck color={'#1396ce'} />Accounts and wallet</li>
                                            <li><FiCheck color={'#1396ce'} />Separate chat for each audio / video appointment</li>
                                            <li><FiCheck color={'#1396ce'} />Alerts and notifications</li>
                                            <li><FiCheck color={'#1396ce'} />Fully responsive design</li>
                                            <li><FiCheck color={'#1396ce'} />And many more….</li>

                                            {/* <li><FiCheck color={'#1396ce'} />Alerts and notifications.</li> */}

                                        </ul>

                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-healthcare'} left={'d-sm-block'} paddingTop={'pt--30'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}
                <div className="rn-portfolio-area bg_color--1 ptb--30"
                    style={{ backgroundColor: '#1396ce' }}
                >
                    <div className="container">
                        <CoursierDelivrable title={'16 Deliverables'} items={deliverables} class={'coursier'} value={[7, 5, 4]} />
                    </div>
                </div>
                <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-healthcare'} paddingTop={'pt--30'} />
                <div className="ptb--40">
                    <div className="container">
                        <PlatformUsed color='custom-coursier-color' items={technologies} />
                        <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-healthcare'} paddingTop={'pt--30'} />
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--1 ptb--40">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='User App'
                                    p='Users can book online and physical appointments with the doctor. Also users can search and subscribe for available insurance available on the platform.'
                                    buttonNames={userAppButtonNames}
                                    image={userAppImageAddress}
                                    alt='docfinder user'
                                    color='docfinder'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-healthcare'} paddingTop={'pt--30'} />
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Admin Panel'
                                    p='Admin can manage users, providers (doctor, pharmacy, lab etc), appointments, insurance, EMR and other platform settings'
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt='docfinder admin'
                                    color='docfinder'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <GetaQuoteButton showPopup={this.showPopup} pageName={'btn-healthcare'} paddingTop={'pt--30'} paddingBottom={`pb--30`} />
                    <VerbosSimilarApps color='custom-coursier-color' title={'Simiar Apps'} items={similarapps} slidestoshow={[3, 3]} />
                    <CommonPortfolioButtons class='btn-healthcare' text='View All Our Projects' />
                </div>
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default PharmacistMarketplacePortfolioDetails;
