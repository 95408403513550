import React from 'react'

function GetaQuoteButton({ showPopup, pageName, left, paddingTop, paddingBottom, buttonName }) {
    return (
        <div className={`${left && left} d-flex justify-content-center align-items-center ${paddingTop && paddingTop} ${paddingBottom && paddingBottom}`}>
            <button className={`button rn-button-style--2 ${pageName} text-center`} onClick={showPopup}>
                <span>{buttonName ? buttonName : ' Get a quote'} </span>
            </button>
        </div>
    )
}

export default GetaQuoteButton