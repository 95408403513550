import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import GetaquotePopup from "../component/header/getaquotePopup";
const CommonProductWeb = React.lazy(() => import("../portfolio/CommonProductWeb"));
const PlatformUsed = React.lazy(() => import("../component/Verbos/PlatformUsed"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));
const ProductCommonApp = React.lazy(() => import("../portfolio/ProductCommonAppdemo"))

const similarapps = [

    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #316c98 0%,#205c88 100%)', link: '/doctor-appointment', img: 'doctor-product.webp', h2: 'EMR & Doctor Appointment System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #9700ff 0%,#7209bd 100%)', link: '/service-marketplace', img: 'marketplace-product.webp', h2: 'Service Provider Marketplace', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #ffd94e 0%, #e9bf0b 100%)', link: '/school-management', img: 'school-product.webp', h2: 'School Management System', p: '' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #4c067d 0%,#34104e 100%)', link: '/social-platform', img: 'socialmedia-product.webp', h2: 'Social Media<br /> Platform', p: '' },
]
const technologies = [
    { h5: 'PHP', p: 'Admin Panel', img: 'php.png' },
    { h5: 'PHP', p: 'Backend Development', img: 'php.png' },
    { h5: 'PHP', p: 'User Panel', img: 'php.png' },
]

const adminButtonNames = ['Dashboard', 'Manage Users', 'nCards', 'Sell NFC Cards', 'Subscribed Plans', 'Manage Plans', 'Manage Front Website', 'Front CMS Setting','Cash Payment'];
const adminImageAddress = [
    '/assets/images/our-products/digital-business-cards/admin/dashboard.webp',
    '/assets/images/our-products/digital-business-cards/admin/user list.webp',
    '/assets/images/our-products/digital-business-cards/admin/v card list.webp',
    '/assets/images/our-products/digital-business-cards/admin/sell nfc card.webp',
    '/assets/images/our-products/digital-business-cards/admin/subscribed user.webp',
    '/assets/images/our-products/digital-business-cards/admin/plans.webp',
    '/assets/images/our-products/digital-business-cards/admin/site setting.webp',
    '/assets/images/our-products/digital-business-cards/admin/front CMS setting.webp',
    '/assets/images/our-products/digital-business-cards/admin/cash payment.webp',
];

const subadminButtonNames = ['Dashboard', 'Manage Users','Sell NFC Cards', 'Front CMS Setting',];
const subadminImageAddress = [
    '/assets/images/our-products/digital-business-cards/subadmin/dashboard.webp',
    '/assets/images/our-products/digital-business-cards/subadmin/user list.webp',
    '/assets/images/our-products/digital-business-cards/subadmin/sell nfc card.webp',
    '/assets/images/our-products/digital-business-cards/subadmin/front CMS setting.webp',
];

const userButtonNames = ['Dashboard', 'nCards', 'Virtual Backgrounds', 'Enquiries','Storage'];
const userImageAddress = [
    '/assets/images/our-products/digital-business-cards/user/dashboard.webp',
    '/assets/images/our-products/digital-business-cards/user/v card.webp',
    '/assets/images/our-products/digital-business-cards/user/virtual-backgrounds.webp',
    '/assets/images/our-products/digital-business-cards/user/inquiries.webp',
    '/assets/images/our-products/digital-business-cards/user/storage.webp',
];

const mobileButtonName = ['Sign In', 'Sign Up', 'Menu','Dashboard','nCard','Business Card', 'Appointments','Settings']
const mobileImageAddress = [
    '/assets/images/our-products/digital-business-cards/mobile/Sign In.webp',
    '/assets/images/our-products/digital-business-cards/mobile/Registration.webp',
    '/assets/images/our-products/digital-business-cards/mobile/Menu.webp',
    // '/assets/images/our-products/digital-business-cards/mobile/Edit Profile.webp',
    '/assets/images/our-products/digital-business-cards/mobile/1.webp',
    '/assets/images/our-products/digital-business-cards/mobile/Write NFC.webp',
    '/assets/images/our-products/digital-business-cards/mobile/2.webp',
    '/assets/images/our-products/digital-business-cards/mobile/3.webp',
    '/assets/images/our-products/digital-business-cards/mobile/4.webp',
]


class DigitalBusinessCardProduct extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isOpen: false,
            isVisible: false
        }
        this.openModal = this.openModal.bind(this);
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })
    }
    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Digital Business Card' pageUrl='/digital-business-card' metaTitle="The NFC Card project aims to develop a smart visiting card using Near Field Communication (NFC) technology. This card will allow users to easily share their contact details with others by tapping the card on a compatible mobile device." />
                <Header />

                {/* Start Breadcrump Area */}
                <CommonBreadcrumb
                    title="Digital Business Card"
                    // customColor="custom-digital-business-cards-portfolio"
                    customColor="theme-gradient"
                    dataBlackOverlay={5}
                    bgImage="bg_image--135"
                    p="Web Application"
                    buttonTitle={'get a quote'}
                    openPopup={true}
                />
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}

                <div className="rn-portfolio-details ptb--40 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        {/* <h4><span>TECHNICAL DETAILS</span></h4> */}
                                        <div className="portfolio-view-list d-flex flex-wrap pb--20">
                                        </div>
                                        <h3 className="rn-page-title custom-digital-business-cards-portfolio">Digital Business Card</h3>
                                        <p className="justifypara">The NFC Card project aims to develop a smart visiting card using Near Field Communication (NFC) technology. This card will allow users to easily share their contact details with others by tapping the card on a compatible mobile device. The primary functionalities include opening contact details on the recipient's mobile device and providing options to add the details to their contact list.</p>
                                        <p className="justifypara">Users can register and log in to the user panel, where they can create and manage various features for their NFC cards. The admin panel provides administrative control over user accounts and system settings.</p>
                                        <h3 className="custom-digital-business-cards-portfolio">User Features</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#960178" />Dashboard</li>
                                            <li><FiCheck color="#960178" />Card Templates</li>
                                            <li><FiCheck color="#960178" />Enquiries</li>
                                            <li><FiCheck color="#960178" />Appointments</li>
                                            <li><FiCheck color="#960178" />Product Orders</li>
                                            <li><FiCheck color="#960178" />Virtual Backgrounds</li>
                                            <li><FiCheck color="#960178" />NFC Cards Order Management</li>
                                        </ul>
                                        <h3 className="custom-digital-business-cards-portfolio">Admin Features</h3>
                                        <ul className="list-style--1">
                                            <li><FiCheck color="#960178" />Dashboard</li>
                                            <li><FiCheck color="#960178" />Manage Users</li>
                                            <li><FiCheck color="#960178" />View NFC Cards Created by Users</li>
                                            <li><FiCheck color="#960178" />Manage NFC Card Templates and Orders</li>
                                            <li><FiCheck color="#960178" />Manage Subscription Plans</li>
                                            <li><FiCheck color="#960178" />Manage Coupon Codes</li>
                                            <li><FiCheck color="#960178" />Manage Front Website</li>
                                        </ul>
                                        <GetaquotePopup isVisible={this.state.isVisible} closePopup={this.closePopup} />
                                        <div className="d-sm-block d-flex justify-content-center align-items-center pt--30">
                                            <button className="get-a-quote btn-digital-business text-center" onClick={this.showPopup}>
                                                <span>Get a quote </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start portfolio Area  */}

                <div className="ptb--40">
                    <div className="container">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <PlatformUsed color='custom-digital-business-cards-portfolio' items={technologies} />
                            <div className="d-flex justify-content-center align-items-center pt--30">
                                <button className="get-a-quote btn-digital-business text-center" onClick={this.showPopup}>
                                    <span>Get a quote </span>
                                </button>
                            </div>
                        </React.Suspense>
                    </div>
                </div>
                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                               <React.Suspense fallback={<div>loading...</div>} >
                                <ProductCommonApp
                                    h3='Mobile App'
                                    p={``}
                                    buttonNames={mobileButtonName}
                                    image={mobileImageAddress}
                                    alt="BTK Mobile"
                                    color='digital-business-cards'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='User Panel'
                                    p={`Users can register and log in to the user panel, where they can create and manage various features for their NFC cards.`}
                                    buttonNames={userButtonNames}
                                    image={userImageAddress}
                                    alt="BTK User"
                                    color='digital-business-cards'
                                />
                            </React.Suspense>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center align-items-center pt--30 bg_color--3 ">
                    <button className="get-a-quote btn-digital-business text-center" onClick={this.showPopup}>
                        <span>Get a quote </span>
                    </button>
                </div>
                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Super Admin Panel'
                                    p={`The admin panel provides administrative control over user accounts, NFC card orders, subscriptions and system settings.`}
                                    buttonNames={subadminButtonNames}
                                    image={subadminImageAddress}
                                    alt="BTK admin"
                                    color='digital-business-cards'
                                />
                            </React.Suspense>
                        </div>
                        <div className="d-flex justify-content-center align-items-center pt--30 bg_color--3 ">
                            <button className="get-a-quote btn-digital-business text-center" onClick={this.showPopup}>
                                <span>Get a quote </span>
                            </button>
                        </div>
                    </div>
                    {/* <div className="pt--50">
                        <React.Suspense fallback={<div>loading...</div>} >
                            <VerbosSimilarApps color='custom-food-delivery-product' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} />
                            <VerbosSimilarApps color='custom-digital-business-cards-portfolio' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                        </React.Suspense>
                    </div> */}
                </div>
                <div className="rn-portfolio-area bg_color--3 ">
                    <div className="container">
                        <div className="row">
                            <React.Suspense fallback={<div>loading...</div>} >
                                <CommonProductWeb
                                    h3='Super Admin Panel'
                                    p={`The admin panel provides administrative control over user accounts, NFC card orders, subscriptions and system settings.`}
                                    buttonNames={adminButtonNames}
                                    image={adminImageAddress}
                                    alt="BTK admin"
                                    color='digital-business-cards'
                                />
                            </React.Suspense>
                        </div>
                        <div className="d-flex justify-content-center align-items-center pt--30 bg_color--3 ">
                            <button className="get-a-quote btn-digital-business text-center" onClick={this.showPopup}>
                                <span>Get a quote </span>
                            </button>
                        </div>
                    </div>
                    <div className="pt--50">
                        <React.Suspense fallback={<div>loading...</div>} >
                            {/* <VerbosSimilarApps color='custom-food-delivery-product' title={'Apps'} items={similarapps} slidestoshow={[3, 3]} /> */}
                            <VerbosSimilarApps color='custom-digital-business-cards-portfolio' title={'Other Products You May Like'} text={' '} h2={`45px`} items={similarapps} slidestoshow={[3, 3]} />
                        </React.Suspense>
                    </div>
                </div>
                <CommonPortfolioButtons class='btn-digital-business-cards' text='View All Our Products' link='/our-products' padding='pb--20' />
                {/* End portfolio Area  */}



                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />


            </React.Fragment>
        )
    }
}
export default DigitalBusinessCardProduct;
