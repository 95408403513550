import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX, FiMenu } from "react-icons/fi";
// import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
// import ContactPagePopup from "../.././creative_homePage/ContactPagePopup";


class Header extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded')
        })
    }


    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }


    render() {
        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            var headerElement = document.querySelector('.header--fixed');
            if (headerElement) { // Check if the element exists
                if (value > 100) {
                    headerElement.classList.add('sticky');
                } else {
                    headerElement.classList.remove('sticky');
                }
            }
        });
        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        const script = document.createElement('script');
        script.setAttribute("type", "text/javascript");
        script.setAttribute("id", "hs-script-loader");
        script.setAttribute("async", "true");
        script.setAttribute("defer", "true");
        script.setAttribute("src", "//js-eu1.hs-scripts.com/25639068.js");
        document.body.appendChild(script);

        return (

            <header className={`header-area formobile-menu header--fixed default-color`}>
                {/* <button className="chat_btn popup" onClick={(e) => this.showPopup()} > get a quote</button> */}
                <div className="header-wrapper" id="header-wrapper">
                    <div className="header-left">
                        <div className="logo">
                            <Link to="/">
                                {this.props.ourproduct === 'dark' ?
                                    <img className="logo-1" src="/assets/images/logo/logo-light-new-05.svg" alt="Logo1 " /> :
                                    <img className="logo-1" src="/assets/images/logo/logo-dark-new-04.svg" alt="Logo1 " />}

                                <img className="logo-2" src="/assets/images/logo/logo-light-new-05.svg" alt="Logo2 " />
                            </Link>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu">
                                <li><Link to="/">Home</Link>
                                </li>
                                <li className="has-droupdown"><Link to="/about" >About</Link>
                                    <ul className="submenu">
                                        <li><Link to='/about'>About</Link></li>
                                        <li><Link to='/gallery'>Gallery</Link></li>
                                        <li><Link to='/careers'>Careers</Link></li>
                                        <li><Link to="/tech-stack">Tech-Stack</Link></li>
                                    </ul>
                                </li>
                                {/* <li><Link to="/about">About</Link></li> */}
                                <li><Link to="/our-services" >Services</Link>
                                    {/* <ul className="submenu">
                                        <li><Link to="/service">Service</Link></li>
                                        <li><Link to="/service-details">Service Details</Link></li>
                                    </ul> */}
                                </li>
                                <li><Link to="/industries" >Industries</Link>
                                </li>

                                <li><Link to="/portfolio" >Portfolio</Link>
                                </li>
                                <li><Link to="/healthtech" >Healthtech</Link>
                                </li>
                                <li><Link to="/our-products">Our Products</Link></li>
                                <li><Link to="/contact-us" >Contact</Link></li>
                            </ul>
                        </nav>


                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default Header;