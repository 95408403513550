import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import { FaGlobe } from "react-icons/fa";
import PlatformUsed from "../component/Verbos/PlatformUsed";
import PrimaryCareDelivrables from "./PrimaryCareDeliverables";
import CommonPortfolioButtons from "../component/CommonPortfolioButton";
import CommonBreadcrumb from "../component/common/CommonBreadcrumb";
import PortfolioJson from "./portfolioJson";
const ProductCommonApp = React.lazy(() => import("./ProductCommonAppdemo"));
const CommonProductWeb = React.lazy(() => import("./CommonProductWeb"));
const VerbosSimilarApps = React.lazy(() => import("../component/Verbos/VerbosSimilarApps"));


const SocialShare = [
    { Social: <FaGlobe />, link: ' https://lpcfederate.co.uk/' }
]
const deliverables = [
    { item_bg: 'android.png', item_icon: 'primarynurse.png', h3: 'Practice Nurse ', h4: 'Android App' },
    { item_bg: 'ios.png', item_icon: 'primarynurse.png', h3: 'Practice Nurse ', h4: 'iOS App' },
    { item_bg: 'web.png', item_icon: 'primarynurse.png', h3: 'Practice Nurse ', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'primarydoctor.png', h3: 'Doctor ', h4: 'Android App' },
    { item_bg: 'android.png', item_icon: 'primarydoctor.png', h3: 'Doctor ', h4: 'iOS App' },
    { item_bg: 'web.png', item_icon: 'primarydoctor.png', h3: 'Doctor ', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'primaryadvance.png', h3: 'Advanced Nurse  ', h4: 'Android App' },
    { item_bg: 'ios.png', item_icon: 'primaryadvance.png', h3: 'Advanced Nurse', h4: 'iOS App' },
    { item_bg: 'web.png', item_icon: 'primaryadvance.png', h3: 'Advanced Nurse ', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'primaryhealthcare.png', h3: 'Healthcare Assistant ', h4: 'Android App' },
    { item_bg: 'ios.png', item_icon: 'primaryhealthcare.png', h3: 'Healthcare Assistant', h4: 'iOS App' },
    { item_bg: 'web.png', item_icon: 'primaryhealthcare.png', h3: 'Healthcare Assistant', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'primarypharma.png', h3: 'Clinical Pharmacist  ', h4: 'Android App' },
    { item_bg: 'ios.png', item_icon: 'primarypharma.png', h3: 'Clinical Pharmacist  ', h4: 'iOS App' },
    { item_bg: 'web.png', item_icon: 'primarypharma.png', h3: 'Clinical Pharmacist  ', h4: 'Web' },
    { item_bg: 'android.png', item_icon: 'primaryadministrative.png', h3: 'Administrative Staff  ', h4: 'Android App' },
    { item_bg: 'ios.png', item_icon: 'primaryadministrative.png', h3: 'Administrative Staff  ', h4: 'iOS App' },
    { item_bg: 'web.png', item_icon: 'primaryadministrative.png', h3: 'Administrative Staff  ', h4: 'Web' },
    { item_bg: 'web.png', item_icon: 'primaryadmin.png', h3: 'Admin ', h4: 'Web' },
]
const technologies = [
    { h5: 'Angular', p: 'Admin Panel Development', img: 'angular.png' },
    { h5: 'Swift', p: 'Android & iOS development', img: 'swift.png' },
    { h5: '.Net', p: 'Backend Development', img: 'dotnet.png' },
    { h5: 'Java', p: 'Backend Development', img: 'java.png' },
]
const similarapps = [
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #639cd9 0%, #4636fc 100%)', link: '/healthcare-insurance-marketplace', img: 'healthcare.webp', h2: 'DocFinder', p: 'Healthcare Insurance Marketplace' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)', link: '/covid-antigen-test-verification', img: 'covid.webp', h2: 'Fasterchecks', p: 'Covid Antigen Test Verification' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)', link: '/doctor-appointment-and-online-pharmacy', img: 'mediconnect.webp', h2: 'MediConnect', p: 'Doctor Appointment and pharmacy' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)', link: '/nursing-platform', img: 'nursing.webp', h2: 'NursePortal', p: 'Nursing Platform' },
    { backgroundcolor: '-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)', link: '/pharmacist-marketplace', img: 'electronic.webp', h2: 'HealthConnect', p: 'Pharmacist Marketplace' },
]
const userAppButtonNames = ['Signup', 'Login', 'Dashboard', 'Sub Menu', 'Doctor Profile', 'Document verification', 'Leaves', 'Jobs Feedback', 'Training', 'Search Jobs', 'Library'];
const userAppImages = [
    '/assets/images/portfolio/primary-care/app-02.webp',
    '/assets/images/portfolio/primary-care/app-03.webp',
    '/assets/images/portfolio/primary-care/app-04.webp',
    '/assets/images/portfolio/primary-care/app-05.webp',
    '/assets/images/portfolio/primary-care/app-06.webp',
    '/assets/images/portfolio/primary-care/app-07.webp',
    '/assets/images/portfolio/primary-care/app-08.webp',
    '/assets/images/portfolio/primary-care/app-09.webp',
    '/assets/images/portfolio/primary-care/app-10.webp',
    '/assets/images/portfolio/primary-care/app-11.webp',
    '/assets/images/portfolio/primary-care/app-12.webp',
];
const adminButtonNames = ['Home', 'Login', 'Dashboard', 'GP Surgeries', 'Documents', 'Notifications', 'Jobs', 'Job Master', 'Contracts & Aggrements', 'Admin'];
const adminImaageAddress = [
    '/assets/images/portfolio/primary-care/admin-1.webp',
    '/assets/images/portfolio/primary-care/admin-2.webp',
    '/assets/images/portfolio/primary-care/admin-3.webp',
    '/assets/images/portfolio/primary-care/admin-4.webp',
    '/assets/images/portfolio/primary-care/admin-5.webp',
    '/assets/images/portfolio/primary-care/admin-6.webp',
    '/assets/images/portfolio/primary-care/admin-7.webp',
    '/assets/images/portfolio/primary-care/admin-8.webp',
    '/assets/images/portfolio/primary-care/admin-9.webp',
    '/assets/images/portfolio/primary-care/admin-10.webp',
];
const PrimaryCareData = {
    pageTitle: "primary-care-management-system",
    metaTags: {
        pageTitle: "Primary Care || Noorisys Technologies Pvt Ltd",
        pageUrl: "/primary-care-management-system",
        metaTitle: "Primary Care Management System",
        metaImage: "/assets/images/portfolio/dp-portfolio-07.jpg",
        metaDescription:
            "Primary Care Management System is a platform for the healthcare industry to build strong professional networks between Administrators, Doctors, Advanced Nurse App Practitioners, Clinical pharmacists, Healthcare Assistants and Admin Staff.",
    },
    breadCrumbs: {
        title: "Primary Care Management System",
        customColor: "custom-primary-care-color",
        dataBlackOverlay: 7,
        bgImage: "bg_image--2",
        p: "Web / Android / iOS Application ",
        pb: "pb--120",
    },
    socialShare: [{ Social: <FaGlobe />, link: " https://lpcfederate.co.uk/" }],
    deliverables: [
        {
            item_bg: "android.png",
            item_icon: "primarynurse.png",
            h3: "Practice Nurse ",
            h4: "Android App",
        },
        {
            item_bg: "ios.png",
            item_icon: "primarynurse.png",
            h3: "Practice Nurse ",
            h4: "iOS App",
        },
        {
            item_bg: "web.png",
            item_icon: "primarynurse.png",
            h3: "Practice Nurse ",
            h4: "Web",
        },
        {
            item_bg: "android.png",
            item_icon: "primarydoctor.png",
            h3: "Doctor ",
            h4: "Android App",
        },
        {
            item_bg: "android.png",
            item_icon: "primarydoctor.png",
            h3: "Doctor ",
            h4: "iOS App",
        },
        {
            item_bg: "web.png",
            item_icon: "primarydoctor.png",
            h3: "Doctor ",
            h4: "Web",
        },
        {
            item_bg: "android.png",
            item_icon: "primaryadvance.png",
            h3: "Advanced Nurse  ",
            h4: "Android App",
        },
        {
            item_bg: "ios.png",
            item_icon: "primaryadvance.png",
            h3: "Advanced Nurse",
            h4: "iOS App",
        },
        {
            item_bg: "web.png",
            item_icon: "primaryadvance.png",
            h3: "Advanced Nurse ",
            h4: "Web",
        },
        {
            item_bg: "android.png",
            item_icon: "primaryhealthcare.png",
            h3: "Healthcare Assistant ",
            h4: "Android App",
        },
        {
            item_bg: "ios.png",
            item_icon: "primaryhealthcare.png",
            h3: "Healthcare Assistant",
            h4: "iOS App",
        },
        {
            item_bg: "web.png",
            item_icon: "primaryhealthcare.png",
            h3: "Healthcare Assistant",
            h4: "Web",
        },
        {
            item_bg: "android.png",
            item_icon: "primarypharma.png",
            h3: "Clinical Pharmacist  ",
            h4: "Android App",
        },
        {
            item_bg: "ios.png",
            item_icon: "primarypharma.png",
            h3: "Clinical Pharmacist  ",
            h4: "iOS App",
        },
        {
            item_bg: "web.png",
            item_icon: "primarypharma.png",
            h3: "Clinical Pharmacist  ",
            h4: "Web",
        },
        {
            item_bg: "android.png",
            item_icon: "primaryadministrative.png",
            h3: "Administrative Staff  ",
            h4: "Android App",
        },
        {
            item_bg: "ios.png",
            item_icon: "primaryadministrative.png",
            h3: "Administrative Staff  ",
            h4: "iOS App",
        },
        {
            item_bg: "web.png",
            item_icon: "primaryadministrative.png",
            h3: "Administrative Staff  ",
            h4: "Web",
        },
        {
            item_bg: "web.png",
            item_icon: "primaryadmin.png",
            h3: "Admin ",
            h4: "Web",
        },
    ],
    deliverablesProps: {
        title: "19 Deliverables",
        class: "primary-care",
        value: "[5,4]",
        margin: true,
        colorCode: "298d9c"
    },
    technologies: [
        { h5: "Angular", p: "Admin Panel Development", img: "angular.png" },
        { h5: "Swift", p: "Android & iOS development", img: "swift.png" },
        { h5: ".Net", p: "Backend Development", img: "dotnet.png" },
        { h5: "Java", p: "Backend Development", img: "java.png" },
    ],
    similarApps: {
        items: [
            {
                backgroundcolor:
                    "-webkit-radial-gradient(50% 50%, circle closest-side, #639cd9 0%, #4636fc 100%)",
                link: "/healthcare-insurance-marketplace",
                img: "healthcare.webp",
                h2: "DocFinder",
                p: "Healthcare Insurance Marketplace",
            },
            {
                backgroundcolor:
                    "-webkit-radial-gradient(50% 50%, circle closest-side, #fec24a 0%, #fd7e14 100%)",
                link: "/covid-antigen-test-verification",
                img: "covid.webp",
                h2: "Fasterchecks",
                p: "Covid Antigen Test Verification",
            },
            {
                backgroundcolor:
                    "-webkit-radial-gradient(50% 50%, circle closest-side, #5dacbd 0%, #298d9c 100%)",
                link: "/doctor-appointment-and-online-pharmacy",
                img: "mediconnect.webp",
                h2: "MediConnect",
                p: "Doctor Appointment and pharmacy",
            },
            {
                backgroundcolor:
                    "-webkit-radial-gradient(50% 50%, circle closest-side, #93efed 0%, #22d1ee 100%)",
                link: "/nursing-platform",
                img: "nursing.webp",
                h2: "NursePortal",
                p: "Nursing Platform",
            },
            {
                backgroundcolor:
                    "-webkit-radial-gradient(50% 50%, circle closest-side, #53bfd0 0%,#1296CC 100%)",
                link: "/pharmacist-marketplace",
                img: "electronic.webp",
                h2: "HealthConnect",
                p: "Pharmacist Marketplace",
            },
        ],
        title: "Other Apps",
        slidesToShow: [3, 3],
    },
    screens: [
        {
            type: "app",
            appButtonNames: [
                "Signup",
                "Login",
                "Dashboard",
                "Sub Menu",
                "Doctor Profile",
                "Document verification",
                "Leaves",
                "Jobs Feedback",
                "Training",
                "Search Jobs",
                "Library",
            ],
            appImages: [
                "/assets/images/portfolio/primary-care/app-02.webp",
                "/assets/images/portfolio/primary-care/app-03.webp",
                "/assets/images/portfolio/primary-care/app-04.webp",
                "/assets/images/portfolio/primary-care/app-05.webp",
                "/assets/images/portfolio/primary-care/app-06.webp",
                "/assets/images/portfolio/primary-care/app-07.webp",
                "/assets/images/portfolio/primary-care/app-08.webp",
                "/assets/images/portfolio/primary-care/app-09.webp",
                "/assets/images/portfolio/primary-care/app-10.webp",
                "/assets/images/portfolio/primary-care/app-11.webp",
                "/assets/images/portfolio/primary-care/app-12.webp",
            ],
            h3: "Technology Platform For Local Primary Care",
            p: "With the help of experts in developing technology platforms and solutions, LPC have designed a bespoke digital solution to support and sustain the future for the Primary Care workforce and GP services in Blackburn with Darwen.",
            alt: "primary care user",
            color: "primary-care",
        },
        {
            type: "web",
            adminButtonNames: [
                "Home",
                "Login",
                "Dashboard",
                "GP Surgeries",
                "Documents",
                "Notifications",
                "Jobs",
                "Job Master",
                "Contracts & Aggrements",
                "Admin",
            ],
            adminImageAddress: [
                "/assets/images/portfolio/primary-care/admin-1.webp",
                "/assets/images/portfolio/primary-care/admin-2.webp",
                "/assets/images/portfolio/primary-care/admin-3.webp",
                "/assets/images/portfolio/primary-care/admin-4.webp",
                "/assets/images/portfolio/primary-care/admin-5.webp",
                "/assets/images/portfolio/primary-care/admin-6.webp",
                "/assets/images/portfolio/primary-care/admin-7.webp",
                "/assets/images/portfolio/primary-care/admin-8.webp",
                "/assets/images/portfolio/primary-care/admin-9.webp",
                "/assets/images/portfolio/primary-care/admin-10.webp",
            ],
            h3: "Admin Panel",
            p: "Advanced web admin panel with well-structured code that controls every aspect of users, doctor and Hospital . A dashboard that helps to analyze overall growth and informative graphs/charts makes it more understandable.",
            alt: "primary care admin",
            color: "primary-care",
        },
    ],
    title: "Primary Care Management System",
    p: [
        "Primary Care Management System is a platform for the healthcare industry to build strong professional networks between GP Administrators, Doctors, Advanced Nurse App Practitioners, Clinical pharmacists, Healthcare Assistants and Admin Staff.",
        `This platform helps in hiring the medicos and provides primary care staff seamlessly using any handheld device or computer. It enables doctors to easily apply for specific desired opportunities across locations, manage their professional profiles and network with other doctors. This system also comes built with customizable dashboards for hospital administrators to create and publish rotas, advertise jobs, verify confidential documents and approve payments.`,
        `Primary Care Management  System is a robust, integrated platform that has evolved over many years of understanding the core needs of hospital management by the people who have worked on the front line of the NHS hospital structure. Today it is available as a lightweight downloadable app that each user effectively to manage rota, easily apply for open job positions and facilitate smooth payments.`,
    ],
    features: {
        title: "Features",
        li: [
            "Hiring in medical field made easy.",
            "Powers up your entire recruitment lifecycle.",
            "Profile management.",
            "Secure document management.",
            "Payment approvals.",
            "Smart collaboration.",
            "Advertise jobs.",
            "Leave management.",
            "Notifications in system.",
            "Push notifications on mobile and WhatsApp.",
            "Job search and resume development.",
            "Interactive dashboard.",
            "Makes finding available doctors a simple task by eliminating agencies.",
            "View and manage invoices. Track payments for completed shifts.",
            "View pending payments and effectively manage all accounting needs for the shifts assigned and/or completed.",
            "Cross communicate with hospital, admins, accounting staff and other doctors can bring a cohesive work environment.",
        ],
        colorCode: "298d9c",
    },
    titleClass: "custom-primary-care-color",
    portfolioButton: {
        class: "btn-primary-care",
        text: "View All Our Projects",
        padding: "pb--20",
    },
    pageName: 'btn-primary-care'
}
class HospitalPortfolioDetails extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.pathname) {
            window.gtag("config", "G-F2DRSMH4G3", {
                page_title: this.props.location.pathname,
                page_path: this.props.location.pathname,
            })
            var _hsq = window._hsq = window._hsq || [];
            _hsq.push(['setPath', this.props.location.pathname]);
            _hsq.push(['trackPageView']);
        }
    }
    constructor() {
        super()
        this.state = {
            isVisible: false
        }
        this.showPopup = this.showPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);
    }
    showPopup() {
        this.setState({ isVisible: true })
        window.lintrk('track', { conversion_id: 18604321 });
    }
    closePopup() {
        this.setState({ isVisible: false })

    }
    render() {
        // return (
        //     <React.Fragment>
        //         <PageHelmet pageTitle='Primary Care || Noorisys Technologies Pvt Ltd' pageUrl='/primary-care-management-system' metaTitle="Primary Care Management System" metaImage="/assets/images/portfolio/dp-portfolio-07.jpg"
        //             metaDescription="Primary Care Management System is a platform for the healthcare industry to build strong professional networks between Administrators, Doctors, Advanced Nurse App Practitioners, Clinical pharmacists, Healthcare Assistants and Admin Staff." />

        //         <Header />

        //         {/* Start Breadcrump Area */}
        //         <CommonBreadcrumb
        //             title='Primary Care Management System'
        //             customColor='custom-primary-care-color'
        //             dataBlackOverlay={7}
        //             bgImage='bg_image--2'
        //             p='Web / Android / iOS Application '
        //             pb='pb--120'
        //             socialShare={SocialShare}
        //         />
        //         {/* End Breadcrump Area */}

        //         {/* Start Portfolio Details */}

        //         <div className="rn-portfolio-details ptb--60 bg_color--1">
        //             <div className="container">
        //                 <div className="row">
        //                     <div className="col-lg-12">
        //                         <div className="portfolio-details">
        //                             <div className="inner">
        //                                 {/* <h4><span>TECHNICAL DETAILS</span></h4>
        //                                 <div className="portfolio-view-list d-flex flex-wrap pb--20">
        //                                     <div className="port-view">
        //                                         <span>Category</span>
        //                                         <h6>Web / Android / iOS Application</h6>
        //                                     </div>

        //                                     <div className="port-view">
        //                                         <span>Project Type</span>
        //                                         <h6>Hospital Management </h6>
        //                                     </div>

        //                                     <div className="port-view">
        //                                         <span>Technology Used</span>
        //                                         <h6>.NET (C#),Android, JAVA, iOS, Swift, AngularJS</h6>
        //                                     </div>
        //                                 </div> */}
        //                                 <h3 className="rn-page-title custom-primary-care-color">Primary Care Management System</h3>
        //                                 {/* <p className="subtitle">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p> */}
        //                                 <p className="justifypara">Primary Care Management System is a platform for the healthcare industry to build strong professional networks between GP Administrators, Doctors, Advanced Nurse App Practitioners, Clinical pharmacists, Healthcare Assistants and Admin Staff.</p>
        //                                 <p className="justifypara">This platform helps in hiring the medicos and provides primary care staff seamlessly using any handheld device or computer. It enables doctors to easily apply for specific desired opportunities across locations, manage their professional profiles and network with other doctors. This system also comes built with customizable dashboards for hospital administrators to create and publish rotas, advertise jobs, verify confidential documents and approve payments.</p>
        //                                 <p className="justifypara">Primary Care Management  System is a robust, integrated platform that has evolved over many years of understanding the core needs of hospital management by the people who have worked on the front line of the NHS hospital structure. Today it is available as a lightweight downloadable app that each user effectively to manage rota, easily apply for open job positions and facilitate smooth payments.</p>
        //                                 <h4 className="title">Features</h4>
        //                                 <ul className="list-style--1">
        //                                     <li className="justifypara"><FiCheck color='298d9c' />Hiring in Medical field made easy.</li>
        //                                     <li className="justifypara"><FiCheck color='298d9c' />Powers up your entire recruitment lifecycle.</li>
        //                                     <li className="justifypara"><FiCheck color='298d9c' />Profile management.</li>
        //                                     <li className="justifypara"><FiCheck color='298d9c' />Secure document management.</li>
        //                                     <li className="justifypara"><FiCheck color='298d9c' />Payment approvals.</li>
        //                                     <li className="justifypara"><FiCheck color='298d9c' />Smart collaboration.</li>
        //                                     <li className="justifypara"><FiCheck color='298d9c' />Advertise jobs.</li>
        //                                     <li className="justifypara"><FiCheck color='298d9c' />Leave Management.</li>
        //                                     <li className="justifypara"><FiCheck color='298d9c' />Notifications in system.</li>
        //                                     <li className="justifypara"><FiCheck color='298d9c' />Push notifications on mobile and WhatsApp.</li>
        //                                     <li className="justifypara"><FiCheck color='298d9c' />Job search and resume development.</li>
        //                                     <li className="justifypara"><FiCheck color='298d9c' />Interactive dashboard.</li>
        //                                     <li className="justifypara"><FiCheck color='298d9c' />Makes finding available doctors a simple task by eliminating agencies.</li>
        //                                     <li className="justifypara"><FiCheck color='298d9c' />View and manage invoices. Track payments for completed shifts.</li>
        //                                     <li className="justifypara"><FiCheck color='298d9c' />View pending payments and effectively manage all accounting needs for the shifts assigned and/or completed.</li>
        //                                     <li className="justifypara"><FiCheck color='298d9c' />Cross communicate with hospital, admins, accounting staff and other doctors can bring a cohesive work environment.</li>




        //                                 </ul>



        //                                 {/* <div className="portfolio-share-link mt--20 pb--70 pb_sm--40">
        //                                     <ul className="social-share rn-lg-size d-flex justify-content-start liststyle mt--15">
        //                                         {SocialShare.map((val, i) => (
        //                                             <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
        //                                         ))}
        //                                     </ul>
        //                                 </div> */}
        //                             </div>
        //                             {/* <div className="portfolio-thumb-inner">
        //                                 <div className="thumb position-relative mb--30">
        //                                     <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
        //                                     <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
        //                                     <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
        //                                 </div>

        //                                 <div className="thumb mb--30">
        //                                     <img src="/assets/images/portfolio/portfolio-big-02.jpg" alt="Portfolio Images"/>
        //                                 </div>

        //                                 <div className="thumb">
        //                                     <img src="/assets/images/portfolio/portfolio-big-01.jpg" alt="Portfolio Images"/>
        //                                 </div>
        //                             </div> */}
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //         {/* End Portfolio Details */}
        //         <div className="rn-portfolio-area bg_color--3 ptb--80" style={{ backgroundColor: '#298d9c' }} data-black-overlay='3'>
        //             <div className="container" >
        //                 <PrimaryCareDelivrables title={'19 Deliverables'} items={deliverables} class={'primary-care'} value={[5, 4]} margin={true} />
        //             </div>
        //         </div>
        //         <div className="ptb--90">
        //             <div className="container">
        //                 <PlatformUsed color='custom-primary-care-color' items={technologies} />
        //             </div>
        //         </div>
        //         {/* Start portfolio Area  */}
        //         <div className="rn-portfolio-area bg_color--1 ">
        //             <div className="container">
        //                 <div className="row">
        //                     <React.Suspense fallback={<div>loading...</div>} >
        //                         <ProductCommonApp
        //                             h3='Technology Platform For Local Primary Care'
        //                             p='With the help of experts in developing technology platforms and solutions, LPC have designed a bespoke digital solution to support and sustain the future for the Primary Care workforce and GP services in Blackburn with Darwen.'
        //                             buttonNames={userAppButtonNames}
        //                             image={userAppImages}
        //                             alt='primary care user'
        //                             color='primary-care'
        //                         />
        //                         <CommonProductWeb
        //                             h3='Admin Panel'
        //                             p='Advanced web admin panel with well-structured code that controls every aspect of users, doctor and Hospital . A dashboard that helps to analyze overall growth and informative graphs/charts makes it more understandable.'
        //                             buttonNames={adminButtonNames}
        //                             image={adminImaageAddress}
        //                             alt='primary care admin'
        //                             color='primary-care'
        //                         />
        //                     </React.Suspense>
        //                 </div>
        //             </div>
        //             <React.Suspense fallback={<div>loading...</div>} >
        //                 <VerbosSimilarApps color='custom-primary-care-color' title={'Other Apps'} items={similarapps} slidestoshow={[3, 3]} />
        //             </React.Suspense>
        //         </div>
        //         <CommonPortfolioButtons class='btn-primary-care' text='View All Our Projects' padding='pb--20' />
        //         {/* End portfolio Area  */}



        //         {/* Start Back To Top */}
        //         <div className="backto-top">
        //             <ScrollToTop showUnder={160}>
        //                 <FiChevronUp />
        //             </ScrollToTop>
        //         </div>
        //         {/* End Back To Top */}

        //         <Footer />


        //     </React.Fragment>
        // )
        return (
            <PortfolioJson
                metaTags={PrimaryCareData.metaTags}
                breadCrumbs={PrimaryCareData.breadCrumbs}
                socialShare={PrimaryCareData.socialShare}
                title={PrimaryCareData.title}
                p={PrimaryCareData.p}
                features={PrimaryCareData.features}
                colorCode={PrimaryCareData.features.colorCode}
                deliverablesProps={PrimaryCareData.deliverablesProps}
                deliverables={PrimaryCareData.deliverables}
                technologies={PrimaryCareData.technologies}
                titleClass={PrimaryCareData.titleClass}
                screens={PrimaryCareData.screens}
                similarApps={PrimaryCareData.similarApps}
                portfolioButton={PrimaryCareData.portfolioButton}
                primaryCare={true}
                isVisible={this.state.isVisible}
                showPopup={this.showPopup}
                closePopup={this.closePopup}
                getAquote={true}
                pageName={PrimaryCareData.pageName}
            />
        )
    }
}
export default HospitalPortfolioDetails;
