import React from "react";
import { Link } from 'react-router-dom';

const CommonPortfolioButtons = (props) => {
    return (
        <div className={`wrapper ${props.padding}`}>
            <div className="container">
                <div className="row ">
                    <div className="col-lg-12">
                        <div className="view-more-btn mt--40 mb--30 text-center">
                            <Link className={`rn-button-style--2 ${props.class}`} to={props.link !== undefined ? props.link : "/portfolio"}><span>{props.text}</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CommonPortfolioButtons