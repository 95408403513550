// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

import CreativeHome from './creative_homePage/CreativeHome';
import ContactUs from './creative_homePage/Contact';
import RequestQoute from './creative_homePage/RequestQoute';

// import GroceryPortfolioDetail from './portfolio/PortfolioDetails';
// import CourierPortfolioDetal from './portfolio/CourierPortfolioDetails';
// import GroceryPortfolioDetail from './portfolio/GroceryPortfolioDetails';
// import HealthCarePortfolioDetail from './portfolio/HealthcarePortfolioDetails';
import HospitalPortfolioDetail from './portfolio/HospitalPortfolioDetails';
// import SocalrshipPortfolioDetail from './portfolio/ScholarshipPortfolio';
import TourismPortfolioDetail from './products/TourismPortfolio';
// import HotelPortfolioDetail from './portfolio/HotelPortfolioDetails';
import PrimaryCare from './portfolio/PrimaryCare';
// import TicketBookingPlatforms from './portfolio/TicketBookingPortfolioDetails';
// import MultingualPregnancy from './portfolio/MultingualPregnacyPortfolio';
import FasterChecks from './portfolio/FasterChecksPortfolioDetails';
import Portery from './portfolio/PorteryPortfolioDetails';
import NursingPlatform from './portfolio/NursingPlatformPortfolioDetails';
import OnlineBook from './portfolio/OnlineBookShopPortfolioDetails';
import OnlineShop from './portfolio/OnlineShopPortfoloDetails';
import ELearning from './portfolio/ElearningPortalPortfolioDetails';
import ResearcherPersonal from './portfolio/ResearcherPersonalPortfolioDetails';
import DeliveryManagement from './portfolio/DeliveryManagmentPortfolioDetails';
import GovermentFinancial from './portfolio/GovernmentFinancialPortfolioDetails';
import ElectronicMedical from './portfolio/ElectronicMedicalPortfolioDetails';
import PharmacistMarketplace from './portfolio/PharmacistMarketplacePortfolioDetails';
import DietApp from './portfolio/DietAppPortfolioDetails';
import CouponsAndCashback from './portfolio/Coupons&CashbackPortfolioDetails';
import DoctorAppointment from './portfolio/DoctorAppointmentandHospitalPortfolioDetails';
import ERP from './portfolio/ERPForOilComponyPortfolioDetails';
import EventManagement from './portfolio/EventManagmentPortfolioDetails';
import LeadManagement from './portfolio/LeadManagmentPortfolioDetails';
import MedicationApp from './portfolio/MeditationAppPortfolioDetails';
import OnlineFood from './portfolio/OnlineFoodDeliveryPortfolioDetails';
import OnlinePayment from './portfolio/OnlinePaymentPortfolioDetails';
import SchoolManagement from './portfolio/SchoolManagmentPortfolioDetails';
import AssetManagement from './portfolio/AssestManagmentPortfolioDetails';
import PropertyShowcase from './portfolio/PropertyShowcasePortfolioDetails';

import Blogs from './blogs/Blog';
import ChatBots from './blogs/ChatbotsBlogDetails';
import ConnectedWorld from './blogs/ConnectedBlogDetails';
import DigitalTwin from './blogs/DigitalTwinBlogDetails';
import HealthCareBlog from './blogs/HealthcareBlogDetails';
import HyperAutomation from './blogs/HyperautomationBlogDetails';
import iPass from './blogs/ipassRestBlogDetails';
import LifiBlog from './blogs/LifiBlogDetails';
import Metaverse from './blogs/MetaverseBlogDetails';
import NewTech from './blogs/NewTechBlogDetails';
import RestAPI from './blogs/RestBlogDetails';

import WebSiteDevelopment from './websitedevelopment_landing/WebsiteDevelopment';
import MobileDevelopment from './mobiledevelopment_landing/MobileDevelopment';
import SupportServicesDevelopment from './supportservices_landing/SupportServices';

import TechStack from './tech_stack/TechStack';
import NodeJS from './tech_stack/NodeJS';
import Angular from './tech_stack/Angular';
import CI4 from './tech_stack/CI4';
import DotNet from './tech_stack/DotNet';
import Flutter from './tech_stack/Flutter';
import Java from './tech_stack/Java';
import Kotlin from './tech_stack/Kotlin';
import Laravel from './tech_stack/Laravel';
import ObjectiveC from './tech_stack/Objectivec';
import Python from './tech_stack/Python';
import ReactJS from './tech_stack/Reactjs';
import ReactNative from './tech_stack/Reactnative';
import Shopify from './tech_stack/Shopify';
import Swift from './tech_stack/Swift';
import WordPress from './tech_stack/Wordpress';



import Our_approch from './about/Our_approch';



import OurServices from './our_services/OurService';
import Industries from './Industries/Industries';
import HeathCare from './Industries/HealthCare';
import Fintech from './Industries/Fintech';
import Logistics from './Industries/Logistics';
import eCommerce from './Industries/eCommerce';
import Tourism from './Industries/Tourism';


import PersonalPortfolio from './home/PersonalPortfolio';
import InteriorLanding from './home/Interior';
import error404 from "./elements/error404";
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Link from 'antd/es/typography/Link';
import * as serviceWorker from './serviceWorker';
import HajjApplicationPortfolioDetails from './portfolio/HajjApplicationPortfolioDetails';
import HideNumbersPortfolioDetails from './portfolio/HideNumbersPortfolioDetails';
import MemoriesPortfolioDetails from './portfolio/MemoriesPortfolioDetails';
import PowerloomInvoicePortfolioDetails from './portfolio/PowerloomInvoicePortfolioDetails';
import ServiceCenterPortfolioDetails from './portfolio/ServiceCenterPortfolioDetails';
import SocialAppPortfolioDetails from './portfolio/SocialAppPortfolioDetails';
import VehicleManagementPortfolioDetails from './portfolio/VehicleManagmentPortfolioDetails';
import VolkPortfolioDetails from './portfolio/VolkPortfolioDetails';
import ChoiceMakingPortfolioDetails from './portfolio/ChoiceMakingPortfolio';
// import ClinicPresentationPortfolioDetails from './portfolio/ClinicPresentationPortfolioDetails';
import DiscoveryMusicPortfolioDetails from './portfolio/DiscoveryMusicPortfolioDetails';
import DoctorPersonalPortfolioDetails from './portfolio/DoctorPersonalPortfolioDetails';
// import HabitreePortfolioDetails from './portfolio/HabitreePortfolioDetails';
import OnlineLaundaryPortfolioDetails from './portfolio/OnlineLaundryPortfolioDetails';
import OnlineKiranaPortfolioDetails from './portfolio/OnlineKiranaPortfolioDetails';
import FreelancingPortalPortfolioDetails from './portfolio/FreelancingPortalPortfolioDetails';
import CovidAntigenPortfolioDetails from './portfolio/CovidAntigenPortfolio';
import CookieModal from './popup/CookieModal';
import CookieConsen from 'react-cookie-consent'
import Career from './home/Career';
// import PortfolioTab from './portfolio/DesignerPortfolio';
import CreativePortfolio from './home/CreativePortfolio';
import About from './about/About'
import CareerWebDevelopment from './component/Career/CareerWebDevelopment';
import CareerMobileApp from './component/Career/Career_Mobile_App';
import CareerUXUI from './component/Career/Career_UX_UI';
import CareerFinanceHR from './component/Career/Career_Finance_HR';
import CareerSalesMarketing from './component/Career/Career_Sales_Marketing';
import CareerCustomerSupport from './component/Career/Career_Customer_support';
import PrivacyPolicy from './component/common/PrivacyPolicy';
import CookiesPolicy from './component/common/CookiesPolicy';
// import SliderPortfolio from './component/Verbos/SliderPortfolio';
import HealthTech from './Industries/HealthTech';
// import FooterType from './component/Verbos/FooterType';
// import TabGallery from './component/Verbos/Tabexample';
// import Delivrable from './component/Verbos/Deleverable';
// import VerbosSimilarApps from './component/Verbos/VerbosSimilarApps';
// import PlatformUsed from './component/Verbos/PlatformUsed';
// import CrusierMedicalPortfolio from './portfolio/CoursierMedicalPortfolio';
// import DownloadAppDemo from './component/Verbos/DownloadAppDemo';
// import Download1 from './component/Verbos/Download1';
// import Download2 from './component/Verbos/Download2';
// import CommonAppDemo from './portfolio/CommonAppDemo';
import RiseOfAI from './blogs/RiseOfArtificialInteligence';
import WebDevelopmentTrend from './blogs/Webdevelopmenttrendblog';
import Portfolio from './portfolio/Portfolio';
// import UmrahVisaPortfolioDetails from './portfolio/UmrahVisaPortfolioDetails';
import HostingYourDreamsBlog from './blogs/HostingYourDreamsBlog';
import AgileMethodologyBlog from './blogs/AgileMethodologyBlog';
// import DigitalSolutionForCivilDocuments from './portfolio/DigitalSolutionForCivil';
import CarSpacePortfolio from './portfolio/CarSpacePortfolio';
import InfluencerMarketPlacePortfolio from './portfolio/InfluencerMarketPlacePortfolio';
import DataProtectionAssesment from './data-assesment/DataProtectionAssesment';
import Sitemap from './creative_homePage/sitemap';
import FixMeeting from './Industries/FixaMeeting';
// import DubaiStall from './Industries/DubaiStall';
import WelzoBlog from './blogs/WelzoBlog';
import OurProductsTechTrailcopy from './Industries/OurProductsTechTrail copy';
import LearningManagement from './products/LearningManagement';
import ServiceMarketPlace from './products/ServiceMarket';
import FoodDelivery from './products/FoodeDelivery';
import SocialMediaPlatform from './products/SocialMediaPlatform';
import SchoolManagementOurProducts from './products/SchoolManagementourproducts';
import DoctorAppointmentProduct from './products/DoctorAppointmentProduct';
import TukusipanPortfolio from './portfolio/TukusipanPortfolio';
import MatnessAppPortfolio from './portfolio/MatnessAppPortfolio';
import BTKPortfolio from './portfolio/BTKportfolio';
import QueueManagementPortfolio from './portfolio/QueuemanagementPortfolio';
import OTAPortfolio from './portfolio/OTAPortfolio';
import MugenziEMRProduct from './products/MugenziEMRProduct';
import CollectionPortfolio from './portfolio/CollectionPortfolio';
import StudyMatchingPortfolio from './portfolio/StudyMatchingProduct';
import QysmatPortfolio from './portfolio/QysmatProduct';
import HomeCleaningPortfolio from './portfolio/homeCleanongPortfolio';
import ServiceManagementPortfolio from './portfolio/ServiceManagementPortfolio';
import SocialMediaPlatformPortfolio from './portfolio/SocialMediaPlatformPortfolio';
import DigitalBusinessCardProduct from './products/DigitalBusinessCardProduct';
import NDashakaPortfolio from './portfolio/NDashakaPortfolio';
import RealStatePortfolio from './portfolio/RealStatePortfolio';
import PharmacyOrderingProduct from './products/PharmacyOrderingProduct';
import SocialMediaMarketingToolPortfolio from './portfolio/SocialMediaMarketingTool';
import HelpDeskProduct from './products/HelpDeskProduct';
import TransportManagementSystem from './products/TransportManagementSystem';
import WorldHealthDay from './blogs/WorldHealthDay';
import CacheBuster from 'react-cache-buster'
import packageFile from '../package.json'
import Loading from './popup/Loading';
// import InteractiveAgency from './home/InteractiveAgency';
import HireNow from './home/HireNow';
import WorldHeritageDay from './blogs/worldheritageblog';
import QuantumComputingRealm from './blogs/QuantumComputinRealm';
import LatestAdvancementVirtualReality from './blogs/LatestAdvancementsVirtualReality';
import HowToWriteCodePoetry from './blogs/HowtoWriteCodePoetry';
import RedirectComponent from './Redirect';
import EvolutionOfMobileApps from './blogs/EvolutionOfMobileApps';
import FutureOfEducationAndLearning from './blogs/FutureOfEducationAndLearning';
import FitnessProduct from './products/fitness_product';
import FiveGBlog from './blogs/5gblog';
import MainPortfolio from './portfolio/mainPortfolio';
import EdgeComputing from './blogs/EdgeComputingBlog';
import Layout1 from './blogs/Layout1';
import Layout2 from './blogs/Layout2';
import Layout3 from './blogs/Layout3';
import DynamicLayout from './blogs/DynamicLayout';
import HealthcarePortfolioDetails from './portfolio/HealthcarePortfolioDetails';
import DigitalBanking from './portfolio/DigitalBanking';
import SocialSync from './portfolio/SocialSync';
import HospitalManagement from './products/HospitalManagement';
import GitexPass from './popup/GitexPass'
import OnlineGrocery from './portfolio/OnlineGrocery';
import Parcel from './portfolio/Parcel';
class Root extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCookieModal: false,
            showCookieConsen: false,
            showGitexModal: false
        };
    }

    componentDidMount() {
        if (!localStorage.getItem('acceptedCookies')) {
            // localStorage.setItem('hasVisited', true);
            this.setState({ showCookieConsen: true })
        }
        setTimeout(() => {
            this.setState({ showGitexModal: true });
        }, 3000);
    }
    handleDecline = () => {
        this.setState({ showCookieConsen: false })
        this.setState({ showCookieModal: false })
    }

    handleGitexDecline = () => {
        this.setState({ showGitexModal: false })
    }

    handleAcceptCookies = () => {
        localStorage.setItem('acceptedCookies', true);
        this.setState({ showCookieModal: false });
        this.setState({ showCookieConsen: false })
    }
    showcookiepolicy = () => {
        this.setState({ showCookieModal: true })
        this.setState({ showCookieConsen: false })
    }

    render() {
        const isProduction = process.env.NODE_ENV === 'production';
        const { version } = packageFile
        return (
            <CacheBuster
                currentVersion={version}
                isEnabled={true}
                isVerboseMode={false}
                loadingComponent={<Loading />}
                metaFileDirectory={'./'}
            >
                <BrowserRouter basename={'/'}>
                    <PageScrollTop>
                        {this.state.showCookieConsen && <CookieConsen
                            onAccept={this.handleAcceptCookies}
                            buttonText="ALLOW COOKIES"
                            className="custom-modal"
                            buttonStyle={{ background: '#f9004d', color: '#ffffff', marginRight: '530px', padding: '10px', borderRadius: '4px' }}
                            location='bottom'
                            buttonClasses="ant-btn"
                            expires={30}
                        >
                            <p>Your experience on this site will be improved by allowing cookies  <Link onClick={this.showcookiepolicy}> Cookie Policy</Link> </p>
                            {/* <h4>When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.</h4> */}
                        </CookieConsen>}

                        {this.state.showCookieModal && <CookieModal onAccept={this.handleAcceptCookies} onDecline={this.handleDecline} />}
                        {this.state.showGitexModal && <GitexPass onAccept={this.handleAcceptCookies} onDecline={this.handleGitexDecline} />}
                        <React.Suspense fallback={<div>loading...</div>} >
                            <Switch>
                                <Route exact path={`${process.env.PUBLIC_URL}/`} component={CreativeHome} />
                                <Route exact path={`${process.env.PUBLIC_URL}/sitemap`} component={Sitemap} />
                                <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About} />
                                <Route exact path={`${process.env.PUBLIC_URL}/contact-us`} component={ContactUs} />
                                <Route exact path={`${process.env.PUBLIC_URL}/get-a-quote`} component={RequestQoute} />
                                <Route exact path={`${process.env.PUBLIC_URL}/our-approach`} component={Our_approch} />
                                <Route exact path={`${process.env.PUBLIC_URL}/careers`} component={Career} />
                                {/* <Route exact path={`${process.env.PUBLIC_URL}/mobile-app-development-services`} component={InteractiveAgency} /> */}
                                <Route exact path={`${process.env.PUBLIC_URL}/hire-now`} component={HireNow} />

                                {/* <Route exact path={`${process.env.PUBLIC_URL}/sliderportfolio`} component={SliderPortfolio} />
                        <Route exact path={`${process.env.PUBLIC_URL}/tabportfolio`} component={TabGallery} />
                        <Route exact path={`${process.env.PUBLIC_URL}/common`} component={CommonAppDemo} />
                        <Route exact path={`${process.env.PUBLIC_URL}/similar`} component={VerbosSimilarApps} />
                        <Route exact path={`${process.env.PUBLIC_URL}/footer`} component={FooterType} />
                        <Route exact path={`${process.env.PUBLIC_URL}/deliver`} component={Delivrable} />
                        <Route exact path={`${process.env.PUBLIC_URL}/download`} component={DownloadAppDemo} />
                        <Route exact path={`${process.env.PUBLIC_URL}/platform`} component={PlatformUsed} />
                        <Route exact path={`${process.env.PUBLIC_URL}/download1`} component={Download1} />
                    <Route exact path={`${process.env.PUBLIC_URL}/download2`} component={Download2} /> */}

                                <Route exact path={`${process.env.PUBLIC_URL}/web-development-career`} component={CareerWebDevelopment} />
                                <Route exact path={`${process.env.PUBLIC_URL}/mobile-app-career`} component={CareerMobileApp} />
                                <Route exact path={`${process.env.PUBLIC_URL}/ux-ui-career`} component={CareerUXUI} />
                                <Route exact path={`${process.env.PUBLIC_URL}/finance-and-hr-career`} component={CareerFinanceHR} />
                                <Route exact path={`${process.env.PUBLIC_URL}/sales-and-marketing-career`} component={CareerSalesMarketing} />
                                <Route exact path={`${process.env.PUBLIC_URL}/customer-support-career`} component={CareerCustomerSupport} />

                                <Route exact path={`${process.env.PUBLIC_URL}/website-development`} component={WebSiteDevelopment} />
                                <Route exact path={`${process.env.PUBLIC_URL}/mobile-development`} component={MobileDevelopment} />
                                <Route exact path={`${process.env.PUBLIC_URL}/support-services`} component={SupportServicesDevelopment} />

                                <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio} />

                                {/* <Route exact path={`${process.env.PUBLIC_URL}/digital-solution-for-civil-documents`} component={DigitalSolutionForCivilDocuments} /> */}
                                {/* <Route exact path={`${process.env.PUBLIC_URL}/doctor-appointment-and-online-pharmacy`} component={CrusierMedicalPortfolio} /> */}
                                {/* <Route exact path={`${process.env.PUBLIC_URL}/scholarship-distribution-system`} component={SocalrshipPortfolioDetail} /> */}
                                {/* <Route exact path={`${process.env.PUBLIC_URL}/online-groceries-ordering-system`} component={GroceryPortfolioDetail} /> */}
                                {/* <Route exact path={`${process.env.PUBLIC_URL}/courier-and-transport-management`} component={CourierPortfolioDetal} /> */}
                                <Route exact path={`${process.env.PUBLIC_URL}/hospital-chain-management-system`} component={HospitalPortfolioDetail} />
                                <Route exact path={`${process.env.PUBLIC_URL}/healthcare-insurance-marketplace`} component={HealthcarePortfolioDetails} />
                                <Route exact path={`${process.env.PUBLIC_URL}/primary-care-management-system`} component={PrimaryCare} />
                                <Route exact path={`${process.env.PUBLIC_URL}/digital-banking-system`} component={DigitalBanking} />

                                {/* <Route exact path={`${process.env.PUBLIC_URL}/ticket-booking-platform`} component={TicketBookingPlatforms} /> */}
                                {/* <Route exact path={`${process.env.PUBLIC_URL}/multilingual-pregnancy-app`} component={MultingualPregnancy} /> */}
                                {/* <Route exact path={`${process.env.PUBLIC_URL}/hotel-booking-platform`} component={HotelPortfolioDetail} /> */}
                                <Route exact path={`${process.env.PUBLIC_URL}/faster-checks`} component={FasterChecks} />
                                <Route exact path={`${process.env.PUBLIC_URL}/grocery`} component={OnlineGrocery} />
                                <Route exact path={`${process.env.PUBLIC_URL}/online-parcel`} component={Parcel} />
                                <Route exact path={`${process.env.PUBLIC_URL}/nursing-platform`} component={NursingPlatform} />
                                <Route exact path={`${process.env.PUBLIC_URL}/service-provider-marketplace`} component={Portery} />
                                <Route exact path={`${process.env.PUBLIC_URL}/online-book`} component={OnlineBook} />
                                <Route exact path={`${process.env.PUBLIC_URL}/pharmacist-marketplace`} component={PharmacistMarketplace} />
                                <Route exact path={`${process.env.PUBLIC_URL}/online-shop`} component={OnlineShop} />
                                <Route exact path={`${process.env.PUBLIC_URL}/researcher-personal-presentation`} component={ResearcherPersonal} />
                                <Route exact path={`${process.env.PUBLIC_URL}/covid-antigen-test-verification`} component={CovidAntigenPortfolioDetails} />
                                <Route exact path={`${process.env.PUBLIC_URL}/delivery-management`} component={DeliveryManagement} />
                                <Route exact path={`${process.env.PUBLIC_URL}/government-financial-scheme-presentation-system`} component={GovermentFinancial} />
                                <Route exact path={`${process.env.PUBLIC_URL}/electronic-medical-record`} component={ElectronicMedical} />
                                <Route exact path={`${process.env.PUBLIC_URL}/e-learning`} component={ELearning} />
                                <Route exact path={`${process.env.PUBLIC_URL}/diet-app`} component={DietApp} />
                                <Route exact path={`${process.env.PUBLIC_URL}/coupons-and-cashback-offer-application`} component={CouponsAndCashback} />
                                <Route exact path={`${process.env.PUBLIC_URL}/doctor-appointment-and-hospital-management`} component={DoctorAppointment} />
                                <Route exact path={`${process.env.PUBLIC_URL}/erp-oil-company-outlets`} component={ERP} />
                                <Route exact path={`${process.env.PUBLIC_URL}/event-management-system`} component={EventManagement} />
                                <Route exact path={`${process.env.PUBLIC_URL}/lead-management-system`} component={LeadManagement} />
                                <Route exact path={`${process.env.PUBLIC_URL}/meditation-app`} component={MedicationApp} />
                                <Route exact path={`${process.env.PUBLIC_URL}/online-food-delivery`} component={OnlineFood} />
                                <Route exact path={`${process.env.PUBLIC_URL}/online-payment-application`} component={OnlinePayment} />
                                <Route exact path={`${process.env.PUBLIC_URL}/school-management-system`} component={SchoolManagement} />
                                <Route exact path={`${process.env.PUBLIC_URL}/asset-management-repairing-service`} component={AssetManagement} />
                                <Route exact path={`${process.env.PUBLIC_URL}/property-showcase`} component={PropertyShowcase} />
                                <Route exact path={`${process.env.PUBLIC_URL}/hajj-application`} component={HajjApplicationPortfolioDetails} />
                                <Route exact path={`${process.env.PUBLIC_URL}/hide-numbers`} component={HideNumbersPortfolioDetails} />
                                <Route exact path={`${process.env.PUBLIC_URL}/memories`} component={MemoriesPortfolioDetails} />
                                <Route exact path={`${process.env.PUBLIC_URL}/powerloom-invoice-management`} component={PowerloomInvoicePortfolioDetails} />
                                <Route exact path={`${process.env.PUBLIC_URL}/service-center-booking-system`} component={ServiceCenterPortfolioDetails} />
                                <Route exact path={`${process.env.PUBLIC_URL}/social-app`} component={SocialAppPortfolioDetails} />
                                <Route exact path={`${process.env.PUBLIC_URL}/vehicle-managment-system`} component={VehicleManagementPortfolioDetails} />
                                <Route exact path={`${process.env.PUBLIC_URL}/survey-application`} component={VolkPortfolioDetails} />
                                <Route exact path={`${process.env.PUBLIC_URL}/choice-making-app`} component={ChoiceMakingPortfolioDetails} />
                                {/* <Route exact path={`${process.env.PUBLIC_URL}/clinic-presentation`} component={ClinicPresentationPortfolioDetails} /> */}
                                <Route exact path={`${process.env.PUBLIC_URL}/discovery-music`} component={DiscoveryMusicPortfolioDetails} />
                                <Route exact path={`${process.env.PUBLIC_URL}/doctor-personal-presentation`} component={DoctorPersonalPortfolioDetails} />
                                {/* <Route exact path={`${process.env.PUBLIC_URL}/habit-forming-app`} component={HabitreePortfolioDetails} /> */}
                                <Route exact path={`${process.env.PUBLIC_URL}/online-laundry`} component={OnlineLaundaryPortfolioDetails} />
                                <Route exact path={`${process.env.PUBLIC_URL}/online-grocery`} component={OnlineKiranaPortfolioDetails} />
                                <Route exact path={`${process.env.PUBLIC_URL}/freelancing-portal`} component={FreelancingPortalPortfolioDetails} />
                                {/* <Route exact path={`${process.env.PUBLIC_URL}/visa-processing-system`} component={UmrahVisaPortfolioDetails} /> */}
                                <Route exact path={`${process.env.PUBLIC_URL}/car-space-rental-platform`} component={CarSpacePortfolio} />
                                <Route exact path={`${process.env.PUBLIC_URL}/influencer-marketplace`} component={InfluencerMarketPlacePortfolio} />
                                <Route exact path={`${process.env.PUBLIC_URL}/social-sync`} component={SocialSync} />
                                <Route exact path={`${process.env.PUBLIC_URL}/job-marketplace`} component={TukusipanPortfolio} />
                                <Route exact path={`${process.env.PUBLIC_URL}/fitness`} component={MatnessAppPortfolio} />
                                <Route exact path={`${process.env.PUBLIC_URL}/btk`} component={BTKPortfolio} />
                                <Route exact path={`${process.env.PUBLIC_URL}/queue-management`} component={QueueManagementPortfolio} />
                                <Route exact path={`${process.env.PUBLIC_URL}/online-travel-agency`} component={OTAPortfolio} />
                                <Route exact path={`${process.env.PUBLIC_URL}/Apparel-ecommerce`} component={CollectionPortfolio} />
                                <Route exact path={`${process.env.PUBLIC_URL}/match-making`} component={QysmatPortfolio} />
                                <Route exact path={`${process.env.PUBLIC_URL}/study-matching`} component={StudyMatchingPortfolio} />
                                <Route exact path={`${process.env.PUBLIC_URL}/home-cleaning-services`} component={HomeCleaningPortfolio} />
                                <Route exact path={`${process.env.PUBLIC_URL}/service-management-resolution-platform`} component={ServiceManagementPortfolio} />
                                <Route exact path={`${process.env.PUBLIC_URL}/social-media-platform`} component={SocialMediaPlatformPortfolio} />
                                <Route exact path={`${process.env.PUBLIC_URL}/market-place`} component={NDashakaPortfolio} />
                                <Route exact path={`${process.env.PUBLIC_URL}/real-estate-portal`} component={RealStatePortfolio} />
                                <Route exact path={`${process.env.PUBLIC_URL}/social-media-marketing-tool`} component={SocialMediaMarketingToolPortfolio} />
                                {/* <Route exact path={`${process.env.PUBLIC_URL}/website_development2`} component={WebSiteDevelopment2} /> */}

                                <Route exact path={`${process.env.PUBLIC_URL}/blogs`} component={Blogs} />
                                <Route exact path={`${process.env.PUBLIC_URL}/data-protection-assessment`} component={DataProtectionAssesment} />
                                {/*  */}
                                {/* <Route exact path='/layout1' component={Layout1}></Route>    
                                <Route exact path='/layout2' component={Layout2}></Route>    
                                <Route exact path='/layout3' component={Layout3}></Route>     */}
                                {/*  */}
                                <Route exact path={`${process.env.PUBLIC_URL}/edge-computing`} component={EdgeComputing} />
                                <Route exact path={`${process.env.PUBLIC_URL}/5g-technology`} component={FiveGBlog} />
                                <Route exact path={`${process.env.PUBLIC_URL}/future-of-education-and-learning`} component={FutureOfEducationAndLearning} />
                                <Route exact path={`${process.env.PUBLIC_URL}/evolution-of-mobile-app`} component={EvolutionOfMobileApps} />
                                <Route exact path={`${process.env.PUBLIC_URL}/how-to-write-a-code-poetry`} component={HowToWriteCodePoetry} />
                                <Route exact path={`${process.env.PUBLIC_URL}/welzo-digital-healthcare`} component={WelzoBlog} />
                                <Route exact path={`${process.env.PUBLIC_URL}/rest-api-blog`} component={RestAPI} />
                                <Route exact path={`${process.env.PUBLIC_URL}/chat-bots-blog`} component={ChatBots} />
                                <Route exact path={`${process.env.PUBLIC_URL}/ipass-blog`} component={iPass} />
                                <Route exact path={`${process.env.PUBLIC_URL}/digital-twin-blog`} component={DigitalTwin} />
                                <Route exact path={`${process.env.PUBLIC_URL}/hyper-automation-blog`} component={HyperAutomation} />
                                <Route exact path={`${process.env.PUBLIC_URL}/li-fi-blog`} component={LifiBlog} />
                                <Route exact path={`${process.env.PUBLIC_URL}/connected-world-blog`} component={ConnectedWorld} />
                                <Route exact path={`${process.env.PUBLIC_URL}/new-tech-blog`} component={NewTech} />
                                <Route exact path={`${process.env.PUBLIC_URL}/metaverse-blog`} component={Metaverse} />
                                <Route exact path={`${process.env.PUBLIC_URL}/health-care-blog`} component={HealthCareBlog} />
                                <Route exact path={`${process.env.PUBLIC_URL}/rise-of-ai`} component={RiseOfAI} />
                                <Route exact path={`${process.env.PUBLIC_URL}/web-development-trend`} component={WebDevelopmentTrend} />
                                <Route exact path={`${process.env.PUBLIC_URL}/hosting-dream-blog`} component={HostingYourDreamsBlog} />
                                <Route exact path={`${process.env.PUBLIC_URL}/agile-methodology`} component={AgileMethodologyBlog} />
                                <Route exact path={`${process.env.PUBLIC_URL}/unlocking-health-with-technology`} component={WorldHealthDay} />
                                <Route exact path={`${process.env.PUBLIC_URL}/world-heritage-day`} component={WorldHeritageDay} />
                                <Route exact path={`${process.env.PUBLIC_URL}/quantum-computing`} component={QuantumComputingRealm} />
                                <Route exact path={`${process.env.PUBLIC_URL}/virtual-reality`} component={LatestAdvancementVirtualReality} />
                                <Route exact path={`${process.env.PUBLIC_URL}/hospital-management-system`} component={HospitalManagement} />


                                <Route exact path={`${process.env.PUBLIC_URL}/industries`} component={Industries} />

                                <Route exact path={`${process.env.PUBLIC_URL}/health-care`} component={HeathCare} />
                                <Route exact path={`${process.env.PUBLIC_URL}/healthtech`} component={HealthTech} />
                                <Route exact path={`${process.env.PUBLIC_URL}/fintech`} component={Fintech} />
                                <Route exact path={`${process.env.PUBLIC_URL}/logistics`} component={Logistics} />
                                <Route exact path={`${process.env.PUBLIC_URL}/ecommerce`} component={eCommerce} />
                                <Route exact path={`${process.env.PUBLIC_URL}/tourism`} component={Tourism} />
                                <Route exact path={`${process.env.PUBLIC_URL}/our-products`} component={OurProductsTechTrailcopy} />
                                {/* <Route exact path={`${process.env.PUBLIC_URL}/trail-demo1`} component={NoorisysTrail} /> */}



                                <Route exact path={`${process.env.PUBLIC_URL}/our-services`} component={OurServices} />

                                <Route exact path={`${process.env.PUBLIC_URL}/tech-stack`} component={TechStack} />

                                <Route exact path={`${process.env.PUBLIC_URL}/fix-a-meeting`} component={FixMeeting} />
                                {/* <Route exact path={`${process.env.PUBLIC_URL}/gitex-booth-location`} component={DubaiStall} /> */}

                                <Route exact path={`${process.env.PUBLIC_URL}/angular`} component={Angular} />
                                <Route exact path={`${process.env.PUBLIC_URL}/ci4`} component={CI4} />
                                <Route exact path={`${process.env.PUBLIC_URL}/dot-net`} component={DotNet} />
                                <Route exact path={`${process.env.PUBLIC_URL}/flutter`} component={Flutter} />
                                <Route exact path={`${process.env.PUBLIC_URL}/java`} component={Java} />
                                <Route exact path={`${process.env.PUBLIC_URL}/kotlin`} component={Kotlin} />
                                <Route exact path={`${process.env.PUBLIC_URL}/laravel`} component={Laravel} />
                                <Route exact path={`${process.env.PUBLIC_URL}/objective-c`} component={ObjectiveC} />
                                <Route exact path={`${process.env.PUBLIC_URL}/python`} component={Python} />
                                <Route exact path={`${process.env.PUBLIC_URL}/react-js`} component={ReactJS} />
                                <Route exact path={`${process.env.PUBLIC_URL}/react-native`} component={ReactNative} />
                                <Route exact path={`${process.env.PUBLIC_URL}/shopify`} component={Shopify} />
                                <Route exact path={`${process.env.PUBLIC_URL}/swift`} component={Swift} />
                                <Route exact path={`${process.env.PUBLIC_URL}/wordpress`} component={WordPress} />
                                <Route exact path={`${process.env.PUBLIC_URL}/node-js`} component={NodeJS} />
                                <Route exact path={`${process.env.PUBLIC_URL}/tourism-company-management-system`} component={TourismPortfolioDetail} />

                                <Route exact path={`${process.env.PUBLIC_URL}/fitness-app`} component={FitnessProduct} />
                                <Route exact path={`${process.env.PUBLIC_URL}/umrah-app`} component={TourismPortfolioDetail} />
                                <Route exact path={`${process.env.PUBLIC_URL}/learning-management`} component={LearningManagement} />
                                <Route exact path={`${process.env.PUBLIC_URL}/service-marketplace`} component={ServiceMarketPlace} />
                                <Route exact path={`${process.env.PUBLIC_URL}/food-delivery`} component={FoodDelivery} />
                                <Route exact path={`${process.env.PUBLIC_URL}/social-platform`} component={SocialMediaPlatform} />
                                <Route exact path={`${process.env.PUBLIC_URL}/school-management`} component={SchoolManagementOurProducts} />
                                <Route exact path={`${process.env.PUBLIC_URL}/doctor-appointment`} component={DoctorAppointmentProduct} />
                                <Route exact path={`${process.env.PUBLIC_URL}/mugenzi-emr`} component={MugenziEMRProduct} />
                                <Route exact path={`${process.env.PUBLIC_URL}/digital-business-card`} component={DigitalBusinessCardProduct} />
                                <Route exact path={`${process.env.PUBLIC_URL}/pharmacy-ordering`} component={PharmacyOrderingProduct} />
                                <Route exact path={`${process.env.PUBLIC_URL}/helpdesk-ticketing-platform`} component={HelpDeskProduct} />
                                <Route exact path={`${process.env.PUBLIC_URL}/transport-management-system`} component={TransportManagementSystem} />

                                {/* Dynamic blog page start */}
                                <Route exact path={`${process.env.PUBLIC_URL}/blog-detail/:postId`} component={DynamicLayout}/>
                                {/* Dynamic blog page end */}

                                <Route exact path={`${process.env.PUBLIC_URL}/become-a-partner`} component={PersonalPortfolio} />
                                <Route exact path={`${process.env.PUBLIC_URL}/referral-program`} component={InteriorLanding} />
                                <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={CreativePortfolio} />
                                {/* Element Layot */}
                                <Route exact path={`${process.env.PUBLIC_URL}/top-mobile-app-development-company-india`} component={RedirectComponent} />
                                <Route path={`${process.env.PUBLIC_URL}/privacy-policy`} component={PrivacyPolicy} />
                                <Route path={`${process.env.PUBLIC_URL}/cookies-policy`} component={CookiesPolicy} />
                                {/* <Route path={`${process.env.PUBLIC_URL}/zoom`} component={() => {
                            window.location.href = 'https://us06web.zoom.us/j/84714059655?pwd=QVY2dGVJS0dOWGJleWRreFJWSXVTdz09#success'; // replace with the link you want to redirect to
                            return null;
                        }} /> */}
                                {/* <Route exact path="/ionic.php">
                                <Redirect to="/flutter" />
                            </Route> */}
                                <Route exact path='/portfoliotab'>
                                    <Redirect to='/portfolio' />
                                </Route>
                                <Route path={`${process.env.PUBLIC_URL}/404`} component={error404} />
                                <Route exact path={`${process.env.PUBLIC_URL}/:portfolioName`} component={MainPortfolio} />
                                <Route path={`${process.env.PUBLIC_URL}/*`} component={error404} />
                                <Route component={error404} />
                            </Switch>
                        </React.Suspense>
                    </PageScrollTop>
                </BrowserRouter>
            </CacheBuster>
        )
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();