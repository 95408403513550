import React from 'react'

function IndustriesClientReveiw({ Testimonial, bgColor }) {
    return (
        <div className={`slider-wrapper ${bgColor}`}>
            {/* Start Single Slide */}
            {Testimonial.map((value, index) => (
                <div className="slide designer-portfolio2 slider-style-3  d-flex align-items-center justify-content-center  rn-slider-height" key={index}>
                    <div className="container">
                        <div className="row align-items-center">

                            <div className="col-lg-7 mt_md--40 mt_sm--40">
                                <div className={`${value.textPosition}`}>
                                    {value.category ? <span>{value.category}</span> : ''}
                                    <span className='text-center'>{value.name}</span>
                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="designer-thumbnail">
                                    <img src={value.img} alt={value.alt} loading='lazy' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {/* End Single Slide */}
        </div>
    )
}

export default IndustriesClientReveiw